/**
 * Abbreviates a large number with a suffix such as K, M, B, T, P, E.
 *
 * @param {number} number - The number to abbreviate.
 * @param {number} [precision=2] - The number of decimal places to round to. Default is 2.
 * @returns {string} The abbreviated number as a string with the appropriate suffix.
 */
export const abbreviateNumber = (number, decimal = 2) => {
  if (number < 1000) {
    return number.toString();
  }

  let lengthOfNumber = ('' + number).length;
  lengthOfNumber -= lengthOfNumber % 3;
  const pow = Math.pow(10, decimal);
  const suffixes = ['', 'k', 'M', 'B', 'T', 'P', 'E'];
  return (
    (number * pow) / Math.pow(10, lengthOfNumber) / pow +
    suffixes[lengthOfNumber / 3]
  );
};

export function findLowerNumber({ num1, num2 }) {
  // Check for null or undefined values
  if (num1 == null && num2 == null) {
    return null;
  } else if (num1 == null) {
    return num2;
  } else if (num2 == null) {
    return num1;
  }

  // Compare the numbers and return the cheapest one
  return Math.min(num1, num2);
}

export function fromBasisPointToPercentage(basisPoint) {
  return basisPoint / 100;
}

export function isNumber(value) {
  return typeof value === 'number' && isFinite(value);
}
