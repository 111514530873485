import axios from '/services/axios';

const createPromptRegistration = (payload) => {
  return axios.post(`/prompt-registration`, payload);
};

const getPromptRegistration = ({ promptId }) => {
  return axios.get(`/prompt-registration/${promptId}`);
};

const getPromptRegistrationList = ({ promptId }) => {
  return axios.get(`/prompt-registrations?promptId=${promptId}`);
};

export { createPromptRegistration, getPromptRegistration, getPromptRegistrationList };
