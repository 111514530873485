import React from 'react';
import { HyperlinkWrapper } from '/design-systems/Organisms/HyperlinkWrapper';
import { mergeClassNames } from '/utils/string';
import { Typography } from '/design-systems/Atoms/Typography';

export const NavMenu = ({
  id,
  url,
  title,
  transparent,
  className,
  newTab = false
}) => {
  return (
    <HyperlinkWrapper id={id} url={url} newTab={newTab}>
      <Typography
        variant="medium"
        weight="medium"
        className={mergeClassNames(
          'px-2',
          transparent
            ? 'text-[#f5f5f5] hover:text-neutral-200'
            : 'text-neutral-800 hover:text-neutral-500 dark:text-neutral-100 dark:hover:text-neutral-100',
          className
        )}
      >
        {title}
      </Typography>
    </HyperlinkWrapper>
  );
};
