/* eslint-disable no-undef */
import React, { useMemo, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faX } from '@fortawesome/pro-regular-svg-icons';

import { Typography } from '../Typography';
import { mergeClassNames } from '/utils/string';
import { Button } from '../Button';

export const DropDownInput = ({
  id,
  value,
  error,
  touched,
  disabled,
  placeholder,
  options,
  onChange,
  onSelect = (options, value) =>
    options?.find((option) => option.value === value),
  wrapperClassName,
  textOnly,
  variant = 'default',
  bodyClassName: bodyClassName_ = '',
  withClear = false,
  onClear = null,
  selectedEl = null
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [v, setV] = useState(value);

  const handleHideMenu = (event) => {
    const path =
      event.path ||
      (event.composedPath && event.composedPath()) ||
      composedPath(event.target);

    if (!path.filter((item) => item.id === id).length) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    setV(value);
  }, [value]);

  useEffect(
    () => {
      window.addEventListener('mousedown', handleHideMenu);

      return () => {
        window.removeEventListener('mousedown', handleHideMenu);
      };
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const _wrapperClassName = mergeClassNames(
    'bg-white dark:bg-neutral-900',
    'border dark:border-none',
    touched && error
      ? 'border-[#fca5a5]'
      : 'border-neutral-300 dark:border-neutral-600',
    variant === 'thin' ? 'px-3 py-2' : 'p-3',
    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
    disabled && 'bg-[#efefef4d]',
    showMenu && 'outline outline-2 outline-black',
    'rounded-lg',
    'select-none'
  );

  const containerClassName = 'flex items-center justify-between gap-2';
  const textClassName = mergeClassNames(
    v ? 'text-neutral-900 dark:text-neutral-100' : 'text-[#9CA3AF]',
    'overflow-hidden text-ellipsis	whitespace-nowrap',
    'flex items-center gap-2 w-full'
  );
  const iconClassName = mergeClassNames(
    'transition-transform transform duration-200'
  );
  const bodyClassName = mergeClassNames(
    'options-dropdown',
    'absolute',
    'mt-3',
    'bg-white dark:bg-neutral-900 overflow-hidden',
    'w-full',
    'border border-neutral-300 dark:border-none',
    showMenu
      ? 'max-h-[240px] opacity-100 overflow-y-auto transition duration-200'
      : 'opacity-0 max-h-0',
    'rounded-lg',

    'drop-shadow-lg',

    'z-[50]',

    bodyClassName_
  );

  const optionClassName = mergeClassNames(
    'w-full',
    'flex items-center',
    'border-[#D1D5DB] dark:border-none',
    'p-3',
    'text-black dark:text-neutral-100',
    'bg-white dark:bg-neutral-900 hover:bg-neutral-50 dark:hover:bg-neutral-800',

    // cursor
    'cursor-pointer'
  );

  const getCurrOption = () => onSelect(options, v);

  const getDisplay = () => getCurrOption()?.title;

  const handleSelect = (option) => {
    onChange?.(option.value);
    setShowMenu(false);
    setV(option.value);
  };

  const showSelectedEl = useMemo(() => withClear && v, [withClear, v]);
  const showSelectInput = useMemo(() => !withClear || !v, [withClear, v]);

  if (disabled && textOnly) {
    return (
      <Typography variant="medium" weight="normal">
        {getDisplay() || '-'}
      </Typography>
    );
  }

  return (
    <div id={id} className="relative">
      {showSelectedEl && (
        <div className="flex w-full items-center justify-between rounded-xl bg-neutral-50 p-3 dark:bg-neutral-800">
          {getCurrOption()?.title}

          {/* button for clearing */}
          <Button
            type="button"
            variant="custom"
            color="custom"
            iconOnly
            rounded
            className="bg-transparent hover:bg-neutral-200 dark:hover:bg-neutral-700"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setV(null);
              onClear?.();
            }}
          >
            <FontAwesomeIcon
              icon={faX}
              className={mergeClassNames(
                'h-3 w-3 text-neutral-500 dark:text-neutral-100'
              )}
            />
          </Button>
        </div>
      )}

      {showSelectInput && (
        <div
          className={`${_wrapperClassName} ${wrapperClassName}`}
          onClick={() => !disabled && setShowMenu((val) => !val)}
        >
          <div className={containerClassName}>
            <div className={textClassName}>
              {getCurrOption()?.icon}
              {getDisplay() || placeholder}
            </div>
            <FontAwesomeIcon
              icon={faChevronDown}
              className={mergeClassNames(
                'h-4 w-4 dark:text-neutral-100',
                showMenu ? 'rotate-180' : '',
                iconClassName
              )}
            />
          </div>
        </div>
      )}

      <div className={bodyClassName}>
        {options.map((option, idx) => (
          <div
            id={`option-${option.value}`}
            key={option.value}
            className={mergeClassNames(
              optionClassName,
              idx === options.length - 1 ? 'border-b-0' : 'border-b'
            )}
            onClick={() => handleSelect(option)}
          >
            <div className="flex w-full items-center gap-2">
              {option.icon}
              {option.title}
            </div>
          </div>
        ))}
      </div>
      {touched && error && (
        <div className="mt-2">
          <Typography variant="small" color="#ef4444">
            {error}
          </Typography>
        </div>
      )}
    </div>
  );
};
