import { Contract } from './contractService';

class RoyaltyEngineService {
  contract;

  constructor(address, abi) {
    this.contract = new Contract(address, abi);
  }

  getRoyaltyView({ tokenId, tokenAddress, value }) {
    return this.contract.read('getRoyaltyView', [tokenAddress, tokenId, value]);
  }
}

export default RoyaltyEngineService;
