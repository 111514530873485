import React from 'react';
import { mergeClassNames } from '/utils/string';
import { Typography } from '/design-systems/Atoms/Typography';

/**
 * variant = 'black' | 'warning'
 */
export const Banner = ({
  id,
  variant = 'black',
  title,
  description,
  descriptionVariant = 'small',
  descriptionLeading = 'leading-normal',
  descriptionWeight = 'normal',
  icon,
  actions
}) => {
  return (
    <div
      className="flex overflow-hidden rounded-xl border border-neutral-300 bg-white"
      id={id}
    >
      <div className={mergeClassNames('h-30 w-1', bannerColors[variant])}></div>
      <div className="flex w-full items-center gap-x-3 p-3">
        {icon}

        {/* content */}
        <div className="flex flex-1 flex-col gap-y-2">
          {title && (
            <Typography variant="medium" weight="medium">
              {title}
            </Typography>
          )}
          {description && (
            <Typography
              weight={descriptionWeight}
              className={descriptionLeading}
              variant={descriptionVariant}
              colorVariant="secondary"
            >
              {description}
            </Typography>
          )}
        </div>

        {actions}
      </div>
    </div>
  );
};

const bannerColors = {
  black: 'bg-black',
  warning: 'bg-[#FBBF24]'
};
