import React, { useEffect, useState, useMemo } from 'react';
import { Typography } from '/design-systems/Atoms/Typography';
import { mergeClassNames } from '/utils/string';
import { objectBlank } from '/utils/object';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

export const Section = ({
  id,
  title,
  description,
  icon,
  children,
  collapsible,
  expanded = false,
  className = '',
  titleClassName,
  titleTextProps: _titleTextProps,
  contentClassName
}) => {
  const [show, setShow] = useState(expanded);
  const titleTextProps = useMemo(() => {
    if (objectBlank(_titleTextProps))
      return {
        variant: 'large',
        weight: 'medium'
      };

    return _titleTextProps;
  }, [_titleTextProps]);

  // update the default expand state
  useEffect(() => {
    setShow(expanded);
  }, [expanded]);

  const showContent = useMemo(() => {
    return !collapsible || show;
  }, [collapsible, show]);

  return (
    <div
      className={mergeClassNames(
        showContent && 'flex flex-col gap-4',
        'transition-all duration-300',
        className
      )}
    >
      {(title || description) && (
        <div
          id={id}
          className={`flex w-full items-center justify-between gap-x-4 ${
            collapsible && 'cursor-pointer select-none'
          } ${titleClassName}`}
          onClick={() => setShow((val) => !val)}
        >
          <div className="flex w-full flex-col gap-2">
            <div className="flex items-center gap-2">
              {icon}
              {title && (
                <div className="">
                  <Typography {...titleTextProps}>{title}</Typography>
                </div>
              )}
            </div>
            {description && show && (
              <div className="">
                <Typography variant="small" colorVariant="secondary">
                  {description}
                </Typography>
              </div>
            )}
          </div>
          {collapsible && (
            <FontAwesomeIcon
              icon={faChevronDown}
              className={mergeClassNames(
                'transform transition-transform duration-300',
                'h-5 w-5 dark:text-neutral-100',
                show ? 'rotate-180' : ''
              )}
            />
          )}
        </div>
      )}
      {showContent ? (
        <div
          className={`max-h-[9999px] w-full opacity-100 transition-all duration-300 ease-in-out ${contentClassName}`}
        >
          {children}
        </div>
      ) : (
        <div
          className={`max-h-0 w-full transition-all duration-300 ease-in-out`}
        ></div>
      )}
    </div>
  );
};
