import React, { useEffect, useState, useMemo } from 'react';
import { SearchIcon, XIcon } from '@heroicons/react/outline';
import { Typography } from '/design-systems/Atoms/Typography';
import { mergeClassNames } from '/utils/string';
import { usePage } from '/hooks/usePage';

export const SearchBar = ({
  id = 'search-input',
  autoFocus = false,
  query,
  onSubmit,
  placeholder = 'Search',
  showQuery = false,
  handleResetQuery,
  variant,
  wrapperClassName = '',
  containerClassName = ''
}) => {
  const [queryString, setQueryString] = useState(query);
  const [submittedValue, setSubmittedValue] = useState();
  const isNav = useMemo(() => variant === 'nav', [variant]);

  const { showTransparentNav, showTransparentNavLight } = usePage();

  const isTransparent = useMemo(
    () => showTransparentNav || showTransparentNavLight,
    [showTransparentNav, showTransparentNavLight]
  );

  const navInputClassName = useMemo(() => {
    if (showTransparentNavLight) {
      return 'bg-neutral-200 dark:bg-neutral-700';
    }
    return 'bg-neutral-100 dark:bg-white/10 dark:text-white dark:placeholder-neutral-100 dark:caret-white dark:backdrop-blur-xl';
  }, [showTransparentNav, showTransparentNavLight]);

  const navSearchIconClassName = useMemo(() => {
    if (showTransparentNavLight) {
      return 'bg-neutral-200 dark:bg-neutral-700';
    }
    return 'bg-neutral-100 dark:bg-transparent';
  }, [showTransparentNav, showTransparentNavLight]);

  useEffect(() => {
    setQueryString(query);
  }, [query]);

  const handleSubmit = (queryString) => {
    onSubmit(queryString);
    setSubmittedValue(queryString);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      handleSubmit(queryString);
    }
  };

  return (
    <div className={`flex w-full flex-row gap-2 ${wrapperClassName}`}>
      <div
        className={mergeClassNames(
          'relative',
          'grow overflow-hidden rounded-lg',
          isNav || 'max-w-sm border border-neutral-300',
          containerClassName
        )}
      >
        <input
          id={id}
          type="text"
          name="query"
          value={queryString}
          className={mergeClassNames(
            'w-full px-4 py-3 font-normal outline-none',
            isNav && navInputClassName,
            isTransparent && 'backdrop-blur-xl'
          )}
          placeholder={placeholder}
          onChange={(e) => {
            setQueryString(e.target.value);
          }}
          onKeyPress={handleKeyPress}
          autoFocus={autoFocus}
        />

        <div
          id={`${id}-button`}
          className={mergeClassNames(
            `absolute right-0 top-0 flex h-full cursor-pointer items-center items-center justify-center px-4 py-2`,
            isNav ? navSearchIconClassName : 'bg-white'
          )}
          onClick={() => handleSubmit(queryString)}
        >
          <SearchIcon className="h-5 w-5 text-neutral-500 dark:text-neutral-300" />
        </div>
      </div>
      {showQuery && submittedValue && (
        <div className="flex max-w-[200px] items-center gap-x-1 rounded-md border border-neutral-300 p-3">
          <Typography variant="medium" weight="medium" className="line-clamp-1">
            {submittedValue}
          </Typography>
          <XIcon
            className="h-5 w-5 min-w-[20px] cursor-pointer"
            onClick={(e) => {
              setQueryString('');
              setSubmittedValue('');
              handleSubmit('');
              handleResetQuery?.(e);
            }}
          />
        </div>
      )}
    </div>
  );
};
