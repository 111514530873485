import React from 'react';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Divider } from '/design-systems/Atoms/Divider';
import { ModalTitleRow } from '/design-systems/Molecules/Modals/ModalTitleRow';
import { Button } from '/design-systems/Atoms/Button';
import { UserRow } from '../../UserRow';
import Router from 'next/router';

export const OfferNoteModal = ({
  open,
  onClose,
  title,
  actionTitle,
  action,
  offer,
  canAcceptOffer = false
}) => {
  return (
    <Modal
      id="offer-note-modal"
      open={open}
      padding="p-6"
      onClose={onClose}
      innerContainerClassName="h-auto"
      isTop={true}
    >
      <div className="flex flex-col gap-4">
        <ModalTitleRow title={title} onClose={onClose} />

        <Divider size="none" />

        <div className="flex w-full flex-col gap-4">
          <UserRow
            className="cursor-pointer"
            user={offer?.User}
            onView={(profileUrl) => {
              Router.push(profileUrl);
            }}
          />
          {offer?.note && (
            <Typography variant="large" className="leading-7">
              {offer?.note}
            </Typography>
          )}
        </div>

        {canAcceptOffer && (
          <Button
            id="offer-note-modal-btn"
            variant="primary"
            color="black"
            onClick={() => {
              action({ offer });
            }}
          >
            {actionTitle}
          </Button>
        )}
      </div>
    </Modal>
  );
};
