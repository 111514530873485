import { createSlice } from '@reduxjs/toolkit';

export const projectSlice = createSlice({
  name: 'project',
  initialState: {
    all: [],
    current: {},
    projects: [],
  },
  reducers: {
    setAll: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.all = action.payload;
    },
    edit: (state, action) => {
      state.all = state.all.map((project) => {
        if (project.id == action.payload.id) {
          return {
            ...action.payload,
          };
        }

        return project;
      });

      state.current = { ...action.payload };
    },
    setCurrentProject: (state, action) => {
      state.current = {
        ...action.payload,
        project: { ...action.payload.project },
      };
      state.projects = state.projects.map((project) =>
        project.id === action.payload.project?.id ? action.payload.project : project
      );
      state.all = state.all.map((project) =>
        project.id === action.payload.project?.id ? action.payload.project : project
      );
    },
    setBookmarkedProjects: (state, action) => {
      state.projects = action.payload;
    },
    publishProject: (state, action) => {
      state.projects = state.projects
        .map((project) => (project.id === action.payload.id ? action.payload : project))
        .sort((projectA, projectB) => projectA.status > projectB.status);

      state.all.push(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAll, edit, setCurrentProject, setBookmarkedProjects, publishProject } = projectSlice.actions;
export default projectSlice.reducer;
