import { useRouter } from 'next/router';

import { ROUTES } from '/config/routes';

export const useAuthRouterHelpers = () => {
  const router = useRouter();

  const navigate = ({ pathname, query, returnUrl }) => {
    const payload = {
      pathname,
    };

    if (query) {
      payload.query = query;
    }

    const storedReturnUrl = localStorage.getItem('returnUrl');
    if (returnUrl) {
      payload.query = {
        ...payload.query,
        returnUrl: returnUrl,
      };
    } else if (storedReturnUrl) {
      payload.query = {
        ...payload.query,
        returnUrl: storedReturnUrl,
      };
    }

    router.push(payload);
  };

  const getReturnUrl = () => localStorage.getItem('returnUrl');

  const navigateToLogin = ({ returnUrl = '', isForce = false } = {}) => {
    const url = returnUrl || (isForce ? router.asPath : '');
    localStorage.setItem('returnUrl', url);
    navigate({ pathname: ROUTES.auth.login() });
  };

  const navigateToSignup = ({ returnUrl = '', isForce = false } = {}) => {
    const url = returnUrl || (isForce ? router.asPath : '');
    localStorage.setItem('returnUrl', url);
    navigate({ pathname: ROUTES.auth.signup() });
  };

  const redirectToReturnURL = () => {
    let returnUrl = router.query?.returnUrl || localStorage.getItem('returnUrl');

    let returnUrlArr;
    let returnPath;
    let returnQueryString;
    let queryObj;
    if (returnUrl) {
      returnUrl = decodeURI(returnUrl);
      returnUrlArr = returnUrl.split('?');
      returnPath = returnUrlArr[0];
      returnQueryString = returnUrlArr[1];
      if (returnQueryString) {
        queryObj = JSON.parse(
          '{"' + returnQueryString.replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
        );
      }
    }

    navigate({
      pathname: returnPath || ROUTES.home(),
      query: queryObj ? queryObj : null,
    });
  };

  const redirectToOnboarding = () => {
    navigate({
      pathname: ROUTES.profile.edit(),
      query: {
        ...(router.query || {}),
        onboarding: true,
      },
      returnUrl: router.query?.returnUrl || '/',
    });
  };

  const redirectToEmailVerify = ({ onboarding = false } = {}) => {
    navigate({
      pathname: ROUTES.auth.verifyEmail(),
      query: {
        ...(router.query || {}),
        onboarding,
      },
      returnUrl: router.query?.returnUrl || '/',
    });
  };

  const redirectToCompleteProfile = () => {
    navigate({
      pathname: ROUTES.auth.completeProfile(),
      query: {
        ...(router.query || {}),
      },
    });
  };

  return {
    navigateToLogin,
    navigateToSignup,
    redirectToReturnURL,
    redirectToOnboarding,
    redirectToEmailVerify,
    redirectToCompleteProfile,
    getReturnUrl,
  };
};
