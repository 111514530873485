import axios from '/services/axios';
import { objectBlank } from '/utils/object';
import { generateQueryParams } from '/utils/url';

const getOwnedAssets = ({
  userId,
  page = 1,
  size = 12,
  walletAddressId,
  galleryId
}) => {
  const query = generateQueryParams({ page, size, walletAddressId, galleryId });
  return axios.get(`/assets/${userId}/owned?${query}`);
};

const getCreatedAssets = ({
  userId,
  page = 1,
  size = 12,
  showHiddenInProfile = true,
  filters
}) => {
  const query = generateQueryParams({
    page,
    size,
    showHiddenInProfile,
    ...filters
  });
  return axios.get(`/assets/${userId}/created?${query}`);
};

const getAssets = ({ page = 1, size = 12, filters }) => {
  let campaigns = '';
  let cleanFilters = filters;
  if (!objectBlank(filters) && filters.campaigns) {
    ({ campaigns, ...cleanFilters } = filters);
    campaigns = campaigns.join(',');
  }
  const query = generateQueryParams({
    page,
    size,
    ...(campaigns && { campaigns }),
    ...cleanFilters
  });
  return axios.get(`/assets?${query}`);
};

const getAsset = ({ assetId, withGallery, withGallerySection }) => {
  const query = generateQueryParams({ withGallery, withGallerySection });
  return axios.get(`/asset/${assetId}?${query}`);
};

const getFeaturedAssets = () => {
  return axios.get('/assets/winners');
};

const likeAsset = ({ assetId }) => {
  return axios.post(`/asset/${assetId}/like`);
};

const unlikeAsset = ({ assetId }) => {
  return axios.delete(`/asset/${assetId}/like`);
};

const createAsset = (payload) => {
  return axios.post(`/asset`, payload);
};

const updateAsset = ({
  assetId,
  title,
  description,
  link,
  usageRightId,
  files
}) => {
  return axios.put(`/asset/${assetId}`, {
    title,
    description,
    link,
    usageRightId,
    files
  });
};

const linkNft = ({ assetId, contractAddress, tokenId, link, chain }) =>
  axios.post(`/asset/link-nft`, {
    assetId,
    contractAddress,
    tokenId,
    link,
    chain
  });

const importCreatedNft = ({ walletAddressId }) =>
  axios.post(`/asset/import-created-nft`, { walletAddressId });

const hideAsset = ({ assetId, hide }) =>
  axios.put(`/asset/${assetId}/hide`, { hide });

const mergeAssets = ({ assetIds, selectedAssetId }) =>
  axios.put('/assets/merge', { assetIds, selectedAssetId });

const getTokenAsset = ({ tokenId, contractAddress, chain }) =>
  axios.get(
    `/token/asset/${tokenId}/${contractAddress}?${generateQueryParams({
      chain
    })}`
  );

const deleteAsset = ({ assetId }) => axios.put(`/asset/${assetId}/delete`);

const getAssetOwners = ({ assetId, size = 12, search }) => {
  const query = generateQueryParams({ size, search });
  return axios.get(`/asset/${assetId}/owners?${query}`);
};
const saveAsset = ({ assetId }) => axios.post(`/asset/${assetId}/save`);

const unsaveAsset = ({ assetId }) => axios.delete(`/asset/${assetId}/save`);

export {
  getAsset,
  getAssets,
  getOwnedAssets,
  getCreatedAssets,
  getFeaturedAssets,
  likeAsset,
  unlikeAsset,
  updateAsset,
  createAsset,
  linkNft,
  importCreatedNft,
  hideAsset,
  mergeAssets,
  getTokenAsset,
  deleteAsset,
  getAssetOwners,
  saveAsset,
  unsaveAsset
};
