import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import XIcon from '@heroicons/react/outline/XIcon';

import { Modal } from '/design-systems/Atoms/Modal';
import { FormInput } from '/design-systems/Atoms/FormInput';
import { Typography } from '/design-systems/Atoms/Typography';
import { Button } from '/design-systems/Atoms/Button';
import { Divider } from '/design-systems/Atoms/Divider';
import { DropDownInput } from '/design-systems/Atoms/DropDownInput';
import { Image } from '/design-systems/Atoms/Image';
import { useSpaces } from '/hooks/useSpaces';
import { mergeClassNames } from '/utils/string';
import { META_ENTITIES } from '/utils/constants';
import { ROUTES } from '/config/routes';
import placeholder from '/images/placeholder.svg';
import { getLogo } from '/utils/image';
import { emptyArray } from '/utils/object';

export const NewGalleryModal = ({ open, onClose, title, galleryType }) => {
  const router = useRouter();
  const currentUser = useSelector((state) => state.user.current);

  const producerId = currentUser?.id;
  const { spaces } = useSpaces({
    enabled: Boolean(producerId),
    producerId,
    size: 50
  });

  const [showcaseScope, setShowcaseScope] = useState('');
  const [spaceId, setSpaceId] = useState(0);

  const isSpaceSelected = useMemo(() => {
    return showcaseScope === GALLERY_SCOPES.space;
  }, [showcaseScope]);

  const isDisabled = useMemo(
    // prettier-ignore
    () => !(
      showcaseScope === GALLERY_SCOPES.standalone ||
      (showcaseScope === GALLERY_SCOPES.space && spaceId)
    ),
    [showcaseScope, spaceId]
  );

  const spaceSelectorOptions = useMemo(() => {
    return (
      spaces?.map((space) => ({
        value: space.id,
        title: (
          <div className="flex w-full flex-row items-center gap-3 rounded-lg">
            <div className="h-10 w-10">
              <Image
                placeholder={placeholder.src}
                className="h-full w-full rounded-lg object-cover"
                src={getLogo(space)}
              />
            </div>
            <Typography variant="small" weight="medium">
              {space.title}
            </Typography>
          </div>
        )
      })) ?? []
    );
  }, [spaces]);

  const handleChange = useCallback(
    (event) => {
      if (event.target.value) {
        setSpaceId(event.target.value);
      }
    },
    [setSpaceId]
  );

  const handleNavigate = useCallback(() => {
    let route = ROUTES.story.new();
    if (galleryType === META_ENTITIES.SHOWCASE) {
      route = ROUTES.showcase.new();
    }

    if (spaceId) {
      route += `?projectId=${spaceId}`;
    }

    router.push(route);
    onClose();
  }, [router, spaceId, galleryType, onClose]);

  return (
    <Modal
      open={open}
      title={title}
      maxWidth={600}
      autoHeight={true}
      onClose={onClose}
    >
      <div className="relative w-full text-center">
        <button
          className="absolute left-0 top-0 bottom-0 cursor-pointer"
          onClick={onClose}
        >
          <XIcon className="h-6 w-6 stroke-[#111827]" />
        </button>
        <Typography heading variant="h6" weight="semibold">
          New {galleryType}
        </Typography>
      </div>
      <Divider className="mt-6 mb-6" size="smallest" />
      <div className="mt-3 flex w-full flex-col gap-3">
        {Object.values(GALLERY_SCOPES).map((galleryScope) => {
          const isSelected = showcaseScope === galleryScope;

          return (
            <div key={galleryScope}>
              <FormInput
                type="radio"
                id={`scope-${galleryScope}-input`}
                name="galleryScope"
                value={galleryScope}
                className="peer hidden"
                inputWithoutWrapper
                checked={isSelected}
              />
              <label
                id={`scope-${galleryScope}`}
                htmlFor={galleryScope}
                className={mergeClassNames(
                  `flex w-full  flex-col justify-between rounded-lg border bg-white p-5`,
                  isSelected
                    ? 'border-2 border-black'
                    : 'cursor-pointer border-neutral-200 hover:bg-neutral-100'
                )}
                onClick={() => setShowcaseScope(galleryScope)}
              >
                <Typography variant="large" weight="medium">
                  {GALLERY_OPTION_TITLES[galleryScope]}
                </Typography>
                <Typography variant="small" colorVariant="secondary">
                  {GALLERY_OPTION_SUBTITLES[galleryScope]}
                </Typography>
              </label>
            </div>
          );
        })}

        {isSpaceSelected && (
          <div
            id="select-space"
            className="mt-3 flex w-full flex-col items-start gap-2"
          >
            <Typography variant="small" weight="medium">
              Space
            </Typography>

            <div className="w-full whitespace-nowrap text-sm">
              {emptyArray(spaceSelectorOptions) ? (
                <Typography
                  variant="small"
                  weight="medium"
                  colorVariant="secondary"
                >
                  No space is available.
                </Typography>
              ) : (
                <DropDownInput
                  id="space"
                  arrowClassName=""
                  bodyClassName={spaceId && '!top-[64px]'}
                  placeholder="Select a Space"
                  options={spaceSelectorOptions}
                  onChange={(value) =>
                    handleChange({
                      target: { value }
                    })
                  }
                />
              )}
            </div>
          </div>
        )}
        <div className="mt-3">
          <Button
            id="scope-selected-next-button"
            variant="primary"
            color="black"
            className="w-full"
            onClick={handleNavigate}
            disabled={isDisabled}
          >
            Next
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const GALLERY_SCOPES = {
  standalone: 'standalone',
  space: 'space'
};

const GALLERY_OPTION_TITLES = {
  [GALLERY_SCOPES.standalone]: 'Add to profile',
  [GALLERY_SCOPES.space]: 'Add to a Space'
};

const GALLERY_OPTION_SUBTITLES = {
  [GALLERY_SCOPES.standalone]: 'Create your personal collection.',
  [GALLERY_SCOPES.space]:
    'Use pieces from an open call. Work With collaborators from your Space.'
};
