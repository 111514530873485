import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Divider } from '/design-systems/Atoms/Divider';
import { MiniAssetCard } from '../../Cards/MiniAssetCard';
import { formatEther } from 'ethers';
import { mergeClassNames, toShortAddress } from '/utils/string';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';
import { formatDuration } from '/utils/date';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import { openLink } from '/utils/url';
import { getChainIdFromChainName, getTransactionLink } from '/utils/web3';
import { ROUTES } from '/config/routes';
import { Team } from '../../Team';
import Router from 'next/router';

/**
 * ShowcaseActivityModal Component
 *
 * This component displays a modal that presents a list of activities. It's typically used to show activities related to a showcase.
 *
 * @param {Array} activities - An array of activity data to be displayed in the modal.
 * @param {boolean} open - A boolean indicating whether the modal is open or closed.
 * @param {function} onClose - A function to handle the modal's close action.
 */

export const ShowcaseActivityModal = ({ activities = [], open, onClose }) => {
  const ActivityColumns = [
    'Unit Price',
    'Quantity',
    'Total',
    'To',
    'Time',
    'Txn.'
  ];
  const ActivityDetails = ({
    classname = '',
    unitPrice,
    quantity,
    total,
    collector,
    time,
    blockchain,
    txHash
  }) => {
    const getDuration = (fromDate) => {
      const duration = formatDuration(fromDate, new Date(), 'minimal');
      return duration.split(' ')?.[0];
    };

    return (
      <>
        <Typography weight="semibold" className={classname}>
          {formatEther(unitPrice)} ETH
        </Typography>
        <Typography weight="semibold" className={classname}>
          {quantity}
        </Typography>
        <Typography weight="semibold" className={classname}>
          {formatEther(total)} ETH
        </Typography>
        <Team
          className={mergeClassNames(classname, 'w-[150%]')}
          users={[collector]}
          handleProfileClick={({ profileId }) => {
            onClose?.();
            Router.push(ROUTES.profile.view({ profileId }));
          }}
          rounded={true}
          size={32}
          noHover
        />
        <Typography className={classname}>{getDuration(time)} ago</Typography>
        {txHash && (
          <div className={mergeClassNames(classname, 'w-[50%]')}>
            <ExternalLinkIcon
              className="hover:text-neutral-black h-5 w-5 cursor-pointer text-neutral-500"
              onClick={() =>
                openLink(
                  getTransactionLink({
                    chainId: getChainIdFromChainName(blockchain),
                    txHash
                  })
                )
              }
            />
          </div>
        )}
      </>
    );
  };

  return (
    <Modal
      id="showcase-activity-modal"
      className="relative"
      wrapperClassName="pt-10"
      autoHeight={true}
      maxWidth={960}
      open={open}
      onClose={onClose}
      innerContainerClassName="!p-0"
    >
      {/* title row */}
      <div className="p-6">
        <div className="relative flex items-center">
          <button
            className="absolute left-0 top-0 flex h-full cursor-pointer items-center"
            onClick={onClose}
          >
            <XIcon className="h-[24px] w-[24px] stroke-[#111827] dark:stroke-neutral-100" />
          </button>

          <div className="flex w-full items-center justify-center gap-1">
            <Typography
              heading
              variant="h6"
              weight="semibold"
              className="tracking-wide text-[#111827]"
            >
              Activities
            </Typography>
          </div>
        </div>

        <div className="my-6 w-full">
          <Divider size="smallest" className="bg-gray-200" />
        </div>

        {activities?.length >= 1 ? (
          <div className="flex h-full w-full flex-col gap-4 overflow-y-auto">
            <div className="flex-cols flex items-center justify-between gap-x-4">
              <Typography
                weight="medium"
                className="w-full tracking-wide text-gray-500"
              >
                Piece
              </Typography>
              <div className={`flex-cols flex w-full items-center gap-x-4`}>
                {ActivityColumns.map((columnName) => (
                  <Typography
                    key={columnName}
                    weight="medium"
                    className={`${
                      columnName === 'Txn.'
                        ? 'w-[50%]'
                        : columnName === 'To'
                        ? 'w-[150%]'
                        : 'w-full'
                    } tracking-wide text-gray-500`}
                  >
                    {columnName}
                  </Typography>
                ))}
              </div>
            </div>
            {activities?.map((activity, index) => {
              const token = activity?.Order?.Token;
              const tokenAsset = token?.AssetMint?.Asset;
              return (
                <div
                  className="flex-cols flex items-center justify-between gap-x-4"
                  key={index}
                >
                  <MiniAssetCard
                    id={token?.id}
                    title={tokenAsset?.title}
                    titleSize="medium"
                    files={tokenAsset?.Files}
                    heading={false}
                    username={tokenAsset?.User?.username}
                    walletAddresses={tokenAsset?.User?.WalletAddresses}
                    twitter={tokenAsset?.User?.twitter}
                    avatar={
                      tokenAsset?.User?.avatar ||
                      tokenAsset?.User?.Files?.[0]?.fileUrl
                    }
                    imageSize="80px"
                    className="w-full tracking-wide"
                    url={`${ROUTES.asset.view(tokenAsset?.id)}?galleryId=${
                      activity?.Order?.galleryId
                    }&galleryType=showcase`}
                    openLinkInNewTab={true}
                  />
                  <div className={`flex-cols flex w-full items-center gap-x-4`}>
                    <ActivityDetails
                      unitPrice={activity?.Order?.priceUnit}
                      quantity={activity?.fulfilledUnits}
                      total={activity?.saleAmount}
                      time={activity?.createdAt}
                      collector={activity?.toUser}
                      blockchain={token?.chain}
                      txHash={activity?.transactionHash}
                      classname="w-full"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <Typography className="my-4 flex w-full justify-center">
            No Activities Available
          </Typography>
        )}
      </div>
    </Modal>
  );
};
