import { getPromptRegistrationList } from '/services/joynApi/users/prompt-registration';
import { objectBlank } from './object';
import { submissionVisibilityOptions } from '.';
import { emptyArray } from '/utils/object';
import { getCover, getAvatar, promptPlaceholderImg } from '/utils/image';

const getAvatarAndId = (user) => {
  return {
    id: user?.id,
    avatar: getAvatar(user)
  };
};

const promptCardImg = (prompt) => {
  const baseUrl = process.env.CLOUDFRONT_URL;

  if (getCover({ Files: prompt.Files })) {
    return {
      src: getCover({ Files: prompt.Files }),
      staticFile: false
    };
  }

  const inDescription = imgsInDescription(prompt);

  if (!emptyArray(inDescription)) {
    return { src: inDescription[0], staticFile: true };
  }

  if (getCover(prompt.Projects?.[0])) {
    return {
      src: getCover(prompt.Projects[0]),
      staticFile: false
    };
  }

  return {
    src: promptPlaceholderImg(prompt),
    staticFile: true,
    placeholder: true
  };
};

const imgsInDescription = (prompt) => {
  const match = prompt?.description?.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
  if (!match) return [];

  return match.map((x) => x.replace(/.*src="([^"]*)".*/, '$1'));
};

const hideSubmissions = (prompt) => {
  return (
    prompt.submissionVisibility ===
      submissionVisibilityOptions.ONLY_THE_PRODUCERS ||
    (prompt.title.toLowerCase().includes('blind gallery') &&
      prompt.title.toLowerCase().includes('private'))
  );
};

const fetchPromptCandidates = async ({
  promptId,
  setCandidates,
  setCandidatesCount
}) => {
  getPromptRegistrationList({ promptId }).then(
    ({
      data: {
        data: { rows, count }
      }
    }) => {
      setCandidates(
        rows
          ?.filter((row) => !objectBlank(row) && !objectBlank(row.User))
          .map((row) => getAvatarAndId(row.User)) || []
      );
      setCandidatesCount(count);
    }
  );
};

const isSubmissionShortlistedForCurrentUser = (
  shortlistedBy = [],
  currentUser
) => {
  return !!shortlistedBy?.find(
    (User) => String(User.id) === String(currentUser?.id)
  );
};

export {
  fetchPromptCandidates,
  imgsInDescription,
  hideSubmissions,
  isSubmissionShortlistedForCurrentUser,
  promptCardImg
};
