import axios from 'axios';
import { IMAGE_EXTENSIONS, VIDEO_EXTENSIONS } from './constants';

export const isImageVideo = (fileType) => {
  return (
    fileType?.match(
      /(.)+.(psd|png|svg|SVG|jpe?g|gif|mkv|mp4|mov|wmv|avi|PNG|JPE?G|GIF|MP4|MOV|WMV|AVI|MKV|PSD|video)$/
    ) ||
    fileType?.match(
      /(psd|png|svg|SVG|jpe?g|gif|mkv|mp4|mov|wmv|avi|PNG|JPE?G|GIF|MP4|MOV|WMV|AVI|MKV|PSD|quicktime|xml)$/
    )
  );
};

export const isHtmlFile = (fileType) => fileType?.match(/(.)+.(html|HTML)$/);

/**
 * @dev Add file type from file extension
 * If `token.media.format` doesnt have correct source type, it should be updated.
 *
 * @example fileType = 'mp4' => return 'video/mp4'
 * @example fileType = 'png' => return `image/png'
 */
export const completeImageVideoExtension = (fileType) => {
  if (!fileType) return;

  const fileExtension = fileType.toLowerCase();
  if (IMAGE_EXTENSIONS.includes(fileExtension)) {
    return `image/${fileExtension}`;
  }

  if (VIDEO_EXTENSIONS.includes(fileExtension)) {
    return `video/${fileExtension}`;
  }

  return fileExtension;
};

// reorder the files so that the first file is an image / video
export const showImageOrVideoAsFirst = (originalFiles) => {
  if (isImageVideo(originalFiles?.[0]?.fileType)) {
    return originalFiles;
  }

  // find the first image / video, then swap
  const firstImgVideoIndex = originalFiles.findIndex((file) =>
    isImageVideo(file?.fileType)
  );
  if (!firstImgVideoIndex) {
    return originalFiles;
  }

  return [originalFiles[firstImgVideoIndex], originalFiles[0]];
};

export const downloadBlobFile = async ({ urls, doc, fileExtension }) => {
  await axios({
    url:
      window.location.protocol === 'https:'
        ? urls + '?no-cache'
        : urls.replace(/^https:\/\//i, 'https://'),
    method: 'GET',
    responseType: 'blob'
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', createFileName(doc, fileExtension));
      document.body.appendChild(link);
      link.click();
    })
    .catch(console.error);
};

export const createFileName = (fileName, fileExtension) => {
  if (fileName.endsWith(fileExtension)) {
    return fileName;
  }
  return `${fileName}.${fileExtension}`;
};

export const filterFiles = (files) => {
  return files
    .filter((file) => !file.uploaded && !file.error)
    .map((file) => ({
      url: file.url,
      fileName: file.fileName,
      type: file.type,
      size: file.size
    }));
};

export const formatFilesForUploader = (files) => {
  return (
    files?.map((file, index) => ({
      ...file,
      url: file.fileUrl,
      type: file.fileType,
      size: file.byteSize,
      fileName: `${index}.${file.fileExtension}`,
      uploaded: file.uploaded || false
    })) || []
  );
};

export const getFileType = (file) => {
  const fileType = file?.fileType?.split('/')[0];
  if (fileType === 'image' || fileType === 'video') {
    return fileType;
  }

  return 'other';
};

// return the first media file type. if there is no image/video file, return 'other'
export const getFirstMediaFileType = (files) => {
  const file = files?.find((file) => isImageVideo(file.fileType));
  return getFileType(file);
};

export const getFirstMediaFile = (files) => {
  return files?.find((file) => isImageVideo(file?.fileType));
};

export const getHtmlFile = (files) =>
  files?.find((file) => isHtmlFile(file?.fileType));

export const hasHtmlFile = (files) => !!getHtmlFile(files);
