import React from 'react';
import { Typography } from '/design-systems/Atoms/Typography';
import { removeSpacesFromText } from '/utils/string';

/**
 * @note Tab Interface
 * {
 *    title: string;          // Tab header title
 *    badge: string;          // Tab header badge title
 *    className: string;      // Tab header external classname
 *    titleClassName: string  // Tab header title external classname
 *    badgeClassName: string: // Badge external classname
 * }
 *
 * @note Tabs Interface
 * {
 *    tabs: Tab[];
 *    className: Tabs external classname
 *    headerClassName: Tabs Header external classname
 *    bodyClassName: Tabs Body external classname
 * }
 */

const getEmptyString = (val) => val || '';

export const TabContainer = ({
  tabs,
  tabIndex,
  className,
  headerClassName,
  headerInnerClassName,
  bodyClassName,
  children,
  onChange
}) => {
  const handleClickTab = (e, index) => {
    // Click event should be prevented to avoid submitting form data automatically
    e.preventDefault();
    onChange(index);
  };

  const renderTabHeader = () => {
    const isActive = (index) => tabIndex === index;
    const getTextColor = (index) =>
      isActive(index)
        ? 'text-neutral-900 dark:text-neutral-100'
        : 'text-neutral-500 group-hover:text-neutral-900 dark:text-neutral-400 dark:group-hover:text-neutral-100';

    return tabs.map((tab, index) => {
      return (
        <div
          id={`gallery-tab-${removeSpacesFromText(tab.title).toLowerCase()}`}
          key={index}
          className="group"
        >
          <div
            className={`flex items-center gap-2 p-4 ${
              !isActive(index) && 'cursor-pointer transition duration-200'
            } ${getEmptyString(tab.className)}`}
            onClick={(e) => handleClickTab(e, index)}
          >
            <Typography
              id={`${tab.key}-tab`}
              weight="medium"
              className={`${getTextColor(index)} ${getEmptyString(
                tab.titleClassName
              )} whitespace-nowrap`}
            >
              {tab.title}
            </Typography>
            {!!tab.badge && (
              <Typography
                className={`${getTextColor(index)} ${getEmptyString(
                  tab.badgeClassName
                )}`}
              >
                {tab.badge}
              </Typography>
            )}
          </div>
          <div
            className={`h-[3px] w-full bg-neutral-900 dark:bg-neutral-100 ${
              isActive(index)
                ? 'opacity-100'
                : 'opacity-0 group-hover:opacity-20'
            } transition duration-200`}
          />
        </div>
      );
    });
  };

  return (
    <div className={`${getEmptyString(className)} flex flex-col gap-6`}>
      <div
        className={`flex overflow-auto border-b border-neutral-300 dark:border-neutral-700 ${getEmptyString(
          headerClassName
        )}`}
      >
        <div className={`flex ${getEmptyString(headerInnerClassName)}`}>
          {renderTabHeader()}
        </div>
      </div>
      {children && (
        <div className={getEmptyString(bodyClassName)}>{children}</div>
      )}
    </div>
  );
};
