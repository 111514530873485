import dayjs from 'dayjs';
import { orderItemTypes } from './constants';

export const buildOrder = ({ order, restrictedByZone = false }) => ({
  restrictedByZone,
  zone: order.zone,
  zoneHash: order.zoneHash,
  orderType: order.orderType,
  allowPartialFills: order.allowPartialFills,
  offer: order.Offers.map(
    ({
      itemType,
      startAmount,
      identifierOrCriteria,
      endAmount,
      orderId,
      fulfilledUnits,
      id,
      ...rest
    }) => ({
      ...rest,
      amount: startAmount,
      identifier: identifierOrCriteria,
      itemType: orderItemTypes[itemType]
    })
  ),
  consideration: order.Considerations.map(
    ({
      itemType,
      identifierOrCriteria,
      startAmount,
      endAmount,
      orderId,
      id,
      fulfilledUnits,
      ...rest
    }) => {
      return {
        ...rest,
        itemType: orderItemTypes[itemType],
        identifier: identifierOrCriteria,
        amount: startAmount
      };
    }
  ),
  startTime: dayjs.utc(order.startTime).unix(),
  endTime: dayjs.utc(order.endTime).unix()
});
