import React from 'react';
import { Typography } from '../Typography';

/**
 * The Icon List item
 * @param {string} title The list item content
 * @param {boolean} active The list item activation
 */
export const IconListItem = ({
  id,
  name = '',
  title,
  icon,
  active,
  onClick,
  thin,
  flat,
  className: _className = '',
  rowClassName
}) => {
  const className = [
    'px-2',
    'flex items-center gap-x-[11px] block cursor-pointer',
    flat ? 'mb-0' : 'mb-[8px]',
    active && 'selected',
    _className
  ].join(' ');

  const handleClick = (e) => onClick?.(e);

  return (
    <div id={id} name={name} className={className} onClick={handleClick}>
      <div
        className={`icon-list-item flex w-full items-center gap-x-2 rounded-lg px-2 py-2 ${rowClassName} hover:bg-neutral-100 dark:hover:bg-neutral-800`}
      >
        {icon && (
          <div className={`p-[3px] ${thin ? 'w-[30px] md:m-auto' : ''}`}>
            {icon}
          </div>
        )}
        <span className={thin ? 'hidden' : ''}>
          <Typography
            variant="small"
            weight={active ? 'bold' : 'normal'}
            overflow="truncate"
          >
            {title}
          </Typography>
        </span>
      </div>
    </div>
  );
};
