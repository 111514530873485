import React, { useMemo } from 'react';

import { Image } from '/design-systems/Atoms/Image';
import { Typography } from '/design-systems/Atoms/Typography';

import { profilePlaceholderImg } from '/utils/image';
import { mergeClassNames } from '/utils/string';

export const UserInfo = ({
  id,
  img,
  name,
  showInRow,
  avatarSize, // px
  staticImage = false,
  transparent = false,
  description,
  title
}) => {
  const avatarSizeClassName = useMemo(() => {
    if (avatarSize) {
      return `h-[${avatarSize}px] w-[${avatarSize}px]`;
    }

    return showInRow ? 'h-8 w-8' : 'h-6 w-6';
  }, [avatarSize, showInRow]);

  return (
    <div className="user-info flex flex-col gap-2">
      {title && <Typography weight="medium">{title}</Typography>}
      <div className="flex max-w-full items-center gap-3 overflow-hidden">
        <Image
          src={img}
          placeholder={profilePlaceholderImg(id).src}
          alt="contributor-img"
          className={mergeClassNames(avatarSizeClassName, 'rounded-full')}
          style={{ objectFit: 'cover' }}
          resolution="s"
          staticFile={staticImage}
        />
        <div className="flex flex-col gap-2 overflow-hidden">
          <Typography
            weight="medium"
            colorVariant={!transparent && 'secondary'}
            className="max-w-full truncate"
          >
            {name}
          </Typography>
          {description && (
            <Typography
              variant="small"
              colorVariant="secondary"
              className="user-description"
            >
              {description}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};
