import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNetwork, useAccount, useSwitchNetwork } from 'wagmi';

import { useModalManager } from '/context/ModalManager';
import { useAuthRouterHelpers } from '/hooks/router';
import { emptyArray, objectBlank } from '/utils/object';
import { DEFAULT_CHAIN_ID } from '/utils/web3';
import { Button } from '/design-systems/Atoms/Button';
import { sleep } from '/utils/index';

export const ChainButton = ({
  id,
  className,
  chainId = DEFAULT_CHAIN_ID,
  handleCallbackAfterSwitchNetwork = true,
  title,
  loading,
  disabled = false,
  onClick,
  btnVariant = 'primary',
  btnColor = 'black',
  blocked = true
}) => {
  const { chain } = useNetwork();
  const { isConnected } = useAccount();
  const { switchNetworkAsync, isLoading: isSwitchingNetwork } =
    useSwitchNetwork();
  const { navigateToLogin } = useAuthRouterHelpers();
  const { showConnectWalletModal, closeConnectWalletModal } = useModalManager();
  const currentUser = useSelector((state) => state.user.current);
  const isAuthorized = !objectBlank(currentUser);
  const hasConnectedWallets = !emptyArray(currentUser?.WalletAddresses);

  const handleConnectWallet = useCallback(() => {
    showConnectWalletModal({
      withUnlockWallet: hasConnectedWallets,
      onClose: closeConnectWalletModal
    });
  }, [hasConnectedWallets, showConnectWalletModal, closeConnectWalletModal]);

  const isCorrectNetworkConnected = useMemo(
    () => chainId === chain?.id,
    [chain?.id, chainId]
  );

  const handleClick = useCallback(async () => {
    if (disabled) return;
    if (!isCorrectNetworkConnected) {
      try {
        await switchNetworkAsync(chainId);
        await sleep(1);
      } catch (error) {
        console.error('switching network cancelled');
        return;
      }
    }
    if (handleCallbackAfterSwitchNetwork || isCorrectNetworkConnected)
      onClick?.();
  }, [
    chainId,
    handleCallbackAfterSwitchNetwork,
    isCorrectNetworkConnected,
    disabled,
    switchNetworkAsync,
    onClick
  ]);

  return (
    <Button
      id={id}
      className={className}
      variant={btnVariant}
      color={btnColor}
      blocked={blocked}
      onClick={
        isConnected
          ? handleClick
          : isAuthorized
          ? handleConnectWallet
          : () => navigateToLogin?.({ isForce: true })
      }
      disabled={isConnected && (isSwitchingNetwork || loading || disabled)}
    >
      {title}
    </Button>
  );
};
