import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { ROUTES } from '/config/routes';
import { useAuthRouterHelpers } from '/hooks/router';
import { usePage } from '/hooks/usePage';

import { getHeaderProfileName } from '/utils/user';
import { getAvatar } from '/utils/image';
import { objectBlank } from '/utils/object';

import {
  MoonIcon,
  SunIcon,
  SearchIcon,
  ArrowLeftIcon
} from '@heroicons/react/outline';
import { mergeClassNames } from '/utils/string';
import { NavMenu } from './NavMenu';
import { ZIndexClassnames } from '/utils/classnames';
import { Image } from '/design-systems/Atoms/Image';
import { SearchBar } from '/design-systems/Atoms/SearchBar';
import { Typography } from '/design-systems/Atoms/Typography';
import { Logo } from '/design-systems/Atoms/Logo';
import logoSq from '/images/logo-sq.png';
import { Button } from '/design-systems/Atoms/Button';
import { UserMenu } from '/design-systems/Molecules/UserMenu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faObjectsColumn } from '@fortawesome/pro-solid-svg-icons';
import { MODALS, useModalManager } from '/context/ModalManager';

export function Header({ isDarkTheme, setIsDarkTheme }) {
  const { showModal, closeModal } = useModalManager();

  const router = useRouter();
  const { navigateToSignup, navigateToLogin } = useAuthRouterHelpers();
  const {
    isHomePage,
    isMarketingPage,
    isAuthPage,
    isUserOnboardingPage,
    isAssetView,
    isManagePromptPage,
    isGallerySubmissionPage,
    isManageAssetPage,
    showTransparentNav,
    showTransparentNavLight,
    isPreviewPage,
    isOraclePage,
    isMaxHeaderWidth,
    is1120
  } = usePage();
  const [isSearching, setIsSearching] = useState(false);

  const currentUser = useSelector((state) => state.user.current);
  const avatar = useMemo(() => getAvatar(currentUser) || '', [currentUser]);

  const noBorder = useMemo(
    () => isHomePage || isMarketingPage || isGallerySubmissionPage,
    [isHomePage, isMarketingPage, isGallerySubmissionPage]
  );

  const searchBarWidth = useMemo(() => {
    return is1120 || '2xl:w-[480px]';
  }, [is1120]);

  const maxContainerWidth = useMemo(() => {
    if (isMaxHeaderWidth) return 'max-w-full';
    if (is1120) return 'max-w-[1120px]';
    return 'max-w-[1280px]';
  }, [isMaxHeaderWidth, is1120]);

  /**
   * If false, hide search, EXPLORE, CREATE buttons on header
   */
  const showDetails = useMemo(
    () =>
      !(
        isManageAssetPage ||
        isManagePromptPage ||
        isOraclePage ||
        isGallerySubmissionPage ||
        isUserOnboardingPage ||
        isAuthPage
      ),
    [
      isManageAssetPage,
      isManagePromptPage,
      isOraclePage,
      isGallerySubmissionPage,
      isUserOnboardingPage,
      isAuthPage
    ]
  );

  const bgClassName = useMemo(() => {
    if (isManageAssetPage || isManagePromptPage) return 'bg-transparent';

    return 'bg-white dark:bg-neutral-900 dark:bg-opacity-80';
  }, [isManageAssetPage, isManagePromptPage]);

  const className = useMemo(() => {
    if (isPreviewPage && !isAssetView) return 'hidden';

    const baseClassName = mergeClassNames(
      'header w-full justify-center h-[68px] px-6 flex items-center',
      isAssetView ? ZIndexClassnames.headerInAssetView : ZIndexClassnames.header
    );
    if (showTransparentNav || showTransparentNavLight) {
      return mergeClassNames(baseClassName, 'absolute bg-transparent');
    }

    return mergeClassNames(
      baseClassName,
      'fixed font-medium',
      !noBorder && 'border-b border-neutral-200 dark:border-neutral-700',
      bgClassName,
      'backdrop-blur-lg dark:backdrop-blur-xl'
    );
  }, [
    bgClassName,
    isPreviewPage,
    isAssetView,
    showTransparentNav,
    showTransparentNavLight,
    noBorder
  ]);

  const onDarkThemeToggleClick = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const [searchBarQuery, setSearchBarQuery] = useState(router.query.q);

  useEffect(() => {
    if (router.isReady) {
      if (router.query.q) {
        setSearchBarQuery(router.query.q);
      } else {
        setSearchBarQuery('');
      }
    }
  }, [router]);

  const onSearchSubmit = (queryString) => {
    let explorePage = ROUTES.explore.pieces;
    if (Object.values(ROUTES.explore).includes(router.pathname)) {
      explorePage = router.pathname;
    }
    const redirectPage = queryString
      ? `${explorePage}?q=${queryString}`
      : explorePage;
    router.push(redirectPage);
  };

  const handleCompose = useCallback(() => {
    showModal({
      modalId: MODALS.COMPOSE_MODAL,
      props: {
        onClose: closeModal
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="header" className={className}>
      {/* container */}
      <div
        className={mergeClassNames(
          `mx-auto flex w-full items-center`,
          maxContainerWidth
        )}
      >
        {/* left-side nav actions */}
        <div
          className={mergeClassNames(
            'flex-grow md:w-1/4',
            isSearching ? 'max-h-0 max-w-0' : 'max-h-full max-w-full',
            'overflow-hidden transition-all duration-200'
          )}
        >
          <div className="flex items-center gap-3 md:gap-6">
            {/* desktop logo */}
            <div className="contents hidden md:block">
              <Link href={ROUTES.home()}>
                <a>
                  <Logo isDarkTheme={isDarkTheme || showTransparentNav} />
                </a>
              </Link>
            </div>

            {/* mobile logo */}
            <div className="contents md:hidden">
              <div className="w-[56px]">
                <Image
                  src={logoSq.src}
                  staticFile
                  className={mergeClassNames(
                    'w-full',
                    (isDarkTheme || showTransparentNav) && 'invert'
                  )}
                />
              </div>
            </div>

            {showDetails && (
              <div className="hidden lg:flex">
                <NavMenu
                  id="header-explore"
                  url={ROUTES.explore.pieces}
                  title="Explore"
                  transparent={showTransparentNav}
                />
              </div>
            )}
          </div>
        </div>

        {/* search bar */}
        {showDetails && (
          <div className="flex hidden flex-grow justify-center sm:block">
            <SearchBar
              query={searchBarQuery}
              showQuery={false}
              onSubmit={onSearchSubmit}
              variant="nav"
              wrapperClassName={mergeClassNames(
                'justify-center mx-auto',
                searchBarWidth,
                showTransparentNav && 'dark'
              )}
            />
          </div>
        )}

        {/* mobile search bar */}
        {showDetails && (
          <div
            className={mergeClassNames(
              'flex',
              isSearching && 'flex-grow',
              'block justify-center sm:hidden',
              showTransparentNav && 'dark'
            )}
          >
            {isSearching ? (
              <div className="flex w-full items-center gap-4">
                <button onClick={() => setIsSearching(false)}>
                  {/* Add your back button icon or text here */}
                  <ArrowLeftIcon className="h-[20px] w-[20px] text-neutral-900 dark:text-neutral-300" />
                </button>

                <SearchBar
                  query={searchBarQuery}
                  showQuery={false}
                  onSubmit={onSearchSubmit}
                  variant="nav"
                  wrapperClassName={mergeClassNames(
                    'justify-center',
                    showTransparentNav && 'dark'
                  )}
                  autoFocus={true}
                />
              </div>
            ) : (
              <button
                className="md:hidden"
                onClick={() => setIsSearching(true)}
              >
                <SearchIcon className="h-5 w-5 text-neutral-500 dark:text-neutral-100" />
              </button>
            )}
          </div>
        )}

        {/* right-side nav actions */}
        {!isSearching && (
          <div
            className={mergeClassNames(
              'flex items-center justify-between px-[2px] sm:flex-grow sm:px-[6px] md:w-1/4',
              isSearching ? 'max-h-0 max-w-0' : 'max-h-full max-w-full',
              'transition-all duration-200'
            )}
          >
            <div className="flex w-full items-center justify-end gap-3">
              {/* dark mode button (disabled) */}
              {false && (
                <Button
                  variant="icon-only"
                  color="neutral"
                  iconOnly
                  rounded
                  id="header-menu-campaigns-link"
                  className="hidden cursor-pointer rounded-lg border text-neutral-800 hover:bg-neutral-50 hover:text-neutral-500 dark:border-neutral-600 dark:text-neutral-100 dark:text-neutral-300 dark:hover:bg-neutral-800 md:block"
                  onClick={onDarkThemeToggleClick}
                >
                  {isDarkTheme ? (
                    <SunIcon className="h-[24px] w-[24px] stroke-neutral-500 dark:stroke-neutral-400" />
                  ) : (
                    <MoonIcon className="h-[24px] w-[24px] stroke-neutral-500 dark:stroke-neutral-400" />
                  )}
                </Button>
              )}

              {showDetails && !objectBlank(currentUser) && (
                <div
                  className={mergeClassNames(
                    'hidden lg:flex',
                    showTransparentNav && 'dark'
                  )}
                >
                  <Button
                    id="header-activity"
                    color="neutral"
                    variant="outlined"
                    onClick={() => router.push(ROUTES.dashboard())}
                  >
                    <FontAwesomeIcon icon={faObjectsColumn} />
                    Dashboard
                  </Button>
                </div>
              )}

              {isAuthPage ? (
                <></>
              ) : objectBlank(currentUser) ? (
                <div
                  className={mergeClassNames(
                    'flex items-center',
                    showTransparentNav && 'dark'
                  )}
                >
                  {/* TODO update to button element */}
                  <div
                    id="login-button"
                    className="cursor-pointer px-4"
                    onClick={() => navigateToLogin({ isForce: true })}
                  >
                    <Typography
                      variant="medium"
                      weight="medium"
                      className="hover:text-neutral-500 dark:text-neutral-100 dark:hover:text-neutral-100"
                    >
                      Login
                    </Typography>
                  </div>
                  <div
                    id="header-level-sign-up-button"
                    className="hidden pl-4 md:block"
                  >
                    <Button
                      variant="primary"
                      color="black"
                      className="min-w-[104px]"
                      onClick={() => navigateToSignup({ isForce: true })}
                    >
                      Sign up
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  {showDetails && (
                    <div
                      className={mergeClassNames(showTransparentNav && 'dark')}
                    >
                      <Button
                        id="header-create-button"
                        onClick={handleCompose}
                        className="hidden sm:block"
                        variant="primary"
                        color="black"
                      >
                        Compose
                      </Button>
                    </div>
                  )}

                  <div className="pl-4" id="user-actions-avatar">
                    <UserMenu
                      avatar={avatar}
                      title={getHeaderProfileName(currentUser)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
