import React, { useMemo } from 'react';
import { XIcon } from '@heroicons/react/outline';

import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Divider } from '/design-systems/Atoms/Divider';
import { FAQSection } from '/design-systems/Molecules/FAQSection';
import { HyperlinkWrapper } from '/design-systems/Organisms/HyperlinkWrapper';
import { ROUTES } from '/config/routes';

/**
 * @description WhereSubmissionModal to guide the user to find their submission
 *
 */
export const WhereSubmissionModal = ({ submissionsType, open, onClose }) => {
  const faqSections = useMemo(() => {
    if (submissionsType === 'Gallery') {
      return [
        {
          title: 'I submitted my Showcase/Story on X. Why can’t I see it?',
          content: (
            <>
              <Typography>
                For curation contests, submissions are updated every several
                hours.
              </Typography>
              <Typography>
                This is different than regular open calls on Joyn, where
                submissions are updated every 15 minutes.
              </Typography>
            </>
          )
        },
        {
          title: 'How do I edit my Showcase/Story submission?',
          content: (
            <>
              <Typography>
                On the Showcase/Story page, you will see the "︙" dropdown next
                the title. Then select "Edit".
              </Typography>
            </>
          )
        },
        {
          title: 'Still need help?',
          content: (
            <Typography>
              If you need assistance, feel free to open a support ticket{' '}
              <HyperlinkWrapper
                url={ROUTES.socials.discord}
                onClick={false}
                newTab={true}
                className="font-medium underline"
              >
                in our Discord
              </HyperlinkWrapper>
              , and we’ll get it sorted out.
            </Typography>
          )
        }
      ];
    }

    return [
      {
        title: 'Did you submit within the last 15 minutes?',
        content: (
          <Typography>
            If so, please check back later — our system refreshes every 15
            minutes.{' '}
          </Typography>
        )
      },
      {
        title: 'Did you attach an image or video file in your tweet?',
        content: (
          <>
            <Typography>If not, you would need to resubmit.</Typography>
            <Typography>
              Also note that GIFs are only supported if submitted through the
              Joyn platform, due to limitation on Twitter’s end.
            </Typography>
          </>
        )
      },
      {
        title: 'Have you checked the “My submissions” section?',
        content: (
          <>
            <Typography>
              If you sign in with the same Twitter account, you should be able
              to see your submissions.
            </Typography>
            <Typography>
              Note: You don’t need a Joyn account to participate via Twitter.
              Signing in just makes things easier.
            </Typography>
          </>
        )
      },
      {
        title: 'Still can’t find it?',
        content: (
          <Typography>
            No worries. You can{' '}
            <HyperlinkWrapper
              url={ROUTES.socials.discord}
              onClick={false}
              newTab={true}
              className="font-medium underline"
            >
              contact our support team in Discord
            </HyperlinkWrapper>
            , and we’ll get it sorted out.
          </Typography>
        )
      }
    ];
  }, [submissionsType]);

  return (
    <Modal
      id="where-submission-modal"
      className="relative"
      wrapperClassName="pt-10"
      autoHeight={true}
      open={open}
      onClose={onClose}
    >
      <button
        className="absolute left-0 top-0 h-6 w-6 cursor-pointer"
        onClick={onClose}
      >
        <XIcon className="h-[24px] w-[24px] stroke-[#111827]" />
      </button>

      <div className="flex items-center justify-center gap-1">
        <Typography
          heading
          variant="h6"
          weight="semibold"
          className="text-[#111827]"
        >
          Where's my submission?
        </Typography>
      </div>

      <Divider size="small" />

      <FAQSection sections={faqSections} />
    </Modal>
  );
};
