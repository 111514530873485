import React, { useCallback, useMemo } from 'react';
import jwt from 'jsonwebtoken';

import { MODALS, useModalManager } from '/context/ModalManager';
import { Button } from '/design-systems/Atoms/Button';
import { WalletIcon } from '/design-systems/Atoms/Icon/wallet';
import { Typography } from '/design-systems/Atoms/Typography';

import { useAuth } from '/hooks/useAuth';
import { initializeForCurrentUser } from '/utils/auth';
import { mergeClassNames } from '/utils/string';
import { useGlobal } from '/context/GlobalManager';

export const ConnectWallet = ({
  id,
  label,
  fullWidth = true,
  noIcon = false,
  btnColor,
  btnVariant,
  withCompleteProfile = false,
  withReturn = false,
  isConnectWallet = true,
  isUnlockWallet = false,
  disabled = false,
  className
} = {}) => {
  const {
    showConnectWalletModal,
    closeConnectWalletModal,
    showModal,
    closeModal
  } = useModalManager();
  const { onRefreshToken } = useAuth();
  const { connectorInProcess } = useGlobal();

  const buttonLabel = useMemo(() => {
    if (isUnlockWallet) return 'Unlock wallet';
    if (!isConnectWallet) return 'Add new wallet';
    return 'Connect wallet';
  }, [isConnectWallet, isUnlockWallet]);

  const handleCloseConnectWalletModal = useCallback(
    (err) => {
      closeConnectWalletModal();
      if (err?.response?.data.data?.userId) {
        showModal({
          modalId: MODALS.MERGE_USERS_MODAL,
          props: {
            onClose: closeModal,
            type: 'wallet',
            duplicateUserId: err.response.data.data.userId,
            onConfirm: async () => {
              await onRefreshToken({
                refreshToken: localStorage.getItem('refreshToken')
              }).then((response) => {
                return initializeForCurrentUser({
                  user: jwt.decode(response.accessToken),
                  jwtToken: response.accessToken,
                  refreshToken: response.refreshToken
                });
              });
              window.location.reload();
            }
          }
        });
      }
    },
    [closeConnectWalletModal, closeModal, onRefreshToken, showModal]
  );

  const handleConnect = useCallback(() => {
    setTimeout(() => {
      showConnectWalletModal({
        withCompleteProfile,
        withReturn,
        withUnlockWallet: isUnlockWallet,
        onClose: handleCloseConnectWalletModal
      });
    }, 200);
  }, [
    showConnectWalletModal,
    withCompleteProfile,
    withReturn,
    isUnlockWallet,
    handleCloseConnectWalletModal
  ]);

  return (
    <Button
      id={id}
      blocked={fullWidth}
      color={btnColor || 'primary'}
      variant={btnVariant || 'secondary'}
      onClick={(e) => {
        e.stopPropagation();
        handleConnect();
        e.preventDefault();
      }}
      className={mergeClassNames('flex items-center gap-x-2', className)}
      disabled={disabled || Boolean(connectorInProcess)}
      loading={Boolean(connectorInProcess)}
    >
      {!noIcon && <WalletIcon className="h-[20px] w-[20px]" />}
      <Typography className="flex w-auto" weight="medium">
        {label || buttonLabel}
      </Typography>
    </Button>
  );
};
