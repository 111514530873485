import { useMemo } from 'react';

import { getUserAvatarDetails } from '/utils/user';
import { makeArrayWithUniqueElements, sortByAsc } from '/utils/array';
import { getCover, getLogo, promptPlaceholderImg } from '/utils/image';
import { createUrlBySlug } from '/utils/url';
import { META_ENTITIES } from '/utils/constants';
import { emptyArray } from '/utils/object';
import { imgsInDescription } from '/utils/prompt';
import { objectBlank } from '/utils/object';

import { ROUTES } from '/config/routes';

export const useGalleryDetails = ({ gallery }) => {
  const viewLink = useMemo(() => {
    if (!gallery) return '';

    const link =
      gallery.customUrl || createUrlBySlug(gallery.title, gallery.slug);

    if (gallery.galleryType === META_ENTITIES.SHOWCASE) {
      return ROUTES.showcase.view(link);
    }

    return ROUTES.story.view(link);
  }, [gallery]);

  const curator = useMemo(() => getUserAvatarDetails(gallery?.User), [gallery]);

  const collaborators = useMemo(() => {
    return gallery?.GalleryCollaborators?.map(
      (collaborator) => collaborator.User
    );
  }, [gallery]);

  const creators = useMemo(() => {
    return makeArrayWithUniqueElements({
      array: (
        gallery?.GallerySections?.map((section) =>
          section.Assets?.map((Asset) => Asset?.User)
        ).flat() ?? []
      ).filter(Boolean),
      attribute: 'id'
    }).map((user) => getUserAvatarDetails(user));
  }, [gallery]);

  const creatorCount = useMemo(() => {
    const userIds = gallery?.GallerySections?.map((section) =>
      section.Assets?.map((Asset) => Asset?.userId)
    );

    if (!userIds) return 0;
    return [...new Set(userIds.flat())].length;
  }, [gallery]);

  const numPieces = useMemo(
    () =>
      gallery?.GallerySections?.map((section) => section.Assets?.length).reduce(
        (acc, curr) => acc + curr,
        0
      ),
    [gallery]
  );

  const projectDetails = useMemo(() => {
    return {
      id: gallery?.Project?.id,
      title: gallery?.Project?.title,
      logo: getLogo(gallery?.Project),
      link: ROUTES.space.viewBySlug(
        gallery?.Project?.title,
        gallery?.Project?.slug
      )
    };
  }, [gallery?.Project]);

  const coverImage = useMemo(() => {
    if (!gallery) return {};

    const file = {
      src: promptPlaceholderImg({ id: gallery.id }),
      staticFile: true
    };

    // get cover image only if gallery is showcase
    if (gallery.galleryType === META_ENTITIES.SHOWCASE) {
      const coverImagePath = getCover(gallery);
      if (coverImagePath) {
        file.src = coverImagePath;
        file.staticFile = false;
        return file;
      }
    }

    const gallerySections = gallery.GallerySections;
    if (emptyArray(gallerySections)) return file;

    // sort sections by order
    const sections = sortByAsc({ array: gallerySections, attribute: 'order' });

    // get first asset image, not video
    for (let i = 0; i < sections.length; i++) {
      if (!emptyArray(sections[i]?.Assets)) {
        for (let j = 0; j < sections[i].Assets.length; j++) {
          const files = sections[i].Assets[j]?.Files;
          if (!emptyArray(files)) {
            const nonVideoFile = files.find(
              (f) => f && f.fileType && !f.fileType.includes('video')
            );

            if (nonVideoFile) {
              file.src = nonVideoFile.fileUrl;
              file.staticFile = false;
              return file;
            }
          }
        }
      }
    }

    // get image from section description
    for (let i = 0; i < sections.length; i++) {
      const imageLink = imgsInDescription(sections[i])?.[0];
      if (imageLink) {
        file.src = imageLink;
        file.staticFile = true;
        return file;
      }
    }

    return file;
  }, [gallery]);

  const startDate = useMemo(
    () => (gallery?.startDate ? new Date(gallery?.startDate) : null),
    [gallery?.startDate]
  );

  const isScheduled = useMemo(
    () => startDate && startDate > new Date(),
    [startDate]
  );

  const withHeroAsset = useMemo(
    () => !objectBlank(gallery?.customData?.heroSection?.heroAsset),
    [gallery?.customData?.heroSection]
  );

  const withRecipients = useMemo(
    () =>
      !emptyArray(gallery?.GalleryCuratorSplits) ||
      !emptyArray(gallery?.curatorSplits),
    [gallery?.GalleryCuratorSplits]
  );

  const canSendRequests = useMemo(() => withRecipients, [withRecipients]);

  return {
    viewLink,
    curator,
    collaborators,
    creators,
    creatorCount,
    numPieces,
    projectDetails,
    coverImage,
    startDate,
    isScheduled,
    withHeroAsset,
    withRecipients,
    canSendRequests
  };
};
