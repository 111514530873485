import Router from 'next/router';
import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNetwork, useAccount } from 'wagmi';
import { IconListItem } from '/design-systems/Atoms/IconListItem';
import { Divider } from '/design-systems/Atoms/Divider';
import { FloatingTooltip } from '/design-systems/Atoms/FloatingTooltip';
import { Avatar } from '/design-systems/Atoms/Avatar';
import { ConnectWallet } from '/design-systems/Molecules/Buttons/ConnectWallet';
import { UserRow } from '/design-systems/Molecules/UserRow';
import { ROUTES } from '/config/routes';
import { useAuth } from '/hooks/useAuth';
import { ZIndexClassnames } from '/utils/classnames';
import { adjustDecimals, mergeClassNames } from '/utils/string';
import { Typography } from '/design-systems/Atoms/Typography';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';
import { toShortAddress } from '/utils/string';
import { getProfileRoute } from '/utils/user';
import { useTokenBalances } from '/hooks/useTokenBalances';
import { useErc20Contract } from '/hooks/on-chain/useErc20Contract';
import { DEFAULT_CHAIN_ID, isSupportedChain } from '/utils/web3';
import { getWrappedNativeTokenContractAddress } from '/utils/contracts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGrid2,
  faCog,
  faCircleQuestion,
  faArrowRightFromBracket,
  faUpRightFromSquare,
  faCircleExclamation
} from '@fortawesome/pro-regular-svg-icons';
import { objectBlank } from '/utils/object';
import { ChainButton } from '../Buttons/ChainButton';
import { Alert } from '/design-systems/Atoms/Alert';
import { useWallet } from '/hooks/useWallet';
import { NetworkIcon } from '/design-systems/Atoms/Icon/network';
import { CHAIN_ID_TO_CHAIN_NAME } from '/utils/constants';

export const UserMenu = ({ avatar }) => {
  const currentUser = useSelector((state) => state.user.current);
  const [showMenu, setShowMenu] = useState(false);
  const { onSignOut } = useAuth();

  const { isConnected } = useAccount();
  const { isConnectedCorrectWallet } = useWallet({
    walletAddresses: currentUser?.WalletAddresses ?? []
  });

  const { chain } = useNetwork();

  const handleSignOutClick = () => {
    onSignOut();
  };

  const handleShowMenu = () => {
    setShowMenu((val) => !val);
  };

  const userAddress = retrieveMainWalletAddressFromWalletAddresses(
    currentUser?.WalletAddresses
  )?.address;

  const isWalletConnectedWithAccount = useMemo(
    () => !objectBlank(currentUser) && !!currentUser.WalletAddresses?.length,
    [currentUser]
  );

  const isCorrectNetworkConnected = useMemo(
    () => isSupportedChain({ chainId: chain?.id }),
    [chain]
  );

  const hasWalletConnectionError = useMemo(
    () => !isConnected || !isWalletConnectedWithAccount,
    [isConnected, isWalletConnectedWithAccount]
  );

  const { balance } = useErc20Contract({
    tokenAddress: getWrappedNativeTokenContractAddress({ chainId: chain?.id }),
    walletAddress: userAddress
  });

  const { nativeCurrencyBalance } = useTokenBalances();

  return (
    <div className="relative select-none">
      <div
        className={`flex cursor-pointer items-center justify-between gap-x-2`}
        onClick={handleShowMenu}
      >
        <div className="flex items-center rounded-full p-[2px] transition duration-200 hover:shadow-lg">
          <Avatar
            id={currentUser?.id}
            resolution="s"
            rounded
            bordered
            src={avatar}
            size={40}
          />
        </div>
      </div>
      {showMenu && (
        <>
          <div
            className={mergeClassNames(
              'absolute right-[0px] mt-2 rounded-xl pb-2',
              'w-full min-w-[300px] overflow-hidden',
              'border border-neutral-200 bg-white shadow-xl dark:border-none dark:bg-neutral-900',
              ZIndexClassnames.userMenu
            )}
          >
            <UserRow
              user={currentUser}
              onView={() => {
                Router.push(getProfileRoute(currentUser));
                setShowMenu(false);
              }}
              className="m-2"
            />
            {hasWalletConnectionError || !isCorrectNetworkConnected ? (
              <>
                {(!isConnected || !isWalletConnectedWithAccount) && (
                  <div className="m-2">
                    <ConnectWallet
                      noIcon={true}
                      isUnlockWallet={!isConnected}
                      isConnectWallet={!isWalletConnectedWithAccount}
                    />
                  </div>
                )}

                {!isCorrectNetworkConnected && !hasWalletConnectionError && (
                  <div className="m-2">
                    <ChainButton
                      title="Switch Network"
                      btnVariant="secondary"
                      btnColor="primary"
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                {!isConnectedCorrectWallet ? (
                  <Alert
                    className="mx-2 mb-2 h-fit p-0"
                    icon={
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className="text-top h-3 w-3 self-start pl-2 text-neutral-500"
                      />
                    }
                  >
                    Your current wallet is different from the main wallet of
                    this account.
                  </Alert>
                ) : (
                  <div className="m-2 rounded-lg border dark:bg-neutral-600">
                    <div className="flex justify-between p-3">
                      <div className="flex flex-col gap-2">
                        <Typography colorVariant="secondary" weight="medium">
                          {nativeCurrencyBalance?.symbol} balance
                        </Typography>
                        <div className="flex items-center gap-2">
                          <NetworkIcon
                            chain={
                              CHAIN_ID_TO_CHAIN_NAME[
                                chain?.id || DEFAULT_CHAIN_ID
                              ]
                            }
                            className="h-4 w-4"
                          />
                          <Typography variant="large" weight="semibold">
                            {nativeCurrencyBalance?.formatted ?? 0}{' '}
                            {nativeCurrencyBalance?.symbol}
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <Typography colorVariant="secondary">
                          {toShortAddress(userAddress, 4)}
                        </Typography>
                      </div>
                    </div>
                    <Divider size="none" className="!bg-neutral-200" />

                    <FloatingTooltip
                      label={`Swap for WETH on Uniswap.`}
                      placement="bottom"
                    >
                      <div
                        onClick={() => {
                          window.open(
                            ROUTES.external.uniswap_weth({
                              chain: chain?.network,
                              chainId: chain?.id
                            }),
                            '_blank'
                          );
                          setShowMenu(false);
                        }}
                        className="flex cursor-pointer flex-col gap-2 p-3 hover:bg-neutral-100"
                      >
                        <div className="flex items-center justify-between">
                          <Typography colorVariant="secondary" weight="medium">
                            WETH balance
                          </Typography>
                          <FontAwesomeIcon
                            icon={faUpRightFromSquare}
                            className="h-3 w-3 text-neutral-500"
                          />
                        </div>
                        <div className="flex items-center gap-x-2">
                          <NetworkIcon
                            chain={
                              CHAIN_ID_TO_CHAIN_NAME[
                                chain?.id || DEFAULT_CHAIN_ID
                              ]
                            }
                            className="h-4 w-4"
                          />
                          <Typography variant="large" weight="semibold">
                            {adjustDecimals(balance, 6) ?? 0} WETH
                          </Typography>
                        </div>
                      </div>
                    </FloatingTooltip>
                  </div>
                )}
              </>
            )}

            <IconListItem
              flat
              icon={
                <FontAwesomeIcon
                  icon={faGrid2}
                  className="h-4 w-4 dark:text-neutral-100"
                />
              }
              title="My Spaces"
              onClick={() => {
                Router.push(ROUTES.space.mySpaces());
                setShowMenu(false);
              }}
            />
            <IconListItem
              flat
              icon={
                <FontAwesomeIcon
                  icon={faCog}
                  className="h-4 w-4 dark:text-neutral-100"
                />
              }
              title="Settings"
              onClick={() => {
                Router.push(ROUTES.settings.index());
                setShowMenu(false);
              }}
            />
            <IconListItem
              flat
              icon={
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  className="h-4 w-4 dark:text-neutral-100"
                />
              }
              title="Help"
              onClick={() => {
                window.open(ROUTES.socials.discord, '_blank');
                setShowMenu(false);
              }}
            />
            <IconListItem
              id="sign-out-button"
              flat
              icon={
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  className="h-4 w-4 dark:text-neutral-100"
                />
              }
              title="Sign out"
              onClick={() => {
                handleSignOutClick();
                setShowMenu(false);
              }}
            />
          </div>
          <div
            className={mergeClassNames(
              'fixed left-0 top-0 h-screen w-screen',
              ZIndexClassnames.userMenuBody
            )}
            onClick={() => setShowMenu(false)}
          />
        </>
      )}
    </div>
  );
};
