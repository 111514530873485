import equal from 'deep-equal';

export const objectBlank = (obj = {}) => !obj || !Object.keys(obj)?.length;

export const emptyArray = (obj = []) => !obj?.length;

/**
 * Deep compare 2 objects
 * @param {number | string | object | array} obj1
 * @param {number | string | object | array} obj2
 * @returns {boolean} returns true if 2 objects are same
 */
export const deepCompare = (obj1, obj2) => {
  return equal(obj1, obj2);
};
