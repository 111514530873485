import React from 'react';

export const Tooltip = ({ children, contentHtml, showTooltip = true }) => {
  return (
    <div className="tooltip-container">
      <div className="tooltip-placeholder">{children}</div>
      {contentHtml && showTooltip && (
        <div className="tooltip-body">
          <div className="ml-6 inline-block w-[16px] overflow-hidden">
            <div className="h-[8px] w-[16px] origin-bottom-left rotate-45 transform bg-[#111827cc]"></div>
          </div>
          <div className="tooltip-content rounded-lg bg-[#111827cc] p-3">
            {contentHtml}
          </div>
        </div>
      )}
    </div>
  );
};
