import React, { useState, useEffect, useMemo } from 'react';

import { getHtmlFile } from '/utils/file';
import { mergeClassNames } from '/utils/string';

export const HtmlDisplay = ({ files, className = '', staticFile = false }) => {
  const [htmlFile, setHtmlFile] = useState(null);

  const iframeSrc = useMemo(
    () =>
      htmlFile?.includes('.arweave.net/') || staticFile
        ? htmlFile
        : `${process.env.CLOUDFRONT_URL}/${htmlFile}`,
    [htmlFile]
  );

  useEffect(() => {
    const file = getHtmlFile(files);
    if (file) {
      setHtmlFile(file.fileUrl);
    }
  }, [files]);

  return (
    <div
      className={mergeClassNames(
        'flex-1 bg-neutral-100 dark:bg-neutral-800',
        className
      )}
    >
      {htmlFile && (
        <iframe
          src={iframeSrc}
          className="h-full min-h-[300px] min-h-[500px] w-full"
        ></iframe>
      )}
    </div>
  );
};
