import React from 'react';
import { Formik } from 'formik';
import { ProductOwner } from '../../Product/ProductOwner';
import { QuantitySelector } from '../../QuantitySelector';
import PlaceholderSVG from '/images/placeholder.svg';
import { objectBlank } from '/utils/object';
import { ChainButton } from '../../Buttons/ChainButton';
import { addressValidation } from '/utils/regex';
import { useCallback, useMemo, useState } from 'react';
import { useAssetContract } from '/hooks/on-chain/useAssetContract';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';
import { useSelector } from 'react-redux';
import { toBigInt } from 'ethers';
import { toast } from 'react-toastify';
import { getTransactionLink } from '/utils/web3';
import { openLink } from '/utils/url';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Image } from '/design-systems/Atoms/Image';
import { FormInput } from '/design-systems/Atoms/FormInput';

export const TransferAssetModal = ({
  open,
  onClose,
  assetImage,
  title,
  assetChainId,
  assetCreator,
  assetCreatorAddress,
  isOneOfMany,
  totalSupply,
  tokenStandard,
  tokenId,
  tokenAddress
}) => {
  const [txHash, setTxHash] = useState('');

  const currentUser = useSelector((state) => state.user.current);
  const owner = useMemo(
    () =>
      retrieveMainWalletAddressFromWalletAddresses(
        currentUser?.WalletAddresses ?? []
      )?.address,
    [currentUser]
  );

  const { safeTransferFrom } = useAssetContract({
    assetChainId,
    tokenId,
    tokenAddress,
    owner,
    tokenStandard
  });

  const handleSubmit = useCallback(
    async ({ to, value }, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const tx = await safeTransferFrom({
          from: owner,
          to,
          tokenId,
          value: toBigInt(value)
        });
        setTxHash(tx.hash);

        toast.success('Your NFT is Transferred successfully.');
        setTxHash('');
        onClose();
      } catch (error) {
        toast(error?.reason || error?.message, { type: 'error' });
        console.error('Transfer NFT error', error);
      } finally {
        setSubmitting(false);
      }
    },
    [tokenId, owner]
  );

  return (
    <Modal
      id="make-offer-modal"
      open={open}
      showCloseIconOnTop
      padding="p-6"
      onClose={onClose}
      innerContainerClassName="h-auto"
      isTop={true}
    >
      <Formik
        enableReinitialize
        initialValues={{ value: 1 }}
        validate={({ to }) => {
          const errors = {};

          if (!to) {
            errors.to = 'Contract address is a required field';
          } else if (!addressValidation(to)) {
            errors.to = 'Please add valid Contract Address';
          }

          return errors;
        }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setValues,
          isSubmitting
        }) => (
          <form
            className="flex flex-col items-center gap-y-6"
            onSubmit={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <Typography heading variant="h6" weight="semibold">
              Transfer NFT
            </Typography>
            <div className="flex w-full items-center">
              <Image
                src={assetImage?.fileUrl}
                fileType={assetImage?.fileType}
                placeholder={PlaceholderSVG.src}
                alt={assetImage?.fileName}
                className="h-[100px] w-[100px] rounded-lg"
                style={{ objectFit: 'cover' }}
                staticFile={assetImage?.staticFile}
              />
              <div className="ml-4">
                {title && (
                  <Typography
                    weight="semibold"
                    variant="small"
                    className="mb-2"
                  >
                    {title}
                  </Typography>
                )}
                {(assetCreator || assetCreatorAddress) && (
                  <ProductOwner
                    owner={assetCreator}
                    address={assetCreatorAddress}
                    tweet={''}
                    withRedirectProfile={!objectBlank(assetCreator)}
                  />
                )}
              </div>
            </div>
            {isOneOfMany && (
              <div className="flex w-full justify-between">
                <div className="flex flex-col gap-2">
                  <Typography variant="medium" weight="medium">
                    Quantity
                  </Typography>
                  <Typography
                    variant="small"
                    className="self-start"
                    colorVariant="secondary"
                  >
                    {totalSupply} available
                  </Typography>
                </div>

                <QuantitySelector
                  quantity={values.value}
                  onQuantityChange={(value) =>
                    handleChange({ target: { name: 'value', value } })
                  }
                  minusButtonDisabled={values.value === 1}
                  plusButtonDisabled={values.value === totalSupply}
                />
              </div>
            )}
            <div className="flex w-full flex-col gap-2">
              <Typography variant="medium" weight="medium">
                To address
              </Typography>
              <div className="align-center flex">
                <FormInput
                  name="to"
                  value={values.to}
                  error={errors.to}
                  touched
                  placeholder="0x...."
                  onChange={handleChange}
                />
              </div>
            </div>
            {txHash && (
              <div
                className="flex cursor-pointer items-center gap-x-1 self-start text-neutral-500 underline"
                onClick={() =>
                  openLink(
                    getTransactionLink({
                      chainId: assetChainId,
                      txHash
                    })
                  )
                }
              >
                View transaction
                <ExternalLinkIcon className="h-[16px] w-[16px]" />
              </div>
            )}
            <ChainButton
              submit
              id="transfer-nft-btn"
              chainId={assetChainId}
              title={
                isSubmitting
                  ? txHash
                    ? 'Awaiting transaction'
                    : 'Transferring...'
                  : 'Transfer'
              }
              disabled={isSubmitting}
              onClick={handleSubmit}
            />
          </form>
        )}
      </Formik>
    </Modal>
  );
};
