import React from 'react';
import { Typography } from '../Typography';

export const ToggleButton = ({ id, label, checked, onChange }) => {
  return (
    <label id={id} className="relative inline-flex items-center">
      <input
        type="checkbox"
        className="peer sr-only"
        checked={checked}
        readOnly
      />
      <div
        onClick={onChange}
        className="peer mr-2 h-6 w-11 cursor-pointer rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[50%] after:h-5 after:w-5 after:-translate-y-[50%] after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-neutral-700 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-neutral-300 dark:bg-neutral-700"
      />
      <Typography
        variant="medium"
        colorVariant="secondary"
        className="cursor-pointer"
        onClick={onChange}
      >
        {label}
      </Typography>
    </label>
  );
};
