import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '/utils/queries';
import { getWalletAddressOwner } from '/services/joynApi/users/wallet-address';
import { useMemo } from 'react';

export const useWalletAddress = (address) => {
  const { isLoading: isLoadingWalletAddressOwner, data: walletAddressOwner } = useQuery(
    [QUERY_KEYS.WALLET_ADDRESS.GET_OWNER, address],
    () => getWalletAddressOwner({ address }),
    {
      select: (res) => res.data.data?.User,
      enabled: !!address,
    }
  );

  return useMemo(
    () => ({ isLoadingWalletAddressOwner, walletAddressOwner }),
    [isLoadingWalletAddressOwner, walletAddressOwner]
  );
};
