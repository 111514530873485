import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import pluralize from 'pluralize';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(advancedFormat);

export const formatDate = (day, format = 'DD/MM/YYYY') => {
  if (!day) return '';
  return dayjs(day).format(format);
};

export const toDate = (day) => {
  return dayjs(day).toDate();
};

export const durationTimestamp = (fromDate, toDate) => {
  return new Date(toDate).getTime() - new Date(fromDate).getTime();
};

export const formatDuration = (fromDate, toDate, format = 'default') => {
  const dis = durationTimestamp(fromDate, toDate);
  if (dis < 0) {
    return 'Invalid date time';
  }

  const d = dayjs.duration(dis);
  let year = d.asYears().toFixed();
  let month = parseInt(d.subtract({ years: year }).asMonths());
  let day = parseInt(d.asDays());
  let hour = parseInt(d.subtract(day, 'days').asHours());
  let minute = parseInt(d.subtract({ days: day, hours: hour }).asMinutes());
  let seconds = parseInt(
    d.subtract({ days: day, hours: hour, minutes: minute }).asSeconds()
  );

  if (format === 'short') {
    // round the number of days, for a better approximation
    if (day >= 1 && hour >= 12) {
      day += 1;
      hour -= 24;
    }

    if (day > 0) {
      return `${day} ${pluralize('days', day)}`;
    } else if (hour > 0) {
      return `${hour} ${pluralize('hours', hour)}`;
    } else {
      return `${minute} ${pluralize('minutes', minute)}`;
    }
  }

  if (format === 'minimal') {
    const day = parseInt(d.subtract({ years: year, months: month }).asDays());
    // Convert date to 'y mo d h m s' format eg, '1y 1mo 1d 1h 13m 8s'.
    return `${year > 0 ? `${year}y ` : ''}${month ? `${month}mo ` : ''}${
      day ? `${day}d ` : ''
    }${hour ? `${hour}h ` : ''}${minute ? `${minute}m ` : ''}${
      seconds ? `${seconds}s ` : ''
    }`;
  }

  return `${day ? `${day}d ` : ''}${hour ? `${hour}hr ` : ''}${
    minute ? `${minute}m` : ''
  }`;
};

export const formatScheduledDate = (day) => {
  const sevenDaysFromNow = dayjs().add(7, 'day');
  if (dayjs(day).isAfter(sevenDaysFromNow)) {
    return dayjs(day).format('MMM D');
  }
  return dayjs(day).format('ddd, h:mm A');
};

export const timeFromNow = (day, number, unit = 'day') => {
  return dayjs(day).add(number, unit);
};

/**
 * Formats a Unix timestamp to an ISO 8601 string using the dayjs library.
 *
 * @param {number} timestamp - The Unix timestamp to be formatted.
 * @param {number} fraction - Optional. The fraction of the ISO string to include.
 *                            If provided, only includes the specified number of time components (e.g., 3:hours, 2:minutes, 1:seconds, 0:miliseconds).
 * @returns {string} - The formatted ISO 8601 string.
 *
 * */

export const formatDateToISOString = (timestamp, fraction = 0) => {
  if (!timestamp) return;
  const ISOString = dayjs.unix(timestamp).format();
  if (fraction) {
    return ISOString.split(':').slice(0, fraction).join(':');
  }
  return ISOString;
};
