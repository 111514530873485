import React from 'react';

import { UsernameAvatarBadge } from '/design-systems/Molecules/UsernameAvatarBadge';
import { TwitterIcon } from '/design-systems/Atoms/Icon/twitter';
import { Typography } from '/design-systems/Atoms/Typography';

import { getAvatar } from '/utils/image';
import { getTwitterUrl } from '/utils/user';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';

export const ProductOwner = ({
  title,
  owner,
  address,
  tweet,
  selected = false,
  withRedirectProfile = true,
  avatarSize = 32,
  fontSize
}) => {
  return (
    <div>
      {title && (
        <Typography
          variant="medium"
          weight="medium"
          colorVariant="secondary"
          className="mb-2"
        >
          {title}
        </Typography>
      )}
      <div className="flex w-full items-center gap-2">
        <UsernameAvatarBadge
          avatarUrl={
            getAvatar(owner || {}) || tweet?.twitterProfileImageUrl || ''
          }
          username={owner?.username || tweet?.twitterUserHandle}
          staticImage={
            !getAvatar(owner || {}) && !!tweet?.twitterProfileImageUrl
          }
          userWalletAddress={
            retrieveMainWalletAddressFromWalletAddresses(owner?.WalletAddresses)
              ?.address ?? address
          }
          avatarSize={avatarSize}
          withRedirectProfile={withRedirectProfile}
          fontSize={fontSize}
        />

        {owner?.twitter && (
          <a
            className="flex items-center gap-1"
            href={getTwitterUrl(owner?.twitter)}
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon className="h-[20px] w-[20px]" />
          </a>
        )}

        {selected && (
          <div className="mr-2 rounded bg-green-100 px-2.5 py-0.5 text-xs font-semibold text-green-800">
            Selected
          </div>
        )}
      </div>
    </div>
  );
};
