import ERC721Service from './ERC721Service';
import { getJoynAssetContract } from '/utils/contracts';
import Abis from '/abis';

class JoynAssetChainService extends ERC721Service {
  constructor(chainId) {
    super(getJoynAssetContract({ chainId }), Abis.JoynAssetABI);
  }

  mint({ uuid, signature, tokenURI, royaltyReceiver, royaltyFee }) {
    return this.contract.write('mint', [
      signature.v,
      signature.r,
      signature.s,
      uuid,
      tokenURI,
      royaltyReceiver,
      royaltyFee
    ]);
  }
}

export default JoynAssetChainService;
