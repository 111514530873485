import React from 'react';
import { mergeClassNames } from '/utils/string';

export const DotBadge = ({ className }) => {
  return (
    <span className={mergeClassNames('relative flex h-2 w-2', className)}>
      <span className="pulse-gradient-dot absolute"></span>
      <span class="absolute right-[-2px] top-[2px] inline-flex h-2 w-2 animate-pulse rounded-full bg-neutral-700"></span>
    </span>
  );
};
