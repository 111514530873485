import axios from '/services/axios';

const createPromptReward = (payload) => {
  return axios.post(`/prompt-reward`, payload);
};

const updatePromptReward = (promptRewardId, payload) => {
  return axios.put(`/prompt-reward/${promptRewardId}`, payload);
};

const deletePromptReward = (promptRewardId) => {
  return axios.delete(`/prompt-reward/${promptRewardId}`);
};

export { createPromptReward, updatePromptReward, deletePromptReward };
