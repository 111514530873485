import React from 'react';
import Head from 'next/head';

export const MetaTags = ({ metadata }) => {
  return (
    <Head>
      <meta name="og:title" property="og:title" content={metadata?.title} />
      <meta
        name="og:description"
        property="og:description"
        content={metadata?.description}
      />
      <meta
        name="og:image"
        property="og:image"
        content={`${metadata?.image}?d=l`}
      />
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  );
};
