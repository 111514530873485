import { useMemo } from 'react';
import { useOffer } from '/hooks/useOffer';
import { useFeatureFlags } from '/hooks/useFeatureFlags';
import { emptyArray, objectBlank } from '/utils/object';

export const useAssetOffers = ({
  tokenIdForListing,
  tokenOwner,
  auctionDetail,
  hasAuction,
  currentUser,
  assetMint,
  assetToken
}) => {
  const { isLazyMintingEnabled } = useFeatureFlags();
  const { offers, highestOffer, refetchOffers, refetchHighestOffer } = useOffer(
    {
      tokenId: tokenIdForListing,
      auctionId: auctionDetail?.id,
      enabled: hasAuction || auctionDetail === null
    }
  );

  const showOfferExpanded = useMemo(() => !emptyArray(offers), [offers]);

  const isAssetAvailableForOffer = useMemo(() => {
    // if user is signed out, we assume user can make an offer
    // on make offer click, will be redirected to login
    if (!currentUser) {
      return true;
    }

    return (
      assetMint?.minted &&
      !tokenOwner?.ownsAllSupply &&
      (!assetToken?.lazyMinted || isLazyMintingEnabled)
    );
  }, [
    tokenOwner,
    currentUser,
    assetMint,
    assetToken?.lazyMinted,
    isLazyMintingEnabled
  ]);

  const isAssetAvailableToAcceptOffer = useMemo(() => {
    return !objectBlank(highestOffer) && tokenOwner?.isOwner;
  }, [highestOffer, tokenOwner?.isOwner]);

  const userHasOffers = useMemo(() => {
    return offers?.some(
      (offer) => Number(offer.userId) === Number(currentUser.id)
    );
  }, [offers, currentUser]);

  return {
    showOfferExpanded,
    offers,
    highestOffer,
    refetchOffers,
    refetchHighestOffer,
    isAssetAvailableForOffer,
    isAssetAvailableToAcceptOffer,
    userHasOffers
  };
};
