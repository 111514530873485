import React, { useMemo } from 'react';

import { WalletConnectionErrorMessages } from '../ErrorMessages/WalletConnectionErrorMessages';

import { toShortAddress } from '/utils/string';
import { useWallet } from '/hooks/useWallet';
import { emptyArray } from '/utils/object';
import { DropDownInput } from '/design-systems/Atoms/DropDownInput';

/**
 * `id` is the Dropdown component's id that is needed for closing dropdown menu when user clicks the outside of dropdown.
 * `walletAddresses` is the list of WalletAddress data.
 * `showFullWalletAddress` is for showing full address. it's showing short address as default.
 * `showFullWidth` is for setting width as 100%.
 * `withSync` is for syncing the connected wallet with wallet address dropdown
 */
export const WalletAddressDropDown = ({
  id = 'wallet-dropdown',
  walletAddresses = [],
  showFullWalletAddress = false,
  showFullWidth = false,
  withSync = false,
  onChange,
}) => {
  const addresses = useMemo(
    () =>
      walletAddresses?.map((walletAddress) => ({
        title: showFullWalletAddress ? walletAddress.address : toShortAddress(walletAddress.address, 4),
        value: walletAddress.id,
      })) || [],
    [walletAddresses, showFullWalletAddress]
  );

  const { walletAddressId, isConnectedCorrectWallet, onChangeWallet } = useWallet({
    walletAddresses,
    withSync,
  });

  const handleChange = (id) => {
    onChangeWallet(id);
    onChange?.(id);
  };

  return (
    <div className={`${showFullWidth ? 'w-full' : showFullWalletAddress ? 'w-[450px]' : 'w-[150px]'}`}>
      <DropDownInput
        id={id}
        placeholder='Select wallet address'
        options={addresses}
        onChange={handleChange}
        value={walletAddressId}
      />
      {withSync && (
        <WalletConnectionErrorMessages
          isConnectedCorrectWallet={isConnectedCorrectWallet}
          hasWallets={!emptyArray(addresses)}
          hasMultipleWallets={Number(addresses?.length) > 1}
        />
      )}
    </div>
  );
};
