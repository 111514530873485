import React, { useMemo } from 'react';
import { SidebarTab } from '/design-systems/Atoms/SidebarTab';
import { mergeClassNames } from '/utils/string';

export const TabsSidebar = ({
  tabs,
  tabIndex,
  onChangeTab,
  variant = 'thin',
  className,
  helpButton,
  style
}) => {
  const isThin = useMemo(() => variant === 'thin', [variant]);
  const isFull = useMemo(() => variant === 'full', [variant]);

  return (
    <div
      className={mergeClassNames(
        'sticky z-[15]',
        'h-[calc(100vh-68px)]',
        'flex min-w-fit flex-col justify-between bg-white dark:bg-neutral-900',
        'border-r border-neutral-200 dark:border-neutral-600',
        isThin ? 'top-[68px]' : 'top-0',
        isFull && 'w-[240px] min-w-[240px] flex-shrink-0 p-3',
        className
      )}
      style={style}
    >
      <div className="flex flex-col">
        {tabs.map((tab, index) => {
          return (
            <SidebarTab
              key={index}
              icon={tab.icon}
              activeIcon={tab.activeIcon}
              title={tab.title}
              isActive={tabIndex === index}
              showBadge={tab.showBadge}
              onClick={() => onChangeTab(index)}
              variant={variant}
            />
          );
        })}
      </div>

      <div className="relative">{helpButton}</div>
    </div>
  );
};
