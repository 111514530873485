import React from 'react';
import { submissionVisibilityOptions } from '.';
import { formatDate } from '/utils/date';
import { isProduction } from '/utils/environment';
import ethIcon from '/images/ethereum-logo.png';
import tezosIcon from '/images/tezos-logo.svg';
import optIcon from '/images/optimism-logo.svg';
import dayjs from 'dayjs';
import { CHAIN_IDS } from './web3';
const GALLERY_SECTION_LIMIT = 3;

const treasuryAddress = '0x18ADe71913c08079826E40DFE23AD982332A4b47';

const joynSupportedProtocols = {
  FOUNDATION: 'FOUNDATION',
  SEAPORT: 'SEAPORT'
};

const UPLOADING_MESSAGE = 'Uploading...';
const MILESTONE_STATUS = {
  inProgress: 'In-progress',
  completed: 'Completed'
};
const RegisterToPromptCTA = "I'm interested";
const headingClasses = {
  1: 'heading-h4 font-semibold',
  2: 'heading-h5 font-semibold',
  3: 'heading-h6 font-semibold',
  4: 'heading-h4',
  5: 'heading-h5',
  6: 'heading-h6'
};

const AVAILABLE_CHAINS = [
  {
    // value: 1,
    value: 'ETHEREUM',
    title: 'Ethereum'
  },
  {
    // value: 137,
    value: 'POLYGON',
    title: 'Polygon (MATIC)'
  },
  {
    // value: 42161,
    value: 'ARBITRUM',
    title: 'Arbitrum'
  }
];

const ASSET_FILTER_TYPES = {
  featured: 'featured',
  pending: 'pending'
};

const ROLE_TYPES = {
  ERC20: 'ERC20',
  ERC721: 'ERC721',
  ERC1155: 'ERC1155',
  ALLOWLIST: 'ALLOWLIST',
  FREE: 'FREE'
};

const AVAILABLE_ROLE_TYPES = [
  {
    value: ROLE_TYPES.ERC721,
    title: 'ERC721'
  },
  {
    value: ROLE_TYPES.ERC1155,
    title: 'ERC1155'
  }
];

const NAV_TABS_LABEL = [
  {
    value: 'Projects',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
        />
      </svg>
    ),
    link: '/'
  },
  {
    value: 'Co-Create',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
        />
      </svg>
    ),
    link: '/co-create'
  },
  {
    value: 'Rewards',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    ),
    link: '/rewards'
  }
];

const PROMPT_REWARD_TYPES = {
  eth: 1,
  other: 2,
  noExplicit: 3,
  pop: 4,
  exhibition: 5
};

const CO_CREATE_PROMPTABLE_TYPES = {
  CONTEST: 'Contest',
  GIVEAWAY: 'Giveaway'
};

const CO_CREATE_TYPES = {
  CONTEST: CO_CREATE_PROMPTABLE_TYPES.CONTEST,
  GIVEAWAY: CO_CREATE_PROMPTABLE_TYPES.GIVEAWAY,
  POLL: 'Poll',
  TASK: 'Task',
  COLLAB: 'Collab',
  SURVEY: 'Survey'
};

const REQUIREMENT_LOGIC_GATES = {
  AND: 'AND',
  OR: 'OR'
};

const CO_CREATE_REQUIREMENT_TYPES = {
  TWITTER: 'twitter',
  DISCORD: 'discord',
  TOKEN: 'role'
};

const PARTICIPATION_TYPES = {
  JOYN: 'joyn',
  TWITTER: 'twitter',
  DISCORD: 'discord'
};

const TWITTER_REQUIREMENT_ACTION_TYPES = {
  FOLLOW: 'follow',
  LIKE: 'like',
  RETWEET: 'retweet'
};

const TWITTER_REQUIREMENT_ACTIONS = [
  {
    value: TWITTER_REQUIREMENT_ACTION_TYPES.FOLLOW,
    title: 'Follow'
  },
  {
    value: TWITTER_REQUIREMENT_ACTION_TYPES.LIKE,
    title: 'Like'
  },
  {
    value: TWITTER_REQUIREMENT_ACTION_TYPES.RETWEET,
    title: 'Retweet'
  }
];

const TWITTER_REQUIREMENT_INITIAL_STATE = {
  type: CO_CREATE_REQUIREMENT_TYPES.TWITTER,
  action: TWITTER_REQUIREMENT_ACTION_TYPES.FOLLOW,
  twitterHandle: '',
  twitterUrl: ''
};

const DISCORD_REQUIREMENT_ACTION_TYPES = {
  JOIN_SERVER: 'joinServer'
};

const DISCORD_REQUIREMENT_ACTIONS = [
  {
    value: DISCORD_REQUIREMENT_ACTION_TYPES.JOIN_SERVER,
    title: 'Join Server'
  }
];

const DISCORD_REQUIREMENT_INITIAL_STATE = {
  type: CO_CREATE_REQUIREMENT_TYPES.DISCORD,
  action: DISCORD_REQUIREMENT_ACTION_TYPES.JOIN_SERVER,
  discordHandle: '',
  serverId: '',
  serverLink: ''
};

const TOKEN_REQUIREMENT_INITIAL_STATE = {
  type: CO_CREATE_REQUIREMENT_TYPES.TOKEN,
  chain: '',
  tokenType: '',
  tokenAddress: '',
  collectionName: '',
  collectionUrl: ''
};

const CO_CREATE_REQUIREMENTABLE_TYPES = {
  TwitterRequirementStep: CO_CREATE_REQUIREMENT_TYPES.TWITTER,
  DiscordRequirementStep: CO_CREATE_REQUIREMENT_TYPES.DISCORD,
  RoleRequirementStep: CO_CREATE_REQUIREMENT_TYPES.TOKEN
};

const PROMPT_STATUSES = {
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
  UNLISTED: 'unlisted'
};

const CO_CREATE_INITIAL_STATE = (promptableType) => {
  switch (promptableType) {
    case CO_CREATE_PROMPTABLE_TYPES.GIVEAWAY:
      return {
        title: '',
        startDate: '',
        endDate: formatDate(
          new Date().setDate(new Date().getDate() + 7),
          'YYYY-MM-DDTHH:mm'
        ),
        tweetUrls: [''],
        status: PROMPT_STATUSES.UNPUBLISHED,
        submissionVisibility: submissionVisibilityOptions.ANYONE,
        ...(promptableType === CO_CREATE_PROMPTABLE_TYPES.CONTEST
          ? { usageRightId: '' }
          : {}),
        ...(promptableType === CO_CREATE_PROMPTABLE_TYPES.GIVEAWAY
          ? { maxSubmissionsPerPerson: 1 }
          : {}),
        ...(promptableType === CO_CREATE_PROMPTABLE_TYPES.CONTEST
          ? { hashtags: [''] }
          : {})
      };
    case CO_CREATE_PROMPTABLE_TYPES.CONTEST:
      return {
        title: '',
        startDate: '',
        endDate: formatDate(
          new Date().setDate(new Date().getDate() + 7),
          'YYYY-MM-DDTHH:mm'
        ),
        submissionVisibility: submissionVisibilityOptions.ANYONE,
        tweetUrls: [''],
        allowMinting: true,
        status: PROMPT_STATUSES.UNPUBLISHED,
        ...(promptableType === CO_CREATE_PROMPTABLE_TYPES.CONTEST
          ? { usageRightId: '' }
          : {}),
        ...(promptableType === CO_CREATE_PROMPTABLE_TYPES.GIVEAWAY
          ? { maxSubmissionsPerPerson: 1 }
          : {}),
        ...(promptableType === CO_CREATE_PROMPTABLE_TYPES.CONTEST
          ? { hashtags: [''] }
          : {})
      };
    default:
      return {};
  }
};

const PROMPT_REWARD_INITIAL_STATE = (rewardType) => {
  switch (rewardType) {
    case PROMPT_REWARD_TYPES.eth:
      return {
        unit: null,
        rewardAmount: '',
        rewardTypeId: PROMPT_REWARD_TYPES.eth
      };

    case PROMPT_REWARD_TYPES.other:
      return {
        title: '',
        unit: null,
        rewardTypeId: PROMPT_REWARD_TYPES.other
      };

    case PROMPT_REWARD_TYPES.exhibition:
      return {
        title: '',
        unit: null,
        rewardTypeId: PROMPT_REWARD_TYPES.exhibition
      };

    default:
      return {
        unit: null,
        rewardTypeId: PROMPT_REWARD_TYPES.noExplicit
      };
  }
};

const PROMPT_REWARD_TYPE_NAMES = {
  [PROMPT_REWARD_TYPES.eth]: 'ETH',
  [PROMPT_REWARD_TYPES.other]: 'Other',
  [PROMPT_REWARD_TYPES.noExplicit]: 'No Explicit',
  [PROMPT_REWARD_TYPES.pop]: 'POP (Proof of Participation) NFT',
  [PROMPT_REWARD_TYPES.exhibition]: 'Exhibition spot'
};

const REWARD_TYPE_SELECTIONS = [
  {
    value: PROMPT_REWARD_TYPES.pop,
    title: PROMPT_REWARD_TYPE_NAMES[PROMPT_REWARD_TYPES.pop]
  },
  {
    value: PROMPT_REWARD_TYPES.eth,
    title: PROMPT_REWARD_TYPE_NAMES[PROMPT_REWARD_TYPES.eth]
  },
  {
    value: PROMPT_REWARD_TYPES.exhibition,
    title: PROMPT_REWARD_TYPE_NAMES[PROMPT_REWARD_TYPES.exhibition]
  },
  {
    value: PROMPT_REWARD_TYPES.other,
    title: PROMPT_REWARD_TYPE_NAMES[PROMPT_REWARD_TYPES.other]
  }
];

const USAGE_RIGHTS = {
  cc: 1,
  transfer: 2
};

const SORT_ORDERS = {
  Oldest: 'asc',
  Newest: 'desc',
  Random: 'random'
};

const SORT_LABELS = {
  asc: 'Oldest',
  desc: 'Newest'
};

const ASSET_SORT_OPTION_VALUES = {
  OLDEST: 'oldest',
  NEWEST: 'newest',
  RANDOM: 'random'
};

const ASSET_SORT_OPTION_LABELS = {
  OLDEST: 'Oldest',
  NEWEST: 'Newest',
  RANDOM: 'Random'
};

const EXPLORE_ASSET_DEFAULT_SORT_OPTION = ASSET_SORT_OPTION_VALUES.RANDOM;

const CO_CREATE_FILTER_LABELS = {
  ALL: 'All',
  PROMPTS: 'Prompts',
  POLLS: 'Polls'
};

const CO_CREATE_FILTERS = [
  {
    value: 'All'
  },
  {
    value: 'Contests'
  },
  {
    value: 'Giveaways'
  },
  {
    value: 'Polls'
  }
];

const ROLE_NAMES = {
  everyone: 'Everyone'
};

const FEATURES = {
  pollVoting: 'pollVoting',
  promptSubmission: 'promptSubmission'
};

const DRAW_STATUSES = {
  confirmed: 'confirmed',
  unconfirmed: 'unconfirmed'
};

const DISCORD_URL = 'https://discord.com/invite/fku6Zyjd4t';
const TWITTER_URL = 'https://twitter.com/joynxyz';
const MAX_NUMBER_POLL_OPTIONS = 6;

const TWITTER_SUBMISSION_ACTION_TYPES = {
  HASHTAG: 'hashtag',
  REPLY: 'reply'
};

const TWITTER_SUBMISSION_ACTIONS = [
  {
    value: TWITTER_SUBMISSION_ACTION_TYPES.REPLY,
    title: 'Replies with attachment'
  },
  {
    value: TWITTER_SUBMISSION_ACTION_TYPES.HASHTAG,
    title: 'Hashtag'
  }
];

const EMAIL_VERIFICATION_CODE_LENGTH = 6;

const WINNER_SELECTION_TYPES = {
  byTeam: 1,
  randomly: 2
};

const WINNER_SELECTION_NAMES = {
  [WINNER_SELECTION_TYPES.byTeam]: 'By the team',
  [WINNER_SELECTION_TYPES.randomly]: 'Randomly'
};

const WINNER_SELECTIONS = [
  {
    value: WINNER_SELECTION_TYPES.byTeam,
    title: WINNER_SELECTION_NAMES[WINNER_SELECTION_TYPES.byTeam]
  },
  {
    value: WINNER_SELECTION_TYPES.randomly,
    title: WINNER_SELECTION_NAMES[WINNER_SELECTION_TYPES.randomly]
  }
];

const POP_CLAIM_TX_STATUSES = {
  validating: 'validating',
  confirming: 'confirming',
  confirmed: 'confirmed'
};

const BLOCK_EXPLORERS = {
  local: 'https://mumbai.polygonscan.com',
  development: 'https://mumbai.polygonscan.com',
  staging: 'https://mumbai.polygonscan.com',
  test: 'https://mumbai.polygonscan.com',
  production: 'https://polygonscan.com'
};

const MARKETPLACES = {
  opensea: 'OpenSea',
  looksrare: 'LooksRare',
  objkt: 'objkt.com'
};

const BLOCK_EXPLORER = () => {
  return isProduction() ? BLOCK_EXPLORERS.production : BLOCK_EXPLORERS.test;
};

const PAGE_SIZE = 12;

const BLOCKCHAINS = {
  ETHEREUM: 'ETHEREUM',
  TEZOS: 'TEZOS',
  OPTIMISM: 'OPTIMISM'
};

const BLOCKCHAIN_ACTIONS = [
  {
    value: BLOCKCHAINS.ETHEREUM,
    title: 'Ethereum'
  },
  {
    value: BLOCKCHAINS.TEZOS,
    title: 'Tezos'
  },
  {
    value: BLOCKCHAINS.OPTIMISM,
    title: 'Optimism'
  }
];

const CHAIN_ID_TO_CHAIN_NAME = {
  [CHAIN_IDS.mainnet]: BLOCKCHAINS.ETHEREUM,
  [CHAIN_IDS.sepolia]: BLOCKCHAINS.ETHEREUM,
  [CHAIN_IDS.optimism]: BLOCKCHAINS.OPTIMISM,
  [CHAIN_IDS.optimismGoerli]: BLOCKCHAINS.OPTIMISM
};

const CHAIN_ICONS = {
  [BLOCKCHAINS.ETHEREUM]: ethIcon.src,
  [BLOCKCHAINS.TEZOS]: tezosIcon.src,
  [BLOCKCHAINS.OPTIMISM]: optIcon.src
};

const TOKEN_STANDARDS = {
  ERC721: 'ERC721',
  ERC1155: 'ERC1155'
};

const MAX_FILE_SIZE = 200000000;

const META_ENTITIES = {
  PROJECT: 'project',
  CONTEST: 'contest',
  GIVEAWAY: 'giveaway',
  /** Galleries */
  GALLERY: 'gallery',
  STORY: 'story',
  SHOWCASE: 'showcase',
  SINGLE_PIECE_DROP: 'single-piece-drop',

  ASSET: 'asset',
  PROMPT_SUBMISSION: 'prompt-submission',
  PROFILE: 'profile'
};

const HASHTAG_MINIMUM_LENGTH = 4;

const VIDEO_EXTENSIONS = [
  'mp4',
  'quicktime',
  'mov',
  'avi',
  'wmv',
  'flv',
  'webm',
  'mkv',
  'ogg',
  'mpg',
  'mpeg',
  'rm',
  'ram',
  'swf'
];

const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'gif', 'svg', 'png', 'psd'];

const LAUNCH_DARKLY_KINDS = {
  profile: 'profile',
  campaign: 'campaign',
  space: 'space',
  gallery: 'gallery',
  asset: 'asset',
  submission: 'submission',
  user: 'user'
};

const PROMPT_SUBMISSION_CARD_ACTION_NAMES = {
  MERGE: 'merge',
  HIDE: 'hide'
};

const orderItemTypes = {
  NATIVE: 0,
  ERC20: 1,
  ERC721: 2,
  ERC1155: 3,
  ERC721_WITH_CRITERIA: 4,
  ERC1155_WITH_CRITERIA: 5
};

const MARKETPLACE_FEE_STR = '4.20';
const DEFAULT_CURATOR_FEE_PERCENTAGE = '4.20';
const MARKETPLACE_FEE = 4.2 * 100;
const MARKETPLACE_FREE_LISTING_FEE = 0.00069;

const DURATIONS = {
  ONE_DAY: '1 Day',
  THREE_DAYS: '3 Days',
  SEVEN_DAYS: '7 Days',
  ONE_MONTH: '1 Month',
  THREE_MONTHS: '3 Months',
  SIX_MONTHS: '6 Months'
};

const OFFER_DURATIONS = [
  {
    value: dayjs().add(1, 'day').unix(),
    title: '1 Day'
  },
  {
    value: dayjs().add(3, 'day').unix(),
    title: '3 Days'
  },
  {
    value: dayjs().add(7, 'day').unix(),
    title: '7 Days'
  },
  {
    value: dayjs().add(1, 'month').unix(),
    title: '1 Month'
  }
];

const AUCTION_TYPE_ID = {
  TRIGGER_ON_FIRST_BID: 'trigger-on-first-bid',
  DEFAULT: 'default'
};

const DASHBOARD_SECTION_NUM_ITEMS = 3;
const LISTING_DURATIONS = [
  ...OFFER_DURATIONS,
  {
    value: dayjs().add(3, 'month').unix(),
    title: '3 Months'
  },
  {
    value: dayjs().add(6, 'month').unix(),
    title: '6 Months'
  }
];

const COLLAB_REQUEST_STATUS = {
  RECEIVED: 'Received',
  SENT: 'Sent',
  ARCHIVED: 'Archived'
};

const COLLAB_REQUEST_TAB_IDS = {
  RECEIVED: 0,
  SENT: 1,
  ARCHIVED: 2
};

const ACTIVITY_ACTIONS = {
  LISTING: 'listing',
  OFFER: 'offer'
};

const ORDER_STATUSES = {
  CREATED: 'created',
  FULFILLED: 'fulfilled',
  PARTIALLY_FULFILLED: 'partiallyFulfilled',
  CANCELLED: 'cancelled'
};

const TOKEN_ACTIVITY_TYPES = {
  SALE: 'sale',
  MINT: 'mint',
  LIST: 'ask',
  TRANSFER: 'transfer'
};

const ACTIVITY_TYPES = {
  COLLAB_REQUEST: 'bid',
  LISTING: 'listing',
  OFFER: 'offer',
  OFFERS_MADE: 'offersMade'
};
const COLLABORATABLE_TYPES = {
  PROJECT: 'Project',
  PROMPT: 'Prompt',
  GALLERY: 'Gallery'
};

const PAGE_LOAD_MODES = {
  INFINITE_SCROLL: 'infinite-scroll',
  PAGINATION: 'pagination'
};

const EMPTY_GALLERY_SECTION_MESSAGE = {
  gallery:
    'No galleries to show. Create one to tell engaging stories visually.',
  story: 'No stories to show. Create one to tell engaging stories visually.',
  showcase:
    'No showcases to show. Create one to feature top pieces in a curated drop.'
};

const LAZY_MINTING_DISABLED_MESSAGE =
  'Gasless minting on Joyn is temporarily disabled. Please check back later.';

const bidIncrementPercentage = '1000';

const TYPE_OF_SALE = {
  buyNow: 'buy-now',
  timedAuction: 'timed-auction'
};

const COMPOSER_TYPEFORM_URL = 'https://qx0n7cm9kt2.typeform.com/to/UV1NWhp3';

const DOC_URLS = {
  ART_PLUS:
    'https://chestr-labs.notion.site/Art-1557fec2943241bb8a3e473affac97cc',
  SHOWCASE:
    'https://chestr-labs.notion.site/Showcase-07e6ad6011ab447f9410d1b0276a472f?pvs=4'
};

const orderType = {
  LISTING: 'listing',
  OFFER: 'offer'
};

const META_TAGS = {
  DEFAULT: {
    title: 'Open collab for web3 creators | Joyn',
    description:
      'Discover open calls, curation, and opportunities from leading projects.',
    image:
      'https://assets.joyn.xyz/Backend/PromptSubmission/default/23e35bb4508b99f49eed4e42167ede9c9edd665f92b6a1cca31b63a9b7dc16e3.jpg'
  },
  COMPOSER: {
    title: 'Composer | Joyn',
    description:
      'Create Open Calls, curate Showcases, and compose Stories with on-chain media.',
    image:
      'https://assets.joyn.xyz/Backend/Asset/default/Composer-TitleCard.png'
  }
};

export {
  AUCTION_TYPE_ID,
  EMPTY_GALLERY_SECTION_MESSAGE,
  TOKEN_ACTIVITY_TYPES,
  ACTIVITY_TYPES,
  ORDER_STATUSES,
  DASHBOARD_SECTION_NUM_ITEMS,
  ACTIVITY_ACTIONS,
  MARKETPLACE_FEE_STR,
  DEFAULT_CURATOR_FEE_PERCENTAGE,
  MARKETPLACE_FEE,
  orderItemTypes,
  LAUNCH_DARKLY_KINDS,
  VIDEO_EXTENSIONS,
  IMAGE_EXTENSIONS,
  HASHTAG_MINIMUM_LENGTH,
  TOKEN_STANDARDS,
  GALLERY_SECTION_LIMIT,
  UPLOADING_MESSAGE,
  MILESTONE_STATUS,
  RegisterToPromptCTA,
  headingClasses,
  AVAILABLE_CHAINS,
  ASSET_FILTER_TYPES,
  ROLE_TYPES,
  AVAILABLE_ROLE_TYPES,
  CO_CREATE_TYPES,
  CO_CREATE_PROMPTABLE_TYPES,
  PROMPT_STATUSES,
  CO_CREATE_INITIAL_STATE,
  PARTICIPATION_TYPES,
  FEATURES,
  ROLE_NAMES,
  TWITTER_URL,
  DISCORD_URL,
  PROMPT_REWARD_TYPES,
  PROMPT_REWARD_TYPE_NAMES,
  REWARD_TYPE_SELECTIONS,
  PROMPT_REWARD_INITIAL_STATE,
  USAGE_RIGHTS,
  SORT_ORDERS,
  SORT_LABELS,
  ASSET_SORT_OPTION_VALUES,
  ASSET_SORT_OPTION_LABELS,
  EXPLORE_ASSET_DEFAULT_SORT_OPTION,
  CO_CREATE_FILTERS,
  CO_CREATE_FILTER_LABELS,
  NAV_TABS_LABEL,
  REQUIREMENT_LOGIC_GATES,
  CO_CREATE_REQUIREMENT_TYPES,
  TWITTER_REQUIREMENT_ACTION_TYPES,
  TWITTER_REQUIREMENT_ACTIONS,
  TWITTER_REQUIREMENT_INITIAL_STATE,
  DISCORD_REQUIREMENT_INITIAL_STATE,
  DISCORD_REQUIREMENT_ACTIONS,
  DISCORD_REQUIREMENT_ACTION_TYPES,
  TOKEN_REQUIREMENT_INITIAL_STATE,
  CO_CREATE_REQUIREMENTABLE_TYPES,
  MAX_NUMBER_POLL_OPTIONS,
  DRAW_STATUSES,
  TWITTER_SUBMISSION_ACTION_TYPES,
  TWITTER_SUBMISSION_ACTIONS,
  EMAIL_VERIFICATION_CODE_LENGTH,
  WINNER_SELECTION_TYPES,
  WINNER_SELECTIONS,
  POP_CLAIM_TX_STATUSES,
  BLOCK_EXPLORER,
  PAGE_SIZE,
  MARKETPLACES,
  BLOCKCHAINS,
  CHAIN_ID_TO_CHAIN_NAME,
  BLOCKCHAIN_ACTIONS,
  CHAIN_ICONS,
  MAX_FILE_SIZE,
  META_ENTITIES,
  PROMPT_SUBMISSION_CARD_ACTION_NAMES,
  DURATIONS,
  OFFER_DURATIONS,
  LISTING_DURATIONS,
  COLLABORATABLE_TYPES,
  COLLAB_REQUEST_STATUS,
  COLLAB_REQUEST_TAB_IDS,
  PAGE_LOAD_MODES,
  bidIncrementPercentage,
  TYPE_OF_SALE,
  COMPOSER_TYPEFORM_URL,
  DOC_URLS,
  orderType,
  MARKETPLACE_FREE_LISTING_FEE,
  joynSupportedProtocols,
  treasuryAddress,
  META_TAGS,
  LAZY_MINTING_DISABLED_MESSAGE
};
