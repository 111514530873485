import React, { useState, useContext, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { Button } from '/design-systems/Atoms/Button';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(!initComplete || (initComplete && isFirstItemVisible));
  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  if (disabled) return <></>;

  return (
    <Button
      variant='icon-only'
      color='neutral'
      iconOnly
      onClick={() => scrollPrev()}
      className='absolute left-[-20px] top-[40%] z-10 h-fit w-fit rounded-full border border-neutral-200 bg-white drop-shadow-md'
    >
      <ChevronLeftIcon className='h-[20px] w-[20px] text-neutral-900' color='white' />
    </Button>
  );
};

const RightArrow = () => {
  const { isLastItemVisible, scrollNext, visibleElements } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(!visibleElements.length && isLastItemVisible);
  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  if (disabled) return <></>;

  return (
    <Button
      variant='icon-only'
      color='neutral'
      iconOnly
      onClick={() => scrollNext()}
      className='absolute right-[-20px] top-[40%] z-10 h-fit w-fit rounded-full border border-neutral-200 bg-white drop-shadow-md'
    >
      <ChevronRightIcon className='h-[20px] w-[20px] text-neutral-900' color='white' />
    </Button>
  );
};

export const HorizontalScroll = ({ children }) => {
  return (
    <div className='no-scrollbar relative'>
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        scrollContainerClassName='flex gap-x-2 items-stretch pb-2' // there is a separator between items
      >
        {children}
      </ScrollMenu>
    </div>
  );
};
