import { useMemo } from 'react';
import { useAccount } from 'wagmi';
import { useMutation, useQuery } from '@tanstack/react-query';

import JoynMarketplaceChainService from '/services/chain/JoynMarketplaceChainService';
import FoundationMarketplaceService from '/services/chain/FoundationMarketplaceService';

import { QUERY_KEYS } from '/utils/queries';

export const useMarketplaceContract = ({ chainId }) => {
  const { address } = useAccount();

  const {
    data: joynMarketplaceService,
    isLoading: isLoadingJoynMarketplaceService
  } = useQuery(
    [QUERY_KEYS.CHAIN.MARKETPLACE, address, chainId],
    () => new JoynMarketplaceChainService(chainId),
    {
      enabled: !!address && !!chainId
    }
  );

  const {
    data: foundationMarketplaceService,
    isLoading: isLoadingFoundationMarketplaceService
  } = useQuery(
    [QUERY_KEYS.CHAIN.FOUNDATION_MARKETPLACE, address, chainId],
    () => new FoundationMarketplaceService(chainId),
    {
      enabled: !!address && !!chainId
    }
  );

  const { mutateAsync: onFoundationBuyV2 } = useMutation(
    ({ tokenId, tokenAddress, referrerAddress, price }) =>
      foundationMarketplaceService.buyV2({
        tokenAddress,
        tokenId,
        price,
        referrerAddress
      })
  );

  const { mutateAsync: onFoundationCancelListing } = useMutation(
    ({ tokenId, tokenAddress }) =>
      foundationMarketplaceService.cancelBuyPrice({
        tokenAddress,
        tokenId,
      })
  );

  const { mutateAsync: onListAsset } = useMutation(
    ({ tokenId, tokenAddress, fee, price }) =>
      joynMarketplaceService.listItem({
        tokenAddress,
        tokenId,
        fee,
        price
      })
  );

  const { mutateAsync: onCancelListing } = useMutation(
    ({ tokenId, tokenAddress }) =>
      joynMarketplaceService.cancelListing({
        tokenId,
        tokenAddress
      })
  );

  const { mutateAsync: onUpdateListing } = useMutation(
    ({ tokenId, tokenAddress, price, fee }) =>
      joynMarketplaceService.updateListingMarketplaceFeeAndPrice({
        tokenAddress,
        tokenId,
        price,
        fee
      })
  );

  const { mutateAsync: onBuyAsset } = useMutation(
    ({ tokenId, tokenAddress, referrerAddress, price }) =>
      joynMarketplaceService.buyItem({
        tokenAddress,
        tokenId,
        referrerAddress,
        price
      })
  );

  const { mutateAsync: onGetListing } = useMutation(
    ({ tokenId, tokenAddress }) =>
      joynMarketplaceService.getListing({
        tokenId,
        tokenAddress
      })
  );

  return useMemo(
    () => ({
      isLoadingJoynMarketplaceService,
      isLoadingFoundationMarketplaceService,
      onListAsset,
      onCancelListing,
      onUpdateListing,
      onBuyAsset,
      onGetListing,
      onFoundationBuyV2,
      onFoundationCancelListing
    }),
    [
      isLoadingJoynMarketplaceService,
      isLoadingFoundationMarketplaceService,
      onListAsset,
      onCancelListing,
      onUpdateListing,
      onBuyAsset,
      onGetListing,
      onFoundationBuyV2,
      onFoundationCancelListing
    ]
  );
};
