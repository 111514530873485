import React from 'react';

export const EDITOR_HEADINGS = [
  {
    id: 'heading',
    icon: (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.05542 17H4.71542V10.84H10.9154V17H13.5754V3H10.9154V8.74H4.71542V3H2.05542V17Z"
          className="fill-[#4B5563]"
        />
        <path
          d="M17.1893 17H18.8493V10.7H17.5693C17.3693 11.16 16.8093 11.48 15.8893 11.5V12.42H17.1893V17Z"
          className="fill-[#4B5563]"
        />
      </svg>
    ),
    params: {
      level: 1
    }
  },
  {
    id: 'heading',
    icon: (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.755371 17H3.41537V10.84H9.61537V17H12.2754V3H9.61537V8.74H3.41537V3H0.755371V17Z"
          className="fill-[#4B5563]"
        />
        <path
          d="M14.7293 17H20.1493V15.84L16.7893 15.88C19.8093 13.84 20.1293 13.18 20.1293 12.34C20.1293 11.32 19.1093 10.56 17.6293 10.56C15.9093 10.58 14.8693 11.44 14.6293 12.94H16.2493C16.4293 12.12 16.8693 11.74 17.5093 11.74C18.0693 11.74 18.4093 12.04 18.4093 12.46C18.4093 13.06 18.0893 13.44 14.7293 15.9V17Z"
          className="fill-[#4B5563]"
        />
      </svg>
    ),
    params: {
      level: 2
    }
  },
  {
    id: 'heading',
    icon: (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.585449 16.9299H3.24545V10.7699H9.44545V16.9299H12.1054V2.92993H9.44545V8.66993H3.24545V2.92993H0.585449V16.9299Z"
          fill="#4B5563"
        />
        <path
          d="M17.5394 17.0699C19.2394 17.0699 20.3194 16.3099 20.3194 15.1499C20.3194 14.4099 19.8994 13.8699 19.0194 13.6499C19.7594 13.4099 20.1594 12.9099 20.1594 12.2699C20.1594 11.1899 19.1394 10.4899 17.5194 10.4899C15.7794 10.4899 14.7194 11.3499 14.5194 12.6899H16.1594C16.2994 11.9699 16.7394 11.6099 17.4594 11.6099C18.0994 11.6099 18.4794 11.9099 18.4794 12.4099C18.4794 12.8899 18.1794 13.1899 17.6194 13.1899H16.6794V14.2099H17.6394C18.2794 14.2099 18.5994 14.5099 18.5994 15.0699C18.5994 15.6299 18.1594 15.9499 17.4994 15.9499C16.7194 15.9499 16.2594 15.5499 16.1194 14.6499H14.4794C14.6594 16.2299 15.7194 17.0699 17.5394 17.0699Z"
          fill="#4B5563"
        />
      </svg>
    ),
    params: {
      level: 3
    }
  }
];

export const EDITOR_TRANSFORMS = [
  {
    id: 'bold',
    icon: (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.98364 17.875H11.7461C15.0086 17.875 16.9211 16.165 16.9211 13.51C16.9211 11.6875 15.9761 10.3825 14.3336 9.7525C15.7736 9.0775 16.5611 7.8175 16.5611 6.2875C16.5611 3.7225 14.6711 2.125 11.5211 2.125H3.98364V17.875ZM11.3411 4.4875C12.7361 4.4875 13.6136 5.23 13.6136 6.58C13.6136 7.9525 12.7361 8.6725 11.3411 8.6725H6.97614V4.4875H11.3411ZM11.5211 10.99C13.0511 10.99 13.9511 11.845 13.9511 13.285C13.9511 14.725 13.0511 15.535 11.5211 15.535H6.97614V10.99H11.5211Z"
          className="fill-[#4B5563]"
        />
      </svg>
    )
  },
  {
    id: 'italic',
    icon: (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1824 3.65527L10.2051 16.3225H11.7212L11.2488 18.2781H6.01929L6.4917 16.3225H7.7771L10.7983 3.65527H9.40308L9.83154 1.72168H14.8853L14.4128 3.65527H13.1824Z"
          className="fill-[#4B5563]"
        />
      </svg>
    )
  },
  {
    id: 'underline',
    icon: (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.4355 14.0556C13.3819 14.0556 15.3349 12.4057 15.3349 9.54347V2.06812H13.163V9.64449C13.163 11.2945 12.035 12.2205 10.4692 12.2205C8.9371 12.2205 7.8259 11.2945 7.8259 9.62766V2.06812H5.56982V9.52664C5.56982 12.3888 7.50601 14.0556 10.4355 14.0556Z"
          className="fill-[#4B5563]"
        />
        <rect
          x="3.40234"
          y="15.9695"
          width="14.1"
          height="1.9625"
          className="fill-[#4B5563]"
        />
      </svg>
    )
  }
];

export const EDITOR_LINK = [
  {
    id: 'link',
    icon: (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9296 3.52256C15.8452 2.43818 14.0871 2.43818 13.0027 3.52256L12.01 4.51527C11.6842 4.84111 11.1559 4.84111 10.83 4.51527C10.5042 4.18942 10.5042 3.66113 10.83 3.33528L11.8227 2.34258C13.5588 0.60651 16.3735 0.60651 18.1096 2.34258C19.8457 4.07865 19.8457 6.89338 18.1096 8.62945L14.4985 12.2406C12.7624 13.9766 9.94771 13.9766 8.21164 12.2406C7.8858 11.9147 7.8858 11.3864 8.21164 11.0606C8.53748 10.7347 9.06578 10.7347 9.39162 11.0606C10.476 12.145 12.2341 12.145 13.3185 11.0606L16.9296 7.44946C18.014 6.36508 18.014 4.60695 16.9296 3.52256ZM11.513 8.93923C10.4286 7.85485 8.67045 7.85485 7.58607 8.93923L3.97496 12.5503C2.89057 13.6347 2.89057 15.3929 3.97496 16.4772C5.05934 17.5616 6.81747 17.5616 7.90186 16.4772L8.89632 15.4828C9.22217 15.1569 9.75046 15.1569 10.0763 15.4828C10.4021 15.8086 10.4021 16.3369 10.0763 16.6628L9.08184 17.6572C7.34577 19.3933 4.53104 19.3933 2.79497 17.6572C1.0589 15.9212 1.0589 13.1064 2.79497 11.3704L6.40608 7.75925C8.14215 6.02318 10.9569 6.02318 12.6929 7.75925C13.0188 8.08509 13.0188 8.61339 12.6929 8.93923C12.3671 9.26507 11.8388 9.26507 11.513 8.93923Z"
          className="fill-[#4B5563]"
        />
      </svg>
    )
  }
];

export const EDITOR_BULLET_LIST = [
  {
    id: 'bulletList',
    icon: (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.13989 3.90625C5.13989 3.38848 5.55963 2.96875 6.07739 2.96875H18.5774C19.0952 2.96875 19.5149 3.38848 19.5149 3.90625C19.5149 4.42402 19.0952 4.84375 18.5774 4.84375H6.07739C5.55963 4.84375 5.13989 4.42402 5.13989 3.90625ZM5.13989 10.1562C5.13989 9.63848 5.55963 9.21875 6.07739 9.21875H18.5774C19.0952 9.21875 19.5149 9.63848 19.5149 10.1562C19.5149 10.674 19.0952 11.0938 18.5774 11.0938H6.07739C5.55963 11.0938 5.13989 10.674 5.13989 10.1562ZM5.13989 16.4062C5.13989 15.8885 5.55963 15.4688 6.07739 15.4688H18.5774C19.0952 15.4688 19.5149 15.8885 19.5149 16.4062C19.5149 16.924 19.0952 17.3438 18.5774 17.3438H6.07739C5.55963 17.3438 5.13989 16.924 5.13989 16.4062Z"
          className="fill-[#4B5563]"
        />
        <path
          d="M3.77075 3.90625C3.77075 4.59661 3.21111 5.15625 2.52075 5.15625C1.8304 5.15625 1.27075 4.59661 1.27075 3.90625C1.27075 3.21589 1.8304 2.65625 2.52075 2.65625C3.21111 2.65625 3.77075 3.21589 3.77075 3.90625Z"
          className="fill-[#4B5563]"
        />
        <path
          d="M3.77075 10.1562C3.77075 10.8466 3.21111 11.4062 2.52075 11.4062C1.8304 11.4062 1.27075 10.8466 1.27075 10.1562C1.27075 9.46589 1.8304 8.90625 2.52075 8.90625C3.21111 8.90625 3.77075 9.46589 3.77075 10.1562Z"
          className="fill-[#4B5563]"
        />
        <path
          d="M3.77075 16.4062C3.77075 17.0966 3.21111 17.6562 2.52075 17.6562C1.8304 17.6562 1.27075 17.0966 1.27075 16.4062C1.27075 15.7159 1.8304 15.1562 2.52075 15.1562C3.21111 15.1562 3.77075 15.7159 3.77075 16.4062Z"
          className="fill-[#4B5563]"
        />
      </svg>
    )
  }
];

export const EDITOR_IMAGE = [
  {
    id: 'image',
    icon: (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.49292 3.90615C1.49292 2.32351 2.7759 1.04053 4.35854 1.04053H16.546C18.1287 1.04053 19.4117 2.32351 19.4117 3.90615V12.0302C19.4117 12.0308 19.4117 12.0315 19.4117 12.0322V16.0937C19.4117 17.6763 18.1287 18.9593 16.546 18.9593H4.35854C2.7759 18.9593 1.49292 17.6763 1.49292 16.0937V3.90615ZM3.16167 14.408V16.0937C3.16167 16.7547 3.69753 17.2905 4.35854 17.2905H16.546C17.2071 17.2905 17.7429 16.7547 17.7429 16.0937V12.3768L16.3767 11.0106C15.9093 10.5432 15.1515 10.5432 14.6841 11.0106L13.6635 12.0312L15.1048 13.4724C15.4306 13.7983 15.4306 14.3266 15.1048 14.6524C14.7789 14.9782 14.2506 14.9782 13.9248 14.6524L11.8942 12.6217C11.894 12.6215 11.8938 12.6213 11.8936 12.6211C11.8934 12.6209 11.8932 12.6207 11.893 12.6205L9.26736 9.99496C8.79996 9.52755 8.04213 9.52755 7.57473 9.99496L3.16167 14.408ZM12.4835 10.8512L10.4473 8.81497C9.32825 7.69587 7.51384 7.69588 6.39474 8.81497L3.16167 12.048V3.90615C3.16167 3.24514 3.69753 2.70928 4.35854 2.70928H16.546C17.2071 2.70928 17.7429 3.24514 17.7429 3.90615V10.0168L17.5567 9.8306C16.4376 8.7115 14.6232 8.7115 13.5041 9.8306L12.4835 10.8512ZM11.6492 5.9374C11.6492 5.47659 12.0227 5.10303 12.4835 5.10303H12.4937C12.9545 5.10303 13.3281 5.47659 13.3281 5.9374C13.3281 6.39822 12.9545 6.77178 12.4937 6.77178H12.4835C12.0227 6.77178 11.6492 6.39822 11.6492 5.9374Z"
          fill="#4B5563"
        />
      </svg>
    )
  }
];

export const EDITOR_VIDEO = [
  {
    id: 'video',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="#4B5563"
        className="h-5 w-5"
      >
        <path
          strokeLinecap="round"
          d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
        />
      </svg>
    )
  }
];
