import { removeDuplications } from './array';
import { emptyArray } from './object';
import { mergeClassNames } from './string';

export const pageLayoutClassNames = mergeClassNames(
  'mx-auto mt-6 mb-12 flex',
  'w-full max-w-[1280px]',
  'flex-col',
  'gap-y-6 px-6 pt-5 xl:px-0'
);

/**
 * @note When you update z-index of element,
 * consider which components your update will impact.
 */
export const ZIndexClassnames = {
  // Atoms/Modal
  modal: 'z-[300]', // default
  modalTop: 'z-[500]',
  // Organisms/Header
  header: 'z-[200]', // default
  headerInAssetView: 'z-[400]',
  // Molecules/UserMenu
  userMenu: 'z-[9999]',
  userMenuBody: 'z-[9998]'
};

/**
 * Adds CSS class names to the current class names of an element.
 */
export const addClassNames = ({ current, classNames = [] }) => {
  if (emptyArray(classNames)) return current ?? '';

  const newClassNames = current?.split(' ') ?? [];
  newClassNames.push(...classNames);

  return removeDuplications({ array: newClassNames }).join(' ');
};

/**
 * Removes specified CSS class names from the current class names of an element.
 */
export const removeClassNames = ({ current, classNames = [] }) => {
  if (emptyArray(classNames)) return current ?? '';

  const newClassNames = current?.split(' ') ?? [];
  return removeDuplications({
    array: newClassNames.filter((className) => !classNames.includes(className))
  }).join(' ');
};
