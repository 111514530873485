import React, { useCallback } from 'react';
import { XIcon } from '@heroicons/react/outline';

import { useRouter } from 'next/router';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Divider } from '/design-systems/Atoms/Divider';
import { UserRows } from '/design-systems/Molecules/UserRows';

/**
 * @description UsersModal to show the list of users
 *
 * @typedef UserObject
 * @property {string} [id]
 * @property {string} [username]
 * @property {string} [address]
 * @property {string} [name]
 * @property {string} [avatar]
 * @property {string} [text]
 */
export const UsersModal = ({ title, users = [], open, onClose }) => {
  const router = useRouter();

  const handleViewUserProfile = useCallback(async (route) => {
    await router.push(route);
    onClose();
  }, []);

  return (
    <Modal
      id="users-modal"
      className="relative"
      wrapperClassName="pt-10"
      autoHeight={true}
      open={open}
      onClose={onClose}
      innerContainerClassName="!p-0"
    >
      {/* title row */}
      <div className="p-4">
        <div className="relative flex items-center ">
          <button
            className="absolute left-0 top-0 flex h-full cursor-pointer items-center"
            onClick={onClose}
          >
            <XIcon className="h-[24px] w-[24px] stroke-[#111827] dark:stroke-neutral-100" />
          </button>

          <div className="flex w-full items-center justify-center gap-1">
            <Typography
              heading
              variant="h6"
              weight="semibold"
              className="text-[#111827]"
            >
              {title}
            </Typography>
            <Typography
              heading
              variant="h6"
              weight="semibold"
              className="text-[#6B7280]"
            >
              ({users.length})
            </Typography>
          </div>
        </div>
      </div>

      <Divider size="smallest" />

      <div className="flex h-full w-full flex-col overflow-y-auto p-2">
        <UserRows users={users} onView={handleViewUserProfile} />
      </div>
    </Modal>
  );
};
