export const formatPromptSubmissionsData = (res) => ({
  data: res?.data?.data?.rows ?? [],
  count: res?.data?.data?.count ?? 0,
});

export const parsePromptSubmissionsData = (rows, count) => ({
  data: {
    data: {
      rows,
      count,
    },
  },
});

export const formatMyPromptSubmissionsData = (res) => ({
  data: res?.data?.data ?? [],
  count: res?.data?.data?.length ?? 0,
});

export const parseMyPromptSubmissionsData = (data) => ({
  data: {
    data,
  },
});

export const formatWinnerRewards = (res) => ({
  data: res?.data?.data ?? [],
  count: res?.data?.data?.length ?? 0,
});
