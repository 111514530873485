import React from 'react';
import { Divider } from '/design-systems/Atoms/Divider';
import { Button } from '/design-systems/Atoms/Button';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { ModalTitleRow } from '/design-systems/Molecules/Modals/ModalTitleRow';
import { capitalizeFirstLetter } from '/utils/string';

export const ConcurrentUserModal = ({ open, galleryType = 'gallery' }) => {
  const handleClose = () => window.location.reload();

  return (
    <Modal maxWidth={582} open={open} autoHeight onClose={handleClose}>
      <div className="flex flex-col gap-4">
        <ModalTitleRow
          title={`${capitalizeFirstLetter(galleryType)} in use`}
          onClose={handleClose}
        />
        <Divider size="smallest" />
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <Typography variant="medium">
              This {galleryType} is currently being updated in another browser
              tab.
            </Typography>
            <Typography variant="medium">
              To continue editing in the other tab, simply close this current
              tab.
            </Typography>
            <Typography variant="medium">
              Or to edit here in this tab instead, you can close this message.
            </Typography>
          </div>
          <Button
            className="mx-auto w-fit items-center"
            color="black"
            variant="primary"
            onClick={handleClose}
          >
            Edit here
          </Button>
        </div>
      </div>
    </Modal>
  );
};
