import { useMemo } from 'react';
import { useAccount } from 'wagmi';
import { useMutation, useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '/utils/queries';
import abis from '/abis';
import ERC20Service from '/services/chain/ERC20Service';
import { formatCurrency } from '/utils/web3';

export const useErc20Contract = ({ tokenAddress, walletAddress }) => {
  const { address } = useAccount();

  const { data: erc20Service, isLoading: isLoadingErc20Service } = useQuery(
    [QUERY_KEYS.CHAIN.ERC20, address, tokenAddress],
    () => new ERC20Service(tokenAddress, abis.ERC20ABI),
    {
      enabled: !!tokenAddress && !!address
    }
  );

  const { data: balance } = useQuery(
    ['get-balance'],
    async () => {
      const bigNumberBalance = await erc20Service.balanceOf({
        address: walletAddress
      });
      return formatCurrency({ amount: bigNumberBalance, decimal: 18 });
    },
    {
      enabled: !!walletAddress && !isLoadingErc20Service
    }
  );

  const { mutateAsync: allowance } = useMutation(({ owner, spender }) =>
    erc20Service.allowance({ owner, spender })
  );

  const { mutateAsync: approve } = useMutation(({ spender, amount }) =>
    erc20Service.approve({ spender, amount })
  );

  return useMemo(
    () => ({
      isLoadingErc20Service,
      allowance,
      approve,
      balance
    }),
    [isLoadingErc20Service, allowance, approve, balance]
  );
};
