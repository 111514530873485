import React, { useState, useEffect, useRef } from 'react';
import ChevronDownIcon from '@heroicons/react/outline/ChevronDownIcon';
import { Typography } from '/design-systems/Atoms/Typography';

export const ReadMoreSection = ({ height = 160, title = 'Read More', completed = false, children }) => {
  const childRef = useRef();
  const [showFullBody, setShowFullBody] = useState(true);
  const className = `
    ${!showFullBody && `max-h-[${height}px]`} overflow-hidden relative
  `;

  useEffect(() => {
    if (!completed || !height) return;

    setTimeout(() => {
      const childHeight = childRef?.current?.clientHeight || 0;
      if (childHeight > height) setShowFullBody(false);
    }, 300);
  }, [completed, height]);

  return (
    <div className='relative'>
      <div className={className} ref={childRef}>
        {children}
      </div>
      {!showFullBody && completed && (
        <>
          <div className='absolute bottom-0 mt-[-45px] min-h-[80px] w-full w-full bg-gradient-to-b from-[#ffffff4d] to-white dark:from-[#0000004d] dark:to-neutral-900'>
            <div
              className='absolute bottom-[-16px] flex w-full cursor-pointer items-center justify-center gap-4'
              onClick={() => setShowFullBody(true)}
            >
              <Typography align='center' colorVariant='secondary' hoverColor='neutral-900'>
                {title}
              </Typography>
              <ChevronDownIcon className='h-[14px] w-[14px] stroke-[#6B7280]' />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
