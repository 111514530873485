import { Contract } from './contractService';

class ERC1155Service {
  contract;

  constructor(address, abi) {
    this.contract = new Contract(address, abi);
  }

  isApprovedForAll({ owner, operator }) {
    return this.contract.read('isApprovedForAll', [owner, operator]);
  }

  setApprovalForAll({ operator, approved }) {
    return this.contract.write('setApprovalForAll', [operator, approved]);
  }

  safeTransferFrom({ from, to, tokenId, value, data }) {
    return this.contract.write('safeTransferFrom', [
      from,
      to,
      tokenId,
      value,
      data
    ]);
  }
}

export default ERC1155Service;
