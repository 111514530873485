export const PROMPT_LICENSES = {
  'All rights reserved (No transfer of ownership)': {
    title: 'All rights reserved (No transfer of ownership)',
    content:
      'You do not allow your work to be used or adapted in any way without your permission.',
    producerContent:
      'The creator grants permission for you to use their submission as described in this campaign. But the creator retains ownership of the submission.',
    assetContent:
      "You may not use or adapt this work in any way without the creator's permission."
  },
  'Transfer of ownership to the project': {
    title: 'Transfer of ownership',
    content:
      'If your work is selected by the producer and you receive the stated reward, you agree to transfer all ownership and rights to the producer.',
    producerContent:
      'Once you select a submission as winner and send the rewards, its ownership is transferred to you.',
    assetContent:
      'The creator has transferred ownership of this work in a previous campaign.'
  },
  'CC0 (public domain)': {
    title: 'CC0 (public domain)',
    content: 'This allows anyone to use your work for any purpose.',
    producerContent:
      'This allows anyone to use the submissions for any purpose.',
    assetContent: 'Anyone can use this work for any purpose.'
  },
  'CC BY (Creative Commons Attribution)': {
    title: 'Creative Commons Attribution',
    content: `This allows others to create derivatives commercially, with credit back to you.`,
    producerContent:
      'This allows others to create derivatives commercially, with credit back to the creator.',
    assetContent:
      'Anyone can create derivatives commercially, with credit back to the creator.'
  },
  'CC BY-NC (Creative Commons Attribution-NonCommercial)': {
    title: 'Creative Commons Attribution-NonCommercial',
    content: `This allows others to create derivatives only non-commercially, with credit back to you.`,
    producerContent:
      'This allows others to create derivatives only non-commercially, with credit back to the creator.',
    assetContent:
      'Anyone can create derivatives only non-commercially, with credit back to the creator.'
  }
};

export const PROMPT_INVITATION_TITLE = 'Invite a curator';

export const PROMPT_INVITATION_DESCRIPTION =
  'Curators have full edit access in this open call.';
