import { ethers } from 'ethers';
import { getJoynAssetContract } from './contracts';
import { emptyArray } from './object';

export const removeHTMLTags = (string = '') => {
  return string.replace(/<\/?[^>]+(>|$)/g, '');
};

export const removeTwitterHandles = (text) => {
  return text.replace(/[@][A-Za-z0-9_]+/g, '');
};

export const removeTwitterLinks = (text) => {
  return text.replace(/https:\/\/t.co\/[A-Za-z0-9]+/g, '');
};

export const cleanTwitterText = (text) => {
  return removeTwitterLinks(removeTwitterHandles(text));
};

export const toShortAddress = (address = '', num = 5) => {
  return address
    ? `${address.substring(0, num)}...${address.substring(
        address.length - num,
        address.length
      )}`
    : '';
};

export const toShortText = (longText, num = 5) => {
  if (!longText) return '';

  const str = String(longText);
  if (str.length > num * 2) {
    return toShortAddress(str, num);
  }

  return str;
};

export const hideEmail = (email = '') => {
  return email.replace(/(\w{2})[\w.-]+@([\w.]+\w)/, '$1***@$2');
};

export const toHttpsFromIPFS = (chainId, ipfsLink, address) => {
  const content =
    address === getJoynAssetContract({ chainId })
      ? 'https://gateway.joyn.xyz/ipfs/'
      : 'https://ipfs.io/ipfs/';
  return ipfsLink.replace('ipfs://', content);
};

/**
 * Get IPFS link from ipfs hash
 * @param {string} ipfsHash
 */
export const getIPFSLinkFromHash = (ipfsHash) => {
  if (ipfsHash?.toLowerCase()?.includes('ipfs://')) {
    return ipfsHash.replace('ipfs://', 'https://ipfs.io/ipfs/');
  }

  if (ipfsHash?.toLowerCase()?.includes('http')) {
    return ipfsHash;
  }

  return `https://ipfs.io/ipfs/${ipfsHash}`;
};

export const toLong18 = (num) => {
  return ethers.parseEther(`${num}`);
};

export const toShort18 = (num) => {
  return ethers.formatEther(`${num}`);
};

export const isEmptyDOMString = (str) => {
  return !str || str === '<p></p>';
};

export const toFileName = (str) => {
  if (!str) return '';
  const sanitizedStr = str.toLowerCase().replace(/[^a-z0-9 ]+/g, '');
  const words = sanitizedStr.split(' ').filter((word) => word.trim() !== '');
  return words.join('-');
};

export const isDOMString = (str) => {
  /**
   * should search with HTML close tag, not open tag
   * it will be broken if HTML open tag has any attribute e.g. <a href=""></a>
   * `/>` means `<img />`
   */
  const domStrings = ['</p>', '</a>', '</ul>', '/>'];
  return !emptyArray(domStrings.filter((domString) => str.includes(domString)));
};

/**
 * Adjust decimal numbers to show only the proper number of decimals
 * @param {string} num
 * @param {number} digits
 */
export const adjustDecimals = (num, decimals) => {
  return num?.slice(0, num?.indexOf('.') + decimals);
};

/**
 * compares two strings case-insensitively
 * @param {string} str1
 * @param {string} str2
 * @returns {boolean} if equals, return true
 */
export function compareStringsIgnoreCase(str1, str2) {
  return str1?.toLowerCase() === str2?.toLowerCase();
}

/**
 * Capitalize the first letter of text
 *
 * @example 'hello world' => 'Hello world'
 */
export function capitalizeFirstLetter(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * Merge tailwind classnames
 *
 * @example
 * mergeClassNames('w-full', isMobile ? 'flex-col' : 'flex-row')
 */
export function mergeClassNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/**
 * Count the number of LLM tokens for a given string
 */
export const countTokens = (text) => Math.round(text.split(' ').length * 1.2);

export const parseBoolean = (value = 'false') =>
  Boolean(JSON.parse(value ?? 'false'));

/**
 * Removes spaces from a given string.
 *
 * @param {string} inputString - The input string from which spaces will be removed.
 * @returns {string} - A new string with spaces removed.
 */
export const removeSpacesFromText = (inputString) => {
  if (!inputString) return '';
  return inputString.split(' ').join('');
};
