import pluralize from 'pluralize';

import { PROMPT_REWARD_TYPES } from '/utils/constants';
import rewardsEthereumLogo from '/images/ethereum-logo.png';
import { createPromptReward, updatePromptReward, deletePromptReward } from '/services/joynApi/producers/prompt-reward';

export const promptRewardTitle = ({ rewardTypeId, rewardAmount, title, withETHTitle = false }) => {
  switch (rewardTypeId) {
    case PROMPT_REWARD_TYPES.eth:
      return `${rewardAmount} ETH${title && withETHTitle ? ` - ${title}` : ''}`;
    case PROMPT_REWARD_TYPES.other:
    case PROMPT_REWARD_TYPES.pop:
      return title;
    case PROMPT_REWARD_TYPES.exhibition:
      return title || 'Exhibition spot';
    default:
      return '';
  }
};

export const promptRewardTitleLite = ({ rewardTypeId, rewardAmount, title, withETHTitle = false }) => {
  switch (rewardTypeId) {
    case PROMPT_REWARD_TYPES.eth:
      return rewardAmount.toFixed(2);
    case PROMPT_REWARD_TYPES.other:
    case PROMPT_REWARD_TYPES.pop:
      return title;
    case PROMPT_REWARD_TYPES.exhibition:
      return title || 'Exhibition spot';
    default:
      return '';
  }
};

export const promptRewardIconUrl = (reward) => {
  switch (reward.rewardTypeId) {
    case PROMPT_REWARD_TYPES.eth:
      return rewardsEthereumLogo.src;
      return '';
  }
};

export const promptRewardTooltip = (reward) => {
  switch (reward.rewardTypeId) {
    case PROMPT_REWARD_TYPES.eth:
      return `A prize pool of ${promptRewardTitle(reward)}${
        reward.unit ? `, distributed among ${reward.unit} ${pluralize('winners', reward.unit)}` : ''
      }`;
    case PROMPT_REWARD_TYPES.other:
    case PROMPT_REWARD_TYPES.exhibition:
      return `${
        reward.unit ? `${reward.unit} ${pluralize('winners', reward.unit)} will receive ` : ''
      }${promptRewardTitle(reward)}`;
    default:
      return '';
  }
};

export const hasReward = (prompt) => {
  return (
    prompt.PromptRewards &&
    prompt.PromptRewards[0] &&
    (prompt.PromptRewards[0].rewardAmount || prompt.PromptRewards[0].title)
  );
};

export const filterPromptRewards = (rewards = []) => {
  return rewards.filter((reward) => reward.rewardTypeId);
};

export const validateETHPromptReward = (reward) => {
  const errors = {};

  if (!reward.rewardAmount) {
    errors.rewardAmount = 'This is a required field';
  } else if (reward.rewardAmount <= 0) {
    errors.rewardAmount = 'Reward amount should be greater than 0';
  }

  if (!reward.unit) {
    errors.unit = 'This is a required field';
  } else if (reward.unit <= 0) {
    errors.unit = 'Number of winners should be greater than 0';
  }

  return errors;
};

export const validateOtherPromptReward = (reward) => {
  const errors = {};

  if (!reward.title) {
    errors.title = 'This is a required field';
  }

  if (!reward.unit) {
    errors.unit = 'This is a required field';
  } else if (reward.unit <= 0) {
    errors.unit = 'Number of winners should be greater than 0';
  }

  return errors;
};

export const validatePromptReward = (reward) => {
  switch (reward.rewardTypeId) {
    case PROMPT_REWARD_TYPES.eth:
      return validateETHPromptReward(reward);
    case PROMPT_REWARD_TYPES.other:
    case PROMPT_REWARD_TYPES.exhibition:
      return validateOtherPromptReward(reward);
    default:
      return {};
  }
};

export const validatePromptRewards = (rewards) => {
  const errors = rewards.map(validatePromptReward);
  const validated = errors.filter((error) => !!Object.keys(error).length).length === 0;

  return {
    validated,
    errors,
  };
};

export const updateOrCreatePromptRewards = ({ rewards, promptId }) => {
  return Promise.all(
    rewards?.map(({ id, promptId: _, Files: __, Draws: ___, deleted, ...rest }, order) => {
      if (deleted) {
        return id ? deletePromptReward(id) : () => {};
      }

      if (!id) {
        return createPromptReward({
          ...rest,
          order,
          promptId,
        });
      }

      return updatePromptReward(id, { ...rest, order });
    }) || []
  );
};

export const generatePromptRewardForm = (reward) => {
  const { Files: files, ...rest } = reward;
  const file = files?.[0];

  return {
    ...rest,
    file: file
      ? {
          url: file.fileUrl,
          fileName: `Reward-image-${file.id}.${file.fileExtension}`,
          type: file.fileType,
          size: file.byteSize,
        }
      : null,
  };
};

export const getPOPReward = (rewards) => {
  return rewards.find((reward) => reward.rewardTypeId === PROMPT_REWARD_TYPES.pop);
};
