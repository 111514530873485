import React from 'react';
import { Divider } from '../../Atoms/Divider';
import { ShortCountdownRenderer } from '/design-systems/Atoms/DateCountDown/ShortCountdownRenderer';
import { AssetPrice } from '/design-systems/Molecules/Assets/AssetPrice';
import { Typography } from '../../Atoms/Typography';
import Countdown from 'react-countdown';
import { mergeClassNames } from '/utils/string';

export const OfferWindowDetails = ({
  offerLabel = '',
  offerPriceTextSize = 'large',
  offerPrice,
  isDividerRequired = false,
  isForPreviewModal = false,
  countdownLabel = '',
  countdownLabelSize = 'small',
  countdownStyle = '',
  countdownTime,
  countdownTypographyVariant = 'h5',
  withCountDown = true,
  className = '',
  id = 'offer-window-details',
  auctionId = '',
  blockchain
}) => {
  return (
    <div
      id={id}
      data-auction-id={auctionId}
      className={mergeClassNames(
        'flex w-full flex-col items-center gap-2 md:flex-row',
        className
      )}
    >
      <div
        className={mergeClassNames(
          'flex flex-col gap-2',
          isForPreviewModal ? 'gap-2' : 'mt-2'
        )}
      >
        {isForPreviewModal && (
          <Typography
            variant="small"
            weight="medium"
            id="offer-label"
            className="whitespace-nowrap"
          >
            {offerLabel}
          </Typography>
        )}

        <AssetPrice
          id="offer-label"
          className={mergeClassNames(
            !isForPreviewModal && 'justify-start pr-10'
          )}
          isOffer={true}
          label={!isForPreviewModal ? offerLabel : ''}
          price={offerPrice}
          size={offerPriceTextSize}
          blockchain={blockchain}
        />
      </div>

      {isDividerRequired && (
        <Divider
          size="smallest"
          variant="vertical"
          className="divide-neutral-200"
        />
      )}

      {withCountDown && (
        <div
          className={mergeClassNames(
            'flex flex-col gap-2',
            !isForPreviewModal && 'mt-2'
          )}
        >
          <Typography
            variant={countdownLabelSize}
            weight="medium"
            className="whitespace-nowrap"
          >
            {countdownLabel}
          </Typography>

          {countdownTime && (
            <Countdown
              date={new Date(countdownTime)}
              intervalDelay={0}
              precision={3}
              renderer={({ days, hours, minutes, seconds }) => {
                return (
                  <ShortCountdownRenderer
                    days={days}
                    hours={hours}
                    minutes={minutes}
                    seconds={seconds}
                    countdownStyle={countdownStyle}
                    countdownTypographyVariant={countdownTypographyVariant}
                    isForPreviewModal={isForPreviewModal}
                  />
                );
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
