import React from 'react';
import ExternalLinkIcon from '@heroicons/react/outline/ExternalLinkIcon';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { ROUTES } from '/config/routes';
import { POP_CLAIM_TX_STATUSES, BLOCK_EXPLORER } from '/utils/constants';
import { openLink } from '/utils/url';
import Confetti from 'react-confetti';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Image } from '/design-systems/Atoms/Image';

export const POPClaimModal = ({
  open,
  title,
  file,
  status,
  transaction,
  promptTitle,
  onClose
}) => {
  const router = useRouter();
  const currentUser = useSelector((state) => state.user.current);

  const handleViewTransaction = () => {
    openLink(`${BLOCK_EXPLORER()}/tx/${transaction}`);
  };

  const handleViewProfile = () => {
    router.push(
      ROUTES.profile.view({
        profileId:
          currentUser?.username ||
          retrieveMainWalletAddressFromWalletAddresses(
            currentUser?.WalletAddresses
          ).address
      })
    );
    onClose();
  };

  return (
    <Modal
      maxWidth={582}
      open={open}
      autoHeight
      showCloseIconOnTop
      onClose={onClose}
    >
      {status === POP_CLAIM_TX_STATUSES.validating && (
        <>
          <Typography
            variant="h5"
            heading
            align="center"
            weight="semibold"
            className="mb-6"
          >
            Waiting for transaction...
          </Typography>
          <div className="mb-4 flex justify-center">
            <div className="h-[200px] w-[200px] overflow-hidden rounded-full">
              <Image
                src={file?.url}
                className="h-full w-full"
                style={{ objectFit: 'cover' }}
              />
            </div>
          </div>
          <div>
            <Typography
              align="center"
              weight="medium"
              variant="large"
              className="mb-4"
            >
              {promptTitle}
            </Typography>
          </div>
          <Typography align="center">
            Waiting for the POP Claiming transaction to complete.
          </Typography>
        </>
      )}

      {status === POP_CLAIM_TX_STATUSES.confirming && (
        <>
          <Typography
            variant="h5"
            heading
            align="center"
            weight="semibold"
            className="mb-6"
          >
            Your POP is being added...
          </Typography>
          <div className="mb-4 flex justify-center">
            <div className="h-[200px] w-[200px] overflow-hidden rounded-full">
              <Image
                src={file?.url}
                className="h-full w-full"
                style={{ objectFit: 'cover' }}
              />
            </div>
          </div>
          <div>
            <Typography
              align="center"
              weight="medium"
              variant="large"
              className="mb-4"
            >
              {promptTitle}
            </Typography>
          </div>
          <Typography align="center" className="flex justify-center gap-x-1">
            This may take a few minutes.
            <span
              className="flex cursor-pointer items-center text-[#2044FF]"
              onClick={handleViewTransaction}
            >
              View transaction&nbsp;&nbsp;
              <ExternalLinkIcon className="h-[16px] w-[16px]" />
            </span>
          </Typography>
        </>
      )}

      {status === POP_CLAIM_TX_STATUSES.confirmed && (
        <>
          <Confetti />
          <Typography
            variant="h5"
            heading
            align="center"
            weight="semibold"
            className="mb-6"
          >
            Congrats on your new POP 🎉
          </Typography>
          <div className="mb-4 flex justify-center">
            <div className="h-[200px] w-[200px] overflow-hidden rounded-full">
              <Image
                src={file?.url}
                className="h-full w-full"
                style={{ objectFit: 'cover' }}
              />
            </div>
          </div>
          <div>
            <Typography
              align="center"
              weight="medium"
              variant="large"
              className="mb-4"
            >
              {promptTitle}
            </Typography>
          </div>
          <div className="">
            <Typography className="mb-2">
              This POP is an on-chain record of participation in our{' '}
              {promptTitle}. All participants are eligible to claim their POP.
            </Typography>
            <Typography className="mb-4 flex items-center gap-1">
              You can check out your POP collection anytime in{' '}
              <span
                className="flex cursor-pointer items-center text-[#2044FF]"
                onClick={handleViewProfile}
              >
                your user profile.
              </span>
            </Typography>
          </div>
        </>
      )}
    </Modal>
  );
};
