import React, { useMemo } from 'react';
import { Spinner } from '../Spinner';
import { Image } from '../Image';
import { mergeClassNames } from '/utils/string';
import { profilePlaceholderImg } from '/utils/image';

export const Avatar = ({
  id,
  src,
  rounded,
  roundedSize,
  size = 40,
  bordered,
  shadow,
  uploading,
  onClick,
  fileType,
  resolution,
  staticFile,
  className = ''
}) => {
  const classNames = mergeClassNames('relative');
  const imageClassNames = mergeClassNames(
    'block flex justify-center items-center overflow-hidden',
    `w-[${size}px] h-[${size}px] min-w-[${size}px] min-h-[${size}px]`,
    bordered &&
      `border-2 border-white dark:border-white/20 dark:hover:border-white transition duration-200`,
    shadow && 'shadow shadow-md shadow-white',
    rounded && 'rounded-full',
    roundedSize && `rounded-${roundedSize}`,
    onClick && 'hover:bg-neutral-50  dark:hover:bg-neutral-700',
    className
  );

  const placeholder = useMemo(() => profilePlaceholderImg(id), [id]);

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <div className={classNames} onClick={handleClick}>
      <div className={imageClassNames}>
        {uploading ? (
          <Spinner />
        ) : (
          <Image
            id={id}
            className="h-full w-full object-cover"
            src={src}
            placeholder={placeholder?.src}
            alt="avatar"
            fileType={fileType}
            resolution={resolution}
            staticFile={staticFile}
          />
        )}
      </div>
    </div>
  );
};
