import React from 'react';
import { Button } from '/design-systems/Atoms/Button';
import { Typography } from '/design-systems/Atoms/Typography';

export const ApproveCurrency = ({ onSubmit, isSubmitting }) => {
  return (
    <div className='flex flex-col items-center gap-y-5'>
      <Typography heading variant='h6' weight='semibold'>
        Approve Currency
      </Typography>

      <Typography variant='small' className='align-center flex self-start text-[#111827]'>
        Approve the use of{' '}
        <Typography variant='small' weight='semibold' className='ext-[#111827] mx-1'>
          WETH
        </Typography>{' '}
        in your wallet.
      </Typography>

      <Typography variant='small' className='align-center flex self-start text-[#111827]'>
        You only need to do this once.
      </Typography>

      <Button
        variant='primary'
        color='black'
        className='w-full'
        onClick={onSubmit}
        loading={isSubmitting}
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Confirming Transation...' : 'Confirm Transation'}
      </Button>
    </div>
  );
};
