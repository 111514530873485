import React, { useState, useEffect, useRef } from 'react';
import ChevronDownIcon from '@heroicons/react/outline/ChevronDownIcon';

export const ReadMoreLite = ({ height = 28, title = 'See more', completed = false, children }) => {
  const childRef = useRef();
  const [showFullBody, setShowFullBody] = useState(false);
  const className = `
    ${!showFullBody && `max-h-[${height}px]`} overflow-hidden relative
  `;

  useEffect(() => {
    if (!completed || !height) return;

    setTimeout(() => {
      const childHeight = childRef?.current?.clientHeight || 0;
      if (childHeight > height) setShowFullBody(false);
    }, 300);
  }, [completed, height]);

  return (
    <div className='relative'>
      <div className={className} ref={childRef}>
        {children}
      </div>
      {!showFullBody && completed && (
        <>
          <div
            className='flex cursor-pointer items-center gap-x-2 text-neutral-500 hover:text-neutral-700'
            onClick={() => setShowFullBody(true)}
          >
            {title}
            <ChevronDownIcon className='h-4 w-4' />
          </div>
        </>
      )}
    </div>
  );
};
