import React from 'react';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { Button } from '/design-systems/Atoms/Button';
import { Typography } from '/design-systems/Atoms/Typography';

export const BackButton = ({ className, onClick, title }) => (
  <Button
    variant="icon-only"
    color="neutral"
    iconOnly
    rounded
    onClick={onClick}
    className={className}
  >
    <ArrowLeftIcon className="h-5 w-5 font-medium" />
    {title && <Typography variant="medium">{title}</Typography>}
  </Button>
);
