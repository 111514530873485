import React from 'react';

import { Alert } from '/design-systems/Atoms/Alert';
import { Divider } from '/design-systems/Atoms/Divider';
import { Modal } from '/design-systems/Atoms/Modal';
import { InviteForm } from '/design-systems/Molecules/Forms/InviteForm';
import { ModalTitleRow } from '/design-systems/Molecules/Modals/ModalTitleRow';

import {
  PROJECT_INVITATION_TITLE,
  PROJECT_INVITATION_DESCRIPTION
} from '/config/project';
import { Typography } from '/design-systems/Atoms/Typography';

export const InviteFormModal = ({
  open,
  value,
  title,
  onClose,
  onSubmit,
  invitationDescription = PROJECT_INVITATION_DESCRIPTION,
  invitationTitle = PROJECT_INVITATION_TITLE,
  showMessage = true,
  invitationType = 'project'
}) => {
  return (
    <Modal autoHeight open={open} onClose={onClose}>
      <div className="flex flex-col gap-4">
        <ModalTitleRow title={invitationTitle} onClose={onClose} />
        <Divider size="smallest" />
        <Alert variant="grey">{invitationDescription}</Alert>
        <InviteForm
          title={title}
          value={value}
          onSubmit={onSubmit}
          showMessage={showMessage}
          invitationType={invitationType}
        />
      </div>
    </Modal>
  );
};
