import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { ReadMoreLite } from '/design-systems/Molecules/ReadMoreLite';
import { Banner } from '/design-systems/Molecules/Banner';
import { getFilesFromAsset } from '/utils/asset';
import { getImageProps } from '/utils/prompt-submission';
import useToggle from '/hooks/useToggle';
import { mergeClassNames } from '/utils/string';
import { stripHtml } from 'string-strip-html';
import { Modal } from '/design-systems/Atoms/Modal';
import { Image } from '/design-systems/Atoms/Image';
import { Typography } from '/design-systems/Atoms/Typography';
import { Alert } from '/design-systems/Atoms/Alert';
import { AttentionIcon } from '/design-systems/Atoms/Icon/attention';
import { Button } from '/design-systems/Atoms/Button';

export const MergeAssetsModal = ({ assets, onClose, onConfirm }) => {
  const [previewAsset, setPreviewAsset] = useState();

  const mintedAssets = useMemo(() => assets?.filter((asset) => Boolean(asset.AssetMint)) ?? [], [assets]);
  const canMerge = useMemo(() => mintedAssets?.length < 2 && !!previewAsset, [mintedAssets, previewAsset]);
  const canChangeAsset = useMemo(() => !Boolean(previewAsset?.AssetMint) && canMerge, [previewAsset, canMerge]);

  const [showPreview, togglePreview] = useToggle(false);
  const [isMerging, toggleIsMerging] = useToggle(false);

  const handleConfirm = useCallback(async () => {
    toggleIsMerging();
    await onConfirm({
      assetIds: assets.map(({ id }) => id).filter((id) => id !== previewAsset.id),
      selectedAssetId: previewAsset.id,
    });
    toggleIsMerging();
  }, [assets, previewAsset, onConfirm]);

  useEffect(() => {
    setPreviewAsset(mintedAssets?.[0] ?? assets?.[0]);
  }, [mintedAssets, assets]);

  return (
    <Modal
      id='merge-assets-modal'
      open
      autoHeight={true}
      showCloseIconOnTop={true}
      onClose={!isMerging && onClose}
      className='flex flex-col gap-6'
    >
      <Typography weight='semibold' variant='h4' heading align='center'>
        Merge pieces
      </Typography>
      <Banner
        variant='warning'
        icon={<AttentionIcon />}
        title='This action cannot be undone'
        description='This will permanently merge the records of participation into a single piece.'
      />
      <section className='flex flex-col gap-4'>
        {showPreview ? (
          <div className='flex gap-8'>
            <div className='flex w-full flex-col gap-4'>
              <section>
                <Typography weight='medium'>Title</Typography>
                <Typography>{previewAsset.title}</Typography>
              </section>
              <ReadMoreLite height={200}>
                <Typography weight='medium'>Description</Typography>
                <Typography>{stripHtml(previewAsset.description ?? '').result}</Typography>
              </ReadMoreLite>
            </div>

            <div className='w-full'>
              <Image
                {...getImageProps(getFilesFromAsset(previewAsset))}
                className='object-fit max-h-[300px] w-full rounded-xl'
              />
            </div>
          </div>
        ) : (
          <>
            <Typography colorVariant='secondary'>The following pieces will be merged:</Typography>

            <div className='flex max-h-[300px] flex-col gap-2 overflow-auto'>
              {assets?.map((asset) => {
                const files = getFilesFromAsset(asset);
                const chain = asset.AssetMint?.Token?.chain;

                return (
                  <div
                    key={asset.id}
                    className={mergeClassNames(
                      'flex items-center gap-4 rounded-xl p-2',
                      previewAsset?.id === asset.id ? 'bg-neutral-100' : canChangeAsset && 'cursor-pointer'
                    )}
                    onClick={() => canChangeAsset && setPreviewAsset(asset)}
                  >
                    <Image
                      {...getImageProps(files)}
                      className='h-[64px] w-[64px] min-w-[64px] overflow-hidden rounded-xl border object-cover'
                    />
                    <Typography variant='medium' weight='medium' overflow='truncate' className='flex-1'>
                      {asset.title}
                    </Typography>
                    {previewAsset?.id === asset.id && (
                      <Typography weight='medium' colorVariant='secondary' className='pr-2'>
                        Primary
                      </Typography>
                    )}
                  </div>
                );
              })}
            </div>

            {!canMerge && (
              <Alert color='warning'>
                Unable to merge: 2 or more pieces you have selected are already minted on the blockchain.
              </Alert>
            )}
          </>
        )}

        <div className='flex justify-between'>
          <Button
            id='cancel-asset-merge-button'
            type='button'
            variant='secondary'
            color='primary'
            disabled={isMerging}
            onClick={showPreview ? togglePreview : onClose}
          >
            {showPreview ? 'Back' : 'Cancel'}
          </Button>
          <Button
            id='confirm-asset-merge-button'
            type='button'
            color='black'
            variant='primary'
            loading={isMerging}
            disabled={!canMerge}
            onClick={showPreview ? handleConfirm : togglePreview}
          >
            {showPreview ? 'Confirm' : 'Preview'}
          </Button>
        </div>
      </section>
    </Modal>
  );
};
