import { useQuery } from '@tanstack/react-query';
import { getTokenRoyaltyRecipients } from '/services/joynApi/users/token';
import { QUERY_KEYS } from '/utils/queries';
import { useMemo } from 'react';

export const useTokenRoyaltyRecipients = ({ tokenId }) => {
  const { data: tokenRoyaltyRecipients, isLoading: isLoadingTokenRoyaltyRecipients } = useQuery(
    [QUERY_KEYS.TOKEN_ROYALTY_RECIPIENTS.GET, tokenId],
    () => getTokenRoyaltyRecipients({ tokenId }),
    {
      enabled: !!tokenId,
      select: (res) => res.data.data,
    }
  );

  return useMemo(
    () => ({
      tokenRoyaltyRecipients,
      isLoadingTokenRoyaltyRecipients,
    }),
    [isLoadingTokenRoyaltyRecipients, tokenRoyaltyRecipients]
  );
};
