import React, { useCallback, useMemo, useEffect } from 'react';
import {
  faSplit,
  faImage,
  faCog,
  faCircleQuestion,
  faUserGroup
} from '@fortawesome/pro-light-svg-icons';
import {
  faSplit as faSplitSolid,
  faImage as faImageSolid,
  faCog as faCogSolid,
  faCircleQuestion as faCircleQuestionSolid,
  faUserGroup as faUserGroupSolid
} from '@fortawesome/pro-solid-svg-icons';

import { TabsSidebar } from '/design-systems/Molecules/TabsSidebar';
import { SidebarTab } from '/design-systems/Atoms/SidebarTab';
import { FloatingDropdown } from '/design-systems/Atoms/FloatingDropdown';

import { useGalleryForm } from '/store-hooks/useGalleryForm';
import { useGalleryDetails } from '/hooks/gallery/useGalleryDetails';

import { useModalManager, MODALS } from '/context/ModalManager';
import { DOC_URLS, DISCORD_URL } from '/utils/constants';

export const GallerySettingsTab = ({ tabId, onChangeTab }) => {
  const { gallery } = useGalleryForm();
  const { canSendRequests } = useGalleryDetails({ gallery });

  const { showModal, closeModal } = useModalManager();

  const tabsWithBadge = useMemo(() => {
    if (canSendRequests) return SettingsTabMenu;

    return SettingsTabMenu.map((tab) => {
      // show badge on Earnings tab, if the user hasn't yet configured recipients
      if (tab.key === SETTINGS_TAB_IDS.EARNINGS) {
        return {
          ...tab,
          showBadge: true
        };
      }
      return tab;
    });
  }, [canSendRequests]);

  const activeTabIndex = useMemo(() => {
    if (!tabId) return 0;
    return SettingsTabMenu.findIndex((menu) => menu.key === tabId);
  }, [tabId]);

  const handleChangeTab = useCallback(
    (tabIndex) => {
      onChangeTab(SettingsTabMenu[tabIndex].key);
    },
    [onChangeTab]
  );

  const showShowcaseIntroModal = () => {
    showModal({
      modalId: MODALS.SHOWCASE_INTRO_MODAL,
      props: {
        onClose: closeModal
      }
    });
  };

  const handleIntroClick = () => {
    showShowcaseIntroModal();
  };

  const handleHelpOptionClick = (itemLabel) => {
    switch (itemLabel) {
      case HELP_OPTION_LABELS.INTRO:
        handleIntroClick();
        return;
      case HELP_OPTION_LABELS.DOCS:
        window.open(DOC_URLS.SHOWCASE, '_blank');
        return;
      case HELP_OPTION_LABELS.SUPPORT:
        window.open(DISCORD_URL, '_blank');
        return;
      default:
        return;
    }
  };

  // show Showcase intro modal for first-time users
  useEffect(() => {
    const hasSeenModal = localStorage.getItem(SHOWCASE_MODAL_SEEN_KEY);
    if (!hasSeenModal) {
      showShowcaseIntroModal();
      localStorage.setItem(SHOWCASE_MODAL_SEEN_KEY, 'true');
    }
  }, []);

  return (
    <TabsSidebar
      tabs={tabsWithBadge}
      tabIndex={activeTabIndex}
      onChangeTab={handleChangeTab}
      helpButton={
        <FloatingDropdown
          items={Object.values(HELP_OPTION_LABELS)}
          onDropDownClick={handleHelpOptionClick}
          customPosition={{ bottom: 80, left: 16 }}
        >
          <SidebarTab
            key="sidebar-help-tab"
            icon={faCircleQuestion}
            activeIcon={faCircleQuestionSolid}
            title="Help"
          />
        </FloatingDropdown>
      }
    />
  );
};

export const SETTINGS_TAB_IDS = {
  PIECES: 'pieces',
  EARNINGS: 'earnings',
  ACCESS: 'access',
  SETTINGS: 'settings'
};

const SETTINGS_TABS = {
  PIECES: {
    key: SETTINGS_TAB_IDS.PIECES,
    title: 'Pieces',
    icon: faImage,
    activeIcon: faImageSolid
  },
  EARNINGS: {
    key: SETTINGS_TAB_IDS.EARNINGS,
    title: 'Earnings',
    icon: faSplit,
    activeIcon: faSplitSolid
  },
  ACCESS: {
    key: SETTINGS_TAB_IDS.ACCESS,
    title: 'Access',
    icon: faUserGroup,
    activeIcon: faUserGroupSolid
  },
  SETTINGS: {
    key: SETTINGS_TAB_IDS.SETTINGS,
    title: 'Settings',
    icon: faCog,
    activeIcon: faCogSolid
  }
};

const HELP_OPTION_LABELS = {
  INTRO: 'How Showcase works',
  DOCS: 'Documentation',
  SUPPORT: 'Contact support'
};

const SettingsTabMenu = [
  SETTINGS_TABS.PIECES,
  SETTINGS_TABS.EARNINGS,
  SETTINGS_TABS.ACCESS,
  SETTINGS_TABS.SETTINGS
];

const SHOWCASE_MODAL_SEEN_KEY = 'showcaseIntroModalSeen';
