import React, { useCallback, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons';
import { faBookmark as faBookmarkSolid } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { mergeClassNames } from '/utils/string';
import useToggle from '/hooks/useToggle';
import { saveAsset, unsaveAsset } from '/services/joynApi/users/assets';
import { FloatingTooltip } from '/design-systems/Atoms/FloatingTooltip';
import { useAuthRouterHelpers } from '/hooks/router';

export const SaveEntityButton = ({
  id,
  entityId,
  entity,
  saved = false,
  className = '',
  iconClassName = ''
}) => {
  const currentUser = useSelector((state) => state.user.current);
  const isAuthorized = useMemo(() => Boolean(currentUser?.id), [currentUser]);
  const { navigateToLogin } = useAuthRouterHelpers();

  const [savedState, toggleSavedState, setSavedState] = useToggle(saved);

  useEffect(() => setSavedState(saved), [saved]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSaveAsset = useCallback(({ assetId, saved }) => {
    const func = saved ? unsaveAsset : saveAsset;
    return func({ assetId });
  }, []);

  const handleSaveEntity = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!isAuthorized) {
        navigateToLogin({ isForce: true });
        return;
      }

      switch (entity) {
        case ENTITY_NAMES.ASSET:
          handleSaveAsset({ assetId: entityId, saved: savedState });
          break;

        default:
          throw 'Entity name is missing';
      }

      const message = savedState
        ? 'Removed from your Saves.'
        : 'Added to your Saves.';

      toast(message, { type: 'success' });

      toggleSavedState();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entityId, savedState, isAuthorized]
  );

  return (
    <div>
      <FloatingTooltip
        label={savedState ? 'Remove from Saves' : 'Save'}
        placement="bottom"
        widthClassName={savedState ? '' : 'min-w-fit'}
      >
        <button
          id={id || `save-entity-${entityId}`}
          type="button"
          className={mergeClassNames(
            `save-${entity}`,
            'flex h-9 w-9 items-center justify-center rounded-full',
            savedState ? 'bg-black/20' : 'bg-black/20 hover:bg-black/30',
            className
          )}
          onClick={handleSaveEntity}
        >
          <FontAwesomeIcon
            icon={savedState ? faBookmarkSolid : faBookmark}
            className={mergeClassNames('h-[18px] w-[18px]', iconClassName)}
          />
        </button>
      </FloatingTooltip>
    </div>
  );
};

export const ENTITY_NAMES = {
  ASSET: 'asset'
};
