import React from 'react';

/**
 * @description The divider component
 * @param {string} size is enum('large', 'medium', 'small', 'smaller', 'smallest', 'none')
 * large: 32px
 * medium: 24px
 * small: 16px
 * smaller: 8px;
 * smallest: 4px;
 * none: 0px;
 * @param {string} variant is enum ('horizontal', 'vertical')
 */

import { mergeClassNames } from '/utils/string';

export const Divider = ({
  size = 'large',
  variant = 'horizontal',
  className: className_ = ''
} = {}) => {
  const getMargin = () => {
    switch (size) {
      case 'large':
        return 32;
      case 'medium':
        return 24;
      case 'small':
        return 16;
      case 'smaller':
        return 8;
      case 'smallest':
        return 4;
      case 'none':
        return 0;
    }
  };

  return (
    <div
      className={mergeClassNames(
        'divider bg-neutral-300 dark:bg-neutral-700',
        variant === 'horizontal'
          ? `h-[1px] w-full my-[${getMargin()}px]`
          : `h-full w-[1px] mx-[${getMargin()}px]`,
        className_
      )}
    />
  );
};
