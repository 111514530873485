import React, { useMemo } from 'react';
import { Typography } from '/design-systems/Atoms/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

export const ModalTitleRow = ({
  title,
  count,
  onClose,
  showBack,
  onBackClick
}) => {
  const leftButton = useMemo(() => {
    if (showBack && onBackClick) {
      return (
        <button class="flex items-center text-gray-800" onClick={onBackClick}>
          <FontAwesomeIcon icon={faArrowLeft} className="h-5 w-5" />
        </button>
      );
    }

    return (
      <button
        className="modal-close-button flex h-full cursor-pointer items-center"
        onClick={onClose}
      >
        <FontAwesomeIcon icon={faX} className="h-4 w-4 dark:text-white" />
      </button>
    );
  }, [showBack, onBackClick, onClose]);

  return (
    <div className="relative flex w-full items-center">
      {leftButton}

      <div className="flex w-full items-center justify-center gap-1">
        <Typography
          variant="large"
          weight="semibold"
          className="text-[#111827]"
        >
          {title}
        </Typography>
        {count && (
          <Typography
            heading
            variant="h6"
            weight="semibold"
            className="text-[#6B7280]"
          >
            ({count})
          </Typography>
        )}
      </div>

      {/* placeholder icon, to centre-align title */}
      <div className="h-4 w-4"></div>
    </div>
  );
};
