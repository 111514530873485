import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import useToggle from '/hooks/useToggle';

import { getToken } from '/services/joynApi/users/token';

import { SOCKET_SLOTS, useSocketManager } from '/context/SocketManager';
import { QUERY_KEYS } from '/utils/queries';

const LOAD_NFT_TIMEOUT = 10000;

export const useToken = ({
  tokenId,
  tokenAddress,
  enabled = true,
  link,
  chain,
  withMinter = false,
  withOwner = false
}) => {
  const [token, setToken] = useState();
  const [isLoadingToken, , setIsLoadingToken] = useToggle(false);
  const { joynSocket } = useSocketManager();

  const { data: fetchTokenJobInfo } = useQuery(
    [QUERY_KEYS.TOKEN.GET, tokenId, tokenAddress, link],
    () =>
      getToken({
        tokenAddress,
        link,
        tokenId,
        withMinter,
        withOwner,
        chain,
        queryAsync: true
      }),
    {
      enabled: ((!!tokenId && !!tokenAddress) || !!link) && enabled,
      select: (res) => res.data.data
    }
  );

  const onLoadingTokenTimeout = () => {
    setToken(undefined);
    setIsLoadingToken(false);

    joynSocket.socket.off(SOCKET_SLOTS.USER_NFT_DETAILS);
    toast('We had a problem loading the nft, try again later', {
      type: 'error'
    });
  };

  useEffect(
    () => {
      if (!joynSocket || !fetchTokenJobInfo) return;
      setIsLoadingToken(true);

      const socketListener = (nftDetails) => {
        setToken(nftDetails);
        setIsLoadingToken(false);

        if (!nftDetails) {
          toast('We were not able to find an nft', { type: 'error' });
        }
      };

      joynSocket.socket.on(SOCKET_SLOTS.USER_NFT_DETAILS, socketListener);
      return () => {
        // Clean up the listener when the component unmounts
        joynSocket.socket.off(SOCKET_SLOTS.USER_NFT_DETAILS, socketListener);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [joynSocket, fetchTokenJobInfo]
  );

  useEffect(
    () => {
      let timeoutId;

      if (isLoadingToken) {
        // Set up a timeout to wait the socket response
        timeoutId = setTimeout(() => {
          onLoadingTokenTimeout();
        }, LOAD_NFT_TIMEOUT);
      }

      // This will clear the timeout if isLoadingToken changes to false before 10 seconds
      return () => {
        clearTimeout(timeoutId);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingToken] // Only re-run the effect if isLoadingToken changes
  );

  return useMemo(
    () => ({
      token,
      isLoadingToken
    }),
    [token, isLoadingToken]
  );
};
