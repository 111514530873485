import React, { useMemo } from 'react';
import moment from 'moment';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { openLink } from '/utils/url';
import { getTransactionLink, getChainIdFromChainName } from '/utils/web3';
import { toShortAddress } from '/utils/string';
import { getAvatar } from '/utils/image';
import { TOKEN_ACTIVITY_TYPES } from '/utils/constants';
import placeholder from '/images/placeholder.svg';
import { Avatar } from '/design-systems/Atoms/Avatar';
import { Image } from '/design-systems/Atoms/Image';
import { AssetPrice } from '/design-systems/Molecules/Assets/AssetPrice';
import { Typography } from '/design-systems/Atoms/Typography';

export const TokenActivityRow = ({ activity, blockchain }) => {
  const receiverAvatar = useMemo(
    () => getAvatar(activity.toAddressOwner),
    [activity]
  );

  const showPrice = useMemo(
    () =>
      activity.type === TOKEN_ACTIVITY_TYPES.SALE ||
      activity.type === TOKEN_ACTIVITY_TYPES.LIST,
    [activity]
  );

  const displayName = useMemo(
    () => toShortAddress(activity.toAddress),
    [activity]
  );

  const activityDescription = useMemo(() => {
    switch (activity.type) {
      case TOKEN_ACTIVITY_TYPES.SALE:
        return (
          <div className="flex items-center gap-1">
            <Typography variant="medium" weight="semibold">
              Sold
            </Typography>
            <Typography variant="medium">to {displayName}</Typography>
          </div>
        );

      case TOKEN_ACTIVITY_TYPES.MINT:
        return (
          <div className="flex items-center gap-1">
            <Typography variant="medium" weight="semibold">
              Minted
            </Typography>
            <Typography variant="medium">by {displayName}</Typography>
          </div>
        );

      case TOKEN_ACTIVITY_TYPES.LIST:
        return (
          <div className="flex items-center gap-1">
            <Typography variant="medium" weight="semibold">
              Listed
            </Typography>
            <Typography variant="medium">
              by {toShortAddress(activity.fromAddress)}
            </Typography>
          </div>
        );

      case TOKEN_ACTIVITY_TYPES.TRANSFER:
        return (
          <div className="flex items-center gap-1">
            <Typography variant="medium" weight="semibold">
              Transferred
            </Typography>
            <Typography variant="medium">to {displayName}</Typography>
          </div>
        );
      default:
        return activity.type;
    }
  }, [activity.fromAddress, activity.type, displayName]);

  return (
    <div className="flex items-start justify-between">
      <div className="flex items-start gap-3">
        {/* Avatar */}
        {receiverAvatar ? (
          <Avatar src={receiverAvatar} size={24} rounded />
        ) : (
          <Image
            src={placeholder.src}
            className="h-[24px] w-[24px] rounded-full"
            alt="avatar-placeholder"
            staticFile
          />
        )}
        {/* description & timestamp */}
        <div className="flex flex-col gap-1">
          <div>{activityDescription}</div>
          <Typography colorVariant="secondary">
            {moment(activity.createdAt).format('MMM D, YYYY [at] h:mma')}
          </Typography>
        </div>
      </div>
      <div className="flex items-center gap-3">
        {/* price */}
        {showPrice && (
          <AssetPrice
            price={activity.price.amount.decimal}
            blockchain={blockchain}
          />
        )}
        {/* tx link */}
        {activity.txHash && (
          <ExternalLinkIcon
            className="hover:text-neutral-black h-5 w-5 cursor-pointer text-neutral-500"
            onClick={() =>
              openLink(
                getTransactionLink({
                  chainId: getChainIdFromChainName(blockchain),
                  txHash: activity.txHash
                })
              )
            }
          />
        )}
      </div>
    </div>
  );
};
