import { ethers } from 'ethers';
import { isProduction } from '/utils/environment';
import { BLOCKCHAINS, MARKETPLACES } from './constants';
import { mainnet, sepolia, optimism, optimismGoerli } from 'wagmi/chains';

export const CHAIN_IDS = {
  mainnet: mainnet?.id,
  sepolia: sepolia?.id,
  optimism: optimism?.id,
  optimismGoerli: optimismGoerli?.id,
  tezos: 'TEZOS-MAINNET',
  ghost: 'TEZOS-TESTNET'
};

export const SUPPORTED_CHAINS = isProduction()
  ? [mainnet, optimism]
  : [mainnet, optimism, sepolia, optimismGoerli];

export const NETWORKS = {
  EVM: [
    CHAIN_IDS.mainnet,
    CHAIN_IDS.sepolia,
    CHAIN_IDS.optimism,
    CHAIN_IDS.optimismGoerli
  ],
  TEZOS: [CHAIN_IDS.tezos, CHAIN_IDS.ghost]
};

export const CHAIN_NAMES = {
  [CHAIN_IDS.mainnet]: 'ETH Main',
  [CHAIN_IDS.sepolia]: 'ETH Sepolia',
  [CHAIN_IDS.optimism]: 'OP main',
  [CHAIN_IDS.optimismGoerli]: 'Optimism Goerli'
};

export const DEFAULT_CHAIN_ID = isProduction()
  ? CHAIN_IDS.mainnet
  : CHAIN_IDS.sepolia;

export const ALCHEMY_RPC_URL = {
  [CHAIN_IDS.mainnet]: `https://eth-mainnet.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_ETH_MAINNET_KEY}`,
  [CHAIN_IDS.sepolia]: `https://eth-sepolia.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_ETH_SEPOLIA_KEY}`,
  [CHAIN_IDS.optimism]: `https://opt-mainnet.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_OPT_MAINNET_KEY}`,
  [CHAIN_IDS.optimismGoerli]: `https://opt-goerli.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_OPT_GOERLI_KEY}`
};

// Todo: no need to have once we implement ipfs uploading
export const JOYN_ASSET_BASE_TOKEN_URI = `https://live---metadata-5covpqijaa-uc.a.run.app/metadata`;

export const BLOCK_SCAN_EXPLORERS = {
  [CHAIN_IDS.mainnet]: 'https://etherscan.io',
  [CHAIN_IDS.sepolia]: 'https://sepolia.etherscan.io',
  [CHAIN_IDS.tezos]: 'https://tzkt.io',
  [CHAIN_IDS.ghost]: 'https://ghostnet.tzkt.io',
  [CHAIN_IDS.optimism]: 'https://optimistic.etherscan.io',
  [CHAIN_IDS.optimismGoerli]: 'https://goerli-optimism.etherscan.io/'
};

export const getTransactionLink = ({ chainId, txHash }) => {
  return `${BLOCK_SCAN_EXPLORERS[chainId]}/tx/${txHash}`;
};

export const getContractLink = ({ chainId, address }) => {
  // EVM networks
  if (NETWORKS.EVM.includes(chainId)) {
    return `${BLOCK_SCAN_EXPLORERS[chainId]}/address/${address}`;
  }

  // TEZOS networks
  if (NETWORKS.TEZOS.includes(chainId)) {
    return `${BLOCK_SCAN_EXPLORERS[chainId]}/${address}`;
  }

  return '';
};

export const getMarketplaceName = ({ blockchain }) => {
  return blockchain === BLOCKCHAINS.TEZOS
    ? MARKETPLACES.objkt
    : MARKETPLACES.opensea;
};

/**
 * Get Marketplace link
 *
 * Opensea
 * LooksRare
 */

export const getNetworkByChain = (blockchain) => {
  switch (blockchain) {
    case BLOCKCHAINS.ETHEREUM:
      return isProduction() ? 'ethereum' : 'sepolia';
    case BLOCKCHAINS.OPTIMISM:
      return isProduction() ? 'optimism' : 'optimism-goerli';
  }
};

export const getChain = ({ chainId, chain }) => {
  chainId = chainId ? chainId : getChainIdFromChainName(chain);
  return SUPPORTED_CHAINS.find((chain) => chain?.id === chainId);
};

export const getMarketplaceLink = ({
  blockchain,
  tokenId,
  tokenAddress,
  marketplace
}) => {
  if (blockchain === BLOCKCHAINS.TEZOS) {
    return `https://objkt.com/asset/${tokenAddress}/${tokenId}`;
  }

  if (marketplace === MARKETPLACES.opensea) {
    const chain = getNetworkByChain(blockchain);
    const prefix = isProduction() ? '' : 'testnets.';
    return `https://${prefix}opensea.io/assets/${chain}/${tokenAddress}/${tokenId}`;
  } else if (marketplace === MARKETPLACES.looksrare) {
    const prefix = DEFAULT_CHAIN_ID === CHAIN_IDS.mainnet ? '' : 'sepolia.';
    return `https://${prefix}looksrare.org/collections/${tokenAddress}/${tokenId}`;
  }
};

/**
 * Get blockchain network Id from blockchain name
 * @param {'ETHEREUM' | 'TEZOS' | 'OPTIMISM'} blockchain
 * @returns
 */
export const getChainIdFromChainName = (blockchain) => {
  switch (blockchain) {
    case BLOCKCHAINS.ETHEREUM:
      return isProduction() ? CHAIN_IDS.mainnet : CHAIN_IDS.sepolia;
    case BLOCKCHAINS.TEZOS:
      return isProduction() ? CHAIN_IDS.tezos : CHAIN_IDS.ghost;
    case BLOCKCHAINS.OPTIMISM:
      return isProduction() ? CHAIN_IDS.optimism : CHAIN_IDS.optimismGoerli;
  }

  return undefined;
};

export const getCustomChain = (chain) => {
  const alchemyRpcURL = ALCHEMY_RPC_URL[chain?.id];

  return {
    ...chain,
    rpcUrls: {
      ...chain?.rpcUrls,
      public: {
        http: [alchemyRpcURL]
      },
      default: {
        http: [alchemyRpcURL]
      }
    }
  };
};

export const CUSTOM_CHAINS = SUPPORTED_CHAINS.map((chain) => {
  return getCustomChain(chain);
});

export const isSupportedChain = ({ chain, chainId }) => {
  if (chainId || chain) {
    return !!SUPPORTED_CHAINS.find(
      ({ id }) => id === (chainId || getChainIdFromChainName(chain))
    );
  }
  return false;
};

export const formatCurrency = ({ amount, decimal }) =>
  ethers.formatUnits(amount, decimal);

export const parseToWei = ({ amount }) => ethers.parseUnits(amount, 'ether');
