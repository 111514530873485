import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

import { ComposerOptionCard } from './ComposerOptionCard';
import { Illustration } from '/design-systems/Atoms/Illustration';
import { Typography } from '/design-systems/Atoms/Typography';

import { useModalManager, MODALS } from '/context/ModalManager';
import { ROUTES } from '/config/routes';
import { META_ENTITIES } from '/utils/constants';
import { mergeClassNames } from '/utils/string';
import { useFeatureFlags } from '/hooks/useFeatureFlags';
import { useAnalytics } from '/hooks/useAnalytics';

export const COMPOSER_OPTION_IDS = {
  ART_PLUS: 'art-plus',
  SHOWCASE: 'earnings',
  STORY: 'settings'
};

export const ComposerOptions = ({
  title = 'Start creating',
  titleVariant,
  prelaunch,
  options = [
    COMPOSER_OPTION_IDS.ART_PLUS,
    COMPOSER_OPTION_IDS.SHOWCASE,
    COMPOSER_OPTION_IDS.STORY
  ],
  numCols = 3,
  filterSpaceId
}) => {
  const { showModal, closeModal } = useModalManager();
  const { isArtPlusFlowEnabled, isShowcaseOpenAccessEnabled } =
    useFeatureFlags();
  const { trackComposerOptionClickEvent } = useAnalytics();

  const router = useRouter();

  const COMPOSER_OPTIONS = {
    [COMPOSER_OPTION_IDS.ART_PLUS]: {
      id: 'create-artwork-card',
      title: isArtPlusFlowEnabled ? 'Art+' : 'Artwork',
      description: isArtPlusFlowEnabled
        ? 'Present and sell a 1/1 or edition with additional context'
        : 'Mint a new piece, without paying gas fees',
      illustration: <Illustration variant="artwork" />,
      onClick: () => {
        trackComposerOptionClickEvent({ option: 'artwork' });

        if (isArtPlusFlowEnabled) {
          showModal({
            modalId: MODALS.ART_PLUS_MODAL,
            props: {
              onClose: closeModal
            }
          });
        } else {
          router.push(ROUTES.asset.new());
        }
      }
    },
    [COMPOSER_OPTION_IDS.SHOWCASE]: {
      id: 'create-showcase-card',
      title: 'Showcase',
      description: 'Curate and present the artworks you love',
      illustration: <Illustration variant="showcase" />,
      onClick: () => {
        trackComposerOptionClickEvent({ option: 'showcase' });

        if (prelaunch && !isShowcaseOpenAccessEnabled) {
          return;
        }

        if (filterSpaceId) {
          router.push({
            pathname: ROUTES.showcase.new(),
            query: { filterSpaceId }
          });
          return;
        }

        showModal({
          modalId: MODALS.NEW_GALLERY_MODAL,
          props: {
            onClose: closeModal,
            galleryType: META_ENTITIES.SHOWCASE
          }
        });
      }
    },
    [COMPOSER_OPTION_IDS.STORY]: {
      id: 'create-story-card',
      title: 'Story',
      description: 'Write an engaging story',
      illustration: <Illustration variant="story" />,
      onClick: () => {
        trackComposerOptionClickEvent({ option: 'story' });

        if (prelaunch) {
          return;
        }

        if (filterSpaceId) {
          router.push({
            pathname: ROUTES.story.new(),
            query: { filterSpaceId }
          });
          return;
        }

        showModal({
          modalId: MODALS.NEW_GALLERY_MODAL,
          props: {
            onClose: closeModal,
            galleryType: META_ENTITIES.STORY
          }
        });
      }
    }
  };

  const gridClassName = useMemo(() => {
    switch (numCols) {
      case 2:
        return 'grid-cols-1 md:grid-cols-2';
      case 3:
        return 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3';
      default:
        return 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3';
    }
  }, [numCols]);

  const titleProps = useMemo(() => {
    switch (titleVariant) {
      case 'h5':
        return { heading: true, variant: 'h5', weight: 'semibold' };
      default:
        return { heading: false, variant: 'large', weight: 'semibold' };
    }
  }, [titleVariant]);

  const getVariant = useCallback(
    (option) => {
      if (prelaunch) {
        if (option === COMPOSER_OPTION_IDS.ART_PLUS) return 'default';
        if (
          option === COMPOSER_OPTION_IDS.SHOWCASE &&
          isShowcaseOpenAccessEnabled
        )
          return 'default';

        return 'coming-soon';
      }

      return 'default';
    },
    [prelaunch, isShowcaseOpenAccessEnabled]
  );

  return (
    <div className="flex h-auto w-auto flex-col gap-4 ">
      <Typography {...titleProps}>{title}</Typography>
      {/* content type cards */}
      <div className={mergeClassNames('grid gap-6', gridClassName)}>
        {options.map((option) => {
          return (
            <ComposerOptionCard
              key={option}
              {...COMPOSER_OPTIONS[option]}
              variant={getVariant(option)}
            />
          );
        })}
      </div>
    </div>
  );
};
