import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

import { Section } from '../../Section';
import { ProductCampaign } from '../ProductCampaign';
import { Divider } from '/design-systems/Atoms/Divider';

import { useGalleriesForAsset } from '/hooks/useGalleries';
import { useGalleryDetails } from '/hooks/gallery/useGalleryDetails';

import { generateArray } from '/utils/array';
import { emptyArray } from '/utils/object';

export const GalleriesForAsset = ({ assetId, titleTextProps }) => {
  const { isLoadingGalleries, galleries } = useGalleriesForAsset({ assetId });

  const placeholders = useMemo(
    () =>
      generateArray(4, (_, index) => (
        <div
          key={index}
          className="aspect-square w-full animate-pulse rounded-md bg-neutral-200"
        />
      )),
    []
  );

  if (emptyArray(galleries)) return <></>;

  return (
    <>
      <Divider size="smallest" />

      <Section
        title="Featured in"
        collapsible
        expanded
        titleTextProps={titleTextProps}
      >
        {isLoadingGalleries ? (
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            {placeholders}
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            {galleries.map((gallery) => (
              <GalleryRow key={gallery.id} gallery={gallery} />
            ))}
          </div>
        )}
      </Section>
    </>
  );
};

const GalleryRow = ({ gallery }) => {
  const router = useRouter();
  const { viewLink, coverImage } = useGalleryDetails({ gallery });

  const handleViewGallery = useCallback(() => {
    router.push(viewLink);
  }, [router, viewLink]);

  return (
    <ProductCampaign
      key={gallery.id}
      title={gallery.title}
      campaignLogo={coverImage?.src}
      staticFile={coverImage?.staticFile}
      onClick={handleViewGallery}
    />
  );
};
