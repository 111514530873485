import { isProduction } from './environment';
import { isJoynAssetLink, removeQueryParams } from './url';

export const parseLastNumInStr = (str) => {
  return str.match(/[0-9]+$/)?.[0] || '';
};

export const urlValidation = (str) => {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const usernameValidation = (str) => {
  return str.match(/^[a-zA-Z0-9-_.]+$/);
};

export const nameValidation = (str) => {
  return str.match(/^[a-zA-Z0-9-_ ,]+$/);
};

export const emailValidation = (str) => {
  return str.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const numberValidation = (str) => {
  return str.match(/^\d+$/);
};

export const discordURLValidation = (str) => {
  return str.match(/^https?:\/\/discord(\.gg|\.com)\/(?:#!\/)?(\w+)/);
};

export const twitterURLValidation = (str) => {
  return str.match(/^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)/);
};

export const tweetURLValidation = (str) => {
  return str.match(
    /^https?:\/\/twitter\.com\/\w+\/status(es)?\/\d+(?:\?[\w=&]*)?$/
  );
};

export const tweetHashtagValidation = (str) => {
  return str.match(/^#[a-zA-Z0-9#]+$/);
};

export const emailVerifyCodeValidation = (str) => {
  return str.match(/^\d+$/);
};

export const addressValidation = (str) => {
  return str.match(/^0x[a-fA-F0-9]{40}$/);
};

export const openseaURLValidation = (str) => {
  if (isProduction())
    return str.match(
      /^(https?:\/\/opensea.io\/assets\/(ethereum|optimism)\/(0x[a-fA-F0-9]{40})\/(\d+))$/
    );
  return str.match(
    /^(https?:\/\/testnets.opensea.io\/assets\/(sepolia|optimism-goerli)\/(0x[a-fA-F0-9]{40})\/(\d+))$/
  );
};

export const looksrareURLValidation = (str) => {
  if (isProduction())
    return str.match(
      /^https?:\/\/looksrare.org\/collections\/(0x[a-fA-F0-9]{40})\/(\d+)$/
    );
  return str.match(
    /^https?:\/\/sepolia.looksrare.org\/collections\/(0x[a-fA-F0-9]{40})\/(\d+)$/
  );
};

export const marketZoraURLValidation = (str) => {
  if (isProduction())
    return str.match(
      /^https?:\/\/market.zora.co\/collections\/(0x[a-fA-F0-9]{40})\/(\d+)$/
    );
  return str.match(
    /^https?:\/\/testnet.zora.co\/collections\/(0x[a-fA-F0-9]{40})\/(\d+)$/
  );
};

export const objktURLValidation = (str) =>
  str.match(/^https?:\/\/objkt.com\/asset\/([a-zA-Z0-9]+)\/(\d+)$/);

export const raribleURLValidation = (str) =>
  str.match(/^https?:\/\/rarible\.com\/token\/([a-zA-Z0-9]+):(\d+)$/);

export const galleryManifoldXyzURLValidation = (str) =>
  str.match(/^https?:\/\/gallery.manifold.xyz\/(0x[a-fA-F0-9]{40})\/(\d+)$/);

/**
 * Only following examples can be valid.
 * @example https://joyn.xyz/asset/150
 * @example https://app.joyn.xyz/asset/test-url
 * @example https://staging.joyn.xyz/asset/444
 * @example https://qa.joyn.xyz/asset/hello-asset
 * @example http://localhost/asset/test
 * @example http://localhost:3001/asset/222
 */
export const joynAssetURLValidation = (str) =>
  removeQueryParams(str).match(
    /^(https?:\/\/((www\.)?(joyn\.xyz|app\.joyn\.xyz|staging\.joyn\.xyz|qa\.joyn\.xyz)|localhost(:\d+)?))\/asset\/[\w-]+$/
  );

export const foundationURLValidation = (str) =>
  str.match(
    /^https:\/\/foundation.app\/([a-zA-Z0-9\@\.\-\_]+)\/([a-zA-Z0-9\@\.\-\_]+)\/(\d+)$/
  );

export const marketPlaceLinksToScrapeValidation = (str) => {
  if (!str) {
    return false;
  }
  return !!foundationURLValidation(str);
};

export const parseMarketPlaceLink = (str) =>
  !openseaURLValidation(str) &&
  !looksrareURLValidation(str) &&
  !marketZoraURLValidation(str) &&
  !galleryManifoldXyzURLValidation(str) &&
  !objktURLValidation(str) &&
  !isJoynAssetLink(str) &&
  !joynAssetURLValidation(str) &&
  !raribleURLValidation(str);

export const passwordValidation = (str) =>
  str.match(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{12,}$/
  );
