import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useFeatureFlags } from '/hooks/useFeatureFlags';
import ExternalLinkIcon from '@heroicons/react/outline/ExternalLinkIcon';
import ExclamationCircleIcon from '@heroicons/react/outline/ExclamationCircleIcon';
import DotsVerticalIcon from '@heroicons/react/outline/DotsVerticalIcon';

import { DropDown } from '/design-systems/Atoms/DropDown';
import { Typography } from '/design-systems/Atoms/Typography';
import { Button } from '/design-systems/Atoms/Button';
import { Tooltip } from '/design-systems/Atoms/Tooltip';
import { Team } from '/design-systems/Molecules/Team';
import { Editor } from '/design-systems/Molecules/Editor';
import { ReadMoreSection } from '/design-systems/Molecules/ReadMoreSection';
import { ProductEditionInfo } from '/design-systems/Molecules/Product/ProductContent/ProductEditionInfo';
import { ProductOwner } from '/design-systems/Molecules/Product/ProductOwner';

import { customizeExternalLink, getTweetHashtagLink } from '/utils/url';
import { isDOMString, isEmptyDOMString, cleanTwitterText } from '/utils/string';
import { emptyArray, objectBlank } from '/utils/object';
import { TOKEN_STANDARDS, MARKETPLACES } from '/utils/constants';
import { getMarketplaceLink, getMarketplaceName } from '/utils/web3';
import { isJoynContract } from '/utils/contracts';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';
import { ROUTES } from '/config/routes';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import { FloatingTooltip } from '/design-systems/Atoms/FloatingTooltip';
import { refreshTokenMetadata } from '/services/joynApi/users/token';
import { toast } from 'react-toastify';
import { assetOwnerMapper } from '/utils/asset';
import { SaveEntityButton, ENTITY_NAMES } from '../../Buttons/SaveEntityButton';
import { useAnalytics } from '/hooks/useAnalytics';

export const ProductContent = ({
  assetId,
  assetSaved,
  assetChainId,
  assetDetailPageLink,
  assetCreator,
  assetCreatorAddress,
  blockchain,
  tokenId,
  tokenIdForListing,
  tokenAddress,
  editable = false,
  title,
  description,
  link: link_,
  tweet,
  onEdit,
  onDelete,
  isAsset,
  isSubmission,
  isOneOfMany,
  isAssetMinted,
  isAssetAvailableForBuyNow,
  isFullPage = false,
  hasAuction,
  isStandAloneNFT,
  isPreview,
  tokenStandard,
  totalTokenOwners,
  totalAmount,
  onTokenSettings,
  onTransfer,
  lazyMinted = false,
  isAssetOwner,
  onDeletePiece,
  ownsAllSupply,
  isMinted,
  hasActiveOrders,
  galleryId,
  titleVariant = 'default',
  tokenOwners,
  onOpenOwnerListModal
}) => {
  const tokenOwnersFormatted = tokenOwners?.map(assetOwnerMapper);
  const router = useRouter();
  const { isAssetGalleryEnabled } = useFeatureFlags();

  const titleTextProps = useMemo(() => {
    return titleVariant === 'h2'
      ? {
          variant: 'h2'
        }
      : {
          variant: 'h3'
        };
  }, [titleVariant]);

  const tweetLink = useMemo(() => {
    if (!description || isEmptyDOMString(description)) return '';

    if (tweet?.tweetId) {
      // we only need the tweet ID, since Twitter auto-redirects to the author's handle
      return `https://twitter.com/joynxyz/status/${tweet?.tweetId}`;
    }

    const links = description
      .split('\n')
      .join(' ')
      .split(' ')
      .filter(
        (str) => str.includes('https://t.co') || str.includes('http://t.co')
      );
    if (emptyArray(links)) return '';

    return links[links.length - 1];
  }, [description, tweet?.tweetId]);

  const { link, isWhitelistedLink } = useMemo(
    () => customizeExternalLink({ link: link_ }),
    [link_]
  );

  const showMarketplaceLink = useMemo(() => {
    return (
      tokenId &&
      tokenAddress &&
      !isAssetAvailableForBuyNow &&
      !isJoynContract(assetChainId, tokenAddress) &&
      !lazyMinted
    );
  }, [
    tokenId,
    tokenAddress,
    isAssetAvailableForBuyNow,
    assetChainId,
    lazyMinted
  ]);

  const openseaLink = useMemo(
    () =>
      getMarketplaceLink({
        blockchain,
        tokenId,
        tokenAddress,
        marketplace: MARKETPLACES.opensea
      }),
    [blockchain, tokenId, tokenAddress]
  );

  const filterHashtags = (text) => {
    if (!text) return '';

    if (text.includes('\n')) {
      return text.split('\n').map((line) => (
        <>
          {filterHashtags(line)}
          <br />
        </>
      ));
    }

    const val =
      text
        ?.split(' ')
        .filter((str) => str !== tweetLink)
        .map((str) => {
          if (str.startsWith('#')) {
            return (
              <a
                key={str}
                href={getTweetHashtagLink(str)}
                target="_blank"
                rel="noreferrer"
                className="font-medium text-neutral-500 underline"
              >
                {str}{' '}
              </a>
            );
          }

          if (str.startsWith('http')) {
            return (
              <a
                key={str}
                href={str}
                target="_blank"
                rel="noreferrer"
                className="font-medium text-neutral-500 underline"
              >
                {str}{' '}
              </a>
            );
          }

          return `${str} `;
        }) || '';

    return val;
  };

  const renderDescription = () => {
    if (isEmptyDOMString(description) && !tweet) return <></>;

    return (
      <div className="">
        <ReadMoreSection completed={!!description}>
          {isDOMString(description) ? (
            <Editor content={description} editable={false} heightFit />
          ) : (
            <Typography variant="medium" className="leading-7">
              {filterHashtags(cleanTwitterText(description))}
            </Typography>
          )}
        </ReadMoreSection>
      </div>
    );
  };

  const showDeleteAsset = useMemo(
    () =>
      isAsset &&
      ((ownsAllSupply && !hasActiveOrders && lazyMinted) || !isMinted),
    [isAsset, ownsAllSupply, hasActiveOrders, lazyMinted, isMinted]
  );

  const getDropdownItems = () => {
    const items = [];

    if (editable) {
      isSubmission && onEdit && items.push(DROPDOWN_MENU.EDIT_SUBMISSION);
      isSubmission && onDelete && items.push(DROPDOWN_MENU.DELETE_SUBMISSION);

      isAsset &&
        (!isAssetMinted || lazyMinted || isAssetGalleryEnabled) &&
        onEdit &&
        items.push(DROPDOWN_MENU.EDIT);

      isAsset &&
        isAssetMinted &&
        onTokenSettings &&
        lazyMinted &&
        items.push(DROPDOWN_MENU.TOKEN_SETTINGS);

      isAssetOwner && !hasAuction && items.push(DROPDOWN_MENU.TRANFER);
      showDeleteAsset && !hasAuction && items.push(DROPDOWN_MENU.DELETE);
    }

    // only owner can do this, not creator
    isAsset &&
      isAssetMinted &&
      !lazyMinted &&
      isAssetOwner &&
      items.push(DROPDOWN_MENU.REFRESH_METADATA);

    return items;
  };

  const handleRefreshMetadata = useCallback(() => {
    refreshTokenMetadata({ tokenId, tokenAddress }).then(() => {
      toast('Your item is being updated. Please check back shortly.', {
        type: 'success'
      });
    });
  }, [tokenAddress, tokenId]);

  const handleDropdownClick = (item, e) => {
    switch (item) {
      case DROPDOWN_MENU.EDIT:
        onEdit();
        return;
      case DROPDOWN_MENU.EDIT_SUBMISSION:
        onEdit();
        return;
      case DROPDOWN_MENU.DELETE_SUBMISSION:
        onDelete(e);
        return;
      case DROPDOWN_MENU.REFRESH_METADATA:
        handleRefreshMetadata();
        return;
      case DROPDOWN_MENU.TOKEN_SETTINGS:
        onTokenSettings();
        return;
      case DROPDOWN_MENU.TRANFER:
        onTransfer();
        return;
      case DROPDOWN_MENU.DELETE:
        onDeletePiece();
        return;
      default:
        return;
    }
  };

  const amount = useMemo(
    () =>
      isStandAloneNFT
        ? tokenOwnersFormatted?.reduce((acc, s) => acc + s.quantity, 0)
        : totalAmount,
    [tokenOwnersFormatted, totalAmount, isStandAloneNFT]
  );

  const showLink = useMemo(
    () => link || showMarketplaceLink || tweetLink,
    [link, showMarketplaceLink, tweetLink]
  );

  const handleProfileClick = useCallback(
    (userInfo) => router.push(ROUTES.profile.view(userInfo)),
    [router]
  );

  const { trackAssetLinkClickEvent } = useAnalytics();

  const trackLinkClick = useCallback(
    (link) => {
      trackAssetLinkClickEvent({
        assetId,
        galleryId,
        url: link
      });
    },
    [assetId, galleryId, trackAssetLinkClickEvent]
  );

  return (
    <div className="flex cursor-default flex-col gap-y-4">
      <div
        id="product-content"
        data-asset-id={assetId}
        data-token-id={tokenIdForListing}
        className="flex w-full items-start gap-2"
      >
        <Typography
          heading
          weight="semibold"
          {...titleTextProps}
          className="line-clamp-2 w-full"
        >
          {title}
        </Typography>

        <div className="flex items-center gap-2">
          {!isStandAloneNFT && assetId && (
            <SaveEntityButton
              id="asset-save-btn"
              saved={assetSaved}
              entityId={assetId}
              entity={ENTITY_NAMES.ASSET}
              className="!bg-transparent"
              iconClassName="text-neutral-600 dark:text-neutral-400 hover:text-neutral-700 dark:hover:text-neutral-300"
            />
          )}

          {/* view in full page */}
          {!isFullPage && (
            <FloatingTooltip label="Open in full page">
              <Button
                variant="tertiary"
                color="neutral"
                iconOnly
                rounded
                onClick={() => {
                  window.open(assetDetailPageLink || assetId, '_blank');
                }}
              >
                <FontAwesomeIcon icon={faArrowUpRight} className="h-5 w-5" />
              </Button>
            </FloatingTooltip>
          )}

          {!isPreview && !emptyArray(getDropdownItems()) && (
            <DropDown
              id={'dots-vertical-icon-preview-modal'}
              items={getDropdownItems()}
              label={
                <Button variant="tertiary" color="neutral" iconOnly rounded>
                  <DotsVerticalIcon className="h-[24px] w-[24px] stroke-neutral-600 dark:stroke-neutral-400" />
                </Button>
              }
              onDropDownClick={handleDropdownClick}
              hideChevron
              px="0"
              py="0"
              top="9"
            />
          )}
        </div>
      </div>

      {isOneOfMany && !!amount && tokenStandard === TOKEN_STANDARDS.ERC1155 && (
        <div className="flex items-center">
          <ProductEditionInfo amount={amount} />
        </div>
      )}
      <div className="flex flex-wrap gap-x-8 gap-y-2">
        {(assetCreator || assetCreatorAddress) && (
          <ProductOwner
            title={isSubmission ? 'Submitted by' : 'Created by'}
            owner={assetCreator}
            address={assetCreatorAddress}
            tweet={''}
            withRedirectProfile={!objectBlank(assetCreator)}
          />
        )}
        {!emptyArray(tokenOwnersFormatted) &&
          (tokenStandard === TOKEN_STANDARDS.ERC1155 ? (
            <div>
              <Typography
                variant="medium"
                weight="medium"
                colorVariant="secondary"
                className="mb-2"
              >
                Owned by
              </Typography>
              <Team
                users={tokenOwnersFormatted}
                usersCount={totalTokenOwners || tokenOwnersFormatted.length}
                required={4}
                size={32}
                rounded
                noHover
                noWrap
                noLabel
                handleProfileClick={handleProfileClick}
                handleShowMore={() => onOpenOwnerListModal()}
              />
            </div>
          ) : (
            <ProductOwner
              title="Owned by"
              owner={tokenOwnersFormatted?.[0]}
              tweet={''}
              address={
                retrieveMainWalletAddressFromWalletAddresses(
                  tokenOwnersFormatted?.[0]?.WalletAddresses
                )?.address
              }
              withRedirectProfile={!objectBlank(tokenOwnersFormatted?.[0])}
            />
          ))}
      </div>
      {renderDescription()}

      {showLink && (
        <div className="flex items-center gap-4">
          {link && (
            <Tooltip
              showTooltip={!isWhitelistedLink}
              contentHtml={
                <Typography className="text-white">
                  This is not a link we are familiar with. Please proceed with
                  caution.
                </Typography>
              }
            >
              <Typography variant="small" className="">
                <div className="flex items-center gap-1">
                  {isWhitelistedLink || (
                    <ExclamationCircleIcon className="h-4 w-4" />
                  )}
                  <a
                    className="link-underlined font-medium"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => trackLinkClick(link)}
                  >
                    <div className="flex flex-row items-center">
                      View on {new URL(link).hostname}
                      <ExternalLinkIcon className="ml-1 h-4 w-4" />
                    </div>
                  </a>
                </div>
              </Typography>
            </Tooltip>
          )}

          {showMarketplaceLink && (
            <Typography variant="small" className="">
              <div className="">
                <a
                  className="link-underlined font-medium"
                  href={openseaLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => trackLinkClick(openseaLink)}
                >
                  <div className="flex flex-row items-center">
                    View on {getMarketplaceName({ blockchain })}
                    <ExternalLinkIcon className="ml-1 h-4 w-4" />
                  </div>
                </a>
              </div>
            </Typography>
          )}

          {tweetLink && (
            <Typography variant="small" className="">
              <div className="">
                <a
                  className="link-underlined font-medium"
                  href={tweetLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => trackLinkClick(tweetLink)}
                >
                  <div className="flex flex-row items-center">
                    View on Twitter
                    <ExternalLinkIcon className="ml-1 h-4 w-4" />
                  </div>
                </a>
              </div>
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

const DROPDOWN_MENU = {
  EDIT: 'Edit',
  EDIT_SUBMISSION: 'Edit submission',
  DELETE_SUBMISSION: 'Delete submission',
  REFRESH_METADATA: 'Refresh metadata',
  TOKEN_SETTINGS: 'Token settings',
  TRANFER: 'Transfer',
  DELETE: 'Delete'
};
