import { useMemo } from 'react';
import { objectBlank } from '/utils/object';

import { BLOCKCHAINS, TOKEN_STANDARDS } from '/utils/constants';
import { useTokenOwner } from '/hooks/useTokenOwner';
import { useToken } from '/hooks/useToken';

export const useAssetToken = ({
  assetData,
  assetToken,
  assetMint,
  currentUser,
  isCreator,
  asset,
  isSubmission,
  isAsset,
  submissionData,
  queryParam
}) => {
  const isJoynAsset = useMemo(() => Boolean(asset?.User), [asset?.User]);

  const isStandAloneNFT = useMemo(() => !isJoynAsset, [isJoynAsset]);

  // Get tokenId & tokenAddress info from query param for only standalone nft
  const { tokenId, tokenAddress } = useMemo(() => {
    if (isStandAloneNFT && queryParam) {
      const arr = queryParam?.split?.('-');
      return {
        tokenId: arr?.[0],
        tokenAddress: arr?.[1]
      };
    }
    return {};
  }, [isStandAloneNFT, queryParam]);

  const { token, isLoadingToken } = useToken({
    tokenId: isStandAloneNFT ? tokenId : false,
    tokenAddress: isStandAloneNFT ? tokenAddress : false,
    withOwner: true
  });

  const tokenIdForListing = useMemo(
    () =>
      isAsset
        ? assetData?.assetToken?.id
        : isSubmission
        ? submissionData?.submission?.Asset?.AssetMint?.Token?.id
        : false,
    [isAsset, assetData, isSubmission, submissionData]
  );

  const { tokenOwner, refetchTokenOwner } = useTokenOwner({
    tokenId: tokenIdForListing
  });

  const tokenOwnedByCurrentUser = useMemo(
    () => tokenOwner?.ownedSupply || 0,
    [tokenOwner?.ownedSupply]
  );

  const isAssetMinted = useMemo(() => !objectBlank(assetToken), [assetToken]);

  const blockchain = useMemo(
    () =>
      isStandAloneNFT
        ? BLOCKCHAINS.ETHEREUM
        : isAssetMinted
        ? assetToken?.chain
        : undefined,
    [isStandAloneNFT, isAssetMinted, assetToken]
  );

  const assetTokenId = useMemo(
    () =>
      isStandAloneNFT ? tokenId : isAssetMinted ? assetToken?.tokenId : '',
    [isStandAloneNFT, tokenId, isAssetMinted, assetToken]
  );

  const assetTokenAddress = useMemo(
    () =>
      isStandAloneNFT
        ? tokenAddress
        : isAssetMinted
        ? assetToken?.tokenAddress
        : '',
    [isStandAloneNFT, tokenAddress, isAssetMinted, assetToken]
  );

  const tokenOwners = useMemo(() => {
    return isStandAloneNFT ? token?.owners : assetToken?.TokenOwners;
  }, [token?.owners, assetToken?.TokenOwners, isStandAloneNFT]);

  const isOneOfMany = useMemo(
    () =>
      assetToken?.tokenStandard === TOKEN_STANDARDS.ERC1155 &&
      tokenOwner?.totalSupply > 1,
    [assetToken?.tokenStandard, tokenOwner?.totalSupply]
  );

  const isAssetOwner = useMemo(
    () => tokenOwner?.isOwner && !objectBlank(currentUser),
    [tokenOwner?.isOwner, currentUser]
  );

  const isAvailableForMint = useMemo(
    () => !assetMint?.minted && isCreator,
    [assetMint?.minted, isCreator]
  );

  return {
    assetTokenId,
    assetTokenAddress,
    blockchain,
    tokenOwners,
    tokenIdForListing,
    tokenOwner,
    tokenOwnedByCurrentUser,
    refetchTokenOwner,
    isAssetOwner,
    isAssetMinted,
    isJoynAsset,
    isStandAloneNFT,
    isOneOfMany,
    isAvailableForMint,
    token,
    isLoadingToken
  };
};
