import React, { useCallback, useMemo } from 'react';

import { Typography } from '../Typography';
import { mergeClassNames } from '/utils/string';
import { isNumber } from '/utils/number';

export const FormInput = ({
  id,
  name,
  type = 'text',
  value = '',
  resetValue,
  className: _className = '',
  disabled,
  readOnly,
  error,
  placeholder,
  autoFocus,
  transparent,
  onChange,
  onBlur,
  onDoubleClick,
  onEnter,
  touched,
  min,
  max,
  onlyIntegerAccepted,
  postfixContent,
  inputWithoutWrapper = false,
  checked,
  /**
   * @note right | left
   */
  postfixContentPosition = 'right',
  textOnly = false
}) => {
  const className = [
    'w-full p-3 dark:bg-neutral-900 dark:border-none dark:text-neutral-100',
    postfixContent
      ? mergeClassNames(
          'z-[1]',
          postfixContentPosition === 'left' ? 'rounded-r-lg' : 'rounded-l-lg'
        )
      : 'rounded-lg',
    touched && error && 'error',
    transparent && 'transparented',
    (disabled || readOnly) && 'cursor-not-allowed',
    _className || ''
  ].join(' ');

  const postfixContentClassnames = useMemo(() => {
    return {
      wrapper: mergeClassNames(
        'flex items-center focus:ring-2 focus:ring-blue-500',
        postfixContentPosition === 'left' ? 'flex-row-reverse' : 'flex-row'
      ),
      content: mergeClassNames(
        'border-y border-gray-300 bg-neutral-50 p-3 text-neutral-500',
        postfixContentPosition === 'left'
          ? 'rounded-l-lg border-l'
          : 'rounded-r-lg border-r'
      )
    };
  }, [postfixContentPosition]);

  const handleBlur = (e) => {
    if (!isNumber(parseFloat(e.target.value)) && resetValue) {
      const resetEvent = {
        ...e,
        target: { ...e.target, name, value: resetValue }
      };
      onChange && onChange(resetEvent);
      onBlur && onBlur(resetEvent);
      return;
    }

    onBlur && onBlur(e);
  };
  const handleChange = (e) => onChange && onChange(e);
  const handleDoubleClick = (e) => onDoubleClick && onDoubleClick(e);
  const handleOnEnter = (e) => {
    if (e.key === 'Enter') {
      onEnter && onEnter(e);
    }
  };

  function handleKeyDown(event) {
    if (!onlyIntegerAccepted) return;
    // Prevent the user from typing a decimal point
    if (event.key === '.' || event.key === '-') {
      event.preventDefault();
    }
  }

  // on scroll, the input value should not be updated
  const handleWheel = (e) => {
    if (type === 'number') {
      e.preventDefault();
      e.target.blur();
    }
  };

  const renderInput = useCallback(() => {
    if (textOnly) {
      return (
        <Typography variant="medium" weight="normal">
          {value}
        </Typography>
      );
    }

    return (
      <input
        id={id}
        type={type}
        name={name}
        value={value}
        className={className}
        disabled={disabled || readOnly}
        readOnly={readOnly}
        autoFocus={autoFocus}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={handleChange}
        onDoubleClick={handleDoubleClick}
        onWheel={handleWheel}
        onKeyPress={handleOnEnter}
        min={min}
        max={max}
        onKeyDown={handleKeyDown}
        checked={checked}
      />
    );
  }, [
    id,
    type,
    name,
    value,
    className,
    disabled,
    readOnly,
    autoFocus,
    placeholder,
    handleBlur,
    handleChange,
    handleDoubleClick,
    handleOnEnter,
    min,
    checked,
    max,
    handleKeyDown
  ]);

  if (inputWithoutWrapper) {
    return renderInput();
  }

  return (
    <div className="form-input w-full">
      {postfixContent ? (
        <div className={postfixContentClassnames.wrapper}>
          {renderInput()}
          <div className={postfixContentClassnames.content}>
            {postfixContent}
          </div>
        </div>
      ) : (
        renderInput()
      )}
      {touched && error && (
        <Typography
          {...(id && { id: `${id}-error` })}
          color="#ef4444"
          variant="xsmall"
          gutterBottom
        >
          {error}
        </Typography>
      )}
    </div>
  );
};
