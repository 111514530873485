import { toLong18, toShort18 } from '/utils/string';
import {
  getJoynMarketplaceContract,
  DEFAULT_SEAPORT_VERSION,
  getConduitKey,
  getConduitAddress,
  JOYN_ASSET_SHARED_CONTRACT,
  FEE_ZONE_CONTRACT
} from '/utils/contracts';
import { ethers, toBigInt } from 'ethers';
import { Seaport } from '@opensea/seaport-js';
import {
  TYPE_OF_SALE,
  joynSupportedProtocols,
  treasuryAddress
} from '/utils/constants';
import { createOffer } from '/services/joynApi/users/offer';
import { buildOrder } from './seaport';
import { toast } from 'react-toastify';
import { waitForTransaction } from '@wagmi/core';

const conduitKeyToConduitMapping = (chainId) => {
  return {
    [getConduitKey({ chainId })]: getConduitAddress({ chainId })
  };
};

export const ACTIONS = {
  APPROVE: 0,
  CREATE_LISTING: 1,
  CANCEL_LISTING: 2,
  UPDATE_LISTING: 3,
  BUY_LISTING: 4
};

export const STEPS = {
  start: (action) => {
    switch (action) {
      case ACTIONS.CREATE_LISTING:
        return 'List for sale';
      case ACTIONS.UPDATE_LISTING:
        return 'Update listing';
      default:
        return true;
    }
  },
  WAIT_FOR_CONFIRM: 'Confirm this transaction in your wallet.',
  APPROVE: 'Approve collection',
  txConfirming: (action) => {
    switch (action) {
      case ACTIONS.APPROVE:
        return 'Your approval is being processed...';
      case ACTIONS.CREATE_LISTING:
        return 'Your piece is being listed...';
      case ACTIONS.BUY_LISTING:
        return 'Your purchase is being processed...';
      case ACTIONS.UPDATE_LISTING:
        return 'Your listing is being updated...';
      case ACTIONS.CANCEL_LISTING:
        return 'Your cancellation is being processed...';
      default:
        return true;
    }
  },
  txConfirmed: (action) => {
    switch (action) {
      case ACTIONS.CREATE_LISTING:
        return 'Your piece is now listed!';
      case ACTIONS.BUY_LISTING:
        return 'You now own this NFT.';
      case ACTIONS.UPDATE_LISTING:
        return 'Your listing is updated.';
      case ACTIONS.CANCEL_LISTING:
        return 'Your listing is now cancelled.';
      default:
        return true;
    }
  },
  SIGN_ORDER: 'Approve listing'
};

const createSeaportClient = ({
  provider,
  chainId,
  seaportVersion = DEFAULT_SEAPORT_VERSION
}) => {
  return new Seaport(provider, {
    seaportVersion,
    conduitKeyToConduit: conduitKeyToConduitMapping(chainId)
  });
};

const createExtraData = ({ galleryId, signature }) => {
  return ethers.AbiCoder.defaultAbiCoder().encode(
    ['uint256', 'bytes'],
    [galleryId || 0, signature || '0x00']
  );
};

const createNftVoucher = async ({ signer, tokenId, chainId, orderHash }) => {
  const voucher = { tokenId, orderHash };
  const domain = {
    name: 'LazyNFT-Voucher',
    version: '1',
    verifyingContract: FEE_ZONE_CONTRACT[chainId],
    chainId
  };
  const types = {
    NFTVoucher: [
      { name: 'tokenId', type: 'uint256' },
      { name: 'orderHash', type: 'bytes32' }
    ]
  };
  const signature = await signer.signTypedData(domain, types, voucher);
  return {
    ...voucher,
    signature
  };
};

export const cancelV1Listing = async ({
  toast,
  onCancelListing,
  tokenId,
  tokenAddress,
  setState,
  refetchActiveListings
}) => {
  try {
    setState({ step: STEPS.WAIT_FOR_CONFIRM });
    const tx = await onCancelListing({ tokenId, tokenAddress });
    setState({
      step: STEPS.txConfirming(ACTIONS.CANCEL_LISTING),
      txHash: tx.hash
    });
    refetchActiveListings && refetchActiveListings();
    setState({ step: STEPS.txConfirmed(ACTIONS.CANCEL_LISTING) });
    toast.success(`Transaction complete! Your listing is now cancelled`, {
      autoClose: false,
      closeOnClick: false
    });
  } catch (error) {
    console.error('Cancel Listing piece error: ', error);
    setState({ step: STEPS.start(ACTIONS.UPDATE_LISTING) });
  }
};
export const cancelV2Listing = async ({
  setState,
  refetchActiveListings,
  chainId,
  toast,
  provider,
  order,
  seaportVersion,
  protocol,
  onFoundationCancelListing
}) => {
  try {
    setState({ step: STEPS.WAIT_FOR_CONFIRM });

    let tx;

    if (protocol === joynSupportedProtocols.SEAPORT) {
      const seaport = createSeaportClient({
        provider,
        chainId,
        seaportVersion
      });

      const { transact } = seaport.cancelOrders([order.orderData?.parameters]);
      tx = await transact();
    } else if (protocol === joynSupportedProtocols.FOUNDATION) {
      tx = await onFoundationCancelListing({
        tokenAddress: order.Offers[0].token,
        tokenId: order.Offers[0].identifierOrCriteria
      });
    }
    setState({
      step: STEPS.txConfirming(ACTIONS.CANCEL_LISTING),
      txHash: tx.hash
    });
    if (protocol === joynSupportedProtocols.SEAPORT) {
      await tx.wait();
    } else {
      await waitForTransaction({ hash: tx.hash, confirmations: 1 });
    }
    refetchActiveListings && refetchActiveListings();
    setState({ step: STEPS.txConfirmed(ACTIONS.CANCEL_LISTING) });
    toast.success(`Transaction complete! Your listing is now cancelled`, {
      autoClose: false,
      closeOnClick: false
    });
    return true;
  } catch (error) {
    setState({ step: STEPS.start(ACTIONS.UPDATE_LISTING) });
    toast(error?.reason || error?.message, { type: 'error' });
    console.error('Cancel asset error: ', error);
    return false;
  }
};

export const createOrUpdateV1Listing = async ({
  onGetListing,
  tokenId,
  tokenAddress,
  walletAddress,
  setState,
  onListAsset,
  onCreateAssetListing,
  onInitiateAssetListing,
  onUpdateListing,
  price,
  fee,
  assetTokenId,
  chainId,
  isJoynMarketplaceApproved,
  approve,
  refetchTokenOperator
}) => {
  const existingListing = await onGetListing({
    tokenId,
    tokenAddress
  });
  const listingId = toBigInt(existingListing.listingId).toString();
  const existingListingPrice = toBigInt(existingListing.price).toString();
  const createListing =
    listingId === '0' ||
    existingListingPrice === '0' ||
    walletAddress !== existingListing.seller;
  if (createListing) {
    await createV1Listing({
      STEPS,
      setState,
      onCreateV1ListingTx: onListAsset,
      onCreateV1Listing: onCreateAssetListing,
      onInitiateV1Listing: onInitiateAssetListing,
      tokenId,
      chainId,
      tokenAddress,
      priceInEth: price,
      marketplaceFee: Number(fee) * 100,
      assetTokenId,
      isJoynMarketplaceApproved,
      approve,
      refetchTokenOperator
    });
  } else {
    await updateV1ListingPrice({
      newPriceInEth: price,
      newMarketplaceFee: Number(fee) * 100,
      oldPriceInEth: toShort18(existingListing.price),
      oldMarketplaceFee: existingListing.marketplaceFee,
      setState,
      STEPS,
      onUpdateV1Listing: onUpdateListing,
      tokenId,
      tokenAddress
    });
  }
};

export const updateV1ListingPrice = async ({
  newPriceInEth,
  newMarketplaceFee,
  oldPriceInEth,
  oldMarketplaceFee,
  setState,
  onUpdateV1Listing,
  tokenId,
  tokenAddress
}) => {
  if (
    newPriceInEth === oldPriceInEth &&
    newMarketplaceFee === oldMarketplaceFee
  ) {
    setState({ step: STEPS.start(ACTIONS.UPDATE_LISTING) });
    return;
  }
  const newPriceInWei = toLong18(newPriceInEth);

  setState({ step: STEPS.WAIT_FOR_CONFIRM, price: newPriceInEth });
  try {
    const tx = await onUpdateV1Listing({
      tokenId,
      tokenAddress,
      price: newPriceInWei,
      fee: newMarketplaceFee
    });
    setState({
      step: STEPS.txConfirming(ACTIONS.UPDATE_LISTING),
      txHash: tx.hash,
      price: newPriceInEth
    });
    setState({ step: STEPS.txConfirmed(ACTIONS.UPDATE_LISTING) });
  } catch (error) {
    console.error('Updating listing error: ', error);
    setState({ step: STEPS.start(ACTIONS.UPDATE_LISTING) });
  }
};

export const createV1Listing = async ({
  setState,
  onCreateV1ListingTx,
  onCreateV1Listing,
  onInitiateV1Listing,
  tokenId,
  chainId,
  tokenAddress,
  priceInEth,
  marketplaceFee,
  assetTokenId,
  isJoynMarketplaceApproved,
  approve,
  refetchTokenOperator
}) => {
  // if the Joyn marketplace isn't allowed to transfer the token
  // then the seller needs to approve the Joyn marketplace
  if (!isJoynMarketplaceApproved) {
    setState({ step: STEPS.WAIT_FOR_CONFIRM });
    const tx = await approve({
      operator: getJoynMarketplaceContract({
        chainId
      }),
      tokenId
    });
    setState({ step: STEPS.txConfirming(ACTIONS.APPROVE), txHash: tx.hash });
    refetchTokenOperator();
  }
  const priceInWei = toLong18(priceInEth);
  setState({ step: STEPS.WAIT_FOR_CONFIRM });
  const actionResponses = await Promise.all([
    onCreateV1ListingTx({
      tokenId,
      tokenAddress,
      price: priceInWei,
      fee: marketplaceFee
    }),
    onCreateV1Listing({
      tokenId: assetTokenId,
      price: priceInWei.toString()
    })
  ]);

  const tx = actionResponses[0];
  const listing = actionResponses[1];

  setState({
    step: STEPS.txConfirming(ACTIONS.CREATE_LISTING),
    txHash: tx.hash,
    price: priceInEth
  });
  await Promise.all([onInitiateV1Listing({ id: listing.id })]);
  setState({ step: STEPS.txConfirmed(ACTIONS.CREATE_LISTING) });
};

export const createV2Listing = async ({
  setState,
  setApprovalForAll,
  refetchIsApprovedForAll,
  isAuction,
  createListingV2,
  createAuction,
  assetTokenId,
  chainId,
  walletAddress,
  priceInEth,
  amount,
  marketplaceFee,
  provider,
  signListingV2,
  isSeaportApproved,
  startTime,
  endTime,
  duration,
  auctionType,
  curatorFee,
  galleryCollabRequestId
}) => {
  const priceInWei = toBigInt(toLong18(priceInEth)).toString();
  if (!isSeaportApproved) {
    setState({ step: STEPS.APPROVE });
    const tx = await setApprovalForAll({
      operator: getConduitAddress({ chainId }),
      approved: true
    });
    await waitForTransaction({ hash: tx.transactionHash, confirmations: 1 });
    setState({ step: STEPS.txConfirming(ACTIONS.APPROVE), txHash: tx.hash });
    refetchIsApprovedForAll();
  }

  setState({ step: STEPS.SIGN_ORDER });
  // Create listing in backend
  // Royalty and marketplace fee consideration are created in the backend
  let order;

  if (isAuction) {
    const auction = await createAuction({
      tokenId: assetTokenId,
      walletAddress,
      price: priceInWei,
      amount,
      marketplaceFee,
      startTime,
      endTime,
      galleryCollabRequestId,
      curatorFee,
      duration,
      auctionType
    });

    // API only returns listing created for auction in response
    order = auction.Orders[0];
  } else {
    order = await createListingV2({
      tokenId: assetTokenId,
      walletAddress,
      price: priceInWei,
      amount,
      marketplaceFee,
      curatorFee,
      galleryCollabRequestId,
      endTime
    });
  }

  setState({ step: STEPS.SIGN_ORDER });

  const seaport = createSeaportClient({ provider, chainId });

  const seaportOrder = buildOrder({ order, restrictedByZone: true });

  const { executeAllActions } = await seaport.createOrder(
    { ...seaportOrder, conduitKey: getConduitKey({ chainId }) },
    walletAddress
  );

  const signedOrder = await executeAllActions();
  const orderHash = seaport.getOrderHash(signedOrder.parameters);
  signedOrder.orderHash = orderHash;

  await signListingV2({
    orderId: order.id,
    order: {
      parameters: signedOrder.parameters,
      signature: signedOrder.signature,
      orderHash: signedOrder.orderHash
    }
  });

  setState({ step: STEPS.txConfirmed(ACTIONS.CREATE_LISTING) });
};

export const buyV1Listing = async ({
  setState,
  onCreateAssetPurchase,
  listingId,
  onBuyAsset,
  onInitiateAssetPurchase,
  refetchActiveListings,
  tokenId,
  tokenAddress,
  priceUnit,
  assetTitle,
  toast
}) => {
  try {
    setState({ step: STEPS.WAIT_FOR_CONFIRM });
    const purchase = await onCreateAssetPurchase({ listingId });
    const tx = await onBuyAsset({
      tokenId,
      tokenAddress,
      referrerAddress: ethers.ZeroAddress,
      price: priceUnit
    });
    setState({
      step: STEPS.txConfirming(ACTIONS.BUY_LISTING),
      txHash: tx.hash,
      purchasePrice: priceUnit
    });
    await onInitiateAssetPurchase({ id: purchase.id });
    refetchActiveListings();
    setState({ step: STEPS.txConfirmed(ACTIONS.BUY_LISTING) });
    toast.success(`Transaction complete! You now own ${assetTitle}`, {
      autoClose: false,
      closeOnClick: false
    });
  } catch (error) {
    setState({ step: undefined });
    toast(error?.reason || error?.message, { type: 'error' });
    console.error('Buying asset error: ', error);
  }
};

export const buyV2Listing = async ({
  setState,
  provider,
  order,
  chainId,
  unitsToFill,
  priceUnit,
  refetchActiveListings,
  toast,
  assetTitle,
  seaportVersion,
  protocol,
  onFoundationBuyV2,
  openCuratorFeeConsiderations,
  galleryId
}) => {
  try {
    setState({ step: STEPS.WAIT_FOR_CONFIRM });
    setState({ showSellTab: false });

    let tx;

    if (protocol === joynSupportedProtocols.SEAPORT) {
      const seaport = createSeaportClient({
        provider,
        chainId,
        seaportVersion
      });

      const orderParams = {
        order: order.orderData,
        unitsToFill
      };

      const { executeAllActions } = await seaport.fulfillOrder({
        ...orderParams,
        conduitKey: getConduitKey({ chainId }),
        extraData: createExtraData({ galleryId, signature: '0x00' }),
        tips: openCuratorFeeConsiderations
      });
      tx = await executeAllActions();
    } else if (protocol === joynSupportedProtocols.FOUNDATION) {
      tx = await onFoundationBuyV2({
        tokenAddress: order.Offers[0].token,
        tokenId: order.Offers[0].identifierOrCriteria,
        price: priceUnit,
        referrerAddress: treasuryAddress
      });
    }
    setState({
      step: STEPS.txConfirming(ACTIONS.BUY_LISTING),
      txHash: tx.hash,
      purchasePrice: priceUnit
    });
    if (protocol === joynSupportedProtocols.SEAPORT) {
      await tx.wait();
    } else {
      await waitForTransaction({ hash: tx.hash, confirmations: 1 });
    }

    refetchActiveListings();
    setState({ step: STEPS.txConfirmed(ACTIONS.BUY_LISTING) });
    toast.success(`Transaction complete! You now own ${assetTitle}`, {
      autoClose: false,
      closeOnClick: false
    });
  } catch (error) {
    setState({ step: undefined });
    toast(error?.reason || error?.message, { type: 'error' });
    console.error('Buying asset error: ', error);
  }
};

export const isHavingAllowance = async ({
  chainId,
  offerPrice,
  allowance,
  walletAddress
}) => {
  const allowanceRes = await allowance({
    owner: walletAddress,
    spender: getConduitAddress({ chainId })
  });
  return toBigInt(allowanceRes) >= toBigInt(offerPrice);
};

export const makeOffer = async ({
  offer,
  provider,
  chainId,
  signOfferMutation,
  onSuccess,
  address,
  galleryId
}) => {
  if (galleryId) offer.galleryId = galleryId;
  const { data } = await createOffer(offer);
  const offerOrderRes = data?.data?.order;

  const seaport = createSeaportClient({ provider, chainId });

  const seaportOrder = buildOrder({
    order: offerOrderRes,
    restrictedByZone: true
  });
  const { executeAllActions } = await seaport.createOrder(
    {
      ...seaportOrder,
      conduitKey: getConduitKey({ chainId })
    },
    address
  );

  const signedOrder = await executeAllActions();
  const orderHash = seaport.getOrderHash(signedOrder.parameters);
  signedOrder.orderHash = orderHash;

  await signOfferMutation({
    offerId: offerOrderRes.id,
    order: {
      parameters: signedOrder.parameters,
      signature: signedOrder.signature,
      orderHash: signedOrder.orderHash
    }
  });

  toast(
    offer?.auctionId
      ? 'Your bid has been submitted'
      : 'Your offer has been submitted.',
    { type: 'success' }
  );
  onSuccess?.();
};

export const acceptOffer = async ({
  isSeaportApproved,
  setApprovalForAll,
  refetchIsApprovedForAll,
  signer,
  tokenId,
  tokenAddress,
  chainId,
  order,
  unitsToFill,
  refetchOffer,
  onApproveCollection,
  onAcceptOffer,
  onError,
  onSuccess,
  setTxHash,
  seaportVersion,
  galleryId
}) => {
  try {
    if (!isSeaportApproved) {
      onApproveCollection?.();
      const tx = await setApprovalForAll({
        operator: getConduitAddress({ chainId }),
        approved: true
      });
      await waitForTransaction({ hash: tx.transactionHash, confirmations: 1 });
      refetchIsApprovedForAll();
    }
  } catch (error) {
    onError?.();
    toast(error?.reason || error?.message, { type: 'error' });
    console.error('Approving collection error: ', error);
    return;
  }

  onAcceptOffer?.();
  try {
    const seaport = createSeaportClient({
      provider: signer,
      chainId,
      seaportVersion
    });

    let voucher = {};

    if (
      tokenAddress?.toLowerCase() ===
      JOYN_ASSET_SHARED_CONTRACT[chainId]?.toLowerCase()
    ) {
      voucher = await createNftVoucher({
        signer,
        tokenId,
        chainId,
        orderHash: order?.orderHash
      });
    }

    const { executeAllActions } = await seaport.fulfillOrder({
      order,
      unitsToFill,
      conduitKey: getConduitKey({ chainId }),
      extraData: createExtraData({ galleryId, signature: voucher?.signature })
    });

    const tx = await executeAllActions();
    await tx.wait();
    setTxHash?.(tx.hash);

    refetchOffer({ refetchAssetData: true });

    toast.success(`You have accepted the offer.`, {
      autoClose: false,
      closeOnClick: false
    });
    onSuccess?.();
  } catch (error) {
    onError?.();
    toast(error?.reason || error?.message, { type: 'error' });
    console.error('Accepting offer error: ', error);
  }
};

export const cancelOffer = async ({
  refetchOffer,
  provider,
  chainId,
  order,
  seaportVersion
}) => {
  try {
    const seaport = createSeaportClient({
      provider,
      chainId,
      seaportVersion
    });

    const { transact } = seaport.cancelOrders([order]);
    const tx = await transact();
    await tx.wait();
    refetchOffer && refetchOffer({});

    toast.success(`Transaction complete! Your offer is now cancelled`, {
      autoClose: false,
      closeOnClick: false
    });
    return true;
  } catch (error) {
    toast(error?.reason || error?.message, { type: 'error' });
    console.error('Cancel offer error: ', error);
    return false;
  }
};

export const cancelAuction = async ({
  setState,
  cancelAuction: cancelTokenAuction,
  auctionId
}) => {
  try {
    await cancelTokenAuction({ auctionId });
    const typeOfSale = auctionId ? TYPE_OF_SALE.timedAuction : '';
    setState({ step: STEPS.txConfirmed(ACTIONS.CANCEL_LISTING), typeOfSale });
    toast.success(`Auction has been removed`, {
      autoClose: false,
      closeOnClick: false
    });

    return true;
  } catch (error) {
    setState({ step: STEPS.start(ACTIONS.UPDATE_LISTING) });
    toast(error?.reason || error?.message, { type: 'error' });
    console.error('Cancel asset error: ', error);
    return false;
  }
};

export const updateAuction = async ({
  setState,
  updateAuction: updateTokenAuction,
  auctionId,
  reservePriceInEth,
  startDate,
  endDate,
  duration,
  auctionType
}) => {
  try {
    const reservePriceInWei = toBigInt(toLong18(reservePriceInEth)).toString();
    await updateTokenAuction({
      auctionId,
      reservePrice: reservePriceInWei,
      startDate,
      endDate,
      duration,
      auctionType
    });
    setState({ step: STEPS.txConfirmed(ACTIONS.UPDATE_LISTING) });

    return true;
  } catch (error) {
    setState({ step: STEPS.start(ACTIONS.UPDATE_LISTING) });
    toast(error?.reason || error?.message, { type: 'error' });
    console.error('Update asset error: ', error);
    return false;
  }
};
