import ERC721ABI from './ERC721.json';
import ERC1155ABI from './ERC1155.json';
import JoynAssetABI from './JoynAsset.json';
import JoynMarketplaceABI from './JoynMarketplace.json';
import RoyaltyEngineABI from './RoyaltyEngine.json';
import ERC20ABI from './ERC20.json';
import FoundationMarketplaceABI from './FoundationMarketplace.json';

const abis = {
  ERC721ABI,
  ERC1155ABI,
  JoynAssetABI,
  JoynMarketplaceABI,
  RoyaltyEngineABI,
  ERC20ABI,
  FoundationMarketplaceABI
};

export default abis;
