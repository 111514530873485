import axios from '/services/axios';
import { generateQueryParams } from '/utils/url';

const getUserProfile = () => {
  return axios.get(`/me`);
};

const getUser = ({ id }) => {
  return axios.get(`/user/${id}`);
};

const getUsers = ({ page = 1, size = 5, search }) => {
  const query = generateQueryParams({ page, size, search });
  return axios.get(`/users?${query}`);
};

const updateUserProfile = ({ userId, ...payload }) => {
  if (userId) {
    return axios.put(`/user/${userId}`, payload);
  }
  return axios.put(`/user`, payload);
};

const getUserBookmarkedProject = ({ size, page, sortBy } = {}) => {
  const sizeParam = size ? `size=${size}` : '';
  const pageParam = page ? `page=${page}` : '';
  const sortParam = sortBy ? `sortBy=${sortBy}` : '';
  return axios.get(`/project-bookmarks?${sizeParam}&${pageParam}&${sortParam}`);
};

const getUserPermission = (payload) => {
  return axios.post('/user/meet-role-requirement', payload);
};

const connectUserWallet = ({ walletAddress, signature }) => {
  return axios.put(`/user/connect-wallet`, { walletAddress, signature });
};

const disconnectUserWallet = ({ id, userId }) =>
  userId
    ? axios.delete(`/wallet-address/${id}/${userId}`)
    : axios.delete(`/wallet-address/${id}`);

const mainUserWallet = ({ id, userId }) =>
  userId
    ? axios.put(`/wallet-address/${id}/main/${userId}`)
    : axios.put(`/wallet-address/${id}/main`);

const mergeUsers = ({ primaryUserId, mergingUserId }) =>
  axios.put(`/user/${primaryUserId}/merge`, { mergingUserId });

export {
  getUserProfile,
  getUser,
  getUsers,
  updateUserProfile,
  getUserBookmarkedProject,
  getUserPermission,
  connectUserWallet,
  disconnectUserWallet,
  mainUserWallet,
  mergeUsers
};
