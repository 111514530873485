import React from 'react';
import { mergeClassNames } from '/utils/string';

/**
 * @dev Props of the Typography component
 * @reference https://tailwindcss.com/docs/font-family
 *
 * @param { boolean } heading The heading text
 * @param { boolean } link The hyperlink text
 * @param { string } variant 'large' | 'small' | 'medium' | 'xsmall' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
 * @param { string } align 'center' | 'left' | 'right' | 'justify'
 * @param { string } overflow 'truncate' | 'text-ellipsis' | 'text-clip'
 * @param { string } decoration no-underline | underline | overline | line-through
 * @param { string } transform 'normal-case' | 'lowercase' | 'capitalize' | 'uppercase'
 * @param { string } weight thin | extralight | light | normal | medium | semibold | bold | extrabold | black
 * @param { node } children The content of the component
 */
export const Typography = ({
  align,
  link,
  text,
  weight = 'normal',
  heading,
  variant = 'small',
  overflow,
  transform,
  decoration,
  children,
  onClick,
  onDoubleClick,
  color,
  colorVariant,
  hoverColor,
  className,
  style = {},
  id = ''
}) => {
  const wrapperClass = mergeClassNames(
    'block',
    transform,
    overflow,
    decoration,
    align && `text-${align}`,
    weight && `font-${weight}`,
    heading ? `heading` : 'paragraph',
    heading ? `heading-${variant}` : `paragraph-${variant}`,
    (link || onClick) && (text ? 'cursor-text' : 'cursor-pointer'),
    link && 'underline',
    color && `text-[${color}]`,
    colorVariant &&
      `color-${colorVariant} dark:text-${
        colorVariant === 'secondary' ? 'neutral-400' : 'neutral-100'
      }`,
    hoverColor && `hover:text-${hoverColor}`,
    className || ''
  );

  const handleClick = (e) => {
    onClick && onClick(e);
  };

  const handleDoubleClick = () => {
    onDoubleClick && onDoubleClick();
  };

  return (
    <div
      id={id}
      className={wrapperClass}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      style={style}
    >
      {children}
    </div>
  );
};
