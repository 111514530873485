import { toShortAddress } from './string';
import { updateUserProfile } from '/services/joynApi/users/user';
import { getCoverFile, getAvatar } from '/utils/image';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';
import { ROUTES } from '/config/routes';

const onUserProfileEdit = (callback) => {
  return async ({
    name,
    username,
    email,
    instagram,
    profileImage,
    bio,
    website,
    userId,
    hidePop = false,
    hideAchievements = false
  }) => {
    const profile = {
      name,
      username,
      email,
      instagram,
      bio,
      website,
      userId,
      hidePop,
      hideAchievements
    };

    if (!!profileImage?.length) {
      const { fileName, size, type, url } = profileImage[0];
      profile.avatar = {
        fileName,
        size,
        type,
        url
      };
    }
    return updateUserProfile(profile).then(callback);
  };
};

const getHeaderProfileName = (user) => {
  const address = retrieveMainWalletAddressFromWalletAddresses(
    user?.WalletAddresses
  )?.address;
  const twitterName = address ? user?.twitter : null;

  return (
    user?.username ||
    user?.name ||
    twitterName ||
    toShortAddress(address, 4) ||
    ''
  );
};

// how we store `twitter` in the DB is not standardized...
// we need to get a url
const getTwitterUrl = (twitter) => {
  if (twitter.startsWith('http')) return twitter;

  if (twitter.startsWith('twitter')) return `https://${twitter}`;

  if (twitter.startsWith('@')) return `https://twitter.com/${twitter.slice(1)}`;

  return `https://twitter.com/${twitter}`;
};

const getInstagramUrl = (instagram) => {
  if (instagram.startsWith('http')) return instagram;

  if (instagram.startsWith('instagram')) return `https://${instagram}`;

  if (instagram.startsWith('@'))
    return `https://instagram.com/${instagram.slice(1)}`;

  return `https://instagram.com/${instagram}`;
};

const getDiscordUrl = (discord) => {
  return `https://discord.com/users/${discord}`;
};

const getWalletAddressFromUser = ({ walletAddresses = [], address = '' }) => {
  return walletAddresses?.find(
    (walletAddress) =>
      walletAddress.address.toLowerCase() === address?.toLowerCase()
  );
};

const getProfileRoute = (user) => {
  return ROUTES.profile.view({
    profileId:
      user?.username ||
      retrieveMainWalletAddressFromWalletAddresses(user?.WalletAddresses)
        .address
  });
};

const getUserAvatarDetails = (user) => ({
  id: user?.id ?? '',
  name: user?.name ?? '',
  username: user?.username ?? '',
  WalletAddresses: user?.WalletAddresses ?? [],
  address: retrieveMainWalletAddressFromWalletAddresses(
    user?.WalletAddresses ?? []
  )?.address,
  avatar: getAvatar(user ?? {}),
  name: user?.name,
  twitter: user?.twitter
});

export {
  onUserProfileEdit,
  getHeaderProfileName,
  getInstagramUrl,
  getTwitterUrl,
  getDiscordUrl,
  getWalletAddressFromUser,
  getProfileRoute,
  getUserAvatarDetails
};
