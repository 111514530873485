import React, { useEffect, useState } from 'react';
import { MakeOfferForm } from './MakeOfferForm';
import { ApproveCurrency } from './ApproveCurrency';
import { useErc20Contract } from '/hooks/on-chain/useErc20Contract';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';
import { useSelector } from 'react-redux';
import { parseToWei } from '/utils/web3';
import {
  getConduitAddress,
  getWrappedNativeTokenContractAddress
} from '/utils/contracts';
import { toast } from 'react-toastify';
import { useAccount, useNetwork } from 'wagmi';
import { useSigner } from '/utils/ethers';
import { useOffer } from '/hooks/useOffer';
import { isHavingAllowance, makeOffer } from '/utils/marketplace';
import { OfferModal } from '/design-systems/Molecules/Modals/OfferModal';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';

const MAKE_OFFER_STEPS = {
  MAKE_OFFER_FORM: '1',
  APPROVE_CURRENCY: '2',
  APPROVE_OFFER: '3'
};

export const MakeOfferModal = ({
  open,
  onClose,
  assetChainId,
  assetCreatorAddress,
  assetCreator,
  title,
  assetImage,
  isOneOfMany,
  totalSupply,
  tokenId,
  refetchOfferData,
  isAvailableForPlaceBid,
  auctionEndTime,
  auctionId,
  highestOffer,
  reservePrice,
  galleryId
}) => {
  const [step, setStep] = useState(MAKE_OFFER_STEPS.MAKE_OFFER_FORM);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const signer = useSigner();
  const { address } = useAccount();

  const { chain } = useNetwork();

  const { signOfferMutation } = useOffer({});

  const currentUser = useSelector((state) => state.user.current);
  const currentWalletAddress = retrieveMainWalletAddressFromWalletAddresses(
    currentUser?.WalletAddresses ?? []
  )?.address;

  const { allowance, approve } = useErc20Contract({
    tokenAddress: getWrappedNativeTokenContractAddress({ chainId: chain?.id })
  });

  const onMakeOffer = async (payload) => {
    setIsSubmitting(true);

    try {
      if (isAvailableForPlaceBid) {
        payload.auctionId = auctionId;
      }
      if (
        !(await isHavingAllowance({
          chainId: assetChainId,
          offerPrice: payload?.offerPrice,
          allowance,
          walletAddress: currentWalletAddress
        }))
      ) {
        setStep(MAKE_OFFER_STEPS.APPROVE_CURRENCY);

        await approve({
          spender: getConduitAddress({ chainId: assetChainId }),
          amount: parseToWei({ amount: '1000' })
        });

        if (
          await isHavingAllowance({
            chainId: assetChainId,
            offerPrice: payload?.offerPrice,
            allowance,
            walletAddress: currentWalletAddress
          })
        ) {
          await onApproveCurrency({ offer: payload });
        } else {
          toast('Not enough approval given', { type: 'error' });
          setStep(MAKE_OFFER_STEPS.MAKE_OFFER_FORM);
        }
      } else {
        await onApproveCurrency({ offer: payload });
      }
    } catch (error) {
      toast('Approval transaction rejected', { type: 'error' });
      setStep(MAKE_OFFER_STEPS.MAKE_OFFER_FORM);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onApproveCurrency = async ({ offer }) => {
    setStep(MAKE_OFFER_STEPS.APPROVE_OFFER);
    await onApproveOffer({ offer });
  };

  const onApproveOffer = async ({ offer }) => {
    setIsSubmitting(true);
    try {
      await makeOffer({
        chainId: assetChainId,
        offer,
        provider: signer,
        signOfferMutation,
        onSuccess: () => setStep(MAKE_OFFER_STEPS.MAKE_OFFER_FORM),
        address,
        galleryId
      });
      refetchOfferData({});
      onClose();
    } catch (error) {
      toast('Offer creation error', { type: 'error' });
      setStep(MAKE_OFFER_STEPS.MAKE_OFFER_FORM);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    return () => setStep(MAKE_OFFER_STEPS.MAKE_OFFER_FORM);
  }, []);

  return (
    <Modal
      id="make-offer-modal"
      open={open}
      padding="p-6"
      onClose={onClose}
      innerContainerClassName="h-auto"
      isTop={true}
    >
      {MAKE_OFFER_STEPS.MAKE_OFFER_FORM === step && (
        <MakeOfferForm
          onSubmit={onMakeOffer}
          onClose={onClose}
          title={title}
          assetChainId={assetChainId}
          assetImage={assetImage}
          assetCreator={assetCreator}
          assetCreatorAddress={assetCreatorAddress}
          isOneOfMany={isOneOfMany}
          totalSupply={totalSupply}
          tokenId={tokenId}
          isSubmitting={isSubmitting}
          isAvailableForPlaceBid={isAvailableForPlaceBid}
          auctionEndTime={auctionEndTime}
          highestOffer={highestOffer}
          reservePrice={reservePrice}
        />
      )}
      {MAKE_OFFER_STEPS.APPROVE_CURRENCY === step && (
        <ApproveCurrency
          isSubmitting={isSubmitting}
          onSubmit={onApproveCurrency}
        />
      )}
      {MAKE_OFFER_STEPS.APPROVE_OFFER === step && (
        <OfferModal
          isSubmitting={isSubmitting}
          title={title}
          assetChainId={assetChainId}
          assetImage={assetImage}
          assetCreator={assetCreator}
          assetCreatorAddress={assetCreatorAddress}
          modalTitle={isAvailableForPlaceBid ? 'Approve bid' : 'Approve offer'}
          modalContent={
            <Typography
              variant="small"
              className="align-center flex self-start text-[#111827]"
            >
              Please review and confirm this{' '}
              {isAvailableForPlaceBid ? 'bid' : 'offer'} from your wallet.
            </Typography>
          }
          modalActionButtonText="Signing order"
          modalActionButtonLoadingText="Signing order..."
        />
      )}
    </Modal>
  );
};
