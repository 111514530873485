import React from 'react';

import { FloatingTooltip } from '/design-systems/Atoms/FloatingTooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNote } from '@fortawesome/pro-regular-svg-icons';

export const OfferNoteButton = ({ offer, onOfferNote }) => {
  return (
    <FloatingTooltip label="View offer note" placement="top">
      <button
        className="offer-note-btn button flex w-fit items-center justify-center gap-x-2"
        onClick={() => onOfferNote({ offer })}
      >
        <FontAwesomeIcon
          icon={faNote}
          className="h-5 w-5 text-neutral-500 hover:text-neutral-700"
        />
      </button>
    </FloatingTooltip>
  );
};
