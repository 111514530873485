import React, { useCallback, useEffect, useState } from 'react';
import Files from 'react-files';
import { FileList as FileListDefault } from '/design-systems/Molecules/Files/FileList';
import { deleteFile } from '/services/joynApi/users/file';

import {
  getPresignedData,
  uploadImageToS3
} from '/services/joynApi/users/file';
import { MAX_FILE_SIZE } from '/utils/constants';
import { Typography } from '/design-systems/Atoms/Typography';
import { FileDrop as FileDropDefault } from '/design-systems/Atoms/FileDrop';

export const FileUploader = ({
  autoDelete = true,
  disabled = false,
  entity,
  keyName,
  multiple,
  existingFiles = [],
  onChange,
  setUploading,
  accepts,
  isListing = true,
  FileDrop,
  FileList
}) => {
  const [files, setFiles] = useState([]);
  const [uploadingProgresses, setUploadingProgresses] = useState({});
  const [uploadingImages, setUploadingImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [uploadingDone, setUploadingDone] = useState(true);
  const [fileSizeError, setFileSizeError] = useState('');

  const onImageRemove = (index) => {
    const removeImageFromUI = () => {
      const newFiles = files.filter((_, i) => index !== i);
      setFiles(newFiles);
      setErrors((errors) => {
        return {
          ...errors,
          [index]: false
        };
      });
      onChange(newFiles);
    };

    const file = files[index];
    if (file.uploaded && autoDelete) {
      deleteFile(file.id).then(() => {
        removeImageFromUI();
      });
    } else {
      removeImageFromUI();
    }
  };

  useEffect(() => {
    if (!existingFiles?.length) return;
    setFiles(existingFiles);
  }, [existingFiles]);

  const handleUploadProgress = useCallback((val, index) => {
    setUploadingProgresses((value) => ({
      ...(value || {}),
      [index]: val
    }));
  }, []);

  const handleChange = async (newFiles) => {
    if (newFiles?.length) {
      setFileSizeError('');
    }

    const updatedNewFiles = newFiles.map((file) => {
      const {
        name: fileName,
        type: filetype,
        size,
        extension: fileExtension,
        id
      } = file;
      return {
        id,
        fileName,
        filetype,
        uploaded: false,
        size,
        fileExtension,
        file
      };
    });
    const allFiles = [...files, ...updatedNewFiles];
    setFiles(allFiles);
    setUploading(true);

    setUploadingImages(() => {
      return allFiles.map((file) => {
        return updatedNewFiles.findIndex((el) => file.id == el.id) !== -1;
      });
    });

    for (const file of updatedNewFiles) {
      const fileIndex = allFiles.findIndex((el) => {
        return el.id == file.id;
      });
      setUploadingDone(false);

      try {
        const presignedFile = await getPresignedData({
          key: `${entity}/${keyName}`,
          fileName: file.fileName,
          fileType: file.filetype
        });
        const {
          url,
          fields,
          fields: { key }
        } = presignedFile.data.data;

        await uploadImageToS3({
          url,
          presignedData: fields,
          file: file.file,
          onUploadProgress: (progress) => {
            handleUploadProgress(progress, fileIndex);
          }
        });
        allFiles[fileIndex] = {
          ...allFiles[fileIndex],
          url: `${key}`,
          fileName: file.fileName,
          type: file.filetype,
          size: file.size
        };
        setUploadingDone(true);
      } catch (error) {
        allFiles[fileIndex] = {
          ...allFiles[fileIndex],
          error: true
        };
        setErrors((errors) => {
          return {
            ...errors,
            [fileIndex]: true
          };
        });
        setUploadingDone(true);
        console.error(error);
      }
    }

    setUploadingImages(() => {
      return allFiles.map(() => {
        return false;
      });
    });

    onChange(allFiles);
    setUploading(false);
  };

  const FileListing = () => {
    if (isListing) {
      if (FileList) {
        return (
          <FileList
            errors={errors}
            images={files}
            uploadingImages={uploadingImages}
            uploadingProgresses={uploadingProgresses}
            onRemove={onImageRemove}
          />
        );
      } else {
        return (
          <FileListDefault
            errors={errors}
            images={files}
            uploadingImages={uploadingImages}
            uploadingProgresses={uploadingProgresses}
            onRemove={onImageRemove}
          />
        );
      }
    } else {
      return <></>;
    }
  };

  const onFileUploadError = useCallback((error, file) => {
    if (error.code === 2) {
      setFileSizeError(
        `Sorry, the file ${file.name} exceeds the maximum file size limit of 200 MB. Please choose a smaller file to upload.`
      );
    }
  }, []);

  return (
    <div className="files h-full w-full">
      <Files
        className="files-dropzone w-full"
        onChange={handleChange}
        accepts={accepts}
        multiple={multiple}
        minFileSize={0}
        maxFiles={disabled ? 0 : Infinity}
        clickable={!disabled}
        maxFileSize={MAX_FILE_SIZE}
        onError={onFileUploadError}
      >
        {(multiple == true || (!multiple && !files?.length)) && (
          <>
            {FileDrop ? (
              <FileDrop uploadingDone={uploadingDone} />
            ) : (
              <FileDropDefault
                disabled={disabled}
                uploadingDone={uploadingDone}
                multiple={multiple}
              />
            )}
          </>
        )}
      </Files>
      {fileSizeError && (
        <Typography color="#ef4444" variant="xsmall" className="my-2">
          {fileSizeError}
        </Typography>
      )}

      <FileListing />
    </div>
  );
};
