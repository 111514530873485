import React from 'react';
import { getImageProps } from '/utils/prompt-submission';
import { emptyArray } from '/utils/object';
import { Modal } from '/design-systems/Atoms/Modal';
import { Image } from '/design-systems/Atoms/Image';
import { Typography } from '/design-systems/Atoms/Typography';
import { Button } from '/design-systems/Atoms/Button';

export const AssetActionSuccessModal = ({
  open,
  files,
  title,
  price,
  fee,
  description,
  actionTitle,
  onClose,
  onViewNFT,
  onActionNFT,
}) => {
  return (
    <Modal maxWidth={582} open={open} autoHeight showCloseIconOnTop onClose={onClose}>
      <Typography weight='semibold' overflow='truncate' align='center' variant='h3' className='mb-8' heading>
        {title}
      </Typography>
      {description && (
        <Typography className='mb-6' align='center'>
          {description}
        </Typography>
      )}
      {!emptyArray(files) && (
        <div className='mb-6 flex justify-center'>
          <div className='h-[240px] w-[240px]'>
            <Image
              {...getImageProps(files)}
              resolution='m'
              className='h-full w-full rounded-lg'
              style={{ objectFit: 'cover' }}
            />
          </div>
        </div>
      )}
      {(price || fee) && (
        <div className='flex justify-center'>
          <div className='w-[240px]'>
            <div className='mb-4 flex items-center justify-between'>
              <Typography weight='semibold'>Price</Typography>
              <Typography>{price} ETH</Typography>
            </div>
            <div className='mb-4 flex items-center justify-between'>
              <Typography weight='semibold'>Fee</Typography>
              <Typography>{fee}%</Typography>
            </div>
          </div>
        </div>
      )}
      <div className='flex justify-center'>
        <div className='flex w-full max-w-[300px] flex-col gap-4'>
          {onActionNFT && (
            <Button color='black' variant='primary' blocked onClick={onActionNFT}>
              {actionTitle}
            </Button>
          )}
          <Button color='black' variant='outlined' blocked onClick={onViewNFT}>
            View NFT
          </Button>
        </div>
      </div>
    </Modal>
  );
};
