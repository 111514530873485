export const QUERY_KEYS = {
  ASSET: {
    GET_OWNER: 'get-asset-owner',
    GET_APPROVED_OPERATOR: 'get-asset-operator',
    IS_APPROVED_FOR_ALL: 'is-approved-for-all',
    GET_ASSETS: 'get-assets',
    GET_CREATED_ASSETS: 'get-created-assets',
    GET_OWNED_ASSETS: 'get-owned-assets',
    GET_OWNED_JOYN_ASSETS: 'get-owned-joyn-assets',
    GET: 'get-single-asset',
    GET_AUCTION_DETAIL: 'get-auction-details'
  },
  V2_LISTING: {
    GET_ACTIVE_LISTINGS: 'get-v2-active-listings',
    GET_CHEAPEST_ACTIVE_LISTING: 'get-v2-cheapest-active-listing'
  },
  ASSET_MINT: {
    CREATE: 'create-asset-mint'
  },
  ASSET_LIST: {
    CREATE: 'create-asset-list'
  },
  SPACES: {
    GET: 'get-single-space',
    ALL: 'get-spaces',
    WHITELISTED: 'get-whitelisted'
  },
  PROMPT_SUBMISSION: {
    GET: 'get-prompt-submission',
    ALL: 'get-all-prompt-submissions'
  },
  PROMPT_SUBMISSION_APPROVAL: {
    ALL: 'get-all-prompt-submission-approvals'
  },
  MY_PROMPT_SUBMISSION: {
    ALL: 'get-all-my-prompt-submissions'
  },
  CAMPAIGN: {
    GET_ALL: 'get-all-campaigns'
  },
  CONTEST: {
    GET: 'get-single-contest',
    GET_ALL: 'get-all-contests'
  },
  GIVEAWAY: {
    GET: 'get-single-giveaway'
  },
  ORACLE_TEMPLATES: {
    ALL: 'get-oracle-templates',
    SINGLE: 'get-oracle-template'
  },
  ORACLE_FILE: {
    ALL: 'get-oracle-files',
    SINGLE: 'get-oracle-file'
  },
  TOKEN: {
    GET: 'get-token',
    PRICE: 'get-token-price'
  },
  TOKEN_OWNER: {
    GET: 'get-token-owner'
  },
  TOKEN_ACTIVITY: {
    GET: 'get-token-activity'
  },
  TOKEN_ROYALTY_RECIPIENTS: {
    GET: 'get-token-royalty-recipients'
  },
  WALLET_ADDRESS: {
    GET_OWNER: 'get-wallet-address-owner'
  },
  USAGE_RIGHT: {
    ALL: 'get-all-usage-rights'
  },
  CHAIN: {
    ERC721: 'create-erc721-service',
    ERC1155: 'create-erc1155-service',
    MARKETPLACE: 'create-marketplace-service',
    FOUNDATION_MARKETPLACE: 'create-foundation-marketplace-service',
    ROYALTY_REGISTRY: 'create-royalty-registry-service',
    ERC20: 'create-erc20-service'
  },
  USERS: {
    ALL: 'get-users'
  },
  ACTIVITY: {
    LISTINGS: 'get-listings',
    RECEIVED_OFFERS: 'get-received-offers',
    OFFERS_MADE_BY_USER: 'get-offers-made-by-user',
    MADE_OFFERS: 'get-made-offers'
  },
  OFFER: {
    GET_OFFERS: 'get-offers',
    GET_HIGHEST_OFFER: 'get-highest-offer'
  },
  GALLERY: {
    GET: 'get-gallery',
    GET_GALLERIES_FOR_ASSET: 'get-galleries-for-asset',
    Get_Gallery_COLLABORATORS: 'get-gallery-collaborators',
    GET_GALLERY_COLLABORATOR_INVITATIONS: 'get-gallery-collaborator-invitations'
  },
  COLLAB_REQUESTS: {
    GET_RECEIVED: 'get-received-collab-requests',
    GET_SENT: 'get-sent-collab-requests',
    GET_ARCHIVED: 'get-archived-collab-requests'
  }
};
