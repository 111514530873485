import React from 'react';
import { WagmiConfig, createConfig, configureChains } from 'wagmi';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import {
  ALCHEMY_RPC_URL,
  CUSTOM_CHAINS,
  DEFAULT_CHAIN_ID,
  SUPPORTED_CHAINS
} from '/utils/web3';

const { chains, publicClient, webSocketPublicClient } = configureChains(
  SUPPORTED_CHAINS,
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: ALCHEMY_RPC_URL[chain?.id]
      })
    })
  ],
  {
    pollingInterval: 18000,
    targetQuorum: 3
  }
);

const chainConfig = createConfig({
  autoConnect: true,
  chains,
  connectors: [
    new MetaMaskConnector({
      chains: CUSTOM_CHAINS,
      options: {
        shimDisconnect: true,
        shimChainChangedDisconnect: true
      }
    }),
    new CoinbaseWalletConnector({
      chains: CUSTOM_CHAINS,
      options: {
        chainId: DEFAULT_CHAIN_ID,
        reloadOnDisconnect: false
      }
    }),
    new WalletConnectConnector({
      chains: CUSTOM_CHAINS,
      options: {
        projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID
      }
    })
  ],
  publicClient,
  webSocketPublicClient
});

export const ChainManager = ({ children }) => {
  return <WagmiConfig config={chainConfig}>{children}</WagmiConfig>;
};
