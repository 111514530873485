import { useMemo } from 'react';

import {
  getGalleries,
  getGalleriesForAsset
} from '/services/joynApi/producers/gallery';

import { usePaginatedQuery } from './usePaginatedQuery';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '/utils/queries';

export const galleryResponseFormatter = (res) => ({
  data: res.data.data.rows,
  count: res.data.data.count
});

export const useGalleries = ({
  key = '',
  size = 12,
  projectId,
  userId,
  enabled = false,
  filters = {}
}) => {
  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetchingNextPage
  } = usePaginatedQuery(
    ['get-galleries', key],
    getGalleries,
    galleryResponseFormatter,
    {
      enabled
    },
    { projectId, userId, filters, size }
  );

  const { galleries, totalGalleries } = useMemo(
    () => ({
      galleries: data?.map((item) => item.data).flat() ?? [],
      totalGalleries: data?.[0]?.count ?? 0
    }),
    [data]
  );

  return {
    isLoading,
    galleries,
    totalGalleries,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch
  };
};

export const useGalleriesForAsset = ({ assetId }) => {
  const { isLoading: isLoadingGalleries, data: galleries } = useQuery(
    [QUERY_KEYS.GALLERY.GET_GALLERIES_FOR_ASSET, assetId],
    () => getGalleriesForAsset({ assetId }),
    {
      enabled: Boolean(assetId),
      select: (res) => res.data.data
    }
  );

  return useMemo(
    () => ({ isLoadingGalleries, galleries: galleries ?? [] }),
    [isLoadingGalleries, galleries]
  );
};
