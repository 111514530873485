import React, { useCallback } from 'react';
import { Menu } from '@headlessui/react';
import ChevronDownIcon from '@heroicons/react/solid/ChevronDownIcon';

import { Typography } from '../Typography';

import { mergeClassNames } from '/utils/string';

export const DropDown = ({
  id = '',
  title,
  items,
  label,
  hideChevron = false,
  px,
  py,
  top = 0,
  bottom = 0,
  position = 'top', // top | bottom
  className = '',
  itemsClassName,
  onDropDownClick,
  btnClassName
}) => {
  const handleClickMenu = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    <Menu
      id={id}
      as="div"
      className={mergeClassNames(
        'relative inline-block flex text-left',
        className
      )}
    >
      <Menu.Button
        className={mergeClassNames(
          'inline-flex w-full justify-center rounded-full',
          px !== undefined ? `px-${px}` : 'px-4',
          py !== undefined ? `py-${py}` : 'py-2',
          'text-md bg-white text-sm font-medium font-bold text-neutral-900 hover:bg-neutral-100 dark:bg-black',
          btnClassName
        )}
        onClick={handleClickMenu}
      >
        {label}

        {!hideChevron && (
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 " aria-hidden="true" />
        )}
      </Menu.Button>
      <Menu.Items
        className={mergeClassNames(
          'absolute right-0 origin-top-right',
          'z-20 mt-2 w-56 rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-black',
          getMenuContainerClassNames(position, { top, bottom }),
          itemsClassName
        )}
        onClick={handleClickMenu}
      >
        <div className="py-2">
          {title && (
            <div className="mx-2 w-full px-2 py-1">
              <Typography colorVariant="secondary" variant="small">
                {title}
              </Typography>
            </div>
          )}
          {items.map((item, idx) => {
            return (
              <Menu.Item key={idx}>
                {({ active }) => {
                  return (
                    <div
                      href="#"
                      className={mergeClassNames(
                        active
                          ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-800 dark:text-neutral-200'
                          : 'text-neutral-700 dark:text-neutral-300',
                        'mx-2 block cursor-pointer rounded-lg px-2 py-2 font-medium'
                      )}
                      onClick={(e) => onDropDownClick?.(item, e)}
                      value={item}
                    >
                      {item}
                    </div>
                  );
                }}
              </Menu.Item>
            );
          })}
        </div>
      </Menu.Items>
    </Menu>
  );
};

const getMenuContainerClassNames = (position = 'top', { top, bottom }) => {
  switch (position) {
    case 'top':
      return top ? `top-${top}` : 'top-0';
    case 'bottom':
      return bottom ? `bottom-${bottom}` : 'bottom-0';
  }
};
