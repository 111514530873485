import React from 'react';
import { Typography } from '/design-systems/Atoms/Typography';
import { Button } from '/design-systems/Atoms/Button';
import { XIcon } from '@heroicons/react/outline';

export const DisconnectModal = ({ open, onClose, title, onDisconnect }) => {
  if (!open) return <></>;

  const handleClick = (event) => {
    const name = event.target?.attributes?.name?.value;
    if (name === 'modal-wrapper') {
      onClose();
    }
  };

  return (
    <div
      name='modal-wrapper'
      className='modal-wrapper fixed left-0 top-0 z-[9999] flex flex h-screen w-screen flex-col items-center justify-start pt-10'
      onClick={handleClick}
    >
      <div className='float-left w-[2rem] cursor-pointer self-start'>
        <XIcon className='mx-4 h-6 text-white' onClick={handleClick} name='modal-wrapper' />
      </div>
      <div className='flex h-[90%] flex-row items-center' name='modal-wrapper' onClick={handleClick}>
        <div
          className={`modal-container h-full h-[100%] w-full md:w-[570px]`}
          onClick={handleClick}
          name='modal-wrapper'
        >
          <div className='modal-innerContainer flex w-full flex-col justify-between gap-y-4 overflow-auto rounded-lg bg-white shadow shadow-md dark:bg-neutral-900'>
            <div className='modal-body flex flex-col justify-center px-[24px] py-[32px]'>
              <Typography heading variant='h5' weight='semibold' className='cursor-default text-center'>
                {title}
              </Typography>
              <Button variant='primary' color='error' className='mt-[42px]' onClick={onDisconnect}>
                <Typography variant='large' weight='medium'>
                  Disconnect
                </Typography>
              </Button>
              <Button variant='secondary' color='black' className='mt-[16px]' onClick={onClose}>
                <Typography
                  variant='large'
                  weight='medium'
                  className='text-[#4B5563] hover:text-[#1F2937] dark:text-neutral-300'
                >
                  Cancel
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
