import React, { useMemo } from 'react';
import { MODALS, useModalManager } from '/context/ModalManager';
import { BLOCKCHAINS } from '/utils/constants';
import { compareStringsIgnoreCase, mergeClassNames } from '/utils/string';
import { PillBadge } from '/design-systems/Atoms/PillBadge';
import { FloatingTooltip } from '/design-systems/Atoms/FloatingTooltip';
import { NetworkIcon } from '/design-systems/Atoms/Icon/network';
import { Typography } from '/design-systems/Atoms/Typography';
import { getChain, getChainIdFromChainName } from '/utils/web3';
import { WRAPPED_NATIVE_TOKENS } from '/utils/contracts';
import { Button } from '/design-systems/Atoms/Button';
import { faSplit, faEmptySet } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AssetPrice = ({
  id,
  blockchain = BLOCKCHAINS.ETHEREUM,
  showChainIcon = true,
  className = '',
  label = '',
  icon,
  isOffer = false,
  offerCurrency,
  price,
  usdPrice,
  size = 'medium',
  feePercentage,
  showFeePercentage,
  showFeeLabel = true,
  showFeeTooltip = true,
  feePercentageClickable,
  galleryId,
  showPrice = true,
  asset,
  feeBtnClassName,
  priceClassName,
  showFeePercentageValue = true,
  feePercentageIconClassName = 'h-[15px] w-[15px]'
}) => {
  const { showModal, closeModal } = useModalManager();
  const classNames = mergeClassNames(className, 'flex');

  const hasPercentage = useMemo(
    () => feePercentage || feePercentage === 0,
    [feePercentage]
  );

  const defaultIcon = (
    <NetworkIcon
      chain={blockchain}
      className={`${
        blockchain === BLOCKCHAINS.ETHEREUM ? 'h-4 w-4' : 'h-4 w-4'
      } object-cover`}
    />
  );

  const labelVariant = useMemo(() => {
    switch (size) {
      case 'large':
        return 'medium';
      case 'medium':
        return 'small';
      default:
        return 'small';
    }
  }, [size]);

  const currencySymbol = useMemo(() => {
    return isOffer
      ? WRAPPED_NATIVE_TOKENS.find(({ value }) =>
          compareStringsIgnoreCase(
            value(getChainIdFromChainName(blockchain)),
            offerCurrency
          )
        )?.title
      : getChain({ chain: blockchain })?.nativeCurrency?.symbol;
  }, [blockchain, isOffer, offerCurrency]);

  const handleShowCollabRequestModal = (e) => {
    if (!feePercentageClickable) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    showModal({
      modalId: MODALS.GALLERY_COLLAB_REQUEST_MODAL,
      props: {
        onClose: () => {
          closeModal();
        },
        galleryId,
        asset,
        feePercentage
      }
    });
  };

  const feeIcon = useMemo(() => {
    return (
      <FontAwesomeIcon
        icon={hasPercentage ? faSplit : faEmptySet}
        className={feePercentageIconClassName}
      />
    );
  }, [hasPercentage, feePercentageIconClassName]);

  const feeLabel = useMemo(() => {
    return (
      <>
        {((showFeePercentage && feePercentage > 0) ||
          (showFeeLabel && !hasPercentage)) && (
          <div className="collab-request-fee-percentage group">
            {hasPercentage ? (
              <span className={showFeePercentageValue || 'hidden'}>
                {feePercentage + '%'}
              </span>
            ) : (
              <span>Not listed</span>
            )}
          </div>
        )}
      </>
    );
  }, [
    showFeePercentage,
    feePercentage,
    showFeeLabel,
    hasPercentage,
    showFeePercentageValue
  ]);

  return (
    <div
      className={mergeClassNames(
        'flex items-center justify-between',
        classNames
      )}
    >
      {price && (
        <div className="flex flex-col gap-2">
          {label && price && (
            <Typography
              id={id}
              variant={labelVariant}
              weight="medium"
              className="text-neutral-800 dark:text-neutral-200"
            >
              {label}
            </Typography>
          )}
          {showPrice && price && (
            <div className="flex items-center gap-x-1">
              {showChainIcon && showPrice && price
                ? icon
                  ? icon
                  : defaultIcon
                : null}
              {showPrice && price && (
                <Typography
                  variant={size === 'large' ? 'h5' : 'medium'}
                  weight="semibold"
                  className={`asset-price ${priceClassName} ${
                    size === 'large' && 'leading-8'
                  }`}
                  heading={size === 'large'}
                >
                  {price} {currencySymbol}
                </Typography>
              )}
              {usdPrice && (
                <Typography colorVariant="secondary">
                  ${Number(Number(price) * Number(usdPrice)).toFixed(2)} USD
                </Typography>
              )}
            </div>
          )}
        </div>
      )}

      {/* fee info & button */}
      <div className="group flex items-center gap-1 transition duration-300">
        {showFeePercentage && (
          <>
            {feePercentageClickable ? (
              <FloatingTooltip
                label="Click to edit curator fee."
                placement="bottom"
                hide={!feePercentageClickable}
              >
                <Button
                  className={`${feeBtnClassName}`}
                  iconOnly
                  onClick={handleShowCollabRequestModal}
                >
                  {feeIcon}
                  {feeLabel}
                </Button>
              </FloatingTooltip>
            ) : (
              <FloatingTooltip
                label={`Listed with a ${feePercentage}% curator fee.`}
                placement="left"
                hide={!showFeeTooltip}
              >
                <div>
                  <PillBadge
                    textSize="text-sm"
                    className={mergeClassNames('!mr-0 w-fit', feeBtnClassName)}
                    icon={feeIcon}
                    variant="outlined"
                    text={feeLabel}
                  />
                </div>
              </FloatingTooltip>
            )}
          </>
        )}
      </div>
    </div>
  );
};
