import { useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { PAGE_SIZE } from '/utils/constants';

export function usePaginatedQuery(
  key,
  fetcher,
  formatter,
  options,
  fetcherParams
) {
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 1 }) => {
      return fetcher({ size: PAGE_SIZE, page: pageParam, ...fetcherParams });
    },
    {
      getNextPageParam: (lastResponse, allResponses) => {
        const page = allResponses.length;
        const size = fetcherParams?.size ?? PAGE_SIZE;
        const { count, data } = formatter(lastResponse);

        // If there are no more pages, return undefined
        // Or if there are no more items, return undefined

        if (page * size < count || (!count && data?.length)) {
          return page + 1;
        } else {
          return;
        }
      },
      ...options
    }
  );

  const pages = result?.data?.pages;
  const data = useMemo(
    () => pages?.map((item) => (formatter ? formatter(item) : item)) || [],
    [formatter, pages]
  );

  return { ...result, data };
}
