import React from 'react';
import LogoDark from '/images/logo-dark.svg';
import { Image } from '../Image';
import { mergeClassNames } from '/utils/string';

export const Logo = ({ isDarkTheme }) => {
  return (
    <Image
      staticFile
      src={LogoDark.src}
      alt="logo"
      className={mergeClassNames(
        'max-w-[110px] dark:invert',
        isDarkTheme && 'invert'
      )}
    />
  );
};
