import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { ROUTES } from '/config/routes';

export const usePage = () => {
  /**
   * @note `useRouter()` returns undefined in unit test
   */
  const router = useRouter() ?? { pathname: '', query: {} };

  const isHomePage = useMemo(
    () => router.pathname === ROUTES.home(),
    [router.pathname]
  );

  const isMarketingPage = useMemo(
    () => router.pathname === '/composer',
    [router.pathname]
  );

  const isNewPage = useMemo(
    () => router.pathname.endsWith('/new'),
    [router.pathname]
  );

  const isEditPage = useMemo(
    () => router.pathname.endsWith('/edit'),
    [router.pathname]
  );

  const isDemoPage = useMemo(
    () => router.pathname.startsWith('/demo'),
    [router.pathname]
  );

  const isNetworkLandingPage = useMemo(
    () => router.pathname.startsWith('/network'),
    [router.pathname]
  );

  const shouldBypassLayout = useMemo(
    () => isDemoPage || isNetworkLandingPage,
    [isDemoPage, isNetworkLandingPage]
  );

  const isAuthPage = useMemo(
    () => router.pathname.startsWith('/auth'),
    [router.pathname]
  );

  const isUserOnboardingPage = useMemo(
    () => router.pathname.startsWith('/auth/complete-profile'),
    [router.pathname]
  );

  const isAssetView = useMemo(
    () =>
      !!router.query.submissionId ||
      !!router.query.assetId ||
      !!router.query.tokenId,
    [router.query]
  );

  const isMintingPage = useMemo(
    () =>
      (router.pathname?.startsWith('/asset') &&
        router.pathname?.includes('/mint')) ||
      router.pathname?.startsWith('/asset/new'),
    [router.pathname]
  );

  const isListingPage = useMemo(
    () =>
      router.pathname?.startsWith('/asset') &&
      router.pathname?.includes('/list'),
    [router.pathname]
  );

  const isAssetEditPage = useMemo(
    () =>
      router.pathname?.startsWith('/asset') &&
      (router.pathname?.includes('/edit') ||
        router.pathname?.includes('/token-settings')),
    [router.pathname]
  );

  const isAssetComposePage = useMemo(
    () =>
      router.pathname?.startsWith('/asset') &&
      router.pathname?.endsWith('/compose'),
    [router.pathname]
  );

  const isLinkNftPage = useMemo(
    () =>
      router.pathname?.startsWith('/asset') &&
      router.pathname?.endsWith('/link/nft'),
    [router.pathname]
  );

  const isManageAssetPage = useMemo(
    () =>
      isMintingPage ||
      isListingPage ||
      isAssetEditPage ||
      isLinkNftPage ||
      router.pathname?.startsWith('/asset/new') ||
      router.pathname?.startsWith('/my-assets') ||
      router.pathname?.startsWith('/listing/new'),
    [
      isAssetEditPage,
      isLinkNftPage,
      isListingPage,
      isMintingPage,
      router.pathname
    ]
  );

  const isAssetDetailsPage = useMemo(
    () =>
      router.pathname?.startsWith('/asset') &&
      !isManageAssetPage &&
      !isAssetComposePage,
    [router.pathname, isManageAssetPage, isAssetComposePage]
  );

  const isGallerySubmissionPage = useMemo(
    () => router.pathname?.includes('/gallery-submission/new'),
    [router.pathname]
  );

  const isSpaceEditPage = useMemo(() => {
    return (
      router.pathname?.startsWith('/space/new') ||
      (router.pathname?.startsWith('/space') &&
        router.pathname?.includes('/edit'))
    );
  }, [router.pathname]);

  const isSpacePage = useMemo(() => {
    return router.pathname?.startsWith('/space') && !isSpaceEditPage;
  }, [router.pathname, isSpaceEditPage]);

  const isMySpacesPage = useMemo(() => {
    return router.pathname?.startsWith('/my-spaces');
  }, [router.pathname]);

  // TODO remove old project page
  const isProjectPage = useMemo(() => {
    return (
      router.pathname?.startsWith('/project') &&
      !router.pathname?.includes('/prompt-submission/')
    );
  }, [router.pathname]);

  // TODO remove old project page
  const isProjectFormPage = useMemo(() => {
    return (
      (router.pathname?.startsWith('/project') &&
        router.pathname?.includes('/new')) ||
      (router.pathname?.startsWith('/project') &&
        router.pathname?.includes('/edit'))
    );
  }, [router.pathname]);

  const isPromptPage = useMemo(() => {
    return (
      (router.pathname?.includes('/contest') ||
        router.pathname?.includes('/giveaway')) &&
      !router.pathname?.includes('/edit') &&
      !router.pathname?.includes('/new')
    );
  }, [router.pathname]);

  const isManagePromptPage = useMemo(() => {
    return (
      router.pathname?.startsWith('/contest') &&
      (router.pathname?.includes('/edit') || router.pathname?.includes('/new'))
    );
  }, [router.pathname]);

  const isGalleryPage = useMemo(() => {
    return (
      router.pathname?.startsWith('/gallery') &&
      !router.pathname?.includes('/new') &&
      !router.pathname?.includes('/edit')
    );
  }, [router.pathname]);

  const isStoryFormPage = useMemo(
    () =>
      router.pathname === ROUTES.story.new() ||
      router.pathname === ROUTES.story.edit('[galleryId]'),
    [router.pathname]
  );

  const isShowcaseFormPage = useMemo(
    () =>
      router.pathname === ROUTES.showcase.new() ||
      router.pathname === ROUTES.showcase.edit('[galleryId]'),
    [router.pathname]
  );

  const isGalleryFormPage = useMemo(() => {
    return (
      router.pathname === ROUTES.gallery.new() ||
      router.pathname === ROUTES.gallery.edit('[galleryId]') ||
      isStoryFormPage ||
      isShowcaseFormPage
    );
  }, [router.pathname, isShowcaseFormPage, isStoryFormPage]);

  const isProfilePage = useMemo(
    () =>
      router.pathname?.startsWith('/profile') &&
      !router.pathname?.includes('/edit'),
    [router.pathname]
  );

  const isOpenCallPage = useMemo(
    () =>
      router.pathname?.startsWith('/contest') &&
      !router.pathname?.includes('/edit') &&
      !router.pathname?.includes('/new'),
    [router.pathname]
  );

  const isOraclePage = useMemo(
    () => router.pathname.startsWith('/oracle'),
    [router.pathname]
  );

  const isUserActivityPage = useMemo(
    () => router.pathname.startsWith('/dashboard'),
    [router.pathname]
  );

  const isCreateHomePage = useMemo(
    () =>
      router.pathname?.startsWith('/create') ||
      router.pathname?.startsWith('/compose') ||
      router.pathname?.startsWith('/prelaunch/composer'),
    [router.pathname]
  );

  const isShowcasePage = useMemo(
    () =>
      router.pathname?.startsWith('/showcase') &&
      !router.pathname?.includes('/edit') &&
      !router.pathname?.includes('/new'),
    [router.pathname]
  );

  const isGalleryViewPage = useMemo(
    () =>
      (router.pathname?.startsWith('/gallery') ||
        router.pathname?.startsWith('/showcase') ||
        router.pathname?.startsWith('/story')) &&
      !isEditPage &&
      !isNewPage,
    [isEditPage, isNewPage, router.pathname]
  );

  const isDashboardPage = useMemo(
    () => router.pathname.startsWith('/dashboard'),
    [router.pathname]
  );

  const isPreviewPage = useMemo(
    () => router.pathname?.endsWith('/preview'),
    [router.pathname]
  );

  const is1120 = useMemo(
    () => isPromptPage || isMySpacesPage || isCreateHomePage,
    [isPromptPage, isMySpacesPage, isCreateHomePage]
  );

  const isMaxHeaderWidth = useMemo(
    () => isGalleryFormPage || isAssetView || isOraclePage || isDashboardPage,
    [isGalleryFormPage, isAssetView, isOraclePage, isDashboardPage]
  );

  const showTransparentNav = useMemo(() => {
    if (isAssetView) return false;

    return isProfilePage || isShowcasePage || isSpacePage;
  }, [isProfilePage, isAssetView, isShowcasePage, isSpacePage]);

  const showTransparentNavLight = useMemo(() => {
    return isAssetDetailsPage;
  }, [isAssetDetailsPage]);

  const values = {
    isHomePage,
    isMarketingPage,
    isNewPage,
    isEditPage,
    isDemoPage,
    isNetworkLandingPage,
    shouldBypassLayout,
    isAuthPage,
    isUserOnboardingPage,
    isAssetView,
    isMintingPage,
    isListingPage,
    isAssetEditPage,
    isAssetDetailsPage,
    isGallerySubmissionPage,
    isManageAssetPage,
    isAssetComposePage,
    isSpacePage,
    isSpaceEditPage,
    isMySpacesPage,
    isProjectPage,
    isProjectFormPage,
    isPromptPage,
    isManagePromptPage,
    isGalleryPage,
    isGalleryFormPage,
    isStoryFormPage,
    isShowcaseFormPage,
    isProfilePage,
    isOpenCallPage,
    isOraclePage,
    isUserActivityPage,
    isShowcasePage,
    isGalleryViewPage,
    isPreviewPage,
    showTransparentNav,
    showTransparentNavLight,
    isMaxHeaderWidth,
    isDashboardPage,
    is1120
  };

  return useMemo(
    () => values,
    [Object.values(values)] // eslint-disable-line react-hooks/exhaustive-deps
  );
};
