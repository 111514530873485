import React from 'react';

export const AssetModalPlaceholder = ({}) => {
  return (
    <div className='flex h-full animate-pulse flex-col lg:flex-row'>
      {/* image */}
      <div className='flex h-full flex-1 flex-row flex-wrap bg-neutral-200'></div>

      {/* details pane */}
      <div className='flex w-full flex-col gap-y-6 p-6 lg:max-w-[500px]'>
        <div className='flex flex-col gap-y-4'>
          <div className='h-6 w-[160px] rounded-full bg-neutral-200'></div>
          <div className='flex items-center gap-x-2'>
            <div className='h-8 w-8 rounded-full bg-neutral-200'></div>
            <div className='h-4 w-[80px] rounded-full bg-neutral-200'></div>
          </div>
        </div>

        {/* description */}
        <div className='flex flex-col gap-y-4'>
          <div className='h-4 w-full rounded-full bg-neutral-200'></div>
          <div className='h-4 w-full rounded-full bg-neutral-200'></div>
        </div>

        <div className='h-10 w-[120px] rounded-md bg-neutral-200'></div>
      </div>
    </div>
  );
};
