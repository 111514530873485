import axios from '/services/axios';
import { AUCTION_TYPE_ID } from '/utils/constants';

export const getAuction = ({ tokenId }) => axios.get(`/auction/get/${tokenId}`);

export const createAuction = ({
  tokenId,
  walletAddress,
  price,
  amount,
  currencyContractAddress,
  startTime,
  endTime,
  galleryCollabRequestId,
  curatorFee,
  marketplaceFee,
  duration,
  auctionType = AUCTION_TYPE_ID.DEFAULT
}) => {
  const payload = {
    tokenId,
    walletAddress,
    price,
    amount,
    currencyContractAddress,
    galleryCollabRequestId,
    curatorFee,
    marketplaceFee,
    auctionType
  };

  if (auctionType === AUCTION_TYPE_ID.DEFAULT) {
    payload.startTime = startTime;
    payload.endTime = endTime;
  } else {
    payload.duration = duration;
  }

  return axios.post(`/auction`, payload);
};

export const cancelAuction = ({ auctionId }) =>
  axios.put(`/auction/cancel/${auctionId}`);

export const updateAuction = ({
  auctionId,
  reservePrice,
  startDate,
  endDate,
  duration,
  auctionType
}) => {
  const payload = { reservePrice };

  if (auctionType === AUCTION_TYPE_ID.DEFAULT) {
    payload.startDate = startDate;
    payload.endDate = endDate;
  } else {
    payload.duration = duration;
  }

  return axios.put(`/auction/update/${auctionId}`, payload);
};
