import React, { useMemo } from 'react';

import { Typography } from '/design-systems/Atoms/Typography';
import { mergeClassNames } from '/utils/string';

export const ShortCountdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
  countdownStyle: customCountdownStyle = '',
  countdownTypographyVariant = 'h5',
  isForPreviewModal = false
}) => {
  const typoClassName = useMemo(
    () => (!isForPreviewModal ? 'leading-none tracking-wider' : ''),
    [isForPreviewModal]
  );

  const countdownStyle = useMemo(() => {
    if (countdownTypographyVariant !== 'h5') {
      return '';
    }

    if (days > 0) {
      return 'min-w-[200px]';
    }
    if (hours > 0) {
      return 'min-w-[150px]';
    }
    return 'min-w-[100px]';
  }, [days, hours, countdownTypographyVariant]);

  return (
    <div
      className={mergeClassNames('flex', customCountdownStyle, countdownStyle)}
    >
      {days > 0 && (
        <Typography
          heading
          variant={countdownTypographyVariant}
          weight="semibold"
          className={typoClassName}
          id="countdown-days"
        >
          {days}d
        </Typography>
      )}
      {hours > 0 && (
        <Typography
          heading
          variant={countdownTypographyVariant}
          weight="semibold"
          className={typoClassName}
        >
          {hours}h
        </Typography>
      )}
      <Typography
        heading
        variant={countdownTypographyVariant}
        weight="semibold"
        className={typoClassName}
      >
        {minutes}m
      </Typography>
      <Typography
        heading
        variant={countdownTypographyVariant}
        weight="semibold"
        className={typoClassName}
      >
        {seconds}s
      </Typography>
    </div>
  );
};
