import React from 'react';

import { Typography } from '../Typography';

export const Alert = ({
  className: className_ = '',
  icon,
  iconOnly = false,
  color = 'neutral',
  blocked,
  children,
  variant = 'xsmall'
}) => {
  const className = [
    className_,
    'alert',
    `alert-color-${color}`,
    'flex items-center gap-x-2 p-2 rounded-lg dark:bg-neutral-800 dark:text-neutral-200',
    blocked && 'w-full'
  ].join(' ');

  return (
    <div className={className}>
      {icon && <div className="">{icon}</div>}
      {iconOnly || (
        <Typography variant={variant} colorVariant="secondary">
          {children}
        </Typography>
      )}
    </div>
  );
};
