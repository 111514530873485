import { useMemo, useEffect, useState } from 'react';
import { useAuction } from '/hooks/useAuction';
import { objectBlank } from '/utils/object';

export const useAssetAuction = ({
  assetChainId,
  tokenIdForListing,
  currentUser
}) => {
  const { auctionDetail, refetchAuctionDetail } = useAuction({
    tokenId: tokenIdForListing,
    chainId: assetChainId
  });

  const hasAuction = useMemo(() => {
    if (auctionDetail === undefined) {
      return undefined;
    }
    return !objectBlank(auctionDetail);
  }, [auctionDetail]);

  const isAvailableForPlaceBid = useMemo(
    () =>
      Number(currentUser?.id) !== auctionDetail?.userId &&
      !objectBlank(auctionDetail),
    [currentUser, auctionDetail]
  );

  const [isAuctionEnded, setIsAuctionEnded] = useState(
    checkAuctionExpiration(auctionDetail?.endDate)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAuctionEnded(checkAuctionExpiration(auctionDetail?.endDate));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [auctionDetail]);

  const isAuctionStarted = useMemo(() => {
    if (!auctionDetail?.startDate) return true;
    return new Date(auctionDetail?.startDate) < new Date();
  }, [auctionDetail]);

  const auctionOfferDisabled = useMemo(
    () => hasAuction && !isAuctionEnded,
    [hasAuction, isAuctionEnded]
  );

  return {
    auctionDetail,
    refetchAuctionDetail,
    hasAuction,
    isAvailableForPlaceBid,
    isAuctionEnded,
    setIsAuctionEnded,
    isAuctionStarted,
    auctionOfferDisabled
  };
};

const checkAuctionExpiration = (endDate) => {
  if (!endDate) return false;
  return new Date(endDate) < new Date();
};
