import React from 'react';
import { SpinnerFullWidth } from '../SpinnerFullWidth';

/**
 * @param { boolean } blocked  The full width
 * @param { boolean } iconOnly  The icon button
 * @param { string } variant 'primary' | 'secondary' | 'outlined' | 'tertiary'
 * @param { string } color 'neutral' | 'primary' | 'success' | 'warning' | 'error' | 'white' | 'black'
 */
export const Button = React.forwardRef(
  (
    {
      type: buttonType,
      submit,
      variant = 'primary',
      color = 'primary',
      blocked,
      active,
      iconOnly,
      rounded,
      onClick,
      children,
      loading,
      disabled = false,
      className,
      dropShadow,
      width,
      height,
      id,
      isChip,
      isTransparent = false,
      ...props
    },
    ref
  ) => {
    const classNameDefault = [
      'button font-medium',
      iconOnly ? 'px-2 py-2 hover:bg-neutral-100' : isChip ? 'px-3' : 'px-4',
      isChip ? 'py-2' : 'py-[10px]',
      rounded ? 'rounded-full' : 'rounded-lg',
      'flex items-center justify-center', // alignment
      iconOnly ? 'gap-x-1' : 'gap-x-2',
      blocked && 'w-full block',
      `button-variant-${variant}`,
      `button-color-${color}`,
      active && 'activated',
      width && `w-[${width}px]`,
      height && `h-[${height}px]`,
      isTransparent && 'relative overflow-hidden hover:bg-white/10',
      dropShadow && `drop-shadow-${dropShadow}`,
      (disabled || loading) && 'disabled cursor-not-allowed'
    ].join(' ');

    const handleClick = (e) => {
      if (disabled) return;
      onClick && onClick(e);
    };

    return (
      <button
        id={id}
        ref={ref}
        type={buttonType ?? (submit ? 'submit' : 'button')}
        className={`${classNameDefault} ${className}`}
        onClick={handleClick}
        onTouchStart={handleClick}
        disabled={disabled}
        {...props}
      >
        {loading ? (
          <div className="h-[30px] w-[30px]">
            <SpinnerFullWidth />
          </div>
        ) : (
          children
        )}

        {isTransparent && (
          <div className="absolute left-0 top-0 z-[-1] h-full w-full rounded-xl bg-white/10 backdrop-blur-xl" />
        )}
      </button>
    );
  }
);
