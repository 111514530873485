import React from 'react';

import { Modal } from '/design-systems/Atoms/Modal';
import { Divider } from '/design-systems/Atoms/Divider';
import { ComposerOptions } from '/design-systems/Organisms/CreateHomeView/ComposerOptions';
import { ModalTitleRow } from '/design-systems/Molecules/Modals/ModalTitleRow';

export const ComposeModal = ({ open, onClose }) => {
  return (
    <Modal
      id="compose-modal"
      maxWidth={1168}
      open={open}
      onClose={onClose}
      autoHeight
    >
      <div className="flex flex-col gap-4">
        <ModalTitleRow title="Start creating" onClose={onClose} />
        <Divider size="none" />
        <ComposerOptions title="" prelaunch={true} />
      </div>
    </Modal>
  );
};
