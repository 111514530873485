import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
  useMemo
} from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useModalManager, MODALS } from '../ModalManager';

import JOYNWebSocket from '/services/websocket';

import { ROUTES } from '/config/routes';
import { POP_CLAIM_TX_STATUSES } from '/utils/constants';
import { objectBlank } from '/utils/object';
import { toShort18 } from '/utils/string';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';
import { fromBasisPointToPercentage } from '/utils/number';

export const SOCKET_SLOTS = {
  USER_POP_MINTED: 'user-pop-minted',
  USER_ASSET_MINTED: 'user-asset-minted',
  USER_ASSET_LISTED: 'user-asset-listed',
  USER_ASSET_LISTING_UPDATED: 'user-asset-listing-updated',
  USER_ASSET_LISTING_CANCELLED: 'user-asset-listing-cancelled',
  USER_ASSET_BOUGHT: 'user-asset-bought',
  USER_GALLERY_UPDATED: 'user-gallery-updated',
  USER_NFT_DETAILS: 'user-nft-details'
};

export const SocketManagerContext = createContext({});

export const useSocketManager = () => useContext(SocketManagerContext);

export const SocketManager = ({ children }) => {
  const router = useRouter();
  const currentUser = useSelector((state) => state.user.current);
  const { showModal, closeModal } = useModalManager();
  const [joynSocket, setJoynSocket] = useState(null);

  const currentUserWalletAddress = useMemo(() => {
    return retrieveMainWalletAddressFromWalletAddresses(
      currentUser?.WalletAddresses || []
    ).address;
  }, [currentUser]);

  const handleRedirectToProfile = useCallback(
    (assetId) => () => {
      router.push(
        ROUTES.profile.view({
          profileId: currentUser?.username || currentUserWalletAddress,
          assetId
        })
      );
      closeModal();
    },
    [router, currentUserWalletAddress, closeModal]
  );

  const handleRedirectToList = useCallback(
    (assetId) => () => {
      router.push({
        pathname: ROUTES.asset.list(assetId)
      });
      closeModal();
    },
    [router]
  );

  const handleConnectSocket = useCallback(() => {
    console.info('Joyn Websocket is connected');
  }, []);

  /** POP Minted Event */
  const handleUserPOPMinted = useCallback(
    (popClaim) => {
      console.info('user-pop-minted message is arrived');
      const { Files: files, Prompt: prompt } = popClaim?.PromptReward || {};

      showModal({
        modalId: MODALS.POP_CLAIM_MODAL,
        props: {
          title: 'Contest POP is claimed',
          status: POP_CLAIM_TX_STATUSES.confirmed,
          file: { url: files?.[0]?.fileUrl },
          promptTitle: `${prompt?.title}`,
          onClose: closeModal
        }
      });
    },
    [showModal, closeModal]
  );

  /** Asset Minted Event */
  const handleAssetMinted = (asset) => {
    console.info('asset-minted message is arrived', asset);
    const { id: assetId, Files: files } = asset || {};

    if (window?.location?.pathname !== ROUTES.asset.mint(assetId)) {
      showModal({
        modalId: MODALS.ASSET_MINT_SUCCESS_MODAL,
        props: {
          files,
          onClose: closeModal,
          onActionNFT: handleRedirectToList(assetId),
          onViewNFT: handleRedirectToProfile(assetId)
        }
      });
    }
  };

  /** Asset Listed Event */
  const handleAssetListed = (listing) => {
    console.info('asset-listed message is arrived', listing);
    const { Token: token, price, marketplaceFee: fee } = listing || {};
    const files = token?.AssetMint?.Asset?.Files || [];
    const assetId = token?.AssetMint?.Asset?.id;

    if (window?.location?.pathname !== ROUTES.asset.list(assetId)) {
      showModal({
        modalId: MODALS.ASSET_LIST_SUCCESS_MODAL,
        props: {
          files,
          price: toShort18(price),
          fee: fromBasisPointToPercentage(Number(fee)),
          onClose: closeModal,
          onViewNFT: handleRedirectToProfile(assetId)
        }
      });
    }
  };

  /** Asset Listing Updated event */
  const handleAssetListingUpdated = (listing) => {
    console.info('asset-listing-updated message is arrived', listing);
    const { Token: token, price, marketplaceFee: fee } = listing || {};
    const files = token?.AssetMint?.Asset?.Files || [];
    const assetId = token?.AssetMint?.Asset?.id;

    if (window?.location?.pathname !== ROUTES.asset.updateListing(assetId)) {
      showModal({
        modalId: MODALS.ASSET_LISTING_UPDATE_SUCCESS_MODAL,
        props: {
          files,
          price: toShort18(price),
          fee: fromBasisPointToPercentage(Number(fee)),
          onClose: closeModal,
          onViewNFT: handleRedirectToProfile(assetId)
        }
      });
    }
  };

  /** Asset Listing Cancelled Event */
  const handleAssetListingCancelled = ({ files, assetId }) => {
    console.info('asset-listing-cancelled message is arrived', assetId);

    const queryString = window?.location?.search ?? '';
    if (
      window?.location?.pathname !== ROUTES.asset.updateListing(assetId) &&
      !(
        queryString.includes('submissionId=') ||
        queryString.includes('assetId=')
      )
    ) {
      showModal({
        modalId: MODALS.ASSET_LISTING_CANCEL_SUCCESS_MODAL,
        props: {
          files,
          onClose: closeModal,
          onViewNFT: handleRedirectToProfile(assetId)
        }
      });
    }
  };

  /** Asset Bought Event */
  const handleAssetBought = ({ assetFiles, assetId }) => {
    console.info('asset-bought message has arrived', assetId);

    const queryString = window?.location?.search ?? '';
    if (
      !(
        queryString.includes('submissionId=') ||
        queryString.includes('assetId=')
      )
    ) {
      showModal({
        modalId: MODALS.ASSET_BOUGHT_SUCCESS_MODAL,
        props: {
          files: assetFiles,
          onClose: closeModal,
          onViewNFT: handleRedirectToProfile(assetId)
        }
      });
    }
  };

  useEffect(() => {
    if (objectBlank(currentUser)) return;

    const joynWebSocket = new JOYNWebSocket();
    setJoynSocket(joynWebSocket);

    joynWebSocket.socket.on('connect', handleConnectSocket);

    joynWebSocket.socket.on(SOCKET_SLOTS.USER_POP_MINTED, handleUserPOPMinted);

    joynWebSocket.socket.on(SOCKET_SLOTS.USER_ASSET_MINTED, handleAssetMinted);

    joynWebSocket.socket.on(SOCKET_SLOTS.USER_ASSET_LISTED, handleAssetListed);

    joynWebSocket.socket.on(
      SOCKET_SLOTS.USER_ASSET_LISTING_UPDATED,
      handleAssetListingUpdated
    );

    joynWebSocket.socket.on(
      SOCKET_SLOTS.USER_ASSET_LISTING_CANCELLED,
      handleAssetListingCancelled
    );

    joynWebSocket.socket.on(SOCKET_SLOTS.USER_ASSET_BOUGHT, handleAssetBought);

    return () => {
      joynWebSocket.socket?.close();
    };
  }, [currentUser]);

  return (
    <SocketManagerContext.Provider value={{ joynSocket }}>
      {children}
    </SocketManagerContext.Provider>
  );
};
