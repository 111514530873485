import React from 'react';
import { useAccount, useNetwork } from 'wagmi';
import { CHAIN_NAMES, DEFAULT_CHAIN_ID } from '/utils/web3';
import { Alert } from '/design-systems/Atoms/Alert';

export const WalletConnectionErrorMessages = ({ isConnectedCorrectWallet, hasWallets, hasMultipleWallets }) => {
  const { isConnected, address } = useAccount();
  const { chain } = useNetwork();

  if (!isConnected || !address) {
    return (
      <Alert color='warning' className='mt-4'>
        {hasWallets ? 'Please unlock your wallet.' : 'Please connect your wallet.'}
      </Alert>
    );
  }

  if (chain?.unsupported) {
    return (
      <Alert color='warning' className='mt-4'>
        Please change your network to {CHAIN_NAMES[DEFAULT_CHAIN_ID]}.
      </Alert>
    );
  }

  if (!isConnectedCorrectWallet) {
    return (
      <Alert color='warning' className='mt-4'>
        {hasMultipleWallets
          ? 'Please connect with the wallet account that matches the address selected above.'
          : 'Please connect with the correct wallet account.'}
      </Alert>
    );
  }

  return <></>;
};
