import { useMemo } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getV2ActiveListings,
  getV2CheapestActiveListing,
  getV2ListingOpenCuratorFeeConsiderations as fetchV2ListingOpenCuratorFeeConsiderations
} from '/services/joynApi/users/v2-listing.js';
import { QUERY_KEYS } from '/utils/queries';

export const useV2Listing = ({ tokenId, galleryId }) => {
  const {
    isLoading: isLoadingActiveListings,
    data: activeListings,
    refetch: refetchActiveListings,
  } = useQuery([QUERY_KEYS.V2_LISTING.GET_ACTIVE_LISTINGS, tokenId, galleryId], () => getV2ActiveListings({ tokenId, galleryId }), {
    enabled: !!tokenId,
    select: (res) => res.data.data,
  });

  const { mutateAsync: getV2ListingOpenCuratorFeeConsiderations } = useMutation(
    ({ orderId, galleryId }) =>
      fetchV2ListingOpenCuratorFeeConsiderations({
        orderId,
        galleryId
      })
  );

  const {
    isLoading: isLoadingCheapestActiveListing,
    data: cheapestActiveListing,
    refetch: refetchCheapestActiveListing,
  } = useQuery(
    [QUERY_KEYS.V2_LISTING.GET_CHEAPEST_ACTIVE_LISTING, tokenId, galleryId],
    () => getV2CheapestActiveListing({ tokenId, galleryId }),
    {
      enabled: !!tokenId,
      select: (res) => res.data.data,
    }
  );

  return useMemo(
    () => ({
      activeListings,
      isLoadingActiveListings,
      refetchActiveListings,
      isLoadingCheapestActiveListing,
      cheapestActiveListing,
      refetchCheapestActiveListing,
      getV2ListingOpenCuratorFeeConsiderations
    }),
    [
      activeListings,
      isLoadingActiveListings,
      refetchActiveListings,
      isLoadingCheapestActiveListing,
      cheapestActiveListing,
      refetchCheapestActiveListing,
      getV2ListingOpenCuratorFeeConsiderations
    ]
  );
};
