import React, { useCallback } from 'react';
import NextLink from 'next/link';

export const HyperlinkWrapper = ({
  id = '',
  url = '',
  disabled = false,
  children,
  className = '',
  newTab = false
}) => {
  const linkProps = newTab
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};

  const handleClick = useCallback(
    (event) => {
      if (disabled) {
        event.preventDefault();
        return;
      }
    },
    [disabled]
  );

  return (
    <NextLink href={url}>
      <a id={id} className={className} onClick={handleClick} {...linkProps}>
        {children}
      </a>
    </NextLink>
  );
};
