import { useMutation, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '/utils/queries';
import { useMemo } from 'react';
import {
  createAuction as createAuctionService,
  getAuction,
  cancelAuction as cancelAuctionService,
  updateAuction as updateAuctionService
} from '/services/joynApi/users/auction';
import { getWrappedNativeTokenContractAddress } from '/utils/contracts';

export const useAuction = ({ tokenId = '', chainId }) => {
  const {
    isLoading: isLoadingAuctionDetail,
    data: auctionDetail,
    refetch: refetchAuctionDetail
  } = useQuery(
    [QUERY_KEYS.ASSET.GET_AUCTION_DETAIL, tokenId],
    () => getAuction({ tokenId }),
    {
      enabled: !!tokenId,
      select: (res) => res.data.data
    }
  );

  const { mutateAsync: createAuction } = useMutation(
    async ({
      tokenId,
      walletAddress,
      price,
      amount,
      startTime,
      endTime,
      galleryId,
      galleryCollabRequestId,
      curatorFee,
      marketplaceFee,
      duration,
      auctionType
    }) => {
      const res = await createAuctionService({
        tokenId,
        walletAddress,
        price,
        amount,
        currencyContractAddress: getWrappedNativeTokenContractAddress({
          chainId
        }),
        startTime,
        endTime,
        galleryId,
        galleryCollabRequestId,
        curatorFee,
        marketplaceFee,
        duration,
        auctionType
      });
      return res.data.data;
    }
  );

  const { mutateAsync: cancelAuction } = useMutation(async ({ auctionId }) => {
    const res = await cancelAuctionService({ auctionId });
    return res.data.data;
  });

  const { mutateAsync: updateAuction } = useMutation(
    async ({
      auctionId,
      reservePrice,
      startDate,
      endDate,
      duration,
      auctionType
    }) => {
      const res = await updateAuctionService({
        auctionId,
        reservePrice,
        startDate,
        endDate,
        duration,
        auctionType
      });
      return res.data.data;
    }
  );

  return useMemo(
    () => ({
      isLoadingAuctionDetail,
      auctionDetail,
      refetchAuctionDetail,
      createAuction,
      cancelAuction,
      updateAuction
    }),
    [
      isLoadingAuctionDetail,
      auctionDetail,
      refetchAuctionDetail,
      createAuction,
      cancelAuction,
      updateAuction
    ]
  );
};
