import DocumentBackSVG from '/images/document-back.svg';
import MusicBackSVG from '/images/music-back.svg';
import VideoBackSVG from '/images/video-back.svg';
import OtherBackSVG from '/images/other-back.svg';

import { getAvatar } from '/utils/image';
import { toShortAddress } from './string';
import { retrieveMainWalletAddressFromWalletAddresses } from '.';
import { emptyArray } from './object';
import { getFirstMediaFile, getFileType } from './file';

import { CO_CREATE_PROMPTABLE_TYPES, META_ENTITIES } from '/utils/constants';
import { ROUTES } from '/config/routes';

export const SUBMISSION_ORDER_BY_OPTIONS = [
  { title: 'Newest', value: 'desc' },
  { title: 'Oldest', value: 'asc' }
];

export const getSubmissionFileType = (files) => {
  if (!files || !files.length) return 'other';
  return files?.[0]?.fileType?.split('/')?.[0];
};

export const getImageProps = (files) => {
  const file = getFirstMediaFile(files);
  const type = getFileType(file);
  const fileUrl = file?.fileUrl;
  const fileType = file?.fileType;
  switch (type) {
    case 'image':
      return {
        resolution: 'm',
        displayAsVideo: false,
        fileType: file?.fileType,
        src: fileUrl || OtherBackSVG.src,
        staticFile: file?.isStatic || file?.staticFile || !fileUrl,
        alt: 'image-back'
      };
    case 'video':
      return {
        displayAsVideo: true,
        fileType: file?.fileType,
        src: fileUrl || VideoBackSVG.src,
        staticFile: file?.isStatic || file?.staticFile || !fileUrl,
        alt: 'video-back'
      };
    case 'document':
      return {
        staticFile: true,
        src: DocumentBackSVG.src,
        alt: 'document-back'
      };
    case 'music':
      return {
        staticFile: true,
        src: MusicBackSVG.src,
        alt: 'music-back'
      };
    case 'application':
      if (fileUrl.includes('.m3u8')) {
        return {
          displayAsVideo: true,
          fileType: 'application/x-mpegURL',
          src: fileUrl.split('?')[0],
          alt: 'video-back'
        };
      }
    default:
      return {
        displayAsVideo: false,
        src: fileUrl || OtherBackSVG.src,
        fileType,
        staticFile: files?.[0]?.isStatic || files?.[0]?.staticFile || !fileUrl,
        alt: 'image-back'
      };
  }
};

export const getContributorId = (submission) => submission?.User?.id;

export const getContributorName = (submission) =>
  submission?.User?.username ||
  toShortAddress(
    retrieveMainWalletAddressFromWalletAddresses(
      submission?.User?.WalletAddresses
    )?.address
  ) ||
  submission?.PromptTweetReply?.twitterUserHandle;

export const getContributorImage = (submission) =>
  getAvatar(submission?.User || {}) ||
  submission?.PromptTweetReply?.twitterProfileImageUrl ||
  '';

export const isTextAsset = (submission) => {
  return !submission?.Files?.length;
};

export const isContributorImageStatic = (submission) =>
  (!getAvatar(submission?.User || {}) &&
    !!submission?.PromptTweetReply?.twitterProfileImageUrl) ||
  submission?.User?.Files?.[0]?.staticFile;

export const getPromptSubmissionCreateOrUpdatePayload = ({
  title,
  description,
  link,
  usageRightId,
  contributeFiles,
  assetId,
  promptAnswers
}) => {
  const payload = {
    title,
    description,
    link,
    usageRightId,
    answers: promptAnswers.map(
      ({ id, promptSubmissionId, PromptQuestion, ...answerPayload }) =>
        answerPayload
    )
  };
  if (assetId) {
    payload.assetId = assetId;
  } else {
    payload.files = contributeFiles
      .filter((file) => !file.uploaded && !file.error)
      .map((file) => ({
        url: file.url,
        fileName: file.fileName,
        type: file.type,
        size: file.size
      }));
  }

  return payload;
};

export const formatSubmissionForExport = (
  submission,
  promptableType,
  submissionsType
) => {
  if (!submission) return {};

  const userInfo = {
    walletAddress: retrieveMainWalletAddressFromWalletAddresses(
      submission.User?.WalletAddresses
    )?.address,
    twitter: submission.PromptTweetReply
      ? submission.PromptTweetReply.twitterUserHandle
      : submission.User?.twitter
  };
  if (promptableType === CO_CREATE_PROMPTABLE_TYPES.GIVEAWAY) {
    return userInfo;
  }

  const isShortlisted = !emptyArray(submission.ShortlistedBy);

  const answers = {};
  submission?.PromptAnswers?.map((answer) => {
    answers[answer.PromptQuestion.body] = answer.body;
  });

  if (submissionsType?.toLowerCase() === META_ENTITIES.GALLERY) {
    return {
      submissionLink: `${window.location.origin}/${ROUTES[
        submission?.Gallery?.galleryType
      ].view(submission?.Gallery?.id)}`,
      title: submission.title,
      description: submission.description,
      shortlisted: isShortlisted,
      ...userInfo
    };
  }

  return {
    submissionLink: `${window.location.origin}/${window.location.pathname}?submissionId=${submission.id}`,
    ...userInfo,
    title: submission.title,
    description: submission.description,
    fileUrl: emptyArray(submission.Files)
      ? ''
      : `${process.env.CLOUDFRONT_URL}/${submission?.Files?.[0]?.fileUrl}`,
    link: submission.link,
    shortlisted: isShortlisted,
    ...answers
  };
};
