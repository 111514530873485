import React, { useMemo } from 'react';

import { useRouter } from 'next/router';
import Link from 'next/link';

import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Divider } from '/design-systems/Atoms/Divider';
import { FAQSection } from '/design-systems/Molecules/FAQSection';
import { ModalTitleRow } from '/design-systems/Molecules/Modals/ModalTitleRow';
import { HyperlinkWrapper } from '/design-systems/Organisms/HyperlinkWrapper';

export const ArtPlusIntroModal = ({ open, onClose }) => {
  const faqSections = useMemo(() => {
    return [
      {
        title: 'How does it work?',
        content: (
          <>
            <Typography>1. Choose or mint your artwork.</Typography>

            <Typography>
              2. Write about the work, add images and video.
            </Typography>

            <Typography>3. Update the URL extension.</Typography>

            <Typography>4. Choose light or dark mode.</Typography>

            <Typography>5. Preview the Art+ page.</Typography>

            <Typography>6. List your artwork.</Typography>

            <Typography>7. Share the link with collectors.</Typography>
          </>
        )
      },
      {
        title: 'What kind of tokens can I sell?',
        content: (
          <>
            <Typography>
              ERC-721’s and ERC-115’s. Artists can sell work minted on their own
              Manifold, Transient, Superrare or FND contracts.
            </Typography>
            <Typography>
              Alternatively, artists can mint their work on Joyn’s shared
              contract and avoid paying gas.
            </Typography>
          </>
        )
      },
      {
        title: 'Show me some examples.',
        content: (
          <>
            <Typography>
              Check out how other artists used the Art+ template:
            </Typography>

            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-1">
                <Typography weight="medium">Nevin Johnson:</Typography>

                <Typography>
                  <HyperlinkWrapper
                    url="https://www.joyn.xyz/asset/still-chasing-violet-delights"
                    className="link-underlined"
                    newTab
                  >
                    ‘Still Chasing Violet Delights’
                  </HyperlinkWrapper>
                </Typography>
              </div>

              <div className="flex items-center gap-1">
                <Typography weight="medium">Tiffatron:</Typography>

                <Typography>
                  <HyperlinkWrapper
                    url="https://www.joyn.xyz/asset/addogc"
                    className="link-underlined"
                    newTab
                  >
                    ‘A Distorted Dream Of Great Consequence’
                  </HyperlinkWrapper>
                </Typography>
              </div>

              <div className="flex items-center gap-1">
                <Typography weight="medium">cadmonkey:</Typography>

                <Typography>
                  <HyperlinkWrapper
                    url="https://www.joyn.xyz/asset/228159"
                    className="link-underlined"
                    newTab
                  >
                    ‘TREE_01: BAOBAB’
                  </HyperlinkWrapper>
                </Typography>
              </div>
            </div>
          </>
        )
      }
    ];
  }, []);

  return (
    <Modal
      id="art-plus-intro-modal"
      className="relative"
      wrapperClassName="pt-10"
      autoHeight={true}
      open={open}
      onClose={onClose}
      innerContainerClassName="!p-0"
    >
      {/* title row */}
      <div className="px-6 py-4">
        <ModalTitleRow onClose={onClose} title="Introducing Art+" />
      </div>

      <Divider size="smallest" />

      {/* body */}
      <div className="flex flex-col gap-6 p-6 ">
        <div className="leading-7">
          Art+ is a template for artists to sell 1/1 or limited edition
          artworks. It provides the space to add extra details such as the story
          or process behind the piece.
        </div>

        <div className="leading-7">
          Artists have the option to include text, images, or videos to provide
          collectors with more insight.
        </div>

        <div className="flex w-full justify-center">
          <iframe
            src="https://www.youtube.com/embed/lMiNVi6qK6c?si=PKZplDV3LGLWW486"
            width="600"
            height="400"
            frameborder="0"
            allowfullscreen=""
            title="My iframe"
          ></iframe>
        </div>
      </div>

      <Divider size="smallest" />

      {/* FAQ */}
      <div className="flex flex-col gap-6 p-6 ">
        <FAQSection sections={faqSections} />
      </div>
    </Modal>
  );
};
