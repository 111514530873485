import axios from '/services/axios';
import { generateQueryParams } from '/utils/url';

const createOffer = (payload) => axios.post(`/offer`, payload);

const signOffer = ({ offerId, order }) =>
  axios.put(`/offer/${offerId}/sign`, { order });

const getOffers = ({ tokenId, auctionId }) => {
  let queryParams = ``;
  if (auctionId) queryParams = `?auctionId=${auctionId}`;
  return axios.get(`/offers/get/${tokenId}${queryParams}`);
};

const getHighestOffer = ({ tokenId, auctionId }) => {
  let queryParams = ``;
  if (auctionId) queryParams = `?auctionId=${auctionId}`;
  return axios.get(`/offer/get/${tokenId}/highest${queryParams}`);
};

const getReceivedOffersByUser = ({ userId, page, size }) => {
  return axios.get(
    `/offers/received/${userId}?${generateQueryParams({ page, size })}`
  );
};

const getOffersMadeByUser = ({ page, size }) => {
  return axios.get(`/offers/made?${generateQueryParams({ page, size })}`);
};

export {
  createOffer,
  signOffer,
  getOffers,
  getHighestOffer,
  getReceivedOffersByUser,
  getOffersMadeByUser
};
