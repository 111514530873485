import React from 'react';
import pluralize from 'pluralize';
import { abbreviateNumber } from '/utils/number';
import { Typography } from '/design-systems/Atoms/Typography';

export const ProductEditionInfo = ({ amount }) => {
  return (
    <div className="flex items-center">
      <Typography
        variant="small"
        weight="medium"
        className="rounded-full rounded-[50px] bg-neutral-50 py-2 px-3 dark:bg-neutral-800"
      >
        Edition
      </Typography>
      <Typography
        variant="small"
        weight="medium"
        className="ml-6 text-[#6B7280]"
      >
        {amount < 1000
          ? pluralize('item', amount, true)
          : `${abbreviateNumber(amount)} items`}
      </Typography>
    </div>
  );
};
