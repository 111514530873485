import axios from '/services/axios';
import { generateQueryParams } from '/utils/url';

const getGalleryCollabRequests = ({
  assetId = null,
  status,
  toUserId,
  fromUserId,
  page,
  size,
  withPagination = false
} = {}) => {
  const query = generateQueryParams({
    page,
    size,
    assetId,
    status,
    toUserId,
    fromUserId,
    withPagination
  });
  return axios.get(`/gallery-collab-requests?${query}`);
};

const rejectGalleryCollabRequests = async ({ galleryCollabRequestId }) => {
  return axios.put(`/gallery-collab-request/${galleryCollabRequestId}/reject`);
};

const createGalleryCollabRequest = async ({
  galleryId,
  fee,
  assetId,
  walletAddress,
  userId,
  open,
  tokenOwnerIds
}) => {
  return axios.post(`/gallery-collab-request`, {
    galleryId,
    fee,
    assetId,
    walletAddress,
    userId,
    open,
    tokenOwnerIds
  });
};

const deleteGalleryCollabRequest = async ({
  galleryCollabRequestId,
  galleryId
}) => {
  return axios.delete(
    `/gallery-collab-request/${galleryCollabRequestId}?galleryId=${galleryId}`
  );
};

export {
  deleteGalleryCollabRequest,
  getGalleryCollabRequests,
  rejectGalleryCollabRequests,
  createGalleryCollabRequest
};
