/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useFeatureFlags } from '/hooks/useFeatureFlags';
import { toast } from 'react-toastify';
import { useSigner } from '/utils/ethers';
import { hasHtmlFile } from '/utils/file';
import { useAssetTransactions } from '/hooks/asset/useAssetTransactions';
import { useAssetListings } from '/hooks/asset/useAssetListings';

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DownloadIcon,
  CheckIcon,
  EyeOffIcon,
  EyeIcon
} from '@heroicons/react/outline';

import { AssetModalPlaceholder } from '/design-systems/Molecules/Placeholders/AssetModalPlaceholder';
import { AssetPrice } from '/design-systems/Molecules/Assets/AssetPrice';
import { DeleteModal } from '/design-systems/Molecules/Modals/DeleteModal';
import { ImportNftCard } from '/design-systems/Molecules/Cards/ImportNftCard';
import { HtmlDisplay } from '/design-systems/Molecules/HtmlDisplay';
import { MarketplaceCard } from '/design-systems/Molecules/Cards/MarketplaceCard';
import { BlobFileDownload } from '/design-systems/Molecules/Files/BlobFileDownload';
import { ImageCarousel } from '/design-systems/Molecules/ImageCarousel';
import { ProductContent } from '/design-systems/Molecules/Product/ProductContent';
import { AssetDetailSections } from '/design-systems/Organisms/AssetDetailSections';

import { useAsset } from '/hooks/useAsset';
import { useTokenActivity } from '/hooks/useTokenActivity';
import { useAssetOffers } from '/hooks/asset/useAssetOffers';
import { useAssetAuction } from '/hooks/asset/useAssetAuction';
import {
  usePromptSubmission,
  usePromptSubmissionMutations
} from '/hooks/usePromptSubmission';
import { useShallowState } from '/hooks/shallowState';
import { useModalManager } from '/context/ModalManager';
import { useFullScreenManager } from '/context/FullScreenManager';
import { emptyArray } from '/utils/object';
import { isTextAsset } from '/utils/prompt-submission';
import { isSubmissionShortlistedForCurrentUser } from '/utils/prompt';
import { getAssetDetailPageLink } from '/utils/asset';
import { ROUTES } from '/config/routes';
import { useWalletAddress } from '/hooks/useWalletAddress';
import { getIPFSLinkFromHash } from '/utils/string';
import { BLOCKCHAINS, LAUNCH_DARKLY_KINDS } from '/utils/constants';
import { stripHtml } from 'string-strip-html';
import { STEPS } from 'utils/marketplace';
import { deleteAsset } from '/services/joynApi/users/assets';
import { FloatingTooltip } from '/design-systems/Atoms/FloatingTooltip';
import { Button } from '/design-systems/Atoms/Button';
import { Card } from '/design-systems/Atoms/Card';
import { Divider } from '/design-systems/Atoms/Divider';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { removeQueryParam } from '/utils/url';
import { getChainIdFromChainName } from '/utils/web3';
import { MakeOfferModal } from '/design-systems/Molecules/Modals/MakeOfferModal';
import { AcceptOfferModal } from '/design-systems/Molecules/Modals/AcceptOfferModal';
import { OfferNoteModal } from '/design-systems/Molecules/Modals/OfferNoteModal';
import { TransferAssetModal } from '/design-systems/Molecules/Modals/TransferAssetModal';
import { OwnerListModal } from '/design-systems/Molecules/Modals/OwnerListModal';
import { useAssetToken } from '/hooks/asset/useAssetToken';
import {
  ENTITY_NAMES,
  SaveEntityButton
} from '/design-systems/Molecules/Buttons/SaveEntityButton';
import { TRACKED_EVENTS, useAnalytics } from '/hooks/useAnalytics';
import { AuctionAgreementForBuyerModal } from '../AuctionAgreementForBuyerModal';

const minSwipeDistance = 50;

const arrowButtonClassName =
  'mx-4 h-8 cursor-pointer rounded-full bg-white/60 hover:bg-white/70 p-1 text-neutral-900 dark:text-neutral-100';

export const PreviewModal = ({
  allowMinting,
  isSubmission,
  isAsset,
  isStandAloneNFT,
  isPreview,
  contestId,
  promptId,
  onClose,
  onDelete,
  onShortlist,
  onPageChange,
  withPagination,
  // withLike,
  // withSave,
  queryParam,
  galleryId
}) => {
  const { isAssetGalleryEnabled } = useFeatureFlags();
  const ldClient = useLDClient();

  const router = useRouter();
  const currentUser = useSelector((state) => state.user.current);
  const signer = useSigner();
  const prevRef = useRef();
  const nextRef = useRef();
  const [loadingStates, setLoadingStates] = useShallowState({
    shortlist: false,
    hide: false
  });
  const { closeModal } = useModalManager();
  const { onShowFullScreen } = useFullScreenManager();
  const { onSetSubmission, ...submissionData } = usePromptSubmission(
    isSubmission ? queryParam : false,
    {
      withAssetGallery: true
    }
  );
  const assetData = useAsset(isAsset ? queryParam : false, {
    withGallery: true
  });

  const assetChainId = useMemo(
    () =>
      getChainIdFromChainName(
        isAsset
          ? assetData?.assetToken?.chain
          : isSubmission
          ? submissionData.submission?.Asset?.AssetMint?.Token?.chain
          : BLOCKCHAINS.ETHEREUM
      ),
    [isAsset, assetData, isSubmission, submissionData]
  );

  const [deleteModal, setDeleteModal] = useState(false);
  const [showOwnerListModal, setShowOwnerListModal] = useState(false);

  useEffect(() => {
    if (ldClient && isAsset && assetData?.asset?.id) {
      ldClient.identify({
        kind: LAUNCH_DARKLY_KINDS.asset,
        key: assetData?.asset?.id
      });
    }
  }, [ldClient, isAsset, assetData?.asset?.id]);

  useEffect(() => {
    if (ldClient && isSubmission && submissionData?.submission?.id) {
      ldClient.identify({
        kind: LAUNCH_DARKLY_KINDS.submission,
        key: submissionData?.submission?.id
      });
    }
  }, [ldClient, isSubmission, submissionData?.submission?.id]);

  const {
    creator: creatorProfile,
    assetMint,
    assetToken,
    asset
  } = useMemo(() => {
    if (isSubmission) {
      return {
        creator: submissionData.submissionCreator,
        assetMint: submissionData.submission?.Asset?.AssetMint,
        assetToken: submissionData.submission?.Asset?.AssetMint?.Token,
        asset: submissionData.submission?.Asset
      };
    }

    if (isAsset) {
      return {
        creator: assetData.assetCreator,
        assetMint: assetData.assetMint,
        assetToken: assetData.assetToken,
        asset: assetData.asset
      };
    }

    return {};
  }, [isSubmission, isAsset, submissionData, assetData]);

  const { walletAddressOwner: creatorAddressUser } =
    useWalletAddress(creatorAddress);

  const creator = useMemo(() => {
    return isStandAloneNFT ? creatorAddressUser : creatorProfile;
  }, [creatorAddressUser, creatorProfile, isStandAloneNFT]);

  const isCreator = useMemo(
    () => creator?.id === currentUser?.id,
    [currentUser, creator]
  );

  const {
    token,
    isLoadingToken,
    assetTokenId,
    assetTokenAddress,
    blockchain,
    tokenOwners,
    tokenIdForListing,
    tokenOwner,
    tokenOwnedByCurrentUser,
    refetchTokenOwner,
    isAssetOwner,
    isAssetMinted,
    isOneOfMany,
    isAvailableForMint
  } = useAssetToken({
    currentUser,
    assetData,
    asset,
    assetMint,
    assetToken,
    isAsset,
    isSubmission,
    isCreator,
    submissionData,
    queryParam
  });

  const {
    isLoading,
    files,
    obj,
    title,
    description,
    link,
    detailPageLink,
    creatorAddress,
    submission,
    assetCampaigns,
    usageRight,
    tokenURI,
    totalTokenOwners,
    totalAmount,
    canManage
  } = useMemo(() => {
    if (isSubmission) {
      return {
        isLoading: submissionData.isLoadingSubmission,
        submission: submissionData.submission,
        files: submissionData.submissionFiles ?? [],
        obj: submissionData.submission,
        title: submissionData.submission?.title,
        description: submissionData.submission?.description,
        link: submissionData.submission?.link,

        assetCampaigns: [],
        usageRight: submissionData.submission?.UsageRight,
        tokenURI:
          submissionData.submission?.Asset?.ipfsHash &&
          getIPFSLinkFromHash(submissionData.submission?.Asset?.ipfsHash),
        totalTokenOwners: submissionData.submission?.totalOwners,
        totalAmount: submissionData.submission?.totalAmount,
        detailPageLink: getAssetDetailPageLink({
          assetId: submissionData.submission?.Asset?.id,
          customUrl: submissionData.submission?.Asset?.Gallery?.customUrl
        }),
        canManage: submissionData.submission?.permissions?.canModerate
      };
    }

    if (isAsset) {
      return {
        isLoading: assetData.isLoadingAsset,
        files: assetData.assetFiles,
        obj: assetData.asset,
        title: assetData.asset?.title,
        description: assetData.asset?.description,
        link: assetData.asset?.link,

        usageRight: assetData.assetUsageRight,
        assetCampaigns: assetData.assetCampaigns,
        tokenURI:
          assetData.asset?.ipfsHash &&
          getIPFSLinkFromHash(assetData.asset?.ipfsHash),
        totalTokenOwners: assetData.asset?.totalTokenOwners,
        totalAmount: assetData.asset?.totalAmount,
        detailPageLink: getAssetDetailPageLink({
          assetId: assetData?.asset?.id,
          customUrl: assetData?.asset?.Gallery?.customUrl
        }),
        canManage: assetData.asset?.permissions?.canManage
      };
    }

    if (isStandAloneNFT) {
      const mediaFiles = [
        {
          fileUrl: token?.media.url,
          fileType: token?.media.format ? token?.media.format : 'jpeg',
          staticFile: true
        }
      ];

      return {
        files: mediaFiles,
        isLoading: isLoadingToken,
        title: token?.title,
        description: token?.description,
        creatorAddress: token?.contract?.contractDeployer,
        obj: {
          Files: mediaFiles,
          title: token?.title,
          description: token?.description
        },
        tokenURI:
          token?.tokenUri?.raw && getIPFSLinkFromHash(token?.tokenUri?.raw),
        totalTokenOwners: token?.totalTokenOwners,
        canManage: false
      };
    }

    return {};
  }, [
    isSubmission,
    isAsset,
    isStandAloneNFT,
    submissionData,
    assetData,
    token,
    isLoadingToken
  ]);

  /**
   * Get NFT creator info
   * This value will be valid only for StandAloneNFT
   */

  const handleDeletePiece = useCallback(async () => {
    const assetId = asset?.id;
    if (!assetId) return;

    deleteAsset({ assetId })
      .then(() => {
        toast.success('This piece has been deleted successfully');
        closeModal?.();

        router.push(removeQueryParam(router.asPath, ['assetId']), undefined, {
          shallow: true
        });
      })
      .catch((error) => console.error('Deletion Error:', error));
  }, [asset?.id]);

  const { onHidePromptSubmission } = usePromptSubmissionMutations();

  const [state, setState] = useShallowState({
    fileIndex: 0,
    touchStart: null,
    touchEnd: null,
    step: undefined,
    txHash: undefined,
    unitsToFill: 1,
    showSellTab: false,
    purchasePrice: ''
  });

  useEffect(() => {
    setState({ showSellTab: isOneOfMany && isAssetOwner });
  }, [isOneOfMany, isAssetOwner]);

  const shortlisted = useMemo(
    () =>
      isSubmissionShortlistedForCurrentUser(
        submission?.ShortlistedBy,
        currentUser
      ),
    [submission?.ShortlistedBy, currentUser]
  );

  const showImageCarousel = useMemo(
    () => !isTextAsset(obj) && !hasHtmlFile(files),
    [obj, files]
  );

  const {
    auctionDetail,
    refetchAuctionDetail,
    hasAuction,
    isAvailableForPlaceBid,
    isAuctionEnded,
    isAuctionStarted,
    auctionOfferDisabled
  } = useAssetAuction({
    assetChainId,
    tokenIdForListing,
    currentUser
  });

  const {
    showOfferExpanded,
    offers,
    highestOffer,
    refetchOffers,
    refetchHighestOffer,
    isAssetAvailableForOffer,
    isAssetAvailableToAcceptOffer,
    userHasOffers
  } = useAssetOffers({
    hasAuction,
    tokenIdForListing,
    currentUser,
    tokenOwner,
    assetMint,
    auctionDetail,
    assetToken
  });

  const { tokenActivities } = useTokenActivity({
    tokenId: assetTokenId,
    tokenAddress: assetTokenAddress,
    blockchain
  });

  const lastSoldActivity = useMemo(
    () => tokenActivities?.find((activity) => activity.type === 'sale'),
    [tokenActivities]
  );

  const handleChangeFileIndex = useCallback((fileIndex) => {
    setState({ fileIndex });
  }, []);

  const handleShowAssetOnFullScreen = useCallback(
    (fileIndex) => () => {
      onShowFullScreen?.(files?.[fileIndex]);
    },
    [files, onShowFullScreen]
  );

  const handlePageChange = (action) => async () => {
    if (!onPageChange) return;

    if (isSubmission) {
      !isLoading && (await onPageChange?.(submission, action));
    } else if (isAsset) {
      !isLoading && (await onPageChange?.(asset, action));
    }
  };

  const handleTokenSettings = useCallback(() => {
    closeModal?.();
    router.push(ROUTES.asset.tokenSettings(asset?.id));
  }, [asset?.id]);

  const handleEdit = useCallback(() => {
    if (isSubmission) {
      closeModal?.();
      router.push(ROUTES.promptSubmission.edit(contestId, submission?.id));
      return;
    }

    closeModal?.();

    const editRoute = isAssetGalleryEnabled
      ? ROUTES.asset.compose(asset?.id)
      : ROUTES.asset.edit(asset?.id);
    router.push(editRoute);
  }, [contestId, submission?.id, asset?.id, isSubmission, isAsset]);

  const handleDelete = useCallback(
    (e) => {
      if (!isSubmission) return;
      onDelete?.(e, submission?.id);
    },
    [onDelete, isSubmission, submission?.id]
  );

  const onTouchStart = (e) => {
    setState({
      touchStart: e.targetTouches[0].clientX,
      touchEnd: null
    });
  };

  const onTouchMove = (e) =>
    setState({
      touchEnd: e.targetTouches[0].clientX
    });

  const onTouchEnd = () => {
    if (!state.touchStart || !state.touchEnd) return;
    const distance = state.touchStart - state.touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      nextRef?.current?.click();
      return;
    }
    if (isRightSwipe) {
      prevRef?.current?.click();
      return;
    }
  };

  const handleShortlistSubmission = async (e, submissionId, isShortlisted) => {
    setLoadingStates({ shortlist: true });

    const response = await onShortlist(e, submissionId, isShortlisted);

    const isShortlistedToggle = response.length;

    if (isShortlistedToggle) {
      onSetSubmission((value) => ({
        ...value,
        ShortlistedBy: [...(value?.ShortlistedBy || []), currentUser]
      }));
    } else {
      onSetSubmission((value) => ({
        ...value,
        ShortlistedBy: (value?.ShortlistedBy || []).filter(
          (User) => Number(User.id) !== Number(currentUser.id)
        )
      }));
    }
    setLoadingStates({ shortlist: false });
  };

  const handleHideSubmission = (submissionId, hide) => async () => {
    setLoadingStates({ hide: true });

    try {
      await onHidePromptSubmission({
        promptId,
        submissionIds: [submissionId],
        hide
      });

      onSetSubmission({
        ...submission,
        hidden: hide
      });
    } catch (error) {
      console.error('Hide Submissions Error', error);
    }

    setLoadingStates({ hide: false });
  };

  const DownloadComponent = () => (
    <Button color="primary" variant="secondary" className="w-fit">
      <div className="flex flex-row items-center gap-x-2">
        <DownloadIcon className="h-4 w-4" />
        <div>Download</div>
      </div>
    </Button>
  );

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowLeft':
        prevRef?.current?.click();
        break;
      case 'ArrowRight':
        nextRef?.current?.click();
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, true);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const { submissionId, assetId, tokenId } = router.query ?? {};
    if (!submissionId && !assetId && !tokenId && open) {
      closeModal?.();
    }
  }, [closeModal, router]);

  const onUnitsToFillChange = (value) => {
    setState({ unitsToFill: value });
  };

  const onShowSellTabChange = (value) => {
    setState({ showSellTab: value });
  };

  const {
    activeListings,
    cheapestActiveListing,
    refetchActiveListings,
    isListed,
    isAssetAvailableForBuyNow,
    isAvailableForListing,
    listingPrice,
    amountAvailable,
    userHasListings,
    showRelistWithFee
  } = useAssetListings({
    galleryId,
    tokenIdForListing,
    tokenOwner,
    currentUser
  });

  const {
    handleBuyAsset,
    handleCancelListing,
    handleMakeOffer,
    handleCancelOffer,
    isCancelingOffer,
    handleAcceptOffer,
    handleOfferNote,
    handleTransfer,
    isBuyingAsset,
    showMakeOfferModal,
    refetchOfferData,
    setShowMakeOfferModal,
    offerToBeAccepted,
    setShowAcceptOfferModal,
    showAcceptOfferModal,
    showOfferNoteModal,
    setShowOfferNoteModal,
    showTransferModal,
    setShowTransferModal,
    showAuctionAgreementForBuyerModal,
    setShowAuctionAgreementForBuyerModal,
    handleAcceptAuctionTerm
  } = useAssetTransactions({
    currentUser,
    signer,
    asset,
    assetToken,
    assetTokenId,
    assetChainId,
    assetTokenAddress,
    cheapestActiveListing,
    listingPrice,
    refetchActiveListings,
    refetchTokenOwner,
    refetchOffers,
    highestOffer,
    refetchHighestOffer,
    auctionDetail,
    refetchAuctionDetail,
    auctionOfferDisabled,
    title,
    creator,
    creatorAddress,
    files,
    isOneOfMany,
    token,
    tokenOwner,
    tokenOwnedByCurrentUser,
    tokenIdForListing,
    isAvailableForPlaceBid,
    galleryId,
    setState
  });

  /**
   * The flag value to enable the marketplace flow - mint/list/buy
   *
   * `marketplace` is LaunchDarkly flag that will be enabled by LaunchDarkly app.
   *
   * `isStandAloneNFT` is the flag that current asset is standalone nft.
   * We are not supporting marketplace for standalonenft so if this is `false, then hide flow.
   *
   * `files` is asset files
   * if asset doesn't have any files, it won't be available to participate this flow
   *
   * if current logged-in user is asset creator but asset is not minted yet, it should show `mint` button
   * if asset is listed, it should show `buy` or `manage  = ` button
   * if current logged-in user is asset owner, it should show `list`/`manage  = ` button
   */
  const canShowSalesFlow = useMemo(
    () =>
      !isStandAloneNFT &&
      !isPreview &&
      !emptyArray(files) &&
      (isAssetOwner ||
        isAssetAvailableForBuyNow ||
        (isCreator && !isAssetMinted) ||
        isAssetAvailableForOffer) &&
      !(isSubmission && !allowMinting),
    [
      isStandAloneNFT,
      isPreview,
      files,
      assetToken?.tokenStandard,
      isAssetOwner,
      isAssetAvailableForBuyNow,
      isCreator,
      isAssetMinted
    ]
  );

  // track asset view event
  useAnalytics({
    event: TRACKED_EVENTS.ASSET_VIEW,
    values: {
      assetId: asset?.id,
      galleryId,
      pageType: 'asset-modal'
    },
    enabled: isAsset && Boolean(asset?.id)
  });

  useAnalytics({
    event: TRACKED_EVENTS.SUBMISSION_VIEW,
    values: {
      submissionId: submission?.id
    },
    enabled: isSubmission && Boolean(submission?.id)
  });

  return (
    <>
      <Modal
        id="preview-modal"
        open
        showCloseIconOnTop
        maxWidth={''}
        padding="p-0"
        background="bg-transparent"
        onClose={onClose}
        customWrapper
        customContainer
        innerContainerClassName="rounded-none"
        className="h-full"
        wrapperClassName="top-[68px] h-[calc(100vh-60px)]"
        closeIconClassName="h-8 w-8 p-1 stroke-[#111827] hover:bg-neutral-50 rounded-full dark:stroke-neutral-300 dark:hover:bg-white/30"
        closeBtnClassName="absolute top-4 left-4 cursor-pointer z-[100] bg-white/60 hover:bg-white/70 rounded-full dark:bg-white/20 dark:hover:bg-white/30"
      >
        <div
          name="modal-wrapper"
          className="z-[100] flex h-full w-full items-center justify-center gap-1"
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <div className="h-full w-full overflow-hidden overflow-y-scroll bg-white dark:bg-neutral-900">
            {isLoading ? (
              <AssetModalPlaceholder />
            ) : (
              <div className="flex h-full w-full flex-col lg:flex-row">
                {/* left arrow */}
                {!!withPagination && (
                  <div
                    className="absolute top-1/2 z-[100] hidden md:block"
                    ref={prevRef}
                    onClick={handlePageChange('previous')}
                  >
                    <ChevronLeftIcon className={arrowButtonClassName} />
                  </div>
                )}

                {showImageCarousel && (
                  <ImageCarousel
                    className="flex-1 bg-neutral-100 dark:bg-neutral-800"
                    files={files}
                    onChange={handleChangeFileIndex}
                    onShowFullScreen={handleShowAssetOnFullScreen(
                      state?.fileIndex
                    )}
                    resolution="default"
                  />
                )}

                {/* render HTML NFT — only for selected partners for now */}
                {hasHtmlFile(files) && <HtmlDisplay files={files} />}

                {/* right arrow */}
                {!!withPagination && (
                  <div
                    className={`absolute top-1/2 hidden md:block ${
                      emptyArray(files) ? 'right-0' : 'lg:right-[500px]'
                    }`}
                    ref={nextRef}
                    onClick={handlePageChange('next')}
                  >
                    <ChevronRightIcon className={arrowButtonClassName} />
                  </div>
                )}

                <div
                  className={`flex w-full flex-col ${
                    isTextAsset(obj)
                      ? 'mx-auto max-w-[720px]'
                      : 'lg:w-[500px] lg:border-l lg:border-neutral-300 lg:dark:border-neutral-700'
                  } gap-y-6 p-6 lg:overflow-y-scroll`}
                >
                  <ProductContent
                    title={title}
                    description={
                      isSubmission
                        ? description
                        : stripHtml(description ?? '').result
                    }
                    link={link}
                    assetId={isSubmission ? submission?.assetId : asset?.id}
                    assetSaved={asset?.saved}
                    assetChainId={assetChainId}
                    assetDetailPageLink={detailPageLink}
                    assetCreator={creator}
                    assetCreatorAddress={creatorAddress}
                    tweet={isSubmission && submission?.PromptTweetReply}
                    editable={canManage}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    isAsset={isAsset}
                    isSubmission={isSubmission}
                    isAssetMinted={isAssetMinted}
                    isAssetAvailableForBuyNow={isAssetAvailableForBuyNow}
                    hasAuction={hasAuction}
                    isPreview={isPreview}
                    isOneOfMany={isOneOfMany}
                    isStandAloneNFT={isStandAloneNFT}
                    tokenStandard={
                      isStandAloneNFT
                        ? token?.tokenType
                        : assetToken?.tokenStandard
                    }
                    blockchain={blockchain}
                    tokenId={assetTokenId}
                    tokenIdForListing={tokenIdForListing}
                    tokenAddress={assetTokenAddress}
                    tokenOwners={tokenOwners}
                    totalTokenOwners={totalTokenOwners}
                    onOpenOwnerListModal={() => setShowOwnerListModal(true)}
                    totalAmount={totalAmount}
                    onTokenSettings={handleTokenSettings}
                    lazyMinted={assetToken?.lazyMinted}
                    isMinted={assetMint?.minted}
                    hasActiveOrders={assetToken?.Orders?.length > 0}
                    onTransfer={handleTransfer}
                    isAssetOwner={isAssetOwner}
                    onDeletePiece={() => setDeleteModal(true)}
                    ownsAllSupply={tokenOwner?.ownsAllSupply}
                    galleryId={galleryId}
                  />

                  {canShowSalesFlow && (
                    <MarketplaceCard
                      assetId={asset?.id}
                      assetChainId={assetChainId}
                      galleryId={galleryId}
                      listingPrice={listingPrice}
                      amountAvailable={amountAvailable}
                      isAssetCreator={isCreator}
                      isAssetOwner={isAssetOwner}
                      isMinted={assetMint?.minted}
                      isAssetAvailableForBuyNow={isAssetAvailableForBuyNow}
                      isOneOfMany={isOneOfMany}
                      handleBuyAsset={() =>
                        handleBuyAsset({
                          order: cheapestActiveListing,
                          unitsToFill: state.unitsToFill,
                          priceUnit: cheapestActiveListing?.priceUnit,
                          v1Listing: cheapestActiveListing?.v1Listing || false,
                          seaportVersion: cheapestActiveListing.seaportVersion,
                          protocol: cheapestActiveListing?.protocol
                        })
                      }
                      cheapestActiveListingProtocol={
                        cheapestActiveListing?.protocol
                      }
                      step={state.step}
                      txHash={state.txHash}
                      unitsToFill={state.unitsToFill}
                      showSellTab={state.showSellTab}
                      onUnitsToFillChange={onUnitsToFillChange}
                      onShowSellTabChange={onShowSellTabChange}
                      steps={STEPS}
                      purchasePrice={state.purchasePrice}
                      onMakeOffer={handleMakeOffer}
                      isAssetAvailableForOffer={isAssetAvailableForOffer}
                      onAcceptOffer={(offer) => handleAcceptOffer({ offer })}
                      isAssetAvailableToAcceptOffer={
                        isAssetAvailableToAcceptOffer
                      }
                      highestOffer={highestOffer}
                      isAvailableForListing={isAvailableForListing}
                      isAvailableForMint={isAvailableForMint}
                      userHasListings={userHasListings}
                      userHasOffers={userHasOffers}
                      isAvailableForPlaceBid={isAvailableForPlaceBid}
                      reservePrice={auctionDetail?.reservePrice}
                      auctionStartTime={auctionDetail?.startDate}
                      auctionEndTime={auctionDetail?.endDate}
                      hasAuction={hasAuction}
                      isAuctionEnded={isAuctionEnded}
                      isAuctionStarted={isAuctionStarted}
                      auctionId={auctionDetail?.id}
                      blockchain={blockchain}
                      showRelistWithFee={showRelistWithFee}
                    />
                  )}

                  {isStandAloneNFT && (
                    <ImportNftCard
                      label="List for sale"
                      tokenId={assetTokenId}
                      tokenAddress={assetTokenAddress}
                    />
                  )}

                  {!canShowSalesFlow && lastSoldActivity && (
                    <Card className="flex flex-col gap-3 p-6" dropShadow="xl">
                      <Typography variant="medium" weight="medium">
                        Last sold
                      </Typography>
                      <AssetPrice
                        price={lastSoldActivity.price.amount.native}
                        size="large"
                        blockchain={blockchain}
                      />
                    </Card>
                  )}

                  {/* submission buttons */}
                  {isSubmission &&
                    submissionData.submission.permissions?.canModerate && (
                      <div className="flex">
                        <div className="w-full">
                          <div className="flex gap-x-2">
                            <>
                              <FloatingTooltip
                                label={
                                  'Only producers can see which submissions are shortlisted.'
                                }
                                placement="bottom"
                              >
                                <Button
                                  id="shortlist-button"
                                  color={'primary'}
                                  variant={'secondary'}
                                  disabled={loadingStates.shortlist}
                                  onClick={(e) =>
                                    handleShortlistSubmission(
                                      e,
                                      submission.id,
                                      shortlisted
                                    )
                                  }
                                  className="w-fit gap-x-2 px-3"
                                >
                                  <CheckIcon
                                    className="h-[16px] w-[16px]"
                                    color="#111827"
                                  />
                                  {shortlisted ? 'Shortlisted' : 'Shortlist'}
                                </Button>
                              </FloatingTooltip>

                              <FloatingTooltip
                                label={'Hide from this open call'}
                                placement="bottom"
                              >
                                <Button
                                  id="hide-button"
                                  color={
                                    submission?.hidden ? 'neutral' : 'primary'
                                  }
                                  variant={
                                    submission?.hidden
                                      ? 'tertiary'
                                      : 'secondary'
                                  }
                                  disabled={loadingStates.hide}
                                  onClick={handleHideSubmission(
                                    submission.id,
                                    !submission.hidden
                                  )}
                                  className="w-fit gap-x-2 px-3"
                                >
                                  {submission?.hidden ? (
                                    <>
                                      <EyeOffIcon className="h-[16px] w-[16px]" />
                                      Show
                                    </>
                                  ) : (
                                    <>
                                      <EyeIcon className="h-[16px] w-[16px]" />
                                      Hide
                                    </>
                                  )}
                                </Button>
                              </FloatingTooltip>

                              {submissionData.submission.permissions
                                ?.canModerate &&
                                !emptyArray(files) && (
                                  <BlobFileDownload
                                    DownloadComponent={DownloadComponent}
                                    fileUrl={files[state.fileIndex]?.fileUrl}
                                    fileName="joynSubmissionResources"
                                    fileExtension={
                                      files[state.fileIndex]?.fileExtension
                                    }
                                  />
                                )}
                            </>
                          </div>
                        </div>
                      </div>
                    )}

                  {/* details sections */}
                  <div
                    id="asset-detail-section"
                    className="flex flex-col gap-y-3"
                  >
                    <Divider size="smallest" />
                    <AssetDetailSections
                      currentUser={currentUser}
                      assetId={asset?.id}
                      assetCampaigns={assetCampaigns}
                      assetTokenId={assetTokenId}
                      assetChainId={assetChainId}
                      assetTokenAddress={assetTokenAddress}
                      assetToken={assetToken}
                      blockchain={blockchain}
                      tokenOwner={tokenOwner}
                      isStandAloneNFT={isStandAloneNFT}
                      isListed={isListed}
                      isAsset={isAsset}
                      isAssetMinted={isAssetMinted}
                      isAvailableForListing={isAvailableForListing}
                      isAssetAvailableForBuyNow={isAssetAvailableForBuyNow}
                      tokenURI={tokenURI}
                      usageRight={usageRight}
                      offers={offers}
                      activeListings={activeListings}
                      showOfferExpanded={showOfferExpanded}
                      tokenActivities={tokenActivities}
                      purchasePrice={state.purchasePrice}
                      hasAuction={hasAuction}
                      auctionOfferDisabled={auctionOfferDisabled}
                      onBuyAsset={handleBuyAsset}
                      onCancelListing={handleCancelListing}
                      onCancelOffer={handleCancelOffer}
                      isBuyingAsset={isBuyingAsset}
                      isCancelingOffer={isCancelingOffer}
                      onAcceptOffer={({ offer }) =>
                        handleAcceptOffer({ offer })
                      }
                      onOfferNote={handleOfferNote}
                      onClose={onClose}
                    />
                    <Divider size="smallest" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>

      {/* ModalManager doesn't work for the modals below — opening one closes the PreviewModal */}
      <DeleteModal
        title="Delete piece"
        subtitle="Are you sure? This also deletes all records of submissions. And the deletion is not reversible."
        onDelete={handleDeletePiece}
        onClose={() => setDeleteModal(false)}
        open={deleteModal}
      />

      <MakeOfferModal
        open={showMakeOfferModal}
        onClose={() => setShowMakeOfferModal(false)}
        title={title}
        assetChainId={assetChainId}
        assetCreator={creator}
        assetCreatorAddress={creatorAddress}
        assetImage={files[0]}
        isOneOfMany={isOneOfMany}
        totalSupply={tokenOwner?.totalSupply}
        tokenId={tokenIdForListing}
        refetchOfferData={refetchOfferData}
        isAvailableForPlaceBid={isAvailableForPlaceBid}
        auctionEndTime={
          auctionDetail?.endDate ||
          new Date(new Date().getTime() + Number(auctionDetail?.duration))
        }
        auctionId={auctionDetail?.id}
        highestOffer={highestOffer}
        reservePrice={auctionDetail?.reservePrice}
        galleryId={galleryId}
      />

      {showAcceptOfferModal && (
        <AcceptOfferModal
          assetChainId={assetChainId}
          open={showAcceptOfferModal}
          onClose={() => setShowAcceptOfferModal(false)}
          title={title}
          assetCreator={creator}
          assetCreatorAddress={creatorAddress}
          assetImage={files[0]}
          offer={offerToBeAccepted}
          tokenOwnedByCurrentUser={tokenOwnedByCurrentUser}
          assetTokenId={assetTokenId}
          assetTokenAddress={assetTokenAddress}
          tokenStandard={
            isStandAloneNFT ? token?.tokenType : assetToken?.tokenStandard
          }
          refetchOfferData={refetchOfferData}
        />
      )}

      {showOfferNoteModal && (
        <OfferNoteModal
          open={showOfferNoteModal}
          onClose={() => setShowOfferNoteModal(false)}
          title="Offer Note"
          actionTitle="Accept offer"
          action={({ offer }) => {
            setShowOfferNoteModal(false);
            handleAcceptOffer({ offer });
          }}
          offer={offerToBeAccepted}
          canAcceptOffer={!auctionOfferDisabled && tokenOwner?.isOwner}
        />
      )}

      <TransferAssetModal
        open={showTransferModal}
        onClose={() => setShowTransferModal(false)}
        title={title}
        assetChainId={assetChainId}
        assetCreator={creator}
        assetCreatorAddress={creatorAddress}
        assetImage={files[0]}
        isOneOfMany={isOneOfMany}
        totalSupply={tokenOwnedByCurrentUser}
        tokenStandard={
          isStandAloneNFT ? token?.tokenType : assetToken?.tokenStandard
        }
        tokenId={assetTokenId}
        tokenAddress={assetTokenAddress}
      />

      <OwnerListModal
        open={showOwnerListModal}
        onClose={() => setShowOwnerListModal(false)}
        tokenOwners={tokenOwners}
        tokenAddress={assetTokenAddress}
      />

      <AuctionAgreementForBuyerModal
        open={showAuctionAgreementForBuyerModal}
        onClose={() => setShowAuctionAgreementForBuyerModal(false)}
        onAgree={handleAcceptAuctionTerm}
      />
    </>
  );
};
