import axios from '/services/axios';

const loginWithWallet = ({ walletAddress, signature }) => {
  return axios.post(`/login`, {
    signature,
    walletAddress
  });
};

const loginWithEmail = (payload) => {
  return axios.post('/login/email', payload);
};

const signupWithEmail = (payload) => {
  return axios.post('/sign-up/email', payload);
};

const forgotPassword = (payload) => {
  return axios.post('/password-reset', payload);
};

const resetPassword = (payload) => {
  return axios.put('/password-reset/change', payload);
};

const signMessage = ({ walletAddress }) => {
  return axios.post('/signing-message', { walletAddress });
};

const refreshToken = ({ refreshToken }) => {
  return axios.post(
    '/refresh_token',
    { token: refreshToken },
    { bypassInterceptor: true }
  );
};

export {
  loginWithWallet,
  loginWithEmail,
  signupWithEmail,
  forgotPassword,
  resetPassword,
  signMessage,
  refreshToken
};
