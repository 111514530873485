import React, { createContext, useContext, useState, useCallback } from 'react';

import { RewardModal } from '/design-systems/Molecules/Modals/RewardModal';
import { DeleteModal } from '/design-systems/Molecules/Modals/DeleteModal';
import { SelectPromptWinnersModal } from '/design-systems/Molecules/Modals/WinnerModal/SelectPromptWinnersModal';
import { ReviewPromptWinnersModal } from '/design-systems/Molecules/Modals/WinnerModal/ReviewPromptWinnersModal';
import { POPClaimModal } from '/design-systems/Molecules/Modals/POPClaimModal';
import { AssetActionSuccessModal } from '/design-systems/Molecules/Modals/AssetActionSuccessModal';
import { VerifyAccountModal } from '/design-systems/Molecules/Modals/VerifyAccountModal';
import { ConnectWalletModal } from '/design-systems/Molecules/Modals/ConnectWalletModal';
import { InviteFormModal } from '/design-systems/Molecules/Modals/InviteModals/InviteFormModal';
import { FileUploadModal } from '/design-systems/Molecules/Modals/FileUploadModal';
import { DisconnectModal } from '/design-systems/Molecules/Modals/DisconnectModal';
import { AssetFilterModal } from '/design-systems/Molecules/Modals/AssetFilterModal';
import { PreviewModal } from '/design-systems/Molecules/Modals/PreviewModal';
import { MergeAssetsModal } from '/design-systems/Molecules/Modals/MergeAssetsModal';
import { UsersModal } from '/design-systems/Molecules/Modals/UsersModal';
import { NewGalleryModal } from '/design-systems/Molecules/Modals/NewGalleryModal';
import { WhereSubmissionModal } from '/design-systems/Molecules/Modals/WhereSubmissionModal';
import { ConcurrentUserModal } from '/design-systems/Molecules/Modals/ConcurrentUserModal';
import { AcceptOfferModal } from '/design-systems/Molecules/Modals/AcceptOfferModal';
import { MakeOfferModal } from '/design-systems/Molecules/Modals/MakeOfferModal';
import { OfferNoteModal } from '/design-systems/Molecules/Modals/OfferNoteModal';
import { TransferAssetModal } from '/design-systems/Molecules/Modals/TransferAssetModal';
import { GalleryCollabRequestModal } from '/design-systems/Molecules/Modals/GalleryCollabRequestModal';
import { ArtPlusModal } from '/design-systems/Molecules/Modals/ArtPlusModal';
import { ArtPlusIntroModal } from '/design-systems/Molecules/Modals/ArtPlusIntroModal';
import { ShowcaseIntroModal } from '/design-systems/Molecules/Modals/ShowcaseIntroModal';
import { MergeUsersModal } from '/design-systems/Molecules/Modals/MergeUsersModal';
import { ShowcaseActivityModal } from '/design-systems/Molecules/Modals/ShowcaseActivityModal';
import { OwnerListModal } from '/design-systems/Molecules/Modals/OwnerListModal';
import { AcceptRequestUnmintedModal } from '/design-systems/Molecules/Modals/AcceptRequestUnmintedModal';
import { AuctionAgreementModal } from '/design-systems/Molecules/Modals/AuctionAgreementModal';
import { AuctionAgreementForBuyerModal } from '/design-systems/Molecules/Modals/AuctionAgreementForBuyerModal';

import { useShallowState } from '/hooks/shallowState';
import { ComposeModal } from '/design-systems/Molecules/Modals/ComposeModal';

export const MODALS = {
  /* Prompt Reward Select Modal */
  REWARD_MODAL: 1,
  SELECT_WINNERS_MODAL: 2,
  REVIEW_WINNERS_MODAL: 3,
  DELETE_MODAL: 4,
  POP_CLAIM_MODAL: 5,

  /** Asset */
  ASSET_MINT_SUCCESS_MODAL: 9,
  ASSET_LIST_SUCCESS_MODAL: 10,
  ASSET_LISTING_CANCEL_SUCCESS_MODAL: 11,
  ASSET_LISTING_UPDATE_SUCCESS_MODAL: 12,
  ASSET_BOUGHT_SUCCESS_MODAL: 13,
  ASSET_FILTER_MODAL: 17,
  VERIFY_ACCOUNT_MODAL: 7,
  CONNECT_WALLET_MODAL: 8,
  INVITE_PRODUCER_MODAL: 14,

  // Image Upload Modal of editor
  FILE_UPLOAD_MODAL: 15,
  DISCONNECT_MODAL: 16,

  // Submission & Asset Preview modal
  PREVIEW_MODAL: 19,
  MERGE_ASSETS_MODAL: 20,

  USERS_MODAL: 21,

  // composer
  NEW_GALLERY_MODAL: 22,
  ART_PLUS_MODAL: 31,

  WHERE_SUBMISSION_MODAL: 23,
  CONCURRENT_USER_MODAL: 24,
  MERGE_USERS_MODAL: 25,

  // offers
  ACCEPT_OFFER_MODAL: 26,
  MAKE_OFFER_MODAL: 27,
  OFFER_NOTE_MODAL: 28,
  TRANSFER_ASSET_MODAL: 29,

  //Showcase Activity modal
  SHOWCASE_ACTIVITY_MODAL: 30,

  // Gallery collab request modal
  GALLERY_COLLAB_REQUEST_MODAL: 32,
  ACCEPT_REQUEST_UNMINTED_MODAL: 35,

  OWNER_LIST_MODAL: 33,

  // Composer help modal
  ART_PLUS_INTRO_MODAL: 34,
  SHOWCASE_INTRO_MODAL: 36,

  // Auction
  AUCTION_AGREEMENT_MODAL: 37,
  AUCTION_AGREEMENT_FOR_BUYER_MODAL: 38,

  // Gallery Collaborator Invitation
  GALLERY_COLLABORATOR_INVITE_MODAL: 39,

  COMPOSE_MODAL: 40 /** <-- This is the last modal Id. If you add new modal id, move this comment there */
};

export const ModalManagerContext = createContext({});

export const useModalManager = () => useContext(ModalManagerContext);

export const ModalManager = ({ children }) => {
  const [modalId, setModalId] = useState(0);
  const [props, setProps] = useState({});
  const [connectWalletState, setConnectWalletState] = useShallowState({
    show: false,
    props: undefined
  });

  const handleShowModal = ({ modalId: newModalId, props: newProps }) => {
    document.body.classList.add('modal-open');
    setModalId(newModalId);
    setProps(newProps);
  };

  const handleCloseModal = () => {
    document.body.classList.remove('modal-open');
    setModalId(0);
    setProps({});
  };

  const handleShowConnectWalletModal = useCallback(
    (props) => {
      setConnectWalletState({
        show: true,
        props
      });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleCloseConnectWalletModal = useCallback(
    () => {
      setConnectWalletState({
        show: false,
        props: undefined
      });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <ModalManagerContext.Provider
      value={{
        modalId,
        showModal: handleShowModal,
        closeModal: handleCloseModal,
        showConnectWalletModal: handleShowConnectWalletModal,
        closeConnectWalletModal: handleCloseConnectWalletModal
      }}
    >
      {children}

      {modalId === MODALS.REWARD_MODAL && <RewardModal open {...props} />}

      {modalId === MODALS.SELECT_WINNERS_MODAL && (
        <SelectPromptWinnersModal open {...props} />
      )}

      {modalId === MODALS.DELETE_MODAL && <DeleteModal open {...props} />}

      {modalId === MODALS.REVIEW_WINNERS_MODAL && (
        <ReviewPromptWinnersModal open {...props} />
      )}

      {modalId === MODALS.POP_CLAIM_MODAL && <POPClaimModal open {...props} />}
      {modalId === MODALS.CONCURRENT_USER_MODAL && (
        <ConcurrentUserModal open {...props} />
      )}

      {modalId === MODALS.PREVIEW_MODAL && <PreviewModal open {...props} />}

      {modalId === MODALS.MERGE_ASSETS_MODAL && (
        <MergeAssetsModal open {...props} />
      )}

      {modalId === MODALS.ASSET_MINT_SUCCESS_MODAL && (
        <AssetActionSuccessModal
          open
          title="Your NFT has been minted!"
          description="Congratulations! Your NFT is now on the Ethereum blockchain. Your profile page will be updated in a few minutes."
          actionTitle="List for sale"
          {...props}
        />
      )}

      {modalId === MODALS.ASSET_LIST_SUCCESS_MODAL && (
        <AssetActionSuccessModal
          open
          title="Your NFT is now listed!"
          description="Congratulations! Your NFT is listed on the marketplace"
          {...props}
        />
      )}

      {modalId === MODALS.ASSET_LISTING_UPDATE_SUCCESS_MODAL && (
        <AssetActionSuccessModal
          open
          title="Your listing is now updated!"
          description="Congratulations! Your listing is updated"
          {...props}
        />
      )}

      {modalId === MODALS.ASSET_LISTING_CANCEL_SUCCESS_MODAL && (
        <AssetActionSuccessModal
          title="Your NFT listing is now cancelled!"
          open
          {...props}
        />
      )}

      {modalId === MODALS.ASSET_BOUGHT_SUCCESS_MODAL && (
        <AssetActionSuccessModal
          title="Your purchase is now completed!"
          open
          {...props}
        />
      )}

      {modalId === MODALS.ASSET_FILTER_MODAL && (
        <AssetFilterModal open {...props} />
      )}

      {modalId === MODALS.USERS_MODAL && <UsersModal open {...props} />}

      {modalId === MODALS.SHOWCASE_ACTIVITY_MODAL && (
        <ShowcaseActivityModal open {...props} />
      )}

      {modalId === MODALS.VERIFY_ACCOUNT_MODAL && (
        <VerifyAccountModal open {...props} />
      )}

      {modalId === MODALS.INVITE_PRODUCER_MODAL && (
        <InviteFormModal open {...props} />
      )}

      {modalId === MODALS.FILE_UPLOAD_MODAL && (
        <FileUploadModal open {...props} />
      )}

      {modalId === MODALS.DISCONNECT_MODAL && (
        <DisconnectModal open {...props} />
      )}

      {(modalId === MODALS.CONNECT_WALLET_MODAL || connectWalletState.show) && (
        <ConnectWalletModal open {...(connectWalletState.props ?? props)} />
      )}

      {modalId === MODALS.WHERE_SUBMISSION_MODAL && (
        <WhereSubmissionModal open {...props} />
      )}

      {modalId === MODALS.NEW_GALLERY_MODAL && (
        <NewGalleryModal open {...props} />
      )}

      {modalId === MODALS.ART_PLUS_MODAL && <ArtPlusModal open {...props} />}

      {modalId === MODALS.MERGE_USERS_MODAL && (
        <MergeUsersModal open {...props} />
      )}

      {modalId === MODALS.ACCEPT_OFFER_MODAL && (
        <AcceptOfferModal open {...props} />
      )}

      {modalId === MODALS.MAKE_OFFER_MODAL && (
        <MakeOfferModal open {...props} />
      )}

      {modalId === MODALS.OFFER_NOTE_MODAL && (
        <OfferNoteModal open {...props} />
      )}

      {modalId === MODALS.TRANSFER_ASSET_MODAL && (
        <TransferAssetModal open {...props} />
      )}

      {modalId === MODALS.GALLERY_COLLAB_REQUEST_MODAL && (
        <GalleryCollabRequestModal open {...props} />
      )}

      {modalId === MODALS.OWNER_LIST_MODAL && (
        <OwnerListModal open {...props} />
      )}

      {modalId === MODALS.ACCEPT_REQUEST_UNMINTED_MODAL && (
        <AcceptRequestUnmintedModal open {...props} />
      )}

      {modalId === MODALS.ART_PLUS_INTRO_MODAL && (
        <ArtPlusIntroModal open {...props} />
      )}

      {modalId === MODALS.SHOWCASE_INTRO_MODAL && (
        <ShowcaseIntroModal open {...props} />
      )}

      {/* Auction */}

      {modalId === MODALS.AUCTION_AGREEMENT_MODAL && (
        <AuctionAgreementModal open {...props} />
      )}

      {modalId === MODALS.AUCTION_AGREEMENT_FOR_BUYER_MODAL && (
        <AuctionAgreementForBuyerModal open {...props} />
      )}

      {modalId === MODALS.GALLERY_COLLABORATOR_INVITE_MODAL && (
        <InviteFormModal open {...props} />
      )}

      {modalId === MODALS.COMPOSE_MODAL && <ComposeModal open {...props} />}
    </ModalManagerContext.Provider>
  );
};
