import { useMemo, useState } from 'react';
import { useConnect, useSignMessage, useAccount, useDisconnect } from 'wagmi';
import { useMutation } from '@tanstack/react-query';
import {
  loginWithWallet,
  signMessage as getSignature,
  refreshToken
} from '/services/joynApi/users/auth';
import { loginWithEmail, signupWithEmail } from '/services/joynApi/users/auth';

import { initializeForCurrentUser, resetCurrentUser } from '/utils/auth';
import { useAuthRouterHelpers } from './router';
import { useSelector } from 'react-redux';
import { connectUserWallet } from '/services/joynApi/users/user';
import { getWalletAddressFromUser } from '/utils/user';
import { objectBlank } from '/utils/object';

export const useAuth = () => {
  const { redirectToCompleteProfile, redirectToReturnURL } =
    useAuthRouterHelpers();
  const { isConnected, address } = useAccount();
  const currentUser = useSelector((state) => state.user.current);

  const { connectAsync } = useConnect({
    onError(error) {
      console.error('Connecting wallet error: ', error);
    }
  });
  const { signMessageAsync } = useSignMessage({
    onError(error) {
      console.error('Signing wallet error: ', error);
    }
  });
  const { disconnect } = useDisconnect({
    onError(error) {
      console.error('Disconnecting wallet error: ', error);
    },
    onSuccess() {
      resetCurrentUser();
      redirectToReturnURL();
    }
  });

  const { mutateAsync: onSignWallet } = useMutation(
    async ({ account, withReturn = false }) => {
      const respAfterGetSignMessage = await getSignature({
        walletAddress: account
      });
      const message = respAfterGetSignMessage.data.data.messageToSigned;

      const signature = await signMessageAsync({ message });

      const isNewAddress = objectBlank(
        getWalletAddressFromUser({
          walletAddresses: currentUser?.WalletAddresses ?? [],
          address: account
        })
      );

      if (objectBlank(currentUser) || !isNewAddress) {
        const respAfterLoginWithWallet = await loginWithWallet({
          walletAddress: account,
          signature
        });

        const { user, jwtToken, refreshToken } =
          respAfterLoginWithWallet.data.data;

        await initializeForCurrentUser({
          user,
          jwtToken,
          refreshToken
        });

        if (user?.isNewAddress && !user.email && !user.twitter) {
          redirectToCompleteProfile();
          return;
        }
      } else {
        const respAfterLoginWithWallet = await connectUserWallet({
          walletAddress: account,
          signature
        });
        const { user } = respAfterLoginWithWallet.data.data;
        await initializeForCurrentUser({
          user: {
            ...user,
            verified: currentUser?.verified,
            isTwitterConnected: currentUser?.isTwitterConnected
          }
        });
      }

      withReturn && redirectToReturnURL();
    }
  );

  const { isLoading: isConnectingWallet, mutateAsync: onConnectWallet } =
    useMutation(
      async ({ connector, withReturn = false, withUnlockWallet = false }) => {
        let connectedWallet;
        if (!isConnected) {
          connectedWallet = await connectAsync({ connector });
          if (withUnlockWallet) return;
        }

        await onSignWallet({
          account: connectedWallet?.account ?? address,
          withReturn
        });
      },
      {
        onError(error) {
          console.error('Connecting wallet error: ', error);
        }
      }
    );

  const {
    data: afterLogin,
    isLoading: isLoggingIn,
    mutateAsync: onLogin
  } = useMutation(async (payload) => {
    const res = await loginWithEmail(payload);
    return res.data.data;
  });

  const {
    data: afterRefreshToken,
    isLoading: isRefreshingToken,
    mutateAsync: onRefreshToken
  } = useMutation(async (payload) => {
    const res = await refreshToken(payload);
    return res.data.data;
  });

  const {
    data: afterSignup,
    isLoading: isSigningUp,
    mutateAsync: onSignup
  } = useMutation(async (payload) => {
    const res = await signupWithEmail(payload);
    return res.data.data;
  });

  const { mutate: onSignOut } = useMutation(() => {
    if (!isConnected) {
      resetCurrentUser();
    }
    disconnect();
  });

  return useMemo(
    () => ({
      isConnectingWallet,
      isLoggingIn,
      afterLogin,
      isSigningUp,
      afterSignup,
      onLogin,
      onSignup,
      onConnectWallet,
      onSignOut,
      onRefreshToken,
      isRefreshingToken,
      afterRefreshToken
    }),
    [
      isConnectingWallet,
      isLoggingIn,
      afterLogin,
      isSigningUp,
      afterSignup,
      onLogin,
      onSignup,
      onConnectWallet,
      onSignOut,
      onRefreshToken,
      isRefreshingToken,
      afterRefreshToken
    ]
  );
};
