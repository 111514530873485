import { useCallback, useState } from 'react';

import { objectBlank } from '/utils/object';
import {
  buyV2Listing,
  buyV1Listing,
  cancelV2Listing,
  cancelV1Listing,
  cancelOffer
} from 'utils/marketplace';

import { toast } from 'react-toastify';

import { useAssetPurchase } from '/hooks/useAssetPurchase';
import { useMarketplaceContract } from '/hooks/on-chain/useMarketplaceContract';
import { useAuthRouterHelpers } from '/hooks/router';
import { MODALS, useModalManager } from '/context/ModalManager';
import { usePage } from '/hooks/usePage';
import { useV2Listing } from '/hooks/useV2Listing';

export const useAssetTransactions = ({
  currentUser,
  signer,
  asset,
  assetToken,
  assetTokenId,
  assetChainId,
  assetTokenAddress,
  cheapestActiveListing,
  listingPrice,
  refetchActiveListings,
  refetchTokenOwner,
  refetchOffers,
  highestOffer,
  refetchHighestOffer,
  auctionDetail,
  refetchAuctionDetail,
  auctionOfferDisabled,
  title,
  creator,
  creatorAddress,
  files,
  isOneOfMany,
  isStandAloneNFT,
  token,
  tokenOwner,
  tokenOwnedByCurrentUser,
  tokenIdForListing,
  isAvailableForPlaceBid,
  galleryId,
  setState
}) => {
  const { onCreateAssetPurchase, onInitiateAssetPurchase } = useAssetPurchase();
  const {
    onBuyAsset,
    onCancelListing,
    onFoundationBuyV2,
    onFoundationCancelListing
  } = useMarketplaceContract({
    chainId: assetChainId
  });
  const { getV2ListingOpenCuratorFeeConsiderations } = useV2Listing({});

  const { navigateToLogin } = useAuthRouterHelpers();
  const { showModal, closeModal } = useModalManager();
  const [isBuyingAsset, setIsBuyingAsset] = useState(false);
  const [showMakeOfferModal, setShowMakeOfferModal] = useState(false);
  const [showAcceptOfferModal, setShowAcceptOfferModal] = useState(false);
  const [showOfferNoteModal, setShowOfferNoteModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [
    showAuctionAgreementForBuyerModal,
    setShowAuctionAgreementForBuyerModal
  ] = useState(false);

  const { isAssetDetailsPage, isDashboardPage } = usePage();

  const handleBuyAsset = async ({
    order,
    unitsToFill,
    priceUnit,
    v1Listing,
    seaportVersion,
    protocol
  }) => {
    if (objectBlank(currentUser)) {
      navigateToLogin({ isForce: true });
      return;
    }
    setIsBuyingAsset(true);
    if (v1Listing) {
      await buyV1Listing({
        setState,
        onCreateAssetPurchase,
        listingId: cheapestActiveListing?.id,
        onBuyAsset,
        onInitiateAssetPurchase,
        tokenId: assetToken?.tokenId,
        tokenAddress: assetToken?.tokenAddress,
        priceUnit: listingPrice,
        assetTitle: asset?.title,
        toast,
        refetchActiveListings
      });
    } else {
      let openCuratorFeeConsiderations = [];
      if (order.isOpenCuratorFee) {
        const res = await getV2ListingOpenCuratorFeeConsiderations({
          orderId: order?.id,
          galleryId
        });
        openCuratorFeeConsiderations = res?.data?.data || [];
      }

      await buyV2Listing({
        setState,
        provider: signer,
        chainId: assetChainId,
        order,
        unitsToFill,
        priceUnit,
        refetchActiveListings,
        toast,
        assetTitle: asset?.title,
        seaportVersion,
        protocol,
        onFoundationBuyV2,
        openCuratorFeeConsiderations,
        galleryId
      });
    }
    setIsBuyingAsset(false);
  };

  const handleCancelListing = async ({
    order,
    v1Listing,
    seaportVersion,
    protocol
  }) => {
    if (objectBlank(currentUser)) {
      navigateToLogin({ isForce: true });
      return;
    }

    if (v1Listing) {
      await cancelV1Listing({
        onCancelListing,
        tokenId: assetToken?.tokenId,
        tokenAddress: assetToken?.tokenAddress,
        setState,
        refetchActiveListings,
        toast
      });
    } else {
      await cancelV2Listing({
        provider: signer,
        chainId: assetChainId,
        setState,
        refetchActiveListings,
        toast,
        order,
        seaportVersion,
        protocol,
        onFoundationCancelListing
      });
    }
  };

  const refetchOfferData = useCallback(
    ({ refetchAssetData = false }) => {
      refetchOffers();
      refetchHighestOffer();
      refetchAuctionDetail();

      if (refetchAssetData) {
        refetchTokenOwner();
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleAcceptAuctionTerm = () => {
    setShowAuctionAgreementForBuyerModal(false);

    if (isAssetDetailsPage) {
      let auctionEndTime = auctionDetail?.endDate;
      if (!auctionEndTime) {
        auctionEndTime = new Date(
          new Date().getTime() + Number(auctionDetail?.duration)
        );
      }

      showModal({
        modalId: MODALS.MAKE_OFFER_MODAL,
        props: {
          onClose: () => {
            setShowMakeOfferModal(false);
            closeModal();
          },
          title: title,
          assetChainId,
          assetCreator: creator,
          assetCreatorAddress: creatorAddress,
          assetImage: files[0],
          isOneOfMany: isOneOfMany,
          totalSupply: tokenOwner?.totalSupply,
          tokenId: tokenIdForListing,
          refetchOfferData: refetchOfferData,
          isAvailableForPlaceBid: isAvailableForPlaceBid,
          auctionEndTime,
          auctionId: auctionDetail?.id,
          highestOffer: highestOffer,
          reservePrice: auctionDetail?.reservePrice,
          galleryId
        }
      });
    }

    setShowMakeOfferModal(true);
  };

  const handleMakeOffer = () => {
    if (objectBlank(currentUser)) {
      navigateToLogin({ isForce: true });
      return;
    }

    if (objectBlank(auctionDetail)) {
      handleAcceptAuctionTerm();
      return;
    }

    if (isAssetDetailsPage) {
      showModal({
        modalId: MODALS.AUCTION_AGREEMENT_FOR_BUYER_MODAL,
        props: {
          onAgree: () => {
            closeModal();
            handleAcceptAuctionTerm();
          },
          onClose: closeModal
        }
      });
    }

    setShowAuctionAgreementForBuyerModal(true);
  };

  const [isCancelingOffer, setIsCancelingOffer] = useState(null);

  const handleCancelOffer = async ({ offer }) => {
    if (objectBlank(currentUser)) {
      navigateToLogin({ isForce: true });
      return;
    }

    setIsCancelingOffer(offer?.id);
    await cancelOffer({
      chainId: assetChainId,
      order: offer?.orderData?.parameters,
      provider: signer,
      refetchOffer: refetchOfferData,
      seaportVersion: offer?.seaportVersion
    });
    setIsCancelingOffer(null);
  };

  const [offerToBeAccepted, setOfferToBeAccepted] = useState({});

  const handleAcceptOffer = ({ offer }) => {
    if (objectBlank(currentUser)) {
      navigateToLogin({ isForce: true });
      return;
    }

    setOfferToBeAccepted(offer);
    if (isAssetDetailsPage) {
      showModal({
        modalId: MODALS.ACCEPT_OFFER_MODAL,
        props: {
          assetChainId,
          onClose: () => {
            setShowAcceptOfferModal(false);
            closeModal();
          },
          title: title,
          assetCreator: creator,
          assetCreatorAddress: creatorAddress,
          assetImage: files[0],
          offer, //: offerToBeAccepted,
          tokenOwnedByCurrentUser,
          assetTokenId,
          assetTokenAddress,
          tokenStandard: isStandAloneNFT
            ? token?.tokenType
            : assetToken?.tokenStandard,
          refetchOfferData: refetchOfferData
        }
      });
    }

    setShowAcceptOfferModal(true);
  };

  const handleOfferNote = ({ offer }) => {
    if (objectBlank(currentUser)) {
      navigateToLogin({ isForce: true });
      return;
    }
    setOfferToBeAccepted(offer);

    if (isAssetDetailsPage || isDashboardPage) {
      showModal({
        modalId: MODALS.OFFER_NOTE_MODAL,
        props: {
          onClose: () => {
            setShowOfferNoteModal(false);
            closeModal();
          },
          title: 'Offer note',
          actionTitle: 'Accept offer',
          action: ({ offer }) => {
            handleAcceptOffer({ offer });
            closeModal();
          },
          offer,
          canAcceptOffer: !auctionOfferDisabled && tokenOwner?.isOwner
        }
      });
    }

    setShowOfferNoteModal(true);
  };

  const handleTransfer = () => {
    if (isAssetDetailsPage) {
      showModal({
        modalId: MODALS.TRANSFER_ASSET_MODAL,
        props: {
          onClose: () => {
            setShowTransferModal(false);
            closeModal();
          },
          title: title,
          assetCreator: creator,
          assetCreatorAddress: creatorAddress,
          assetImage: files[0],
          isOneOfMany: isOneOfMany,
          totalSupply: tokenOwnedByCurrentUser,
          tokenStandard: isStandAloneNFT
            ? token?.tokenType
            : assetToken?.tokenStandard,
          tokenId: assetTokenId,
          tokenAddress: assetTokenAddress,
          assetChainId
        }
      });
    }

    setShowTransferModal(true);
  };

  return {
    handleBuyAsset,
    handleCancelListing,
    handleMakeOffer,
    refetchOfferData,
    handleCancelOffer,
    isCancelingOffer,
    handleAcceptOffer,
    handleOfferNote,
    offerToBeAccepted,
    handleTransfer,
    isBuyingAsset,
    setShowMakeOfferModal,
    showMakeOfferModal,
    setShowAcceptOfferModal,
    showAcceptOfferModal,
    showOfferNoteModal,
    setShowOfferNoteModal,
    showTransferModal,
    setShowTransferModal,
    showAuctionAgreementForBuyerModal,
    setShowAuctionAgreementForBuyerModal,
    handleAcceptAuctionTerm
  };
};
