import React from 'react';

import { Typography } from '/design-systems/Atoms/Typography';
import { FloatingTooltip } from '/design-systems/Atoms/FloatingTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { mergeClassNames } from '/utils/string';

export const FieldLabel = ({
  label,
  tooltipLabel,
  tooltipIcon = null,
  optional = false,
  className
}) => {
  return (
    <FloatingTooltip label={tooltipLabel} placement="top" hide={!tooltipLabel}>
      <div
        className={mergeClassNames('flex w-fit items-center gap-1', className)}
      >
        <Typography variant="medium" weight="medium">
          {label}
        </Typography>
        {tooltipLabel && (tooltipIcon || DEFAULT_TOOLTIP_ICON)}
        {optional && (
          <Typography colorVariant="secondary">(optional)</Typography>
        )}
      </div>
    </FloatingTooltip>
  );
};

const DEFAULT_TOOLTIP_ICON = (
  <div className="flex p-0.5">
    <FontAwesomeIcon icon={faCircleInfo} className="h-3 w-3" />
  </div>
);
