import { createSlice } from '@reduxjs/toolkit';

export const milestoneSlice = createSlice({
  name: 'milestone',
  initialState: {
    all: [],
    current: null,
  },
  reducers: {
    setAll: (state, action) => {
      state.all = action.payload;
    },
    edit: (state, action) => {
      state.all = state.all.map((milestone) => (milestone.id == action.payload.id ? { ...action.payload } : milestone));
      state.current = { ...action.payload };
    },
    setCurrentMilestone: (state, action) => {
      state.current = state.all.map((milestone) => milestone.id == action.payload.id);
    },
  },
});

export const { setAll, edit, setCurrentMilestone } = milestoneSlice.actions;
export default milestoneSlice.reducer;
