import jwt from 'jsonwebtoken';
import configureStore from '/redux/store';
import {
  setCurrent,
  setSelectedWalletAddressId
} from '/redux/slices/userSlice';
import { setCurrentProject } from '/redux/slices/projectSlice';
import { setBookmarkedProjects } from '/redux/slices/projectSlice';
import { getUserProfile } from '/services/joynApi/users/user';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';

import { removeQueryParams, removeQueryParam } from '/utils/url';
import {
  LOCAL_SOCIAL_AUTH_REDIRECT_URL_KEY,
  LOCAL_SOCIAL_AUTH_TYPE_KEY
} from '/config/routes';

import metamaskIcon from '/images/metamask_fox.png';
import wcIcon from '/images/wc-logo.png';
import coinbaseWalletIcon from '/images/coinbase-wallet-logo.svg';

const isTokenExpired = (token) => {
  const decoded = jwt.decode(token);
  if (!decoded || !decoded.exp) {
    return true;
  }

  const dateNow = new Date();

  // Convert from seconds to milliseconds
  const tokenExpDate = decoded.exp * 1000;

  return tokenExpDate < dateNow;
};

const isUserLoggedIn = () => {
  const jwtToken = localStorage.getItem('jwtToken');
  return !isTokenExpired(jwtToken);
};

const initializeForCurrentUser = async ({
  user,
  jwtToken,
  refreshToken,
  redirectToReturnURL,
  redirectToCompleteProfile
}) => {
  if (jwtToken) {
    localStorage.setItem('jwtToken', jwtToken);
  }
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  }

  const mainWallet = retrieveMainWalletAddressFromWalletAddresses(
    user?.WalletAddresses
  );
  localStorage.setItem('walletAddress', mainWallet?.address);

  configureStore.dispatch(
    setCurrent({ ...user, walletAddress: mainWallet?.address })
  );
  configureStore.dispatch(setSelectedWalletAddressId(mainWallet?.id));

  if (redirectToCompleteProfile && user?.isNewAddress) {
    redirectToCompleteProfile();
    return;
  }

  if (redirectToReturnURL) {
    redirectToReturnURL();
    return;
  }
};

const redirectBeforeAction = async ({ router, type, query, action }) => {
  const pathname = removeQueryParams(router?.asPath);
  const redirect = window.localStorage.getItem(
    LOCAL_SOCIAL_AUTH_REDIRECT_URL_KEY
  );
  const authType = window.localStorage.getItem(LOCAL_SOCIAL_AUTH_TYPE_KEY);

  if (redirect && authType === type && pathname !== redirect) {
    let newRoute = redirect;
    if (!redirect?.includes('?')) {
      newRoute += '?';
    } else {
      newRoute += '&';
    }
    newRoute += query;
    router.push(newRoute);

    window.localStorage.removeItem(LOCAL_SOCIAL_AUTH_REDIRECT_URL_KEY);
    window.localStorage.removeItem(LOCAL_SOCIAL_AUTH_TYPE_KEY);
  } else {
    await action();
  }
};

const socialAuthentication = async ({
  type,
  redirectURL,
  setLoading,
  redirectToLogin,
  getAuthURL,
  isTwitterAuth = false
}) => {
  if (!isUserLoggedIn() && !isTwitterAuth) {
    redirectToLogin();
    return;
  }

  setLoading(true);
  try {
    const data = await getAuthURL();

    if (!isTwitterAuth) {
      window.localStorage.setItem('connecting_twitter', true);
    } else {
      window.localStorage.removeItem('connecting_twitter');
    }

    window.localStorage.setItem(
      LOCAL_SOCIAL_AUTH_REDIRECT_URL_KEY,
      redirectURL
    );
    window.localStorage.setItem(LOCAL_SOCIAL_AUTH_TYPE_KEY, type);
    window.location.href = data.data.data.url;
  } catch (error) {
    console.error('Getting Auth URL error: ', error);
  }
  setLoading(false);
};

const socialAuthorization = async ({
  router,
  path,
  authAction,
  setLoading,
  setUsername
}) => {
  router.push(
    removeQueryParam(router?.asPath, ['oauth_verifier', 'code', 'oauth_token'])
  );

  setLoading(true);

  try {
    const { data } = await authAction();
    setUsername(data.data.user[path]);

    const res = await getUserProfile();
    configureStore.dispatch(setCurrent(res.data.data));
  } catch (error) {
    console.error('authorization error: ', error);
  }

  setLoading(false);
};

const getInitialAuthFormValues = ({
  withEmail = false,
  withPassword = false,
  withUsername = false
}) => {
  const values = {};
  if (withEmail) values.email = '';
  if (withPassword) values.password = '';
  if (withUsername) values.username = '';
  return values;
};

const resetCurrentUser = () => {
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('walletAddress');
  localStorage.removeItem('returnUrl');
  configureStore.dispatch(setCurrent({}));
  configureStore.dispatch(setBookmarkedProjects([]));
  configureStore.dispatch(setCurrentProject({}));
};

const getWalletIcon = (walletName) => {
  return {
    MetaMask: metamaskIcon,
    WalletConnect: wcIcon,
    'Coinbase Wallet': coinbaseWalletIcon
  }[walletName];
};

export {
  isUserLoggedIn,
  isTokenExpired,
  initializeForCurrentUser,
  redirectBeforeAction,
  socialAuthentication,
  socialAuthorization,
  getInitialAuthFormValues,
  resetCurrentUser,
  getWalletIcon
};
