import React from 'react';
import classNames from 'classnames';
import { Avatar } from '/design-systems/Atoms/Avatar';
import { FloatingTooltip } from '/design-systems/Atoms/FloatingTooltip';
import { Typography } from '/design-systems/Atoms/Typography';
import { getHeaderProfileName } from '/utils/user';

export const OverlayCircles = ({
  elements,
  elementsResolution = 's',
  className,
  labelClassName,
  rounded,
  roundedSize,
  staticFile,
  required = 3,
  size = 48,
  noHover = false,
  hideAvatar = false,
  noLabel = false,
  noMore = false,
  noWrap = false,
  handleElementClick,
  getFloatingTipLabel,
  getElementAvatar,
  handleShowMore,
  AdditionalChild
}) => {
  if (!elements || !elements.length) return <></>;

  const isSingle = elements.length === 1;
  const displayableElements = elements.filter((_, idx) => idx < required);
  const firstElement = elements[0];

  return (
    <div
      className={classNames(
        'team flex w-full max-w-[250px] items-center',
        { 'hover:bg-neutral-100': !noHover },
        { 'dark:hover:bg-white/20': !noHover },
        className
      )}
    >
      {isSingle && firstElement ? (
        <div
          className={classNames(
            'flex min-h-[30px] items-center gap-x-2 overflow-hidden',
            { 'cursor-pointer': handleElementClick }
          )}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            handleElementClick?.(firstElement);
          }}
        >
          {!hideAvatar && (
            <FloatingTooltip
              label={getFloatingTipLabel(firstElement)}
              placement="bottom"
              hide={!Boolean(getFloatingTipLabel(firstElement))}
            >
              <div className="flex">
                <Avatar
                  id={firstElement?.id || firstElement?.username}
                  resolution={elementsResolution}
                  src={
                    firstElement.avatar
                      ? firstElement.avatar
                      : getElementAvatar?.(firstElement) ?? ''
                  }
                  size={size}
                  rounded={rounded}
                  bordered
                  staticFile={staticFile}
                />
              </div>
            </FloatingTooltip>
          )}

          {!noLabel && (
            <Typography
              variant="small"
              overflow="truncate"
              weight="medium"
              className={labelClassName}
            >
              {getHeaderProfileName(firstElement)}
            </Typography>
          )}
        </div>
      ) : (
        <div
          className={classNames('flex flex-row', {
            'flex-nowrap': noWrap,
            'flex-wrap': !noWrap
          })}
        >
          {displayableElements.map((element, index) => (
            <div
              key={`circled-overlay-${index}`}
              className={classNames(
                index > 0 && 'ml-[-8px]',
                `w-[30px] z-[${index + 1}]`,
                {
                  'cursor-pointer': !!element.id
                }
              )}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleElementClick(element);
              }}
            >
              <FloatingTooltip
                label={getFloatingTipLabel(element)}
                placement="bottom"
                hide={!Boolean(getFloatingTipLabel(element))}
              >
                <div className="flex">
                  <Avatar
                    id={element?.id}
                    resolution={elementsResolution}
                    key={element.id}
                    src={element.avatar || getElementAvatar?.(element)}
                    size={size}
                    rounded={rounded}
                    roundedSize={roundedSize}
                    bordered
                    staticFile={staticFile}
                  />
                </div>
              </FloatingTooltip>
            </div>
          ))}
          {elements.length > required && !noMore && (
            <div
              className={classNames(
                'team-over flex items-center justify-center rounded-full border-2 border-white',
                'bg-white dark:border-neutral-700 dark:bg-neutral-800 dark:text-neutral-200',
                `w-[${size}px]`,
                `z-[${required + 1}]`,
                `h-[${size}px]`,
                { 'cursor-pointer': !!handleShowMore }
              )}
              onClick={handleShowMore}
            >
              <Typography variant="xsmall">
                +{elements.length - required}
              </Typography>
            </div>
          )}

          {AdditionalChild && <AdditionalChild />}
        </div>
      )}
    </div>
  );
};
