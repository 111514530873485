import { useCallback, useMemo } from 'react';
import { create } from 'zustand';
import { insertToArray, reorder } from '/utils/array';
import { fromBasisPointToPercentage } from '/utils/number';

/**
 * @dev This hook is used only in gallery/story/showcase new & edit pages.
 * All of gallery data would be stored in this store.
 *
 * @note Update this store value if gallery update websocket event handler.
 */
export const useGalleryForm = () => {
  const gallery = useGalleryFormStore(
    ({
      id,
      spaceId,
      title,
      description,
      galleryType,
      theme,
      customUrl,
      status,
      slug,
      curatorSplits,
      startDate,
      customData,
      Files
    }) => ({
      id,
      spaceId,
      title,
      description,
      galleryType,
      theme,
      customUrl,
      status,
      slug,
      curatorSplits,
      startDate,
      customData,
      Files
    })
  );
  const isInitialized = useGalleryFormStore(
    ({ isInitialized }) => isInitialized
  );
  const editingState = useGalleryFormStore(({ editingState }) => editingState);

  const initialize = useGalleryFormStore(({ initialize }) => initialize);

  const browserTabId = useGalleryFormStore(({ browserTabId }) => browserTabId);
  const setBrowserTabId = useGalleryFormStore(
    ({ setBrowserTabId }) => setBrowserTabId
  );

  /**
   * @description Set all of store states
   */
  const setState = useGalleryFormStore(({ setState }) => setState);

  /**
   * @description Set store states partially
   */
  const setPartialState = useGalleryFormStore(
    ({ setPartialState }) => setPartialState
  );
  const setEditingState = useGalleryFormStore(
    ({ setEditingState }) => setEditingState
  );
  const resetState = useGalleryFormStore(({ resetState }) => resetState);

  // gallery section
  const sections = useGalleryFormStore(({ sections }) => sections);
  const addSection = useGalleryFormStore(({ addSection }) => addSection);
  const removeSection = useGalleryFormStore(
    ({ removeSection }) => removeSection
  );

  // gallery section assets
  const assets = useMemo(
    () =>
      sections
        ?.map((section) => section.Assets)
        .filter(Boolean)
        .flat() ?? [],
    [sections]
  );

  const isAssetListed = useCallback(
    ({ assetId }) => {
      return (
        assets.findIndex((asset) => String(asset.id) === String(assetId)) > -1
      );
    },
    [assets]
  );

  const setSectionAssets = useGalleryFormStore(
    ({ setSectionAssets }) => setSectionAssets
  );
  const addAssetToSection = useGalleryFormStore(
    ({ addAssetToSection }) => addAssetToSection
  );
  const removeAssetFromSection = useGalleryFormStore(
    ({ removeAssetFromSection }) => removeAssetFromSection
  );
  const moveAssetToAnotherSection = useGalleryFormStore(
    ({ moveAssetToAnotherSection }) => moveAssetToAnotherSection
  );

  return useMemo(
    () => ({
      gallery,
      isInitialized,
      editingState,
      //
      /**
       * @deprecated Try use `setState` instead.
       */
      initialize,
      setState,
      setPartialState,
      setEditingState,
      resetState,
      //
      sections,
      addSection,
      removeSection,
      //
      assets,
      isAssetListed,
      setSectionAssets,
      addAssetToSection,
      removeAssetFromSection,
      moveAssetToAnotherSection,
      //
      browserTabId,
      setBrowserTabId
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      gallery,
      isInitialized,
      editingState,
      sections,
      assets,
      isAssetListed,
      browserTabId
    ]
  );
};

const useGalleryFormStore = create((set) => ({
  ...initialState,
  // actions
  setState: ({
    id,
    spaceId,
    galleryType,
    title,
    description,
    theme,
    customUrl,
    status,
    slug,
    curatorSplits,
    startDate,
    sections,
    customData,
    Files
  }) =>
    set({
      isInitialized: true,
      id,
      spaceId,
      galleryType,
      title,
      description,
      theme,
      customUrl,
      status,
      slug,
      curatorSplits,
      startDate,
      sections,
      customData,
      Files
    }),

  initialize: () => set({ isInitialized: true }),

  setPartialState: (data) => set(data),

  setEditingState: (editingState) => {
    set({ editingState });

    if (editingState === GALLERY_FORM_EDITING_STATE.SAVED) {
      setTimeout(() => {
        set({ editingState: GALLERY_FORM_EDITING_STATE.UNSAVED });
      }, 1000);
    }
  },
  setBrowserTabId: (browserTabId) => set({ browserTabId }),

  resetState: () => set(initialState),

  addSection: ({ section, where }) =>
    set(({ sections }) => ({
      sections: reorder(
        insertToArray({
          array: sections ?? [],
          item: section,
          where
        }),
        0,
        0
      )
    })),

  removeSection: (sectionId) =>
    set(({ sections }) => ({
      sections: sections.filter(
        (section) => String(section.id) !== String(sectionId)
      )
    })),

  setSectionAssets: ({ sectionId, assets }) =>
    set(({ sections }) => ({
      sections: sections.map((section) => {
        if (String(section.id) === String(sectionId)) {
          return {
            ...section,
            Assets: assets
          };
        }
        return section;
      })
    })),

  addAssetToSection: ({ sectionId, asset, where }) =>
    set(({ sections }) => ({
      sections: sections.map((section) => {
        if (String(section.id) === String(sectionId)) {
          const assets = section.Assets ?? [];
          return {
            ...section,
            Assets: reorderGalleryAssets({
              assets: insertToArray({
                array: assets,
                item: asset,
                where
              }),
              from: 0,
              to: 0
            })
          };
        }
        return section;
      })
    })),

  removeAssetFromSection: ({ sectionId, assetId }) =>
    set(({ sections }) => ({
      sections: sections.map((section) => {
        if (String(section.id) === String(sectionId)) {
          return {
            ...section,
            Assets:
              section?.Assets?.filter(
                ({ id }) => String(id) !== String(assetId)
              ) ?? []
          };
        }
        return section;
      })
    })),

  moveAssetInSection: ({ sectionId, from, to }) =>
    set(({ sections }) => ({
      sections: sections.map((section) => {
        if (String(sectionId) !== String(section.id)) return section;

        return {
          ...section,
          Assets: reorderGalleryAssets({
            assets: section?.Assets,
            from,
            to
          })
        };
      })
    })),

  moveAssetToAnotherSection: ({ fromSectionId, toSectionId, asset, where }) =>
    set(({ sections }) => {
      return {
        sections: sections.map((section) => {
          if (String(section.id) === String(fromSectionId)) {
            return {
              ...section,
              Assets: section?.Assets?.filter(
                ({ id }) => String(id) !== String(asset.id)
              )
            };
          }

          if (String(section.id) === String(toSectionId)) {
            const assets = section?.Assets ?? [];
            return {
              ...section,
              Assets: reorderGalleryAssets({
                assets: insertToArray({
                  array: assets,
                  item: asset,
                  where
                }),
                from: 0,
                to: 0
              })
            };
          }

          return section;
        })
      };
    })
}));

/**
 * @dev This will help you to access store in normal functions, not react component.
 * @note Remember this is different from `useGalleryForm`.
 * It means `getGalleryFormStore()` returns different values from `useGalleryForm()`.
 */
export const getGalleryFormStore = () => useGalleryFormStore.getState();

export const GALLERY_FORM_EDITING_STATE = {
  UNSAVED: 'unsaved',
  SAVED: 'saved',
  SAVING: 'saving',
  REDIRECTING: 'redirecting'
};

const initialState = {
  id: undefined,
  spaceId: undefined,
  galleryType: undefined,
  browserTabId: undefined,
  editingState: GALLERY_FORM_EDITING_STATE.UNSAVED,
  // details
  title: '',
  description: '',
  // settings
  theme: 'light',
  customUrl: '',
  status: 'unpublished',
  startDate: undefined,
  customData: undefined,
  // earnings
  curatorSplits: [],
  // sections
  sections: [],
  // flags
  isInitialized: false,
  Files: []
};

/**
 * @description Re-create the Asset.GallerySectionAsset.order
 */
const reorderGalleryAssets = ({ assets, from, to }) => {
  const updatedAssets = reorder(
    assets?.map((asset) => ({
      ...asset,
      order: asset.GallerySectionAsset?.order
    })) ?? [],
    from,
    to
  );

  return updatedAssets.map((asset) => ({
    ...asset,
    GallerySectionAsset: {
      ...(asset.GallerySectionAsset ?? {}),
      order: asset.order
    }
  }));
};

/**
 * Saving the single gallery data into store.
 * @note Try use this method after `getGallery` function call.
 *
 * @example
 *  getGallery({ galleryId }).then(res =>
 *    saveGalleryFormStateToStore(res.data.data)
 *  );
 */
export const saveGalleryFormStateToStore = (gallery) => {
  if (!gallery) return;

  const { setState } = getGalleryFormStore();

  setState({
    id: gallery.id,
    spaceId: gallery.Project?.id,
    galleryType: gallery.galleryType,
    title: gallery.title,
    description: gallery.description,
    theme: gallery.theme,
    status: gallery.status,
    customUrl: gallery.customUrl,
    slug: gallery.slug,
    curatorSplits:
      gallery.GalleryCuratorSplits?.map((split) => ({
        ...split,
        percentage: fromBasisPointToPercentage(Number(split.percentage))
      })) ?? [],
    startDate: gallery.startDate,
    sections: gallery.GallerySections ?? [],
    customData: gallery.customData,
    Files: gallery.Files
  });
};
