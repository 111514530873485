import React, { useMemo } from 'react';
import { ROUTES } from '/config/routes';

import { UserRow } from '/design-systems/Molecules/UserRow';

export const TokenOwners = ({
  tokenOwners,
  selectionEnabled,
  canSelectSelf = false,
  checkBoxName,
  onChange
}) => {
  const shouldSelectByDefault = useMemo(() => {
    return selectionEnabled && tokenOwners?.length === 1;
  }, [selectionEnabled, tokenOwners]);

  return (
    <div className="flex h-full w-full flex-col overflow-auto">
      {tokenOwners?.map((tokenOwner) => {
        const { ownerAddress, owner, WalletAddress, User, id } = tokenOwner;
        const user = User || WalletAddress?.User;

        return (
          <UserRow
            user={user}
            address={ownerAddress || owner}
            tokenOwner={tokenOwner}
            withHandle={false}
            onView={() => {
              !!user?.id &&
                window.open(
                  ROUTES.profile.view({
                    profileId: user?.username || ownerAddress || owner
                  }),
                  '_blank'
                );
            }}
            canSelectSelf={canSelectSelf}
            selectionEnabled={selectionEnabled}
            isSelectedByDefault={shouldSelectByDefault}
            checkBoxName={checkBoxName}
            onChange={onChange}
            className="gap-3"
          />
        );
      })}
    </div>
  );
};
