import React from 'react';
import { FloatingMenu } from '@tiptap/react';

import {
  EDITOR_HEADINGS,
  EDITOR_BULLET_LIST,
  EDITOR_IMAGE,
  EDITOR_VIDEO
} from '/config/editor';
import { Button } from '/design-systems/Atoms/Button';

const FloatMenu = ({ editor, onAction, hideImg, withVideo = false }) => {
  const MENU_BUTTONS = [
    ...EDITOR_BULLET_LIST,
    ...EDITOR_HEADINGS,
    ...(hideImg ? [] : EDITOR_IMAGE),
    ...(withVideo ? EDITOR_VIDEO : [])
  ];

  if (!editor) {
    return <></>;
  }

  return (
    <FloatingMenu
      shouldShow={false}
      className="editor-floating-menu flex rounded-lg bg-white p-1 drop-shadow-lg"
      editor={editor}
      tippyOptions={{ duration: 100, maxWidth: 500 }}
    >
      {MENU_BUTTONS.map(({ id, icon, params }, index) => (
        <Button
          key={index}
          type="button"
          color="neutral"
          variant="tertiary"
          iconOnly
          active={editor.isActive(id, params)}
          onClick={(e) => onAction(e, id, params)}
        >
          {icon}
        </Button>
      ))}
    </FloatingMenu>
  );
};

export default FloatMenu;
