import React from 'react';
import { Typography } from '../Typography';

export const FormCheckbox = ({ id, name, value, label, onChange, onBlur, error, touched }) => {
  return (
    <>
      <div className='mb-2'>
        <label className='group flex w-full cursor-pointer items-center gap-x-3'>
          <input
            type='checkbox'
            id={id}
            name={name}
            className='h-6 w-6 cursor-pointer accent-neutral-700 group-hover:border-black'
            checked={value}
            onChange={onChange}
            onBlur={onBlur}
          />
          <Typography variant='small' className='hover:text-neutral-800'>
            {label}
          </Typography>
        </label>
      </div>
      {touched && error && (
        <Typography color='#ef4444' variant='xsmall' gutterBottom>
          {error}
        </Typography>
      )}
    </>
  );
};
