import React from 'react';

import { ToggleButton } from '/design-systems/Atoms/ToggleButton';
import { FormCheckbox } from '/design-systems/Atoms/FormCheckbox';
import { InfiniteScroller } from '/design-systems/Organisms/InfiniteScroller';
import { Typography } from '/design-systems/Atoms/Typography';
import { Image } from '/design-systems/Atoms/Image';

import PlaceholderSVG from '/images/placeholder.svg';
import {
  getContributorImage,
  getContributorName,
  getImageProps,
  isContributorImageStatic
} from '/utils/prompt-submission';
import { CO_CREATE_PROMPTABLE_TYPES } from '/utils/constants';
import { falseIfEmpty } from '/utils/index';
import { getFirstAssetFiles } from '/utils/gallery';

export const SubmissionListItem = ({
  submission,
  selectedSubmissions,
  onSelectSubmission
}) => {
  return (
    <div
      id={`submission-${submission.id}`}
      className={`flex items-center ${onSelectSubmission && 'cursor-pointer'} winning-submission`}
      onClick={() => onSelectSubmission?.(submission.id)}
    >
      {onSelectSubmission && (
        <div className="ml-1 h-[32px] w-[50px]">
          <FormCheckbox value={!!selectedSubmissions?.[submission.id]} />
        </div>
      )}

      <div className="flex w-full items-center gap-4 overflow-hidden">
        {submission.Prompt?.promptableType !==
          CO_CREATE_PROMPTABLE_TYPES.GIVEAWAY && (
          <div className="h-[80px] w-[80px] min-w-[80px] overflow-hidden rounded-lg">
            <Image
              {...getImageProps(
                falseIfEmpty(submission?.Files) ||
                  falseIfEmpty(
                    getFirstAssetFiles(submission?.Gallery)?.[0]?.Files
                  ) ||
                  []
              )}
              resolution="m"
              className="h-full w-full"
              style={{ objectFit: 'cover' }}
            />
          </div>
        )}

        <div className="flex w-full flex-col gap-y-2 overflow-hidden">
          {submission.Prompt?.promptableType !==
            CO_CREATE_PROMPTABLE_TYPES.GIVEAWAY && (
            <Typography weight="medium" overflow="truncate" className="">
              {submission.title}
            </Typography>
          )}

          <div className="flex items-center gap-x-2">
            <Image
              src={getContributorImage(submission)}
              placeholder={PlaceholderSVG.src}
              alt="contributor-img"
              className="h-[24px] w-[24px] rounded-full"
              style={{ objectFit: 'cover' }}
              resolution="s"
              staticFile={isContributorImageStatic(submission)}
            />

            <Typography
              weight="semibold"
              colorVariant="secondary"
              className="truncate"
            >
              {getContributorName(submission)}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SubmissionList = ({
  submissions,
  selectedSubmissions,
  selectedSubmissionsCount,
  unit,
  onlyShortlisted,
  onNextPage,
  onSelectSubmission,
  onChangeFilters
}) => {
  return (
    <>
      <div>
        <ToggleButton
          label="Show only shortlisted"
          checked={onlyShortlisted}
          onChange={() =>
            onChangeFilters({ onlyShortlisted: !onlyShortlisted })
          }
        />
      </div>

      <div className="max-h-[360px] overflow-y-auto overflow-x-hidden 2xl:max-h-[480px]">
        {submissions?.length ? (
          <InfiniteScroller onViewMore={onNextPage}>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
              {submissions.map((submission) => (
                <SubmissionListItem
                  key={submission.id}
                  submission={submission}
                  selectedSubmissions={selectedSubmissions}
                  onSelectSubmission={onSelectSubmission}
                />
              ))}
            </div>
          </InfiniteScroller>
        ) : (
          <Typography align="center" className="my-4">
            No submissions to show
          </Typography>
        )}
      </div>

      <Typography
        className="mb-2"
        color={unit < selectedSubmissionsCount ? '#ef4444' : '#000'}
        variant="medium"
      >
        {selectedSubmissionsCount} / {unit} selected
      </Typography>
    </>
  );
};
