import React, { useEffect, useRef, useCallback } from 'react';
import { HorizontalScroll } from '/design-systems/Molecules/HorizontalScroll';
import { Spinner } from '/design-systems/Atoms/Spinner';

/**
 * @description Triggers the callback when the scrollbar is scrolled to the bottom of the browser window.
 *
 * @example
 * <InfiniteScroller loading={isLoading} onViewMore={handleViewMore}>
 *    <div>Your content</div>
 * </InfiniteScroller>
 *
 * @note If you implement infinite scrolling in an element with a specified height,
 *       use the <ScrollTrigger /> component instead.
 *
 */
export const InfiniteScroller = ({
  loading,
  onViewMore,
  horizontalScroller = false,
  children,
  className = ''
}) => {
  const loader = useRef(null);

  const handleObserver = useCallback(
    (entries) => {
      entries?.[0]?.isIntersecting && onViewMore?.();
    },
    [onViewMore]
  );

  useEffect(() => {
    const option = {
      rootMargin: '20px',
      threshold: 0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);

    return () => {
      loader.current && observer.unobserve(loader.current);
    };
  }, [onViewMore]);

  if (horizontalScroller) {
    return (
      <HorizontalScroll>
        {children}
        {loading && (
          <div className="my-4 flex w-full items-center justify-center">
            <Spinner />
          </div>
        )}
        <div ref={loader} />
      </HorizontalScroll>
    );
  }

  return (
    <div className={`flex h-full w-full flex-col gap-4 ${className}`}>
      {children}
      {loading && (
        <div className="my-4 flex w-full items-center justify-center">
          <Spinner />
        </div>
      )}
      <div ref={loader} />
    </div>
  );
};
