import React from 'react';
import { BubbleMenu } from '@tiptap/react';

import { EDITOR_TRANSFORMS, EDITOR_HEADINGS, EDITOR_BULLET_LIST, EDITOR_LINK } from '/config/editor';
import { Button } from '/design-systems/Atoms/Button';

const MENU_BUTTONS = [...EDITOR_TRANSFORMS, ...EDITOR_HEADINGS, ...EDITOR_BULLET_LIST, ...EDITOR_LINK];

const PopUpMenu = ({ editor, onAction }) => {
  if (!editor) return <></>;

  return (
    <BubbleMenu
      className='flex rounded-lg bg-white p-1 drop-shadow-lg'
      editor={editor}
      tippyOptions={{ duration: 100, maxWidth: 500 }}
    >
      {MENU_BUTTONS.map(({ id, icon, params }, index) => (
        <Button
          type='button'
          key={index}
          color='neutral'
          variant='tertiary'
          iconOnly
          active={editor.isActive(id, params)}
          onClick={(e) => onAction(e, id, params)}
        >
          {icon}
        </Button>
      ))}
    </BubbleMenu>
  );
};

export default PopUpMenu;
