import React from 'react';
import { Typography } from '/design-systems/Atoms/Typography';
import { mergeClassNames } from '/utils/string';

const FreeClaimDetails = ({
  classname = '',
  variant = 'medium',
  showAstriesk
}) => {
  const className = mergeClassNames('tracking-wide', classname);
  return (
    <div className="flex items-start">
      <Typography
        id="free-listing-title"
        heading
        variant={variant === 'large' ? 'h5' : 'medium'}
        weight={variant === 'small' ? 'normal' : 'semibold'}
        className={className}
      >
        Free
      </Typography>
      {showAstriesk && (
        <Typography variant="xsmall" colorVariant="secondary">
          *
        </Typography>
      )}
    </div>
  );
};

export default FreeClaimDetails;
