import { Contract } from './contractService';
import { getJoynMarketplaceContract } from '/utils/contracts';
import Abis from '/abis';

class JoynMarketplaceChainService {
  joynMarketplace;

  constructor(chainId) {
    this.joynMarketplace = new Contract(
      getJoynMarketplaceContract({ chainId }),
      Abis.JoynMarketplaceABI
    );
  }

  listItem({ tokenAddress, tokenId, price, fee }) {
    return this.joynMarketplace.write('listItem', [
      tokenAddress,
      tokenId,
      price,
      fee
    ]);
  }

  cancelListing({ tokenAddress, tokenId }) {
    return this.joynMarketplace.write('cancelListing', [tokenAddress, tokenId]);
  }

  updateListingMarketplaceFeeAndPrice({ tokenAddress, tokenId, fee, price }) {
    return this.joynMarketplace.write('.updateListingMarketplaceFeeAndPrice', [
      tokenAddress,
      tokenId,
      fee,
      price
    ]);
  }

  buyItem({ tokenAddress, tokenId, referrerAddress, price }) {
    return this.joynMarketplace.write(
      'buyItem',
      [tokenAddress, tokenId, referrerAddress],
      price
    );
  }

  getListing({ tokenAddress, tokenId }) {
    return this.joynMarketplace.read('getListing', [tokenAddress, tokenId]);
  }
}

export default JoynMarketplaceChainService;
