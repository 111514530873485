import { CHAIN_IDS } from './web3';
import { compareStringsIgnoreCase } from './string';

/**
 * Do not import anything from './constants.js'.
 * constants.js is importing this file.
 */

export const WRAPPED_NATIVE_TOKEN_CONTRACT = {
  [CHAIN_IDS.mainnet]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  [CHAIN_IDS.sepolia]: '0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9',
  [CHAIN_IDS.optimism]: '0x4200000000000000000000000000000000000006',
  [CHAIN_IDS.optimismGoerli]: '0x4200000000000000000000000000000000000006'
};

export const SEAPORT_MARKETPLACE_CONTRACT =
  '0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC';

export const CONDUIT_ADDRESS = {
  [CHAIN_IDS.mainnet]: '0x4a49e91a45Cb6cc4E083e86eD3A6d95fe7384461',
  [CHAIN_IDS.sepolia]: '0xE2820866420D74767a5Ac63A02982ce94534b987',
  [CHAIN_IDS.optimism]: '0x1E0049783F008A0085193E00003D00cd54003c71',
  [CHAIN_IDS.optimismGoerli]: '0xE2820866420D74767a5Ac63A02982ce94534b987'
};

export const CONDUIT_KEY = {
  [CHAIN_IDS.mainnet]:
    '0x263C993863204130DadFAF0d8926FF32Ea52dAE289469d60bf61720dcb9e4821',
  [CHAIN_IDS.sepolia]:
    '0x72D02052b1E2cE498A926ef2cB22D14820B7a99F99469d60bf61720dcb9e4820',
  [CHAIN_IDS.optimism]:
    '0x0000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f0000',
  [CHAIN_IDS.optimismGoerli]:
    '0x72D02052b1E2cE498A926ef2cB22D14820B7a99F99469d60bf61720dcb9e4820'
};

export const FOUNDATION_MARKETPLACE_CONTRACT = {
  [CHAIN_IDS.mainnet]: '0xcDA72070E455bb31C7690a170224Ce43623d0B6f',
  [CHAIN_IDS.sepolia]: '0xaa100831f6bd51C7EA6c95f389f61271F7E11154',
  [CHAIN_IDS.optimism]: '',
  [CHAIN_IDS.optimismGoerli]: ''
};

export const JOYN_MARKETPLACE_CONTRACT = {
  [CHAIN_IDS.mainnet]: '0x91116aEFa229f879088f49296F743E536A8e877b',
  [CHAIN_IDS.sepolia]: '0x806cb4a5151d2263B4F625a869fFA8C4737Eee23',
  [CHAIN_IDS.optimism]: '',
  [CHAIN_IDS.optimismGoerli]: ''
};

export const JOYN_ASSET_CONTRACT = {
  [CHAIN_IDS.mainnet]: '0x4A6979F530677d2E5b10fE1CD85b532FA548A606',
  [CHAIN_IDS.sepolia]: '0x5b7698e3FDc79e55fFe27d641A4080834fC12799',
  [CHAIN_IDS.optimism]: '',
  [CHAIN_IDS.optimismGoerli]: ''
};

export const ROYALTY_ENGINE_ADDRESS = {
  [CHAIN_IDS.mainnet]: '0x0385603ab55642cb4Dd5De3aE9e306809991804f',
  [CHAIN_IDS.sepolia]: '0xEF770dFb6D5620977213f55f99bfd781D04BBE15',
  [CHAIN_IDS.optimism]: '0xEF770dFb6D5620977213f55f99bfd781D04BBE15',
  [CHAIN_IDS.optimismGoerli]: '0xEF770dFb6D5620977213f55f99bfd781D04BBE15'
};

export const JOYN_ASSET_SHARED_CONTRACT = {
  [CHAIN_IDS.mainnet]: '0x1ED731fa3F923C8A8432592dD753D80BFeB6Cd27',
  [CHAIN_IDS.sepolia]: '0x52a7f5A63A677632b080c36A34Bf883c7E5EA9E6',
  [CHAIN_IDS.optimism]: '',
  [CHAIN_IDS.optimismGoerli]: ''
};

export const FEE_ZONE_CONTRACT = {
  [CHAIN_IDS.mainnet]: '0xd5F14558B239FB9170025cE97940C3Ee6cE49598',
  [CHAIN_IDS.sepolia]: '0x9d6f7147975dEDE6251711B3F433E84BE231195D',
  [CHAIN_IDS.optimism]: '',
  [CHAIN_IDS.optimismGoerli]: ''
};

export const WRAPPED_NATIVE_TOKENS = [
  {
    value: (chainId) => WRAPPED_NATIVE_TOKEN_CONTRACT[chainId],
    title: 'WETH'
  }
];

export const getJoynAssetContract = ({ chainId }) =>
  JOYN_ASSET_CONTRACT?.[chainId];
export const getJoynMarketplaceContract = ({ chainId }) =>
  JOYN_MARKETPLACE_CONTRACT?.[chainId];
export const getFoundationMarketplaceContract = ({ chainId }) =>
  FOUNDATION_MARKETPLACE_CONTRACT?.[chainId];
export const getWrappedNativeTokenContractAddress = ({ chainId }) =>
  WRAPPED_NATIVE_TOKEN_CONTRACT?.[chainId];
export const DEFAULT_SEAPORT_VERSION = '1.5';

export const getConduitAddress = ({ chainId }) => CONDUIT_ADDRESS?.[chainId];
export const getConduitKey = ({ chainId }) => CONDUIT_KEY?.[chainId];

export const isJoynContract = (chainId, address) => {
  return compareStringsIgnoreCase(address, getJoynAssetContract({ chainId }));
};

export const getCurrencies = (chainId) =>
  WRAPPED_NATIVE_TOKENS.map(({ value, title }) => {
    return {
      value: value(chainId),
      title
    };
  });
