import React from 'react';
import { Button } from '/design-systems/Atoms/Button';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';

export const VerifyAccountModal = ({ message, open, onClose, onVerify }) => {
  return (
    <Modal maxWidth={582} open={open} autoHeight showCloseIconOnTop onClose={onClose} className='text-center'>
      <div className='flex flex-col gap-y-3'>
        <Typography variant='h3' heading align='center' weight='semibold'>
          Verification required
        </Typography>

        <Typography>{message}</Typography>

        <div className='flex flex-col gap-y-4'>
          <Typography variant='medium' colorVariant='secondary'>
            To prevent abuse, only verified accounts can perform this action.
          </Typography>
          <Typography variant='medium' colorVariant='secondary'>
            Either connect your Twitter account or verify your email.
          </Typography>
          <Typography variant='medium' colorVariant='secondary'>
            If you have already verified, please refresh this page.
          </Typography>

          <div className='flex justify-center'>
            <Button variant='primary' color='black' onClick={onVerify}>
              Verify account
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
