import React from 'react';

export const WalletIcon = ({ className = '', color = '#111827' } = {}) => {
  return (
    <svg
      width='14'
      height='12'
      viewBox='0 0 14 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M12.3335 0H2.3335C1.23083 0 0.333496 0.897333 0.333496 2V10C0.333496 11.1027 1.23083 12 2.3335 12H12.3335C13.0688 12 13.6668 11.402 13.6668 10.6667V1.33333C13.6668 0.598 13.0688 0 12.3335 0ZM2.3335 10.6667C1.9655 10.6667 1.66683 10.3673 1.66683 10V2C1.66683 1.63267 1.9655 1.33333 2.3335 1.33333H12.3335V3.33333H8.3335C7.59816 3.33333 7.00016 3.93133 7.00016 4.66667V7.33333C7.00016 8.06867 7.59816 8.66667 8.3335 8.66667H12.3342V10.6667H2.3335ZM12.3335 4.66667V7.33333H8.3335V4.66667H12.3335Z'
        fill={color}
      />
    </svg>
  );
};
