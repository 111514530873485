import React, { createContext, useContext, useState } from 'react';

const GlobalContext = createContext({
  isSidebarOpen: false,
  connectorInProcess: undefined
});

export const useGlobal = () => useContext(GlobalContext);

export const GlobalManager = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [connectorInProcess, setConnectorInProcess] = useState();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <GlobalContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        closeSidebar,
        connectorInProcess,
        setConnectorInProcess
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
