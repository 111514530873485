import React, { useCallback, useState } from 'react';
import { useRouter } from 'next/router';

import { ModalTitleRow } from '/design-systems/Molecules/Modals/ModalTitleRow';
import { Divider } from '/design-systems/Atoms/Divider';
import { Modal } from '/design-systems/Atoms/Modal';
import { Button } from '/design-systems/Atoms/Button';
import { TemplateCard } from '../../Cards/TemplateCard';
import { ROUTES } from '/config/routes';

export const ArtPlusModal = ({
  open,
  modalTitle = 'New Art+ piece',
  newPiecePath = `${ROUTES.asset.new()}?withNarrative=true`,
  newPieceDescription = 'Upload a new artwork.',
  existingPiecePath = ROUTES.asset.myAssets(),
  existingPieceDescription = 'Add narrative to an existing artwork or NFT.',
  onClose
}) => {
  const router = useRouter();

  const [selectedTemplate, setSelectedTemplate] = useState(
    ART_PLUS_ACTIONS[0].id
  );

  const handleNext = useCallback(() => {
    let path;
    switch (selectedTemplate) {
      case ART_PLUS_ACTION_IDS.CREATE:
        path = newPiecePath;
        break;
      case ART_PLUS_ACTION_IDS.USE_EXISTING:
        path = existingPiecePath;
        break;
      default:
        return;
    }

    router.push(path);
    onClose();
  }, [router, selectedTemplate, onClose]);

  const getDescription = (id) => {
    if (id === ART_PLUS_ACTION_IDS.USE_EXISTING) {
      return existingPieceDescription;
    } else if (id === ART_PLUS_ACTION_IDS.CREATE) {
      return newPieceDescription;
    } else {
      return '';
    }
  };

  return (
    <Modal
      id="create-art-piece-modal"
      title={modalTitle}
      maxWidth={582}
      open={open}
      onClose={onClose}
      autoHeight
    >
      <ModalTitleRow title={modalTitle} onClose={onClose} />

      <Divider size="medium" />

      <div className="flex flex-col gap-4">
        {ART_PLUS_ACTIONS.map(({ id, title }) => (
          <TemplateCard
            key={id}
            id={id}
            title={title}
            description={getDescription(id)}
            selectedTemplate={selectedTemplate}
            onClick={() => setSelectedTemplate(id)}
          />
        ))}

        <Button
          id="art-plus-next-button"
          color="black"
          variant="primary"
          disabled={!selectedTemplate}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </Modal>
  );
};

const ART_PLUS_ACTION_IDS = {
  CREATE: 'art-plus-create-new-piece',
  USE_EXISTING: 'art-plus-use-existing-piece'
};

const ART_PLUS_ACTIONS = [
  {
    id: ART_PLUS_ACTION_IDS.USE_EXISTING,
    title: 'Use an existing piece'
  },
  {
    id: ART_PLUS_ACTION_IDS.CREATE,
    title: 'Create new'
  }
];
