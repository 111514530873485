import SocketIoClient from 'socket.io-client';

const WS_BASE_URL = process.env.NEXT_PUBLIC_JOYN_SOCKET_URL;

class JOYNWebSocket {
  socket;

  constructor() {
    const accessToken = localStorage.getItem('jwtToken');
    this.socket = new SocketIoClient(WS_BASE_URL, {
      extraHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }
}

export default JOYNWebSocket;
