import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useRef
} from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import XCircleIcon from '@heroicons/react/outline/XCircleIcon';
import { useShallowState } from '/hooks/shallowState';
import { Image } from '/design-systems/Atoms/Image';

export const FullScreenManagerContext = createContext({});

export const useFullScreenManager = () => useContext(FullScreenManagerContext);

export const FullScreenManager = ({ children }) => {
  const fullScreenHandle = useFullScreenHandle();
  const [state, setState] = useShallowState({
    showFullScreen: false,
    file: undefined
  });
  // State to store the scroll position when entering fullscreen
  const scrollPositionRef = useRef(0);

  useEffect(() => {
    function handleFullscreenChange(event) {
      if (!document.fullscreenElement) {
        // We have exited full screen. Scroll to the previously captured position.
        window.scrollTo(0, scrollPositionRef.current);
      }
    }

    // Add the event listener when the component mounts.
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    // Return a cleanup function to remove the event listener when the component unmounts.
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []); // No dependencies here since refs don't cause re-renders.

  const handleFullScreenClick = useCallback(
    async (file) => {
      if (state.showFullScreen) {
        await fullScreenHandle.exit();
      } else {
        // Capture the scroll position just before entering fullscreen mode.
        scrollPositionRef.current = window.scrollY;
        await fullScreenHandle.enter();
      }
      setState({ file });
    },
    [state.showFullScreen, fullScreenHandle]
  );

  const handleFreeScreenChange = useCallback(
    (state) => {
      if (state === true) {
        setState({ showFullScreen: true });
      } else {
        setState({ showFullScreen: false });
      }
    },
    [fullScreenHandle]
  );

  return (
    <FullScreenManagerContext.Provider
      value={{
        onShowFullScreen: handleFullScreenClick
      }}
    >
      {children}
      <FullScreen handle={fullScreenHandle} onChange={handleFreeScreenChange}>
        {state.showFullScreen && (
          <>
            <Image
              src={state.file?.fileUrl}
              fileType={state.file?.fileType}
              staticFile={state.file?.staticFile}
              className="flex h-full w-full"
              style={{ objectFit: 'contain' }}
              resolution="xl"
              videoControls={true}
            />
            <div className="absolute right-4 bottom-4">
              <div
                className="cursor-pointer rounded-full p-2 hover:bg-neutral-700"
                onClick={handleFullScreenClick}
              >
                <XCircleIcon className="h-6 w-6 text-neutral-200" />
              </div>
            </div>
          </>
        )}
      </FullScreen>
    </FullScreenManagerContext.Provider>
  );
};
