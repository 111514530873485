import React from 'react';

import { ArrowsExpandIcon } from '@heroicons/react/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/pro-regular-svg-icons';

export const FullScreenButton = ({ onShowFullScreen }) => {
  return (
    <div className="absolute right-4 bottom-4">
      <div
        className="flex cursor-pointer justify-center rounded-full bg-white/60 p-2 hover:bg-white/70 dark:bg-white/20 dark:hover:bg-white/30"
        onClick={onShowFullScreen}
      >
        <FontAwesomeIcon
          icon={faExpand}
          className="h-6 w-6 text-neutral-900 dark:text-neutral-300"
        />
      </div>
    </div>
  );
};
