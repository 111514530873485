import React, { useEffect, useCallback } from 'react';
import { Typography } from '../Typography';
import XIcon from '@heroicons/react/outline/XIcon';
import { mergeClassNames } from '/utils/string';
import { ZIndexClassnames } from '/utils/classnames';

export const Modal = ({
  id = 'modal',
  open,
  title,
  description,
  children,
  autoHeight,
  maxWidth = 664,
  showCloseIconOnTop = false,
  onClose,
  background,
  padding,
  innerContainerClassName = '',
  className,
  wrapperClassName,
  closeIconClassName,
  closeBtnClassName,
  customWrapper = false,
  customContainer = false,
  isTop = false
}) => {
  const handleClose = useCallback(() => {
    document.body.classList.remove('modal-open');
    onClose?.();
  }, [onClose]);

  const handleClick = (event) => {
    const name =
      event.target.attributes &&
      event.target.attributes.name &&
      event.target.attributes.name.value;
    if (name === 'modal-wrapper') {
      handleClose();
    }
  };

  const handleEscKeyPress = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    // Add the event listener for "Escape" key press
    if (open) {
      document.addEventListener('keydown', handleEscKeyPress);
    } else {
      document.removeEventListener('keydown', handleEscKeyPress);
    }

    // Clean up when the component is unmounted or when `open` prop changes
    return () => {
      document.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [open, handleEscKeyPress]);

  if (!open) return <></>;

  return (
    <div
      id={id}
      name="modal-wrapper"
      className={mergeClassNames(
        !customWrapper && 'modal-wrapper',
        'fixed left-0 top-0 flex h-screen w-screen items-center justify-center',
        isTop ? ZIndexClassnames.modalTop : ZIndexClassnames.modal,
        wrapperClassName
      )}
      onClick={handleClick}
    >
      {showCloseIconOnTop && onClose && (
        <button
          type="button"
          className={
            closeBtnClassName || 'absolute left-4 top-4 h-6 w-6 cursor-pointer'
          }
          onClick={handleClose}
        >
          <XIcon
            className={closeIconClassName || 'h-[24px] w-[24px] stroke-[#fff]'}
          />
        </button>
      )}
      <div
        className={`${
          !customContainer && 'modal-container'
        } w-full overflow-y-auto max-w-[${maxWidth}px] ${
          !autoHeight && 'h-full'
        } transition-all duration-300`}
      >
        <div
          className={`modal-innerContainer h-full w-full overflow-auto rounded-xl shadow shadow-md ${
            padding || 'p-6'
          } ${
            background || 'bg-white dark:bg-neutral-950'
          } flex flex-col justify-between gap-y-4 ${innerContainerClassName}`}
        >
          {title && description && (
            <div className="modal-header relative py-2">
              <div className="mb-2">
                <Typography
                  weight="semibold"
                  variant="h4"
                  heading
                  align="center"
                >
                  {title}
                </Typography>
              </div>
              <Typography align="center" colorVariant="secondary">
                {description}
              </Typography>
            </div>
          )}
          {!showCloseIconOnTop && (
            <div
              className="absolute left-0 top-0 h-6 w-6 cursor-pointer"
              onClick={() => onClose?.()}
            >
              <XIcon className="h-[24px] w-[24px] stroke-[#111827]" />
            </div>
          )}
          <div
            className={`modal-body overflow-auto md:overflow-visible ${className}`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
