import React from 'react';
import { Typography } from '../Typography';
import { ProgressBar } from '../ProgressBar';
import { XIcon } from '@heroicons/react/outline';
import OtherBackSVG from '/images/other-back.svg';
import { isImageVideo } from '/utils/file';
import { Button } from '../Button';
import { Image } from '../Image';

export const File = ({ uploading, progress, image, onRemove, error }) => {
  const handleRemove = () => {
    !uploading && onRemove?.();
  };

  if (uploading) {
    return (
      <div className="flex animate-pulse flex-col">
        <div className="h-[120px] w-[120px] rounded-xl bg-neutral-200"></div>

        <div className="my-2">
          <ProgressBar value={progress} />
        </div>
      </div>
    );
  }

  const renderFile = () => {
    const fileType = image.filetype || image.fileType;
    if (!isImageVideo(fileType)) {
      return (
        <Image
          src={OtherBackSVG.src}
          fileType={fileType}
          className="h-full w-full max-w-[120px] rounded-xl object-cover"
          staticFile
        />
      );
    }

    return (
      <Image
        src={image.url}
        fileType={fileType}
        resolution="s"
        className="h-full w-full max-w-[120px] rounded-xl object-cover"
      />
    );
  };

  return (
    <div className="relative flex flex-col">
      <div className="h-[120px] min-w-[120px]">
        {image.url && renderFile()}
        {error && (
          <Typography variant="xsmall" color="red">
            Oops! Upload failed
          </Typography>
        )}
      </div>

      {/* remove button */}
      <div className="absolute top-1 right-1">
        <Button
          type="button"
          variant="custom"
          color="custom"
          iconOnly
          rounded
          onClick={handleRemove}
          className="remove-file-button h-fit w-fit bg-white/60 p-1"
        >
          <XIcon className="h-[16px] w-[16px] stroke-[#4B5563]" />
        </Button>
      </div>
    </div>
  );
};
