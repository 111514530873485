import React from 'react';
import { cloneElement, useState } from 'react';
import {
  Placement,
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss
} from '@floating-ui/react-dom-interactions';
import { mergeClassNames } from '/utils/string';

export const FloatingTooltip = ({
  children,
  label,
  placement = 'right',
  hide = false,
  variant = 'default',
  delay = { open: 0, close: 0 },
  className = '',
  widthClassName
}) => {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [offset(5), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { delay }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context)
  ]);

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({ ref: reference, ...children.props })
      )}
      {open && !hide && (
        <div
          {...getFloatingProps({
            ref: floating,
            className: mergeClassNames(
              variant === 'default' &&
                'Tooltip bg-neutral-700 text-white p-2 rounded text-sm',
              widthClassName || 'min-w-[160px] max-w-[400px]',
              'z-50',
              className
            ),
            style: {
              position: strategy,
              top: y ?? 0,
              left: x ?? 0
            }
          })}
        >
          {label}
        </div>
      )}
    </>
  );
};
