import React, { useCallback, useMemo } from 'react';
import { ExternalLinkIcon, XIcon } from '@heroicons/react/outline';
import { openLink } from '/utils/url';
import { Divider } from '/design-systems/Atoms/Divider';
import { Typography } from '/design-systems/Atoms/Typography';
import { Modal } from '/design-systems/Atoms/Modal';
import { TokenOwners } from '/design-systems/Molecules/TokenOwners';
import { ModalTitleRow } from '/design-systems/Molecules/Modals/ModalTitleRow';

export const OwnerListModal = ({
  open,
  onClose,
  tokenOwners,
  tokenAddress
}) => {
  const handleViewOnEtherscan = useCallback(
    (e) => {
      e.stopPropagation();
      openLink(`https://etherscan.io/token/${tokenAddress}#balances`);
    },
    [tokenAddress]
  );

  return (
    <Modal
      id="owner-list-modal"
      open={open}
      onClose={onClose}
      className="relative"
      isTop={true}
    >
      <ModalTitleRow title="Owners" onClose={onClose} />
      <div className="flex h-full flex-col items-center justify-center">
        <Divider size="small" />

        <TokenOwners tokenOwners={tokenOwners} />

        <div
          className="mx-auto flex cursor-pointer items-center gap-x-1 py-4"
          onClick={handleViewOnEtherscan}
        >
          <Typography variant="small" link>
            View more on Etherscan
          </Typography>
          <ExternalLinkIcon className="h-4 w-4" />
        </div>
      </div>
    </Modal>
  );
};
