import React, { useMemo } from 'react';
import { mergeClassNames } from '/utils/string';

export const PillBadge = ({
  id,
  icon,
  variant,
  text,
  textSize,
  transparent,
  className
}) => {
  const bgColor = useMemo(() => {
    switch (variant) {
      case 'outlined':
        return transparent
          ? 'bg-black/20'
          : 'bg-white border border-neutral-200 dark:bg-neutral-900 dark:border-neutral-800 text-neutral-700 dark:text-neutral-300';
      case 'gray':
        return transparent ? 'bg-black/20' : 'bg-neutral-100';
      case 'red':
        return transparent ? 'bg-red-900/20' : 'bg-red-100';
      case 'green':
        return transparent ? 'bg-green-900/20' : 'bg-green-100';
    }
  }, [variant, transparent]);

  const textColor = useMemo(() => {
    if (transparent) return 'text-white';

    return `text-${variant}-800`;
  }, [variant, transparent]);

  return (
    <span
      id={id}
      className={mergeClassNames(
        bgColor,
        textColor,
        `${textSize} mr-2 rounded-full px-2 py-1.5 font-medium dark:bg-${variant}-800 dark:text-${variant}-300 w-fit`,
        'flex items-center gap-1',
        className
      )}
    >
      {icon}
      {text}
    </span>
  );
};
