import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import {
  PROMPT_REWARD_TYPES,
  REWARD_TYPE_SELECTIONS,
  WINNER_SELECTIONS
} from '/utils/constants';
import { Typography } from '/design-systems/Atoms/Typography';
import { FormInput } from '/design-systems/Atoms/FormInput';
import { FormTextArea } from '/design-systems/Atoms/FormTextArea';
import { DropDownInput } from '/design-systems/Atoms/DropDownInput';
import { Button } from '/design-systems/Atoms/Button';
import { FileUploader } from '/design-systems/Organisms/FileUploader';

const REWARD_WITH_NUM_WINNERS = [
  PROMPT_REWARD_TYPES.eth,
  PROMPT_REWARD_TYPES.other,
  PROMPT_REWARD_TYPES.exhibition
];

export const RewardForm = ({
  initialFormValues,
  initialFormFiles,
  onSubmit
}) => {
  const [formValues, setFormValues] = useState({});
  const [formTouches, setFormTouches] = useState({});
  const [files, setFiles] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const [filesError, setFilesError] = useState('');

  const withSupply = formValues?.rewardTypeId === PROMPT_REWARD_TYPES.pop;

  const withNumWinners =
    formValues?.rewardTypeId &&
    REWARD_WITH_NUM_WINNERS.includes(formValues.rewardTypeId);

  const withWinnerSelection =
    formValues?.rewardTypeId &&
    REWARD_WITH_NUM_WINNERS.includes(formValues.rewardTypeId);

  const withRewardAmount = formValues?.rewardTypeId === PROMPT_REWARD_TYPES.eth;

  const withImageUpload = formValues?.rewardTypeId === PROMPT_REWARD_TYPES.pop;

  const handleChangeRewardTypeId = (rewardTypeId) => {
    setFormValues({ rewardTypeId });
  };

  const handleUpload = (imageList) => {
    if (imageList?.length > 2) {
      setFiles([imageList[imageList.length - 1]]);
    } else setFiles(imageList);

    if (!imageList.length) {
      setFilesError('This is a required field');
    } else {
      setFilesError('');
    }
  };

  useEffect(() => {
    setFormValues(initialFormValues || {});
  }, [initialFormValues]);

  useEffect(() => {
    setFiles(initialFormFiles || []);
  }, [initialFormFiles]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formValues}
      initialTouched={formTouches}
      validate={(values) => {
        const errors = {};

        if (
          !values.title &&
          values.rewardTypeId === PROMPT_REWARD_TYPES.other
        ) {
          errors.title = 'This is a required field';
        }

        if (withSupply && values.unit && values.unit <= 0) {
          errors.unit = 'This should be greater than 0';
        }

        if (withNumWinners) {
          if (!values.unit) {
            errors.unit = 'This is a required field';
          } else if (values.unit <= 0) {
            errors.unit = 'This should be greater than 0';
          }
        }

        if (withRewardAmount) {
          if (!values.rewardAmount) {
            errors.rewardAmount = 'This is a required field';
          } else if (values.rewardAmount <= 0) {
            errors.rewardAmount = 'This should be greater than 0';
          }
        }

        if (!values.rewardTypeId) {
          errors.rewardTypeId = 'This is a required field';
        }

        if (withWinnerSelection && !values.winnerSelectionTypeId) {
          errors.winnerSelectionTypeId = 'This is a required field';
        }

        return errors;
      }}
      onSubmit={async (values) => {
        const payload = values;
        if (withImageUpload) {
          if (!files?.length) {
            setFilesError('This is a required field');
            return;
          }
          setFilesError('');

          const file = files[0];
          payload.file = {
            url: file.url,
            fileName: file.fileName,
            type: file.type,
            size: file.size
          };
        }
        onSubmit(payload);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => {
        const handleSave = (e) => {
          e.preventDefault();

          setFormTouches({
            title: true,
            unit: true,
            rewardAmount: true
          });
          handleSubmit(e);
        };

        return (
          <form>
            <div className="mb-4">
              <Typography className="mb-2" weight="medium" variant="medium">
                Reward type
              </Typography>
              <DropDownInput
                id={`reward-type-select`}
                placeholder="Select reward type"
                options={REWARD_TYPE_SELECTIONS}
                value={values.rewardTypeId}
                error={errors.rewardTypeId}
                touched={touched.rewardTypeId}
                onChange={handleChangeRewardTypeId}
              />
            </div>

            <div className="mb-2">
              <div className="mb-2 flex gap-x-1">
                <Typography weight="medium" variant="medium">
                  Title
                </Typography>
                <Typography variant="medium" colorVariant="secondary">
                  {values.rewardTypeId === PROMPT_REWARD_TYPES.other ||
                  values.rewardTypeId === PROMPT_REWARD_TYPES.pop
                    ? ''
                    : ' (optional)'}
                </Typography>
              </div>
              <FormInput
                id="reward-form-title-input"
                type="text"
                name="title"
                placeholder={
                  values.rewardTypeId === PROMPT_REWARD_TYPES.pop
                    ? 'e.g. Cryptopunk'
                    : 'e.g. 1st place'
                }
                value={values.title}
                error={errors.title}
                touched={touched.title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div className="mb-2">
              <div className="mb-1 flex gap-x-1">
                <Typography weight="medium" variant="medium">
                  Description
                </Typography>
                <Typography variant="medium" colorVariant="secondary">
                  (optional)
                </Typography>
              </div>
              <Typography colorVariant="secondary" className="mb-2">
                Describe the reward and how it will be distributed. This will be
                displayed as a tooltip.
              </Typography>
              <FormTextArea
                id="reward-form-description-input"
                name="description"
                placeholder="e.g. Winners will receive this reward"
                rows={3}
                value={values.description}
                error={errors.description}
                touched={touched.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            {(withSupply || withNumWinners) && (
              <div className="mb-2">
                <Typography weight="medium" variant="medium">
                  {withSupply ? 'Supply (optional)' : 'Number of winners'}
                </Typography>
                <Typography colorVariant="secondary" className="mb-2">
                  {withSupply
                    ? `How many POPs will you need? If you're unsure or would like every qualified submission to get one, simply leave this
              blank.`
                    : `How many winners are there in this reward tier?`}
                </Typography>
                <FormInput
                  id="reward-form-number-of-winners-input"
                  type="number"
                  name="unit"
                  placeholder="10"
                  value={values.unit}
                  error={errors.unit}
                  touched={touched.unit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            )}

            {withWinnerSelection && (
              <div className="mb-2">
                <Typography weight="medium" variant="medium" className="mb-2">
                  How are the winners selected?
                </Typography>
                <DropDownInput
                  id="reward-winner-selection"
                  placeholder="Winner selection"
                  options={WINNER_SELECTIONS}
                  value={values.winnerSelectionTypeId}
                  error={errors.winnerSelectionTypeId}
                  onChange={(value) =>
                    handleChange({
                      target: {
                        name: 'winnerSelectionTypeId',
                        value
                      }
                    })
                  }
                />
              </div>
            )}

            {withRewardAmount && (
              <div className="mb-2">
                <Typography weight="medium" variant="medium" className="mb-2">
                  Amount in ETH
                </Typography>
                <Typography colorVariant="secondary" className="mb-2">
                  ETH amount to reward per winner in this reward tier.
                </Typography>
                <FormInput
                  id="reward-form-reward-amount-input"
                  type="number"
                  name="rewardAmount"
                  placeholder="0.1"
                  value={values.rewardAmount}
                  error={errors.rewardAmount}
                  touched={touched.rewardAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            )}

            {withImageUpload && (
              <div className="mb-2">
                <Typography weight="medium" variant="medium" className="mb-2">
                  Image
                </Typography>
                {withSupply && (
                  <Typography colorVariant="secondary" className="mb-2">
                    The image for the POP NFT.
                  </Typography>
                )}
                <FileUploader
                  accepts={['image/*', 'video/*']}
                  entity="prompt-reward"
                  keyName="default"
                  existingFiles={files}
                  onChange={handleUpload}
                  setUploading={setUploading}
                />
                {filesError && (
                  <Typography color="#ef4444" variant="xsmall" className="mb-4">
                    {filesError}
                  </Typography>
                )}
              </div>
            )}

            <Button
              blocked
              submit
              color="black"
              variant="primary"
              loading={isUploading}
              disabled={isUploading}
              onClick={handleSave}
              id="project-contest-reward-save-button"
            >
              Save
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
