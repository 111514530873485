import React from 'react';

import { HyperlinkWrapper } from '/design-systems/Organisms/HyperlinkWrapper';
import { UserRow } from '/design-systems/Molecules/UserRow';

import { getProfileRoute } from '/utils/user';

export const UserRows = ({ users, onView }) => {
  return (
    <>
      {users.map((user) => (
        <HyperlinkWrapper
          key={user.id}
          disabled={!user.id}
          url={getProfileRoute(user)}
        >
          <UserRow user={user} onView={onView} className="gap-3" />
        </HyperlinkWrapper>
      ))}
    </>
  );
};
