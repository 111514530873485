import React from 'react';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Divider } from '/design-systems/Atoms/Divider';
import { Button } from '/design-systems/Atoms/Button';
import { ModalTitleRow } from '/design-systems/Molecules/Modals/ModalTitleRow';

export const AuctionAgreementModal = ({ open, onClose, onAgree }) => (
  <Modal
    id="auction-agreement-modal"
    open={open}
    padding="p-6"
    onClose={onClose}
    innerContainerClassName="h-auto"
    isTop={true}
  >
    <div className="flex flex-col gap-6">
      <ModalTitleRow title="Auction terms" onClose={onClose} />

      <Divider size="none" />

      <div className="flex flex-col gap-4">
        <Typography variant="medium" weight="normal">
          "Auction" is a beta feature. Remember:
        </Typography>

        <Typography variant="medium" weight="normal" className="flex">
          <ul className="ml-6 flex list-disc flex-col gap-4">
            <li>
              <span className="font-semibold">Accept one of the bids</span>
              &nbsp;within 48 hours after the auction ends.
            </li>
            <li>
              <span className="font-semibold">
                Keep the listed NFT in your wallet.
              </span>
              &nbsp;Don't transfer or list elsewhere during this time.
            </li>
            <li>Joyn does not lock up a collector's funds when making bids.</li>
          </ul>
        </Typography>
      </div>

      <div className="flex flex-col gap-y-2">
        <Button
          id="agreement-button"
          color="black"
          variant="primary"
          onClick={onAgree}
        >
          I understand & agree
        </Button>

        <Button color="black" variant="secondary" className="underline">
          <Typography
            weight="bold"
            variant="h2"
            className="underline"
            onClick={onClose}
          >
            Cancel
          </Typography>
        </Button>
      </div>
    </div>
  </Modal>
);
