import { Contract } from './contractService';
import { getFoundationMarketplaceContract } from '/utils/contracts';
import Abis from '/abis';
const { toBigInt } = require('ethers');

class FoundationMarketplaceService {
  foundationMarketplace;

  constructor(chainId) {
    this.foundationMarketplace = new Contract(
      getFoundationMarketplaceContract({ chainId }),
      Abis.FoundationMarketplaceABI
    );
  }

  buyV2({ tokenAddress, tokenId, price, referrerAddress }) {
    return this.foundationMarketplace.writeWithoutWaiting(
      'buyV2',
      [tokenAddress, tokenId, price, referrerAddress],
      toBigInt(price)
    );
  }

  cancelBuyPrice({ tokenAddress, tokenId }) {
    return this.foundationMarketplace.writeWithoutWaiting('cancelBuyPrice', [
      tokenAddress,
      tokenId
    ]);
  }
}

export default FoundationMarketplaceService;
