import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';

import { getProducerInvitationMessage } from '/config/project';
import { emailValidation } from '/utils/regex';
import { FormInput } from '/design-systems/Atoms/FormInput';
import { FormTextArea } from '/design-systems/Atoms/FormTextArea';
import { Typography } from '/design-systems/Atoms/Typography';
import { Button } from '/design-systems/Atoms/Button';

export const InviteForm = ({
  title,
  value,
  onSubmit,
  showMessage = true,
  invitationType = 'project'
}) => {
  const [initialForm, setInitialForm] = useState({});

  useEffect(() => {
    setInitialForm(
      value || {
        email: '',
        message: getProducerInvitationMessage(title, invitationType)
      }
    );
  }, [value, title]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialForm}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = 'This field is a required field';
        } else if (!emailValidation(values.email)) {
          errors.email = 'This email is invalid';
        }

        if (!values.message) {
          errors.message = 'This field is a required field';
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          await onSubmit(values);
        } catch (error) {
          console.error('Invitation form submission error');
        }
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <Typography variant="medium" weight="medium">
                Email
              </Typography>
              <FormInput
                id="email"
                type="email"
                name="email"
                placeholder="collaborator@gmail.com"
                value={values.email}
                error={errors.email}
                touched={touched.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            {showMessage && (
              <div className="flex flex-col gap-2">
                <Typography variant="medium" weight="medium">
                  Message
                </Typography>
                <FormTextArea
                  id="message"
                  type="message"
                  name="message"
                  placeholder="Add a message"
                  rows={10}
                  value={values.message}
                  error={errors.message}
                  touched={touched.message}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
            )}

            <Button
              color="black"
              variant="primary"
              blocked
              submit
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Send Invite
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
