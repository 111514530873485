import axios from '/services/axios';
import { generateQueryParams } from '/utils/url';

export const getToken = async ({
  tokenId,
  tokenAddress,
  withMinter,
  withOwner,
  chain,
  link,
  queryAsync = false
}) =>
  axios.post('/token', {
    tokenId,
    contractAddress: tokenAddress,
    link,
    withMinter,
    withOwner,
    chain,
    queryAsync
  });

// only supporting Ethereum for now
export const getTokenActivity = async ({ tokenId, tokenAddress, chain }) => {
  return axios.get(
    `/token/${tokenAddress}/${tokenId}/activity?${generateQueryParams({
      chain
    })}`
  );
};

// only supporting Ethereum for now
export const getTokenRoyaltyRecipients = async ({ tokenId }) => {
  return axios.get(`/token/${tokenId}/get/royalty-recipients`);
};

export const getTokenOwners = async ({ tokenId }) =>
  axios.get(`/token/get/${tokenId}/owners`);

export const refreshTokenMetadata = async ({ tokenId, tokenAddress }) =>
  axios.put(`/token/${tokenId}/${tokenAddress}/refresh`);
