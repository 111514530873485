import React, { useMemo, useReducer, useRef } from 'react';

export const useShallowState = (initialState) => {
  const [state, setState] = useReducer(
    (prevState, action = {}) => ({
      ...prevState,
      ...(typeof action === 'function' ? action(prevState) : action)
    }),
    initialState
  );

  const refState = useRef(state);
  refState.current = state;

  const utilityFunctions = useMemo(
    () => ({
      clearState() {
        setState((prevState) =>
          Object.fromEntries(
            Object.keys(prevState).map((key) => [key, undefined])
          )
        );
      },

      clearProperty(property) {
        setState({ [property]: undefined });
      },

      resetState() {
        setState((prevState) =>
          Object.fromEntries(
            Object.keys(prevState).map((key) => [key, initialState[key]])
          )
        );
      }
    }),
    []
  );

  return [state, setState, utilityFunctions, refState];
};
