import React, { useState, useMemo, cloneElement } from 'react';
import {
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useClick,
  useRole,
  useDismiss
} from '@floating-ui/react-dom-interactions';
import { Typography } from '/design-systems/Atoms/Typography';
import { mergeClassNames } from '/utils/string';
import { objectBlank } from '/utils/object';

export const FloatingDropdown = ({
  children,
  items,
  title,
  placement = 'bottom',
  onDropDownClick,
  customPosition = {}
}) => {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [offset(5), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'menu' }),
    useDismiss(context)
  ]);

  const positionStyle = useMemo(() => {
    if (!objectBlank(customPosition)) {
      return customPosition;
    }
    return { top: y ?? 0, left: x ?? 0 };
  }, [customPosition]);

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({ ref: reference, ...children.props })
      )}

      {open && (
        <div
          {...getFloatingProps({
            ref: floating,
            className: mergeClassNames(
              'flex flex-col z-20 bg-white min-w-fit bg-white shadow-lg rounded-xl ring-1 ring-black ring-opacity-5 focus:outline-none py-2',
              'max-w-[400px]'
            ),
            style: {
              position: strategy,
              ...positionStyle
            }
          })}
        >
          {title && (
            <div className="mx-2 w-full px-2 py-1">
              <Typography colorVariant="secondary" variant="small">
                {title}
              </Typography>
            </div>
          )}
          {items.map((item, idx) => (
            <button
              key={idx}
              className="mx-2 w-60 rounded-lg px-2 py-2 text-left font-medium text-neutral-700 hover:bg-neutral-100 hover:text-neutral-900"
              onClick={(e) => {
                onDropDownClick?.(item, e);
                setOpen(false);
              }}
            >
              {item}
            </button>
          ))}
        </div>
      )}
    </>
  );
};
