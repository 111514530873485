import WhitelistedLinks from '/assets/whitelisted-links.json';
import CustomQueries from '/assets/custom-queries.json';

export function removeSpaces(url) {
  return encodeURI(url);
}

export const parseIntArrayFromParam = (param) => {
  return param
    ?.split(',')
    ?.map((value) => parseInt(value))
    ?.filter((value) => !Number.isNaN(value));
};

export const getAssetIdFromUrl = (url) => {
  try {
    if (isJoynAssetLink(url)) {
      const urlObj = new URL(url);
      return urlObj.searchParams.get('assetId');
    }

    const noQueryLink = removeQueryParams(url);
    return noQueryLink.split('/').at(-1);
  } catch {
    return null;
  }
};

export const isJoynAssetLink = (url) => {
  try {
    const urlObj = new URL(url);
    const validHostnames = [
      'joyn.xyz',
      'www.joyn.xyz',
      'app.joyn.xyz',
      'qa.joyn.xyz',
      'staging.joyn.xyz',
      'localhost'
    ];
    if (!validHostnames.includes(urlObj.hostname)) {
      return false;
    }

    const assetId = urlObj.searchParams.get('assetId');
    return !!assetId;
  } catch {
    return false;
  }
};

export const openLink = (url) => {
  if (!url) return;
  window.open(url, '_blank');
};

export const removeQueryParam = (url = '', keys = []) => {
  const link = url.split('?');
  const queryParam = link?.[1];
  if (!queryParam) return url;

  const newQuery = generateQueryParams(
    queryParam.split('&').reduce((val, param) => {
      const p = param.split('=');
      if (keys.includes(p[0])) return val;

      return {
        ...val,
        [p[0]]: p[1]
      };
    }, {})
  );

  return `${link[0]}?${newQuery}`;
};

export const removeQueryParams = (url = '') => {
  return url.split('?')[0];
};

export const removeElementFromQueryParams = ({ url = '', key = '' }) => {
  const link = url.split('?');
  const queryParam = link?.[1];
  if (!queryParam) return url;

  const newQuery = generateQueryParams(
    queryParam.split('&').reduce((val, param) => {
      const p = param.split('=');
      if (p[0] === key) return val;

      return {
        ...val,
        [p[0]]: p[1]
      };
    }, {})
  );

  return `${link[0]}?${newQuery}`;
};

export const createUrlBySlug = (title, slug) => {
  return (
    (title?.toLowerCase()?.replace(/[^A-Za-z]+/g, '-') || 'joyn') + '-' + slug
  );
};

export const getTitleAndSlugFromUrl = (id) => {
  const splittedId = id.split('-');

  return {
    title: splittedId.slice(0, splittedId.length - 1).join('-'),
    slug: splittedId.at(-1),
    customUrl: id
  };
};

export const isNumber = (id) => {
  return Number.isInteger(parseInt(id));
};

export const generateQueryParams = (params = {}) => {
  return Object.keys(params)
    .filter(
      (key) =>
        params[key] !== undefined && params[key] !== null && params[key] !== ''
    )
    .map((key) => `${key}=${params[key]}`)
    .join('&');
};

export const getTweetHashtagLink = (hashtag = '') => {
  const tag = hashtag.substring(1, hashtag.length);
  return `https://twitter.com/hashtag/${tag}`;
};

export const getTweetLink = ({ tweetId, twitterUserHandle }) => {
  return `https://twitter.com/${twitterUserHandle}/status/${tweetId}`;
};

export const addQueryParamToURL = ({ url, key, value }) => {
  const splits = url.split('?');

  const pathname = splits[0];
  const queries = splits[1] || '';

  return `${pathname}?${queries}${queries ? '&' : ''}${key}=${value}`;
};

export const customizeExternalLink = ({ link }) => {
  const initialValue = { link: '', isWhitelistedLink: false };

  if (!link) return initialValue;

  try {
    const { hostname } = new URL(link);
    const match = hostname.match(/(?:www\.)?(.*)$/i);
    const domain = (match ? match[1] : hostname).toLowerCase();

    initialValue.link = link;
    initialValue.isWhitelistedLink = WhitelistedLinks.includes(domain);

    const queries = CustomQueries[domain];
    if (queries) {
      initialValue.link = addQueryParamToURL({
        url: link,
        key: queries.key,
        value: queries.value
      });
    }
  } catch (error) {
    console.error(`Invalid URL: ${link}`);
  }

  return initialValue;
};

export const createUrl = ({ path, params = {} }) => {
  const url = new URL(path, 'http://dummy.com'); // The domain here doesn't matter as we only need the path and searchParams APIs
  Object.keys(params).forEach((key) => {
    url.searchParams.append(key, params[key]);
  });
  return url.pathname + url.search;
};
