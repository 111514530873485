import { useMemo } from 'react';
import { useAccount } from 'wagmi';
import { useMutation, useQuery } from '@tanstack/react-query';

import JoynAssetChainService from '/services/chain/JoynAssetChainService';
import ERC721Service from '/services/chain/ERC721Service';
import ERC1155Service from '/services/chain/ERC1155Service';
import RoyaltyEngineService from '/services/chain/RoyaltyEngineService';
import { QUERY_KEYS } from '/utils/queries';
import {
  getJoynMarketplaceContract,
  getConduitAddress,
  ROYALTY_ENGINE_ADDRESS
} from '/utils/contracts';
import { isJoynContract } from '/utils/contracts';
import { compareStringsIgnoreCase } from '/utils/string';
import abis from '/abis';
import { TOKEN_STANDARDS } from '/utils/constants';

export const useAssetContract = ({
  tokenId,
  assetChainId,
  tokenAddress,
  owner,
  tokenStandard
}) => {
  const { address } = useAccount();

  /**
   * @description Create ERC721 Contract instance.
   * If `tokenAddress` is the joyn asset contract, it creates the joyn asset contract instance.
   * If not, it creates the standard erc721 or erc1155 contract instance.
   *
   * The joyn asset contract instance is needed for asset minting.
   */
  const { data: joynAssetService, isLoading: isLoadingJoynAssetService } =
    useQuery(
      [
        tokenStandard === TOKEN_STANDARDS.ERC721
          ? QUERY_KEYS.CHAIN.ERC721
          : QUERY_KEYS.CHAIN.ERC1155,
        address,
        tokenAddress,
        tokenStandard,
        assetChainId
      ],
      () =>
        isJoynContract(assetChainId, tokenAddress)
          ? new JoynAssetChainService(assetChainId)
          : tokenStandard === TOKEN_STANDARDS.ERC721
          ? new ERC721Service(tokenAddress, abis.ERC721ABI)
          : new ERC1155Service(tokenAddress, abis.ERC1155ABI),
      {
        enabled:
          !!address && !!tokenAddress && !!tokenStandard && !!assetChainId
      }
    );

  const {
    data: royaltyRegistryService,
    isLoading: isLoadingRoyaltyRegistryService
  } = useQuery(
    [QUERY_KEYS.CHAIN.ROYALTY_REGISTRY, address],
    () =>
      new RoyaltyEngineService(
        ROYALTY_ENGINE_ADDRESS[assetChainId],
        abis.RoyaltyEngineABI
      ),
    {
      enabled: !!address
    }
  );

  const { mutateAsync: getRoyaltyView } = useMutation(
    ({ tokenId, tokenAddress, value }) =>
      royaltyRegistryService.getRoyaltyView({ tokenId, tokenAddress, value })
  );

  const {
    isLoading: isLoadingOperator,
    data: tokenOperator,
    refetch: refetchTokenOperator
  } = useQuery(
    [QUERY_KEYS.ASSET.GET_APPROVED_OPERATOR, tokenId, tokenAddress],
    // TODO consider ERC1155 doesnt have getApproved function
    () => joynAssetService.getApproved?.({ tokenId }) ?? null,
    {
      enabled:
        !!joynAssetService &&
        !isLoadingJoynAssetService &&
        !!tokenId &&
        !!tokenAddress
    }
  );

  const {
    isLoading: isLoadingApprovedForAll,
    data: isApprovedForAll,
    refetch: refetchIsApprovedForAll
  } = useQuery(
    [QUERY_KEYS.ASSET.IS_APPROVED_FOR_ALL, owner, assetChainId],
    () =>
      joynAssetService.isApprovedForAll({
        owner,
        operator: getConduitAddress({ chainId: assetChainId })
      }),
    {
      enabled:
        !!joynAssetService &&
        !isLoadingJoynAssetService &&
        !!owner &&
        !!assetChainId
    }
  );

  const { mutateAsync: onMintAsset } = useMutation(
    ({ uuid, signature, tokenURI, royaltyReceiver, royaltyFee }) => {
      // If `tokenAddress` is not the joyn asset contract, it cant be minted
      if (!isJoynContract(assetChainId, tokenAddress)) {
        throw `${tokenAddress} is not the Joyn Asset contract`;
      }

      return joynAssetService.mint({
        uuid,
        signature,
        tokenURI,
        royaltyReceiver,
        royaltyFee
      });
    }
  );

  const { mutateAsync: approve } = useMutation(({ operator, tokenId }) =>
    joynAssetService.approve({ operator, tokenId })
  );

  const { mutateAsync: setApprovalForAll } = useMutation(
    ({ operator, approved }) =>
      joynAssetService.setApprovalForAll({ operator, approved })
  );

  const { mutateAsync: onGetOwnerOfToken } = useMutation(({ tokenId }) =>
    joynAssetService.ownerOf({ tokenId })
  );

  const { mutateAsync: safeTransferFrom } = useMutation(
    ({ from, to, tokenId, value }) => joynAssetService.safeTransferFrom({
      from,
      to,
      tokenId,
      value,
      data: '0x'
    })
    
  );

  const isApproved = useMemo(
    () =>
      compareStringsIgnoreCase(
        tokenOperator,
        getJoynMarketplaceContract({ chainId: assetChainId })
      ),
    [tokenOperator, assetChainId]
  );

  return useMemo(
    () => ({
      isLoadingJoynAssetService,
      isLoadingRoyaltyRegistryService,
      isLoadingApprovedForAll,
      isLoadingOperator,
      isApproved,
      onMintAsset,
      approve,
      refetchTokenOperator,
      refetchIsApprovedForAll,
      isApprovedForAll,
      setApprovalForAll,
      onGetOwnerOfToken,
      royaltyRegistryService,
      getRoyaltyView,
      safeTransferFrom
    }),
    [
      isLoadingJoynAssetService,
      isLoadingRoyaltyRegistryService,
      isLoadingApprovedForAll,
      isLoadingOperator,
      isApproved,
      onMintAsset,
      approve,
      refetchTokenOperator,
      refetchIsApprovedForAll,
      isApprovedForAll,
      setApprovalForAll,
      onGetOwnerOfToken,
      royaltyRegistryService,
      getRoyaltyView,
      safeTransferFrom
    ]
  );
};
