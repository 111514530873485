import {
  REQUIREMENT_LOGIC_GATES,
  CO_CREATE_PROMPTABLE_TYPES,
  CO_CREATE_REQUIREMENT_TYPES,
  TWITTER_REQUIREMENT_ACTION_TYPES,
  CO_CREATE_REQUIREMENTABLE_TYPES,
  TOKEN_REQUIREMENT_INITIAL_STATE
} from '/utils/constants';
import { ROUTES } from '/config/routes';
import Router from 'next/router';
import { createUrlBySlug, openLink } from './url';

const isPrompt = (coCreation) => !!coCreation?.hasOwnProperty('promptableType');

const getRolesFromCoCreation = ({ RoleableRoles: roleableRoles }) => {
  return (
    roleableRoles?.map(
      ({ Role: { id, name, Requirements: requirements } }) => ({
        id,
        name,
        requirements
      })
    ) || []
  );
};

const getCoCreationPageRoute = ({ title, slug, promptableType, action }) => {
  const url = createUrlBySlug(title, slug);
  const contestId = createUrlBySlug(title, slug);
  switch (promptableType) {
    case CO_CREATE_PROMPTABLE_TYPES.CONTEST:
      if (action === 'view') {
        return ROUTES.contest[action](contestId);
      }
      return ROUTES.contest[action](url);
    case CO_CREATE_PROMPTABLE_TYPES.GIVEAWAY:
      return ROUTES.giveaway[action](url);
  }
};

const filterRequirementSteps = (steps) => {
  return steps.map((step) => {
    if (step.type === CO_CREATE_REQUIREMENT_TYPES.TWITTER) {
      if (step.action === TWITTER_REQUIREMENT_ACTION_TYPES.FOLLOW) {
        step.hasOwnProperty('twitterUrl') && delete step.twitterUrl;
      } else {
        step.hasOwnProperty('twitterHandle') && delete step.twitterHandle;
      }
    }

    if (step.type === CO_CREATE_REQUIREMENT_TYPES.TOKEN) {
      step.hasOwnProperty('action') && delete step.action;
    }

    // step.hasOwnProperty('amount') && delete step.amount;
    step.hasOwnProperty('guildRequirementId') && delete step.guildRequirementId;
    step.hasOwnProperty('roleId') && delete step.roleId;
    return step;
  });
};

const getTokenRequirementStep = (step) => {
  switch (step.requirementStepableType) {
    case 'RoleRequirementStep':
      const { type: tokenType, ...rest } = step?.RoleRequirementStep?.Role
        ?.Requirements?.[0] || {
        ...TOKEN_REQUIREMENT_INITIAL_STATE,
        type: ''
      };
      return { ...rest, tokenType };
    default:
      return step[step.requirementStepableType];
  }
};

// checks if there is any 'OR' relationship within the requirement steps
const hasOrLogic = (requirementSteps = []) =>
  requirementSteps.some(
    (step) =>
      step?.RoleRequirementStep?.Role?.logic === REQUIREMENT_LOGIC_GATES.OR
  );

const getRequirementStepDetails = (step) => {
  return {
    ...getTokenRequirementStep(step),
    id: step.id,
    type: CO_CREATE_REQUIREMENTABLE_TYPES[step.requirementStepableType],
    action: step.action
  };
};

const getTokenRequirementSteps = ({ requirementSteps = [] }) => {
  const tokenRequirementSteps = requirementSteps.filter(
    (step) => step.requirementStepableType === 'RoleRequirementStep'
  );

  // in 'OR' logic, Requirements have the same Role. to display the correct token contract info, we need to show the Requirement data
  if (hasOrLogic(requirementSteps)) {
    return tokenRequirementSteps.map((step, index) => {
      // assumption: # of tokenRequirementSteps = # of Requirements
      const reqDetails = step?.RoleRequirementStep?.Role?.Requirements?.[
        index
      ] || {
        ...TOKEN_REQUIREMENT_INITIAL_STATE,
        type: ''
      };

      return {
        ...reqDetails,
        id: step.id,
        type: CO_CREATE_REQUIREMENTABLE_TYPES[step.requirementStepableType],
        action: step.action
      };
    });
  }

  return tokenRequirementSteps.map((step) => getRequirementStepDetails(step));
};

const getRequirementStepForms = (requirementSteps = []) => {
  if (hasOrLogic(requirementSteps)) {
    const tokenStepDetails = getTokenRequirementSteps({ requirementSteps });
    const otherStepDetails = requirementSteps
      .filter((step) => step.requirementStepableType !== 'RoleRequirementStep')
      .map((step) => getRequirementStepDetails(step));

    return [...tokenStepDetails, ...otherStepDetails];
  }

  return requirementSteps.map((step) => getRequirementStepDetails(step));
};

const getRequirementStepViews = (requirementSteps = []) => {
  let stepsByType = requirementSteps.reduce((val, step) => {
    const type = CO_CREATE_REQUIREMENTABLE_TYPES[step.requirementStepableType];
    // insert role requirement steps separately, because the 'OR' logic needs to be handled differently
    if (type === CO_CREATE_REQUIREMENTABLE_TYPES.RoleRequirementStep) {
      return val;
    }

    if (!val[type]) val[type] = [];
    val[type].push(getRequirementStepForms([step])[0]);
    return val;
  }, {});

  // role requirement steps
  const roleRequirementSteps = getTokenRequirementSteps({ requirementSteps });
  if (roleRequirementSteps && roleRequirementSteps.length > 0) {
    stepsByType[CO_CREATE_REQUIREMENTABLE_TYPES.RoleRequirementStep] =
      roleRequirementSteps;
  }

  return stepsByType;
};

const getCoCreationEmoji = (promptableType) => {
  switch (promptableType) {
    case CO_CREATE_PROMPTABLE_TYPES.CONTEST:
      return '📢';
    case CO_CREATE_PROMPTABLE_TYPES.GIVEAWAY:
      return '🎁';
  }
  return '';
};

const getCoCreationLabel = (promptableType) => {
  return {
    [CO_CREATE_PROMPTABLE_TYPES.CONTEST]: 'Open call',
    [CO_CREATE_PROMPTABLE_TYPES.GIVEAWAY]: 'Giveaway'
  }[promptableType];
};

const handleViewCampaign = (e, { title, slug, promptableType }) => {
  const route = getCoCreationPageRoute({
    title,
    slug,
    promptableType,
    action: 'view'
  });

  if (e.ctrlKey || e.metaKey) {
    openLink(route);
    return;
  }

  Router.push(route);
};

export {
  // getCoCreationAccess,
  getRolesFromCoCreation,
  isPrompt,
  getCoCreationPageRoute,
  filterRequirementSteps,
  getRequirementStepForms,
  getRequirementStepViews,
  getCoCreationEmoji,
  getCoCreationLabel,
  handleViewCampaign
};
