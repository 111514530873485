import React, { useMemo, useCallback } from 'react';
import { AssetDetailCard } from '/design-systems/Molecules/Cards/AssetDetailCard';
import { AssetPrice } from '/design-systems/Molecules/Assets/AssetPrice';
import { PillBadge } from '/design-systems/Atoms/PillBadge';
import { Button } from '/design-systems/Atoms/Button';
import { Divider } from '/design-systems/Atoms/Divider';
import { FloatingTooltip } from '/design-systems/Atoms/FloatingTooltip';
import { Typography } from '/design-systems/Atoms/Typography';
import { OfferNoteButton } from '/design-systems/Atoms/OfferNoteButton';
import { ProductCampaign } from '/design-systems/Molecules/Product/ProductCampaign';
import { Section } from '/design-systems/Molecules/Section';
import { TokenActivityRow } from '/design-systems/Molecules/TokenActivityRow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNote } from '@fortawesome/pro-regular-svg-icons';

import { TOKEN_ACTIVITY_TYPES } from '/utils/constants';
import { toShort18, toShortAddress } from '/utils/string';
import { emptyArray, objectBlank } from '/utils/object';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';
import { getLogo } from '/utils/image';
import { fromBasisPointToPercentage } from '/utils/number';
import { formatDuration } from '/utils/date';
import { useRouter } from 'next/router';
import { getCoCreationPageRoute } from '/utils/co-creation';
import { ChainButton } from '/design-systems/Molecules/Buttons/ChainButton';
import { isSupportedChain } from '/utils/web3';
import { useWallet } from '/hooks/useWallet';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GalleriesForAsset } from '/design-systems/Molecules/Product/GalleriesForAsset';
import { getProfileRoute } from '/utils/user';
import { Alert } from '/design-systems/Atoms/Alert';

export const AssetDetailSections = ({
  currentUser,
  assetId,
  assetCampaigns,
  assetTokenId,
  assetChainId,
  assetTokenAddress,
  assetToken,
  blockchain,
  tokenOwner,
  isStandAloneNFT,
  isAssetMinted,
  isAsset,
  isAssetAvailableForBuyNow,
  isAvailableForListing,
  isListed,
  tokenURI,
  usageRight,
  offers,
  activeListings,
  showOfferExpanded,
  tokenActivities,
  hasAuction,
  auctionOfferDisabled,
  purchasePrice,
  onBuyAsset,
  onCancelListing,
  onCancelOffer,
  isCancelingOffer,
  isBuyingAsset,
  onAcceptOffer,
  onOfferNote,
  titleVariant = 'default',
  onClose,
  galleryId
}) => {
  const router = useRouter();
  const { freeSeaportListing } = useFlags();

  const filteredTokenActivities = useMemo(
    () =>
      tokenActivities?.filter((activity) =>
        Object.values(TOKEN_ACTIVITY_TYPES).includes(activity.type)
      ) ?? [],
    [tokenActivities]
  );

  const { isConnectedCorrectWallet } = useWallet({
    walletAddresses: currentUser?.WalletAddresses ?? [],
    withSync: true
  });

  const isFreeListing = useCallback(
    (priceUnit) => (freeSeaportListing ? Number(priceUnit) === 0 : false),
    [freeSeaportListing]
  );

  // check if: in a Showcase, and the asset has a private listing in this Showcase
  const showingFilteredListings = useMemo(
    () =>
      galleryId &&
      activeListings?.some(
        (listing) =>
          listing?.isOpenCuratorFee === false &&
          listing?.galleryId.toString() === galleryId
      ),
    [galleryId, activeListings]
  );

  const titleTextProps = useMemo(() => {
    return titleVariant === 'h5'
      ? {
          heading: true,
          variant: 'h5'
        }
      : { variant: 'large', weight: 'medium' };
  }, [titleVariant]);

  const handleViewCampaign = useCallback(
    ({ title, slug, promptableType }) =>
      (e) => {
        const route = getCoCreationPageRoute({
          title,
          slug,
          promptableType,
          action: 'view'
        });

        if (e.ctrlKey || e.metaKey) {
          window.open(route, '_blank');
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        router.push(route);

        onClose?.();
      },
    [onClose] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <AssetDetailCard
        title="Token details"
        blockchain={blockchain}
        tokenId={assetTokenId}
        tokenType={
          isStandAloneNFT || isAssetMinted ? assetToken?.tokenStandard : ''
        }
        address={assetTokenAddress}
        tokenURI={isStandAloneNFT || isAssetMinted ? tokenURI : ''}
        usageRight={usageRight}
        titleTextProps={titleTextProps}
      />

      {/* Open calls sections */}
      {isAsset && !emptyArray(assetCampaigns) && (
        <>
          <Divider size="smallest" />
          <div className="flex items-center gap-x-1">
            <Typography {...titleTextProps}>Open calls</Typography>
            <Typography
              variant="large"
              weight="medium"
              colorVariant="secondary"
            >
              ({assetCampaigns?.length ?? 0})
            </Typography>
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            {assetCampaigns?.map((campaign) => (
              <ProductCampaign
                key={campaign.id}
                title={campaign.title}
                campaignLogo={getLogo(campaign.Projects[0])}
                onClick={handleViewCampaign(campaign)}
              />
            ))}
          </div>
        </>
      )}

      {/* Featured in section */}
      {Boolean(assetId) && (
        <GalleriesForAsset assetId={assetId} titleTextProps={titleTextProps} />
      )}

      {/* Listings */}
      <>
        <Divider size="smallest" />
        <Section
          title="Listings"
          collapsible
          expanded={isListed}
          titleTextProps={titleTextProps}
        >
          <div className="mb-4">
            {showingFilteredListings ? (
              <Alert variant="grey">
                Showing listings in the current curation.
              </Alert>
            ) : (
              <Alert variant="grey">
                Showing all open listings (excluding private listings)
              </Alert>
            )}
          </div>
          {isListed &&
          (isAssetAvailableForBuyNow || isAvailableForListing) &&
          !hasAuction ? (
            <div
              id="activeListings"
              className="flex max-h-[240px] flex-col gap-4 overflow-y-auto"
            >
              {activeListings?.map((listing) => {
                const {
                  priceUnit,
                  fulfillmentLeft,
                  userId,
                  v1Listing = false,
                  seaportVersion,
                  protocol,
                  isOpenCuratorFee,
                  curatorFeePercentage
                } = listing;

                const showCuratorFee =
                  (tokenOwner?.isOwner || isOpenCuratorFee) &&
                  curatorFeePercentage > 0;

                return (
                  <div
                    key={listing.id}
                    className="listing flex items-center justify-between"
                    data-order-id={listing.id}
                  >
                    <div className="listing-fee-details flex items-center gap-3">
                      <AssetPrice
                        price={toShort18(priceUnit)}
                        size="medium"
                        blockchain={blockchain}
                      />
                      <Typography variant="medium">
                        x
                        {isFreeListing(priceUnit) && userId != currentUser?.id
                          ? 1
                          : fulfillmentLeft}
                      </Typography>
                    </div>
                    <div className="flex items-center gap-3">
                      {/* curator fee */}
                      {showCuratorFee && (
                        <FloatingTooltip
                          label="Curator fee %"
                          placement="bottom"
                        >
                          <div>
                            <PillBadge
                              textSize="text-sm"
                              variant="gray"
                              text={`${fromBasisPointToPercentage(
                                curatorFeePercentage
                              )}%`}
                              className={'curator-fee-percentage'}
                            />
                          </div>
                        </FloatingTooltip>
                      )}
                      {userId == currentUser?.id ? (
                        <ChainButton
                          chainId={assetChainId}
                          btnColor="primary"
                          btnVariant="secondary"
                          className="cancel-listing w-fit gap-x-2 px-3"
                          onClick={() =>
                            onCancelListing({
                              order: listing,
                              v1Listing,
                              seaportVersion,
                              protocol
                            })
                          }
                          handleCallbackAfterSwitchNetwork={false}
                          title="Cancel"
                          blocked={false}
                          disabled={!isConnectedCorrectWallet}
                        />
                      ) : (
                        <ChainButton
                          btnColor="primary"
                          chainId={assetChainId}
                          btnVariant="secondary"
                          onClick={() =>
                            onBuyAsset({
                              order: listing,
                              unitsToFill: isFreeListing(priceUnit)
                                ? 1
                                : fulfillmentLeft,
                              priceUnit,
                              v1Listing,
                              seaportVersion,
                              protocol,
                              galleryId
                            })
                          }
                          className="buy-listing w-fit gap-x-2 px-3"
                          disabled={
                            !!purchasePrice ||
                            !isConnectedCorrectWallet ||
                            (isFreeListing(priceUnit) && tokenOwner?.isOwner)
                          }
                          loading={isBuyingAsset}
                          handleCallbackAfterSwitchNetwork={false}
                          title={isFreeListing(priceUnit) ? 'Claim' : 'Buy'}
                          blocked={false}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Typography colorVariant="secondary">
              No listings available
            </Typography>
          )}
        </Section>
      </>

      {/* Offers section */}
      {
        <>
          <Divider size="smallest" />
          <Section
            title="Bids"
            collapsible
            expanded={showOfferExpanded}
            titleTextProps={titleTextProps}
          >
            {offers?.length > 0 ? (
              <div
                id="offers-section"
                className="flex max-h-[240px] flex-col gap-4 overflow-y-auto"
              >
                {offers?.map((offer) => (
                  <div
                    key={offer.id}
                    className="offer flex items-center justify-between"
                    data-order-id={offer.id}
                  >
                    {/* offer details */}
                    <div className="flex flex-col gap-1">
                      <div className="flex items-center gap-4">
                        {/* price and quantity */}
                        <div className="flex items-center gap-2">
                          <AssetPrice
                            className="offer-price"
                            price={toShort18(offer?.priceUnit)}
                            size="medium"
                            isOffer={true}
                            offerCurrency={
                              offer?.orderData?.parameters?.offer?.[0]?.token
                            }
                            blockchain={blockchain}
                          />
                          <Typography variant="medium">
                            x{offer?.fulfillmentLeft}
                          </Typography>
                        </div>

                        {/* expiry */}
                        {offer?.endTime && (
                          <Typography variant="small" colorVariant="secondary">
                            {/* TODO — conditional logic for if expired */}

                            {`Expires in ${formatDuration(
                              new Date(),
                              new Date(offer?.endTime),
                              'short'
                            )}`}
                          </Typography>
                        )}
                      </div>
                      <Typography
                        variant="small"
                        weight="medium"
                        className="w-fit cursor-pointer text-[#6B7280] hover:underline hover:underline-offset-2"
                        onClick={() => {
                          router.push(getProfileRoute(offer?.User));
                        }}
                      >
                        {`${toShortAddress(
                          retrieveMainWalletAddressFromWalletAddresses(
                            offer?.User?.WalletAddresses ?? []
                          )?.address,
                          5
                        ).toLowerCase()}`}
                      </Typography>
                    </div>

                    {/* offer actions */}
                    {!objectBlank(currentUser) && (
                      <div className="flex gap-4">
                        {offer?.note &&
                          (tokenOwner?.isOwner ||
                            offer?.userId == currentUser?.id) && (
                            <OfferNoteButton
                              offer={offer}
                              onOfferNote={onOfferNote}
                            />
                          )}

                        {tokenOwner?.isOwner && (
                          <FloatingTooltip
                            label={
                              auctionOfferDisabled
                                ? 'You will be able to accept bids at the end of the auction.'
                                : ''
                            }
                            hide={!auctionOfferDisabled}
                            placement="bottom"
                          >
                            <Button
                              color="primary"
                              variant="secondary"
                              onClick={() => onAcceptOffer({ offer })}
                              className="accept-offer w-fit gap-x-2 px-3"
                              disabled={auctionOfferDisabled}
                            >
                              Accept
                            </Button>
                          </FloatingTooltip>
                        )}

                        {offer?.userId == currentUser?.id && (
                          <FloatingTooltip
                            label={
                              hasAuction
                                ? 'Bids made in an auction cannot be canceled.'
                                : ''
                            }
                            hide={!hasAuction}
                            placement="bottom"
                          >
                            <ChainButton
                              btnColor="primary"
                              chainId={assetChainId}
                              btnVariant="secondary"
                              className="cancel-offer w-fit gap-x-2 px-3"
                              onClick={() => onCancelOffer({ offer })}
                              loading={isCancelingOffer === offer?.id}
                              disabled={hasAuction || !isConnectedCorrectWallet}
                              handleCallbackAfterSwitchNetwork={false}
                              title="Cancel"
                              blocked={false}
                            />
                          </FloatingTooltip>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <Typography colorVariant="secondary">No active bids</Typography>
            )}
          </Section>
        </>
      }

      {/* token activity section */}
      {isSupportedChain({ chain: blockchain }) && tokenActivities && (
        <>
          <Divider size="smallest" />
          <Section title="Activity" collapsible titleTextProps={titleTextProps}>
            <div className="flex max-h-[240px] flex-col gap-4 overflow-y-auto">
              {filteredTokenActivities.map((activity) => (
                <TokenActivityRow
                  key={activity}
                  activity={activity}
                  blockchain={blockchain}
                />
              ))}
            </div>
          </Section>
        </>
      )}
    </>
  );
};
