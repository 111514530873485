import React, { useMemo, useState } from 'react';
import { completeImageVideoExtension } from '/utils/file';
import { VIDEO_EXTENSIONS } from '/utils/constants';
import { handleVideoCanPlay } from '/utils/video';

import { getFileURL, getAutomaticallyCreatedVideoThumbnailKey } from './utils';
import { Spinner } from '../Spinner';

export const Image = ({
  src,
  alt,
  className,
  style,
  fileType: fileType_,
  staticFile,
  placeholder,
  // default, s, m, l, xl
  resolution = 'default',
  videoControls = false,
  id,
  onImageLoad,
  onVideoLoad,
  title,
  isVideo = false
}) => {
  const [loading, setLoading] = useState(true);

  // fileType is needed only for <video />
  const fileType = useMemo(() => {
    const type = completeImageVideoExtension(fileType_);
    if (type === 'video/quicktime') return 'video/mp4';
    return type;
  }, [fileType_]);

  const displayAsVideo = useMemo(
    () => isVideo || (fileType && supportedVideoFileTypes.includes(fileType)),
    [fileType, isVideo]
  );

  const isLoading = useMemo(
    () => loading && src && !staticFile,
    [loading, src, staticFile]
  );

  const srcPath = useMemo(
    () =>
      getFileURL({
        src,
        placeholder,
        isStaticFile: staticFile,
        resolution,
        isVideoFile: displayAsVideo
      }),
    [displayAsVideo, placeholder, resolution, src, staticFile]
  );

  return (
    <>
      {isLoading && (
        <div
          className={`h-full ${className} z-[1] flex items-center justify-center`}
          style={style}
        >
          <Spinner className="h-6 w-6 animate-spin" />
        </div>
      )}

      {displayAsVideo ? (
        <video
          autoPlay
          muted
          loop
          poster={
            !staticFile &&
            getFileURL({
              src: getAutomaticallyCreatedVideoThumbnailKey(src),
              resolution
            })
          }
          controls={videoControls}
          onCanPlay={handleVideoCanPlay}
          className={`video-js ${className}`}
          style={{ ...style }}
          onLoadedMetadata={(e) => {
            setLoading(false);
            onVideoLoad && onVideoLoad(e);
          }}
        >
          <source src={srcPath} type={fileType} />
        </video>
      ) : (
        <>
          <img
            id={id}
            title={title}
            src={srcPath}
            alt={alt}
            className={className}
            onLoad={(e) => {
              setLoading(false);
              onImageLoad && onImageLoad(e);
            }}
            onError={() => setLoading(false)}
            style={{ ...style, display: isLoading ? 'none' : 'block' }}
          />
        </>
      )}
    </>
  );
};

const supportedVideoFileTypes = VIDEO_EXTENSIONS.map(
  completeImageVideoExtension
);
