import { useMemo, useCallback, useEffect } from 'react';
import { useAccount } from 'wagmi';
import { useShallowState } from './shallowState';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils';
import { compareStringsIgnoreCase } from '/utils/string';

/**
 * `walletAddresses` is the user's wallet addresses
 * `withSync` is for syncing with connected wallet
 */
export const useWallet = ({ walletAddresses = [], withSync = false }) => {
  const { address } = useAccount();
  const [state, setState] = useShallowState({
    walletAddressId: '',
    walletAddress: ''
  });

  const isConnectedCorrectWallet = useMemo(() => {
    return compareStringsIgnoreCase(state.walletAddress, address);
  }, [address, state.walletAddress]);

  const handleChangeWallet = useCallback(
    (id) => {
      const wallet = walletAddresses?.find((address) => address.id === id);
      const walletAddress = wallet?.address;
      const walletAddressId = wallet?.id;
      setState({ walletAddress, walletAddressId });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [walletAddresses]
  );

  useEffect(() => {
    let walletId;
    const mainWallet =
      retrieveMainWalletAddressFromWalletAddresses(walletAddresses);
    if (withSync) {
      walletId = (
        walletAddresses?.find((wallet) =>
          compareStringsIgnoreCase(wallet.address, address)
        ) ?? mainWallet
      )?.id;
    } else {
      walletId = mainWallet?.id;
    }
    walletId && handleChangeWallet(walletId);
  }, [walletAddresses, withSync, address, handleChangeWallet]);

  return useMemo(
    () => ({
      walletAddress: state.walletAddress,
      walletAddressId: state.walletAddressId,
      isConnectedCorrectWallet,
      onChangeWallet: handleChangeWallet
    }),
    [state, isConnectedCorrectWallet, handleChangeWallet]
  );
};
