import React from 'react';
import jwt from 'jsonwebtoken';
import Axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initializeForCurrentUser, isTokenExpired } from '/utils/auth';

let baseURL;

if (typeof localStorage !== 'undefined') {
  baseURL = process.env.NEXT_PUBLIC_JOYN_API_URL;
} else {
  // server-side rendering
  baseURL = process.env.DOCKER_JOYN_API_URL
    ? process.env.DOCKER_JOYN_API_URL
    : process.env.NEXT_PUBLIC_JOYN_API_URL;
}

const axios = Axios.create({
  baseURL: baseURL
});

let refreshAccessTokenPromise = null;

const requestInterceptor = axios.interceptors.request.use(async (config) => {
  if (config.bypassInterceptor) {
    delete config.bypassInterceptor;
    return config;
  }

  if (typeof localStorage !== 'undefined') {
    let accessToken = localStorage.getItem('jwtToken');
    const refreshToken = localStorage.getItem('refreshToken');

    // If access token is expired, refresh it
    if (!accessToken || isTokenExpired(accessToken)) {
      localStorage.removeItem('jwtToken');
      if (refreshToken && !isTokenExpired(refreshToken)) {
        let response;
        // Make a request to refresh the access token
        try {
          if (!refreshAccessTokenPromise) {
            refreshAccessTokenPromise = Axios.post(`${baseURL}/refresh_token`, {
              token: refreshToken
            })
              .then((response) => {
                localStorage.setItem(
                  'jwtToken',
                  response.data.data.accessToken
                );
                localStorage.setItem(
                  'refreshToken',
                  response.data.data.refreshToken
                );
                return response;
              })
              .catch((error) => {
                refreshAccessTokenPromise = null;
                throw error;
              })
              .finally(() => {
                refreshAccessTokenPromise = null;
              });
          }
          response = await refreshAccessTokenPromise;
        } catch (error) {
          localStorage.removeItem('refreshToken');
          return config;
        }

        accessToken = response.data.data.accessToken;
        // Save the new access token in local storage
        initializeForCurrentUser({
          user: jwt.decode(accessToken),
          jwtToken: accessToken,
          refreshToken: response.data.data.refreshToken
        });
      }
    }

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
  }
  return config;
});

function ToastError({ errorOb }) {
  const itemList = [];
  errorOb.forEach((item, index) => {
    itemList.push(<li key={index}>{item.message}</li>);
  });
  return <ul>{itemList}</ul>;
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (typeof error.response !== 'undefined') {
      if (error.response.data.message == 'Validation Error') {
        const errorOb = error.response.data.data;
        toast.error(<ToastError errorOb={errorOb} />);
      } else {
        toast.error(error.response.data.message);
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
export { requestInterceptor };
