import React from 'react';
import { Typography } from '/design-systems/Atoms/Typography';
import { Button } from '/design-systems/Atoms/Button';
import { mergeClassNames } from '/utils/string';

export const LinkToExistingNFT = ({
  onClick,
  className,
  variant = 'default'
}) => {
  if (variant === 'button') {
    return (
      <Button
        color="primary"
        variant="secondary"
        className="w-fit !whitespace-nowrap"
        onClick={onClick}
      >
        Link NFT
      </Button>
    );
  }

  return (
    <div
      className={mergeClassNames('flex w-full justify-center gap-1', className)}
    >
      <Typography variant="small" align="center">
        Already minted?
      </Typography>
      <Typography
        weight="bold"
        align="center"
        onClick={onClick}
        className="link-underlined"
      >
        Link to an existing NFT.
      </Typography>
    </div>
  );
};
