import React from 'react';
import { Button } from '/design-systems/Atoms/Button';
import { mergeClassNames } from '/utils/string';

export const MakeOfferButton = ({
  className,
  color,
  variant,
  onMakeOffer,
  label,
  disabled
}) => (
  <Button
    id="make-offer-button"
    variant={variant}
    color={color}
    blocked
    className={mergeClassNames('min-w-fit', className)}
    onClick={onMakeOffer}
    disabled={disabled}
  >
    {label}
  </Button>
);
