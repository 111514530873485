export const DEFAULT_PROJECT_TITLE = 'Your New Project';

export const DEFAULT_PROJECT_DESCRIPTION = `
  <h3>About</h3>Tell the world about what you’re working on and what type of collaboration you’re looking for to make it a reality.


  <h3>Vision</h3>
  Describe your project vision or goals in 50 words or less...


  <h3>Images and references</h3>
  Tip: Projects with images are more exciting to look at!
`;

export const PROJECT_STATUSES = {
  published: 'published',
  unpublished: 'unpublished'
};

export const getProducerInvitationMessage = (
  title,
  invitationType = 'project'
) =>
  `Hello!
  
I'm inviting you to become a Producer on the ${invitationType} ${title}, created on Joyn.xyz
`;

export const getProducerInvitationViewMessage = (title) =>
  `I am inviting you to become a Producer on the project ${title}`;

export const PROJECT_INVITATION_TITLE = 'Invite a producer';

export const PROJECT_INVITATION_DESCRIPTION =
  'Each producer will have full access to this Space.';

export const PROJECT_INVITATION_EXPIRED_TITLE =
  'The invite link to this project is no longer active.';

export const PROJECT_INVITATION_EXPIRED_DESCRIPTION =
  'The link has already been activated or Producer access to this project was removed.';

export const PROJECT_FORM_INITIAL_VALUES = {
  title: DEFAULT_PROJECT_TITLE,
  description: '',
  twitterUrl: '',
  discordUrl: '',
  websiteUrl: ''
};
