import { useState, useEffect } from 'react';
import { useNetwork, useWalletClient } from 'wagmi';
import { BrowserProvider } from 'ethers';

export function walletClientToSigner(walletClient, connectedNetwork) {
  const { account, chain, transport } = walletClient;
  const network = {
    chainId: chain?.id || connectedNetwork?.id,
    name: chain?.name || connectedNetwork?.name,
    ensAddress:
      chain?.contracts?.ensRegistry?.address ||
      connectedNetwork?.contracts?.ensRegistry?.address
  };
  const provider = new BrowserProvider(transport, network);
  const signer = provider.getSigner(account?.address);
  return signer;
}

export function useSigner(chainId) {
  const { chain } = useNetwork();
  const { data: walletClient } = useWalletClient({
    chainId: chainId || chain?.id
  });
  const [signer, setSigner] = useState(null);

  useEffect(() => {
    async function setupSigner() {
      walletClient
        ? setSigner(await walletClientToSigner(walletClient, chain))
        : setSigner(undefined);
    }
    setupSigner();
  }, [walletClient]);

  return signer;
}
