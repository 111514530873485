import React, { useMemo, useRef } from 'react';
import ExternalLinkIcon from '@heroicons/react/outline/ExternalLinkIcon';

import { ProductLicense } from '../../Product/ProductLicense';
import { openLink } from '/utils/url';
import { getChainIdFromChainName, getContractLink } from '/utils/web3';
import { styles } from './utils';
import { toShortAddress, toShortText } from '/utils/string';
import { Typography } from '/design-systems/Atoms/Typography';
import { Section } from '/design-systems/Molecules/Section';
import { objectBlank } from '/utils/object';

const RowContent = ({ title, description, onClick }) => {
  const textColor = useMemo(
    () =>
      `flex items-center gap-1 ${
        onClick ? 'link-underlined' : 'text-[#111827]'
      }`,
    [onClick]
  );

  return description ? (
    <div className="flex items-center gap-1 text-[#111827]">
      <Typography variant="medium" weight="semibold">
        {title}
      </Typography>
      <Typography
        variant="medium"
        weight="normal"
        className={textColor}
        onClick={onClick}
      >
        {description}
        {onClick && <ExternalLinkIcon className="h-4 w-4" />}
      </Typography>
    </div>
  ) : (
    <></>
  );
};

/**
 * @description Asset detail section to show the asset's on-chain data like asset title & chainId & token type & token contract address & asset license
 *
 * @typedef AssetDetailCardInterface
 * @type {object}
 * @property {string} title Asset detail section title. `Details` is set as default.
 * @property {"ETHEREUM" | "TEZOS"} blockchain The blockchain network id. If asset is minted, this value should be provided. If not, this value would be undefined.
 * @property {"ERC721" | "ERC1155"} tokenType The NFT token type.
 * @property {`0x${string}`} address The NFT contract address.
 * @property {string} tokenURI The NFT metadata link.
 * @property {string} license The NFT usage right data.
 *
 * @param {AssetDetailCardInterface} props
 */
export const AssetDetailCard = ({
  title = 'Details',
  blockchain,
  tokenType,
  address,
  tokenId,
  tokenURI,
  usageRight,
  className = '',
  titleTextProps
}) => {
  const refTokenId = useRef();
  const onTokenIdClick = () => {
    navigator.clipboard.writeText(tokenId);
    refTokenId.current.ariaLabel = 'Copied!';

    setTimeout(() => {
      refTokenId.current.ariaLabel = 'Copy';
    }, 2000);
  };

  const bodyContent = (
    <>
      <RowContent title="Blockchain:" description={blockchain ?? 'Unknown'} />
      <RowContent title="Token standard:" description={tokenType} />
      <RowContent
        title="Contract address:"
        description={toShortAddress(address)}
        onClick={() =>
          openLink(
            getContractLink({
              chainId: getChainIdFromChainName(blockchain),
              address
            })
          )
        }
      />
      {tokenId && (
        <div className="flex items-center gap-1 text-[#111827]">
          <Typography variant="medium" weight="semibold">
            Token ID:
          </Typography>
          <div
            aria-label="Copy"
            className="flex cursor-pointer items-center justify-center gap-2 px-2 "
            data-balloon-pos="right"
            ref={refTokenId}
            onClick={onTokenIdClick}
          >
            <Typography variant="medium" weight="normal">
              {toShortText(tokenId, 10)}
            </Typography>
            <svg
              fill="none"
              height="14"
              viewBox="0 0 12 14"
              width="12"
              xmlns="http://www.w3.org/2000/svg"
              className="-mt-[3px]"
            >
              <path
                d="M10.2959 3.52778V11.8611H11.4259V3.52778H10.2959ZM9.472 12.685H2.52756V13.815H9.472V12.685ZM1.70367 11.8611V3.52778H0.573672V11.8611H1.70367ZM2.52756 2.70389H3.91645V1.57389H2.52756V2.70389ZM8.08312 2.70389H9.472V1.57389H8.08312V2.70389ZM2.52756 12.685C2.07254 12.685 1.70367 12.3161 1.70367 11.8611H0.573672C0.573672 12.9402 1.44846 13.815 2.52756 13.815V12.685ZM10.2959 11.8611C10.2959 12.3161 9.92702 12.685 9.472 12.685V13.815C10.5511 13.815 11.4259 12.9402 11.4259 11.8611H10.2959ZM11.4259 3.52778C11.4259 2.44867 10.5511 1.57389 9.472 1.57389V2.70389C9.92702 2.70389 10.2959 3.07276 10.2959 3.52778H11.4259ZM1.70367 3.52778C1.70367 3.07276 2.07254 2.70389 2.52756 2.70389V1.57389C1.44846 1.57389 0.573672 2.44867 0.573672 3.52778H1.70367ZM5.30534 1.315H6.69423V0.185H5.30534V1.315ZM6.69423 2.96278H5.30534V4.09278H6.69423V2.96278ZM5.30534 2.96278C4.85032 2.96278 4.48145 2.59391 4.48145 2.13889H3.35145C3.35145 3.21799 4.22624 4.09278 5.30534 4.09278V2.96278ZM7.51812 2.13889C7.51812 2.59391 7.14925 2.96278 6.69423 2.96278V4.09278C7.77333 4.09278 8.64812 3.21799 8.64812 2.13889H7.51812ZM6.69423 1.315C7.14925 1.315 7.51812 1.68387 7.51812 2.13889H8.64812C8.64812 1.05979 7.77333 0.185 6.69423 0.185V1.315ZM5.30534 0.185C4.22624 0.185 3.35145 1.05979 3.35145 2.13889H4.48145C4.48145 1.68387 4.85032 1.315 5.30534 1.315V0.185Z"
                fill="#6B7280"
              />
            </svg>
          </div>
        </div>
      )}
      <RowContent
        title="Metadata:"
        description={tokenURI ? 'IPFS' : undefined}
        onClick={() => openLink(tokenURI)}
      />
      {usageRight && <ProductLicense usageRight={usageRight} />}
    </>
  );

  return (
    <div className={`${className}`}>
      <Section
        title={title}
        collapsible
        titleTextProps={titleTextProps}
        titleClassName={objectBlank(titleTextProps) ? styles.header : ''}
        contentClassName={styles.body}
      >
        {bodyContent}
      </Section>
    </div>
  );
};
