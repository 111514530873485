import axios from '/services/axios';

const createDraw = ({ promptId, numberWinners, promptRewardId }) => {
  return axios.post('/draw', { promptId, numberWinners, promptRewardId });
};

const confirmDraw = ({ id }) => {
  return axios.put(`/draw/confirm/${id}`);
};

const getDraw = ({ id }) => {
  return axios.get(`/draw/${id}`);
};

export { createDraw, confirmDraw, getDraw };
