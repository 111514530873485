import axios from '/services/axios';
import { generateQueryParams } from '/utils/url';

export const getCollaborators = ({ id, collaboratableType }) => {
  const query = generateQueryParams({ collaboratableType });

  return axios.get(`/collaborators/${id}?${query}`);
};

export const removeCollaborator = ({
  id,
  collaboratableType,
  collaboratableId
}) => {
  const query = generateQueryParams({ collaboratableType, collaboratableId });

  return axios.delete(`/collaborator/remove/${id}?${query}`);
};
