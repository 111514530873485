import { useMemo } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getAsset,
  createAsset,
  updateAsset,
  hideAsset,
  mergeAssets
} from '/services/joynApi/users/assets';
import { getAssetUpdatePayload } from '/utils/asset';
import { QUERY_KEYS } from '/utils/queries';
import { deleteFile } from '/services/joynApi/users/file';
import { makeArrayWithUniqueElements } from '/utils/array';

export const useAsset = (
  assetId,
  { withGallery = false, withGallerySection = false } = {}
) => {
  const {
    isLoading: isLoadingAsset,
    data: asset,
    refetch: refetchAsset
  } = useQuery(
    [QUERY_KEYS.ASSET.GET, assetId],
    () => getAsset({ assetId, withGallery, withGallerySection }),
    {
      enabled: !!assetId,
      select: (res) => res.data.data
    }
  );

  const assetFiles = useMemo(
    () =>
      asset?.Files?.map((file) => ({
        ...file,
        uploaded: true
      })) ?? [],
    [asset?.Files]
  );

  const assetCreator = useMemo(() => asset?.User, [asset?.User]);

  const assetUsageRight = useMemo(() => asset?.UsageRight, [asset?.UsageRight]);

  const assetPromptSubmissions = useMemo(
    () => asset?.PromptSubmissions,
    [asset?.PromptSubmissions]
  );

  const assetCampaigns = useMemo(
    () =>
      makeArrayWithUniqueElements({
        array:
          assetPromptSubmissions?.map(({ Prompt: prompt }) => prompt) || [],
        attribute: 'id'
      }),
    [assetPromptSubmissions]
  );

  const assetMint = useMemo(() => asset?.AssetMint, [asset]);
  const assetToken = useMemo(() => assetMint?.Token, [assetMint]);
  const tokenOwners = useMemo(() => assetToken?.TokenOwners, [assetToken]);

  const { mutateAsync: handleUpdateAsset } = useMutation(
    async ({ title, description, link, assetId, usageRightId, files }) => {
      const newFileIds =
        files?.map((file) => file.id).filter((fileId) => Boolean(fileId)) ?? [];
      const removedFileIds =
        assetFiles
          ?.map((file) => file.id)
          .filter((fileId) => !newFileIds.includes(fileId)) ?? [];

      await Promise.all(removedFileIds.map((fileId) => deleteFile(fileId)));
      await updateAsset(
        getAssetUpdatePayload({
          title,
          description,
          link,
          assetId: assetId ?? asset.id,
          usageRightId,
          files
        })
      );
      refetchAsset();
    }
  );

  const { mutateAsync: handleCreateAsset } = useMutation(
    async ({ title, description, link, usageRightId, files }) => {
      const payload = getAssetUpdatePayload({
        title,
        description,
        link,
        usageRightId,
        files
      });
      const res = await createAsset(payload);
      return res.data.data;
    }
  );

  return useMemo(
    () => ({
      asset,
      isLoadingAsset,
      assetFiles,
      assetCreator,
      assetUsageRight,
      assetPromptSubmissions,
      assetCampaigns,
      assetToken,
      tokenOwners,
      assetMint,
      onUpdateAsset: handleUpdateAsset,
      onCreateAsset: handleCreateAsset
    }),
    [
      asset,
      isLoadingAsset,
      assetFiles,
      assetCreator,
      assetUsageRight,
      assetPromptSubmissions,
      assetCampaigns,
      assetToken,
      tokenOwners,
      assetMint,
      handleUpdateAsset,
      handleCreateAsset
    ]
  );
};

export const useAssetMutations = () => {
  const {
    isLoading: isHidingAsset,
    mutate: onHideAsset,
    mutateAsync: onHideAssetAsync
  } = useMutation(({ assetId, hide }) => hideAsset({ assetId, hide }));

  const {
    isLoading: isMergingAssets,
    mutate: onMergeAssets,
    mutateAsync: onMergeAssetsAsync
  } = useMutation(({ assetIds, selectedAssetId }) =>
    mergeAssets({ assetIds, selectedAssetId })
  );

  return useMemo(
    () => ({
      isHidingAsset,
      onHideAsset,
      onHideAssetAsync,
      isMergingAssets,
      onMergeAssets,
      onMergeAssetsAsync
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isHidingAsset, isMergingAssets]
  );
};
