import React from 'react';

import { Section } from '/design-systems/Molecules/Section';
import { Divider } from '/design-systems/Atoms/Divider';

export const FAQSection = ({ sections }) => {
  return (
    <div className="flex h-full w-full flex-col gap-y-4 overflow-y-auto">
      {sections.map((section, index) => (
        <>
          <Section
            title={section.title}
            collapsible={true}
            titleClassName="paragraph-medium"
            contentClassName="flex flex-col gap-2"
          >
            {section.content}
          </Section>
          {index !== sections.length - 1 && <Divider size="smallest" />}
        </>
      ))}
    </div>
  );
};
