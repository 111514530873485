import React, { useRef, useEffect } from 'react';
import { Typography } from '../Typography';
import { mergeClassNames } from '/utils/string';

export const FormTextArea = ({
  id,
  name,
  type = 'text',
  value = '',
  disabled,
  border,
  className,
  wrapperClassName,
  readOnly,
  error,
  rows,
  placeholder,
  autoFocus,
  onChange,
  onBlur,
  onDoubleClick,
  onKeyPress,
  touched,
  textOnly = false
}) => {
  const _className = mergeClassNames(
    'rounded-lg w-full px-[12px] py-[10px] mb-[8px] resize-none dark:bg-neutral-900 dark:border-none dark:text-neutral-100',
    border ||
      'border border-solid border-neutral-300 dark:bg-neutral-900 dark:border-neutral-600',
    (disabled || readOnly) && 'cursor-not-allowed',
    touched && error && 'error'
  );

  const textareaRef = useRef();

  // grow height
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + 'px';
    }
  }, [value]);

  const handleBlur = (el) => onBlur && onBlur(el);
  const handleChange = (el) => {
    if (onChange) onChange(el);
    textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
  };
  const handleDoubleClick = (el) => onDoubleClick && onDoubleClick(el);
  const handleKeyPress = (el) => onKeyPress && onKeyPress(el);

  if (disabled && textOnly) {
    return (
      <Typography variant="medium" weight="normal" className="!line-clamp-5">
        {value || '-'}
      </Typography>
    );
  }

  return (
    <div className={`form-input mb-[8px] w-full ${wrapperClassName}`}>
      <textarea
        ref={textareaRef}
        id={id}
        type={type}
        name={name}
        value={value}
        className={mergeClassNames(className, _className)}
        rows={rows}
        disabled={disabled}
        readOnly={readOnly}
        autoFocus={autoFocus}
        aria-invalid={!!error}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={handleChange}
        onDoubleClick={handleDoubleClick}
        onKeyPress={handleKeyPress}
      />
      {touched && error && (
        <Typography color="#ef4444" variant="xsmall" gutterBottom>
          {error}
        </Typography>
      )}
    </div>
  );
};
