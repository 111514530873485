import React from 'react';
import { Typography } from '/design-systems/Atoms/Typography';
import { Avatar } from '/design-systems/Atoms/Avatar';

export const ProductCampaign = ({
  campaignLogo,
  title,
  onClick,
  staticFile = false
}) => {
  return (
    <div
      className="flex w-full cursor-pointer items-center gap-2"
      onClick={onClick}
    >
      <div className="h-[48px] w-[48px] min-w-[48px] rounded-md">
        <Avatar
          bordered
          src={campaignLogo}
          size={48}
          resolution="m"
          roundedSize="xl"
          staticFile={staticFile}
        />
      </div>
      <div className="flex w-full items-center">
        <Typography weight="medium" overflow="!line-clamp-2">
          {title}
        </Typography>
      </div>
    </div>
  );
};
