import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';
import { createTokenPurchase, initiateTokenPurchase } from '/services/joynApi/users/token-purchase';

export const useAssetPurchase = () => {
  const { mutateAsync: onCreateAssetPurchase } = useMutation(async ({ listingId }) => {
    const res = await createTokenPurchase({ listingId });
    return res.data.data;
  });

  const { mutateAsync: onInitiateAssetPurchase } = useMutation(async ({ id }) => {
    const res = await initiateTokenPurchase({ id });
    return res.data.data;
  });

  return useMemo(
    () => ({
      onCreateAssetPurchase,
      onInitiateAssetPurchase,
    }),
    [onCreateAssetPurchase, onInitiateAssetPurchase]
  );
};
