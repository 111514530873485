import React, { useEffect, useCallback, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import Placeholder from '@tiptap/extension-placeholder';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import FloatingMenu from '@tiptap/extension-floating-menu';
import StarterKit from '@tiptap/starter-kit';
import Heading from '@tiptap/extension-heading';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Underline from '@tiptap/extension-underline';
import Youtube from '@tiptap/extension-youtube';
import { mergeAttributes } from '@tiptap/core';

import PopUpMenu from './PopUpMenu';
import FloatMenu from './FloatMenu';
import { VideoNode, getEditorVideoElement } from './Extensions';
import { Button } from '/design-systems/Atoms/Button';
import { Typography } from '/design-systems/Atoms/Typography';

import { headingClasses } from '/utils/constants';
import { useModalManager, MODALS } from '/context/ModalManager';
import { mergeClassNames } from '/utils/string';
import { addClassNames, removeClassNames } from '/utils/classnames';

const FILE_UPLOAD_MODAL_ID = 'image-upload-modal';

export const getEditor = (content, placeholder) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          HTMLAttributes: {
            class: `list-disc`
          }
        }
      }),
      Placeholder.configure({
        placeholder: placeholder || 'Write something …'
      }),
      Heading.configure({ levels: [1, 2, 3, 4, 5, 6] }).extend({
        renderHTML({ node, HTMLAttributes }) {
          const hasLevel = this.options.levels.includes(node.attrs.level);
          const level = hasLevel ? node.attrs.level : this.options.levels[0];

          return [
            `h${level}`,
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
              class: `${headingClasses[level]}`
            }),
            0
          ];
        }
      }),
      Link.configure({
        HTMLAttributes: {}
      }),
      Image.configure({
        HTMLAttributes: {
          class: 'mx-auto my-0'
        }
      }),
      Underline,
      Youtube.configure({
        controls: false
      }),
      BubbleMenu,
      FloatingMenu,
      /** Custom Extensions */
      VideoNode.configure({
        HTMLAttributes: {
          class: 'mx-auto'
        }
      })
    ],
    // autofocus: 'end',
    content
  });

  return editor;
};

export const Editor = ({
  editable,
  editMode, // for campaign
  content,
  placeholder,
  loading,
  hideSave,
  onChange,
  name,
  hideImg,
  withVideo = false,
  error,
  touched,
  className = '',
  editorContentClassName = '',
  withMinHeight = true,
  isGallerySection = false,
  id = 'tiptap-editor'
}) => {
  const editor = getEditor(content, placeholder);
  const { showModal, closeModal } = useModalManager();

  const [editing, setEditing] = useState(editMode || false);

  const setLink = useCallback(() => {
    if (editor.isActive('link')) {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
    } else {
      const previousUrl = editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();

        return;
      }

      // update link
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    }
  }, [editor]);

  const handleSaveClick = useCallback(() => {
    setEditing(false);
    onChange(editor.getHTML());
  }, [editor, onChange]);

  const handleAddFile = useCallback(
    ({ type, src }) => {
      switch (type) {
        case 'image':
          editor.chain().focus().setImage({ src }).run();
          break;
        case 'video':
          if (src?.includes('youtube')) {
            editor.chain().focus().setYoutubeVideo({ src }).run();
          } else {
            editor
              .chain()
              .focus()
              .insertContent(getEditorVideoElement({ src }))
              .run();
          }
          break;
      }
      handleSaveClick();
      closeModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, handleSaveClick]
  );

  const updateFloatMenuVisibility = useCallback((hide = false) => {
    const floatMenuElement = document.querySelector('.editor-floating-menu');
    if (!floatMenuElement) return;

    if (hide) {
      floatMenuElement.className = addClassNames({
        current: floatMenuElement.className,
        classNames: ['!hidden']
      });
    } else {
      floatMenuElement.className = removeClassNames({
        current: floatMenuElement.className,
        classNames: ['!hidden']
      });
    }
  }, []);

  const handleAction = (e, id, params) => {
    e.preventDefault();
    e.stopPropagation();
    switch (id) {
      case 'bold':
        editor.chain().focus().toggleBold().run();
        break;
      case 'italic':
        editor.chain().focus().toggleItalic().run();
        break;
      case 'underline':
        editor.chain().focus().toggleUnderline().run();
        break;
      case 'heading':
        editor.chain().focus().toggleHeading(params).run();
        break;
      case 'bulletList':
        editor.chain().focus().toggleBulletList().run();
        break;
      case 'link':
        setLink();
        break;
      case 'image':
      case 'video':
        /** Hide float menu to not be over modal */
        updateFloatMenuVisibility(true);

        showModal({
          modalId: MODALS.FILE_UPLOAD_MODAL,
          props: {
            id: FILE_UPLOAD_MODAL_ID,
            type: id,
            onClose: () => {
              updateFloatMenuVisibility(false);
              closeModal?.();
            },
            onAddFile: (src) => {
              updateFloatMenuVisibility(false);
              handleAddFile({ type: id, src });
            }
          }
        });
        break;
    }
  };

  const handleEditorClick = () => {
    setEditing(true);
  };

  const handleUpdate = useCallback(
    (event) => {
      const path = event.path || (event.composedPath && event.composedPath());

      if (
        editable &&
        !path.filter(
          (item) => item.id === id || item.id === FILE_UPLOAD_MODAL_ID
        ).length
      ) {
        const val = editor.getHTML();
        if (val !== content) {
          onChange?.(val);
        }

        if (!editMode) {
          setEditing(false);
        }
      }
    },
    [content, editMode, editable, editor, id, onChange]
  );

  useEffect(() => {
    window.addEventListener('mousedown', handleUpdate);

    return () => {
      window.removeEventListener('mousedown', handleUpdate);
    };
  }, [id, editor, editable, content, handleUpdate]);

  const getSaveBtn = () => {
    return (
      <div className="mb-4 flex justify-end">
        <Button
          color="primary"
          variant="tertiary"
          disabled={loading}
          onClick={handleSaveClick}
        >
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (!editor) return;
    editor.setEditable(editable && editing);
  }, [editor, editable, editing]);

  useEffect(() => {
    if (!editor) return;
    editor.commands.setContent(content);
  }, [editor, content]);

  return (
    <>
      <div className={`relative ${className}`}>
        {!hideSave && editable && editing && getSaveBtn()}
        <div
          id={id}
          name={name}
          onClick={handleEditorClick}
          className={mergeClassNames(
            'tiptap-editor',
            editable && !editing && 'cursor-text p-[12px]',
            editing && editable && 'editing',
            isGallerySection && 'gallery-section',
            withMinHeight && 'min-h-[120px]'
          )}
        >
          {editable && (
            <>
              <PopUpMenu editor={editor} onAction={handleAction} />
              <FloatMenu
                editor={editor}
                onAction={handleAction}
                hideImg={hideImg}
                withVideo={withVideo}
              />
            </>
          )}
          <EditorContent
            editor={editor}
            className={mergeClassNames('leading-7', editorContentClassName)}
          />
        </div>
        {touched && error && (
          <Typography color="#ef4444" variant="xsmall" gutterBottom>
            {error}
          </Typography>
        )}
      </div>
    </>
  );
};
