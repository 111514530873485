import { useMemo } from 'react';
import { usePaginatedQuery } from './usePaginatedQuery';
import { getContests } from '/services/joynApi/users/contest';
import { QUERY_KEYS } from '/utils/queries';

const contestsFormatter = (res) => ({
  data: res.data.data,
  count: res.data.data.count,
});

export const useContests = (projectId) => {
  const {
    isLoading: isLoadingContests,
    isFetchingNextPage: isLoadingNextContests,
    data: contestsData,
    hasNextPage: hasMoreContests,
    fetchNextPage: fetchMoreContests,
    refetch: refetchContests,
  } = usePaginatedQuery(
    [QUERY_KEYS.CONTEST.GET_ALL, projectId],
    getContests,
    contestsFormatter,
    {
      enabled: !!projectId,
    },
    { projectId }
  );

  const { contests } = useMemo(
    () => ({
      contests: contestsData?.map((item) => item.data).flat() ?? [],
    }),
    [contestsData]
  );

  return useMemo(
    () => ({
      contests,
      isLoadingContests,
      hasMoreContests,
      isLoadingNextContests,
      fetchMoreContests,
      refetchContests,
    }),
    [contests, isLoadingContests, hasMoreContests, isLoadingNextContests, fetchMoreContests, refetchContests]
  );
};
