import React from 'react';

export const ProgressBar = ({ value = 0 }) => {
  return (
    <div className='progress-bar block h-2 overflow-hidden rounded-lg'>
      <div
        className='h-2'
        style={{
          width: `${value}%`,
        }}
      />
    </div>
  );
};
