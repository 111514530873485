import React from 'react';
import { Tooltip } from '/design-systems/Atoms/Tooltip';
import { Typography } from '/design-systems/Atoms/Typography';

import { PROMPT_LICENSES } from '/config/prompt';

export const ProductLicense = ({ usageRight }) => {
  return (
    <Tooltip
      contentHtml={
        <>
          <Typography color="#fff" weight="bold" className="mb-1">
            {PROMPT_LICENSES[usageRight.title]?.title}
          </Typography>
          <Typography color="#fff">
            {PROMPT_LICENSES[usageRight.title]?.assetContent}
          </Typography>
        </>
      }
    >
      <div className="flex gap-x-1">
        <Typography variant="medium" weight="semibold">
          License:
        </Typography>
        <Typography variant="medium">
          {PROMPT_LICENSES[usageRight.title]?.title}
        </Typography>
      </div>
    </Tooltip>
  );
};
