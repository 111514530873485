import React from 'react';
import { useRouter } from 'next/router';
import { BackButton } from '/design-systems/Molecules/Buttons/BackButton';
import { Typography } from '/design-systems/Atoms/Typography';

export const PageContainer = ({
  px,
  py,
  pt,
  width,
  className,
  headerClassName: _headerClassName,
  children,
  backLink,
  backUrl,
  pageTitle = ''
}) => {
  const router = useRouter();
  const headerClassName = [
    px !== undefined ? 'px-[' + px + 'px]' : 'px-4 md:px-[24px]',
    py !== undefined ? 'py-[' + py + 'px]' : 'py-4 md:py-[40px]',
    'xl:px-0 mx-auto',
    'relative',
    pt !== undefined ? 'pt-' + pt : 'pt-10',
    width || 'w-full max-w-[920px] xl:min-w[840px]',
    _headerClassName
  ].join(' ');

  const handleBack = () => {
    if (backUrl) {
      router.push(backUrl);
      return;
    }

    router.back();
  };

  return (
    <div className={`flex justify-center ${className || ''}`}>
      <div className={headerClassName}>
        {backLink &&
          (pageTitle ? (
            <div className="mb-8 flex w-full items-center">
              <BackButton
                className="absolute h-fit w-fit"
                onClick={handleBack}
              />

              <Typography
                heading
                variant="h3"
                weight="semibold"
                className="w-full flex-1 text-center "
              >
                {pageTitle}
              </Typography>
            </div>
          ) : (
            <div className="mb-8 flex">
              <BackButton
                className="flex h-fit w-fit gap-x-3"
                onClick={handleBack}
                title="Back"
              />
            </div>
          ))}
        {children}
      </div>
    </div>
  );
};
