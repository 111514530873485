import { useQuery } from '@tanstack/react-query';
import { getTokenActivity } from '/services/joynApi/users/token';
import { QUERY_KEYS } from '/utils/queries';
import { useMemo } from 'react';
import { isSupportedChain } from '/utils/web3';

export const useTokenActivity = ({ tokenId, tokenAddress, blockchain }) => {
  const { data: tokenActivities, isLoading: isLoadingTokenActivities } =
    useQuery(
      [QUERY_KEYS.TOKEN_ACTIVITY.GET, tokenId, tokenAddress],
      () => getTokenActivity({ tokenAddress, tokenId, chain: blockchain }),
      {
        enabled:
          !!tokenId &&
          !!tokenAddress &&
          isSupportedChain({ chain: blockchain }),
        select: (res) => res.data.data
      }
    );

  return useMemo(
    () => ({
      tokenActivities,
      isLoadingTokenActivities
    }),
    [isLoadingTokenActivities, tokenActivities]
  );
};
