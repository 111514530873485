import axios from '/services/axios';
import { generateQueryParams } from '/utils/url';

const getPromptSubmission = ({ submissionId, withAssetGallery = false }) => {
  const query = generateQueryParams({ withAssetGallery });
  return axios.get(`/prompt-submission/${submissionId}?${query}`);
};

const getMyPromptSubmissions = ({
  promptId = null,
  showRequirementStepCompleted,
  onlyHidden,
  showHidden,
  onlyShortlisted,
  excludeWinningSubmissions
}) => {
  return axios.get(
    `/my/prompt-submissions?${generateQueryParams({
      promptId,
      showRequirementStepCompleted,
      onlyHidden,
      showHidden,
      onlyShortlisted,
      excludeWinningSubmissions
    })}`
  );
};

const getPromptSubmissions = ({
  promptId = null,
  size = 12,
  page = 1,
  search = '',
  sortBy,
  showRequirementStepCompleted,
  onlyHidden,
  showHidden,
  onlyShortlisted,
  onlyListed,
  excludeWinningSubmissions,
}) => {
  const url = `/prompt-submissions?${generateQueryParams({
    promptId,
    search,
    sortBy,
    size,
    page,
    showRequirementStepCompleted,
    onlyHidden,
    showHidden,
    onlyShortlisted,
    onlyListed,
    excludeWinningSubmissions,
  })}`;
  return axios.get(url);
};

const getPromptContributors = ({ promptIds = [] }) => {
  return axios.get(`/contributor/prompt-submissions?promptIds=${promptIds}`);
};

const createPromptSubmission = (payload) => {
  return axios.post(`/prompt-submission`, payload);
};

const updatePromptSubmission = (id, payload) => {
  return axios.put(`/prompt-submission/${id}`, payload);
};

const deletePromptSubmission = (id) => {
  return axios.delete(`/prompt-submission/${id}`);
};

export {
  createPromptSubmission,
  getPromptSubmission,
  getMyPromptSubmissions,
  getPromptSubmissions,
  updatePromptSubmission,
  deletePromptSubmission,
  getPromptContributors
};
