import axios from '/services/axios';
import defaultAxios from 'axios';
import { removeSpaces } from '/utils/url';

export const getPresignedData = ({ key, fileName, fileType }) => {
  return axios.post(`/presigned/`, {
    key,
    contentType: fileType,
    extension: removeSpaces(fileName)
  });
};

export const deleteFile = (id) => {
  return axios.delete(`/file/${id}`);
};

export const uploadImageToS3 = ({
  url,
  presignedData,
  file,
  onUploadProgress
}) => {
  const form = new FormData();
  Object.entries(presignedData).forEach(([field, value]) => {
    form.append(field, value);
  });
  form.append('file', file);
  return defaultAxios.post(url, form, {
    onUploadProgress: (progressEvent) => {
      onUploadProgress &&
        onUploadProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
    }
  });
};

const numberWithName = ({ name, files }) => {
  return files.filter((f) => f.name === name).length;
};

const getPlaceholders = (files) => {
  const obj = {};
  files.forEach(({ name }) => {
    obj[name] = [];
  });

  return obj;
};

export const presignAndUpload = async ({ files, payload, fileAsArray }) => {
  const promises = files.map(({ entity, keyName, file }) => {
    return getPresignedData({
      key: `${entity}/${keyName}`,
      fileName: file.name,
      fileType: file.type
    });
  });

  const values_1 = await Promise.all(promises);
  const placeholders = getPlaceholders(files);
  values_1.forEach(async (res, index) => {
    const file_1 = files[index].file;
    const url = res.data.data.url;
    const fields = res.data.data.fields;
    const filePayload = {
      url: url + '/' + fields.key,
      fileName: file_1.name,
      type: file_1.type,
      size: file_1.size
    };
    if (fileAsArray || numberWithName({ name: files[index].name, files }) > 1) {
      placeholders[files[index].name].push(filePayload);
      payload[files[index].name] = placeholders[files[index].name];
    } else {
      payload[files[index].name] = filePayload;
    }

    await uploadImageToS3({
      url,
      presignedData: fields,
      file: file_1
    });
  });
  const payload_1 = payload;
  return payload_1;
};
