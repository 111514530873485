import React from 'react';
import { ChainButton } from '../../Buttons/ChainButton';
import { ProductOwner } from '../../Product/ProductOwner';
import PlaceholderSVG from '/images/placeholder.svg';
import { objectBlank } from '/utils/object';
import { Divider } from '/design-systems/Atoms/Divider';
import { Image } from '/design-systems/Atoms/Image';
import { Typography } from '/design-systems/Atoms/Typography';
import { ModalTitleRow } from '/design-systems/Molecules/Modals/ModalTitleRow';

export const OfferModal = ({
  onSubmit,
  isSubmitting,
  isDisabled,
  assetChainId,
  assetCreator,
  assetCreatorAddress,
  title,
  assetImage,
  modalTitle,
  modalContent,
  modalActionButtonText,
  modalActionButtonLoadingText,
  onClose
}) => (
  <div className="flex flex-col gap-y-6">
    <ModalTitleRow title={modalTitle} onClose={onClose} />

    <Divider size="none" />

    <div className="flex w-full items-center">
      <Image
        src={assetImage?.fileUrl}
        fileType={assetImage?.fileType}
        placeholder={PlaceholderSVG.src}
        alt={assetImage?.fileName}
        className="h-[100px] w-[100px] rounded-lg"
        style={{ objectFit: 'cover' }}
        staticFile={assetImage?.staticFile}
      />
      <div className="ml-4">
        {title && (
          <Typography weight="semibold" variant="small" className="mb-2">
            {title}
          </Typography>
        )}
        {(assetCreator || assetCreatorAddress) && (
          <ProductOwner
            owner={assetCreator}
            address={assetCreatorAddress}
            tweet={''}
            withRedirectProfile={!objectBlank(assetCreator)}
          />
        )}
      </div>
    </div>

    <Divider size="none" />

    {modalContent}

    <ChainButton
      id="offer-modal-btn"
      chainId={assetChainId}
      title={
        isSubmitting ? modalActionButtonLoadingText : modalActionButtonText
      }
      disabled={isSubmitting || isDisabled}
      handleCallbackAfterSwitchNetwork={false}
      onClick={onSubmit}
      loading={isSubmitting}
    />
  </div>
);
