import React from 'react';

import { likeAsset, unlikeAsset } from '/services/joynApi/users/assets';
import { MODALS } from '/context/ModalManager';
import { emptyArray, objectBlank } from '/utils/object';
import { getAvatar } from '/utils/image';

import { CheckCircleIcon } from '@heroicons/react/solid';
import { Spinner } from '/design-systems/Atoms/Spinner';
import { ORDER_STATUSES } from './constants';
import { toShort18 } from './string';
import { findLowerNumber, fromBasisPointToPercentage } from './number';
import { ROUTES } from '/config/routes';

export const ASSET_ORDER_BY_OPTION_VALUES = {
  RANDOM: 'random',
  NEWEST: 'newest',
  OLDEST: 'oldest',
  RECENTLY_FEATURED: 'recentlyFeatured'
};

export const ASSET_ORDER_BY_OPTIONS = [
  { title: 'Random', value: ASSET_ORDER_BY_OPTION_VALUES.RANDOM, tabs: [0, 1] },
  { title: 'Newest', value: ASSET_ORDER_BY_OPTION_VALUES.NEWEST, tabs: [0, 1] },
  { title: 'Oldest', value: ASSET_ORDER_BY_OPTION_VALUES.OLDEST, tabs: [0, 1] },
  {
    title: 'Recently featured',
    value: ASSET_ORDER_BY_OPTION_VALUES.RECENTLY_FEATURED,
    tabs: [0]
  }
];

export const isAssetOnJoyn = (asset) => Boolean(asset?.User);

export const getFilesFromAsset = (asset) => {
  return isAssetOnJoyn(asset) ? asset?.Files : asset?.files;
};

export const isAssetUsageRight = (usageRight) => {
  return !usageRight?.title
    .toLowerCase()
    .includes('transfer of ownership to the project');
};

export const assetUsageRights = (usageRights) => {
  return usageRights?.filter((ur) => isAssetUsageRight(ur));
};

const likeOrDislike = ({ assetId, liked }) => {
  if (liked) {
    return unlikeAsset({ assetId });
  } else {
    return likeAsset({ assetId });
  }
};

export const handleLikeAsset = ({
  e,
  currentUser,
  assetId,
  liked,
  redirectToCompleteProfile,
  navigateToLogin,
  showModal,
  closeModal,
  router
}) => {
  e.stopPropagation();
  // if user is not logged in, redirect to login page
  // if user logged in user not verified, show verify account modal
  // otherwise, call onLike function
  if (objectBlank(currentUser)) {
    closeModal();
    navigateToLogin({ isForce: true });
    return;
  } else if (!currentUser.verified) {
    showModal({
      modalId: MODALS.VERIFY_ACCOUNT_MODAL,
      props: {
        onClose: closeModal,
        onVerify: () => {
          localStorage.setItem('returnUrl', router.asPath);
          redirectToCompleteProfile();
          closeModal();
        }
      }
    });
    return;
  } else {
    return likeOrDislike({ assetId, liked });
  }
};

export const getAssetUpdatePayload = ({ files, ...rest }) => {
  return {
    ...rest,
    files:
      files
        ?.filter((file) => !file.uploaded && !file.error)
        .map((file) => ({
          url: file.url,
          fileName: file.fileName,
          type: file.type,
          size: file.size
        })) ?? []
  };
};

export const ASSET_MINT_STEPS = {
  START: 'Create new NFT',
  WAIT_FOR_CONFIRM: 'Waiting for confirmation...',
  TX_CONFIRMED: 'Your NFT is being minted',
  MINTED: 'Your NFT has been minted!',
  WAIT_FOR_MINTING: 'Waiting for minting...'
};

export const renderMintStepIcon = (step) => {
  switch (step) {
    case ASSET_MINT_STEPS.WAIT_FOR_CONFIRM:
    case ASSET_MINT_STEPS.TX_CONFIRMED:
    case ASSET_MINT_STEPS.WAIT_FOR_MINTING:
      return <Spinner />;
    case ASSET_MINT_STEPS.MINTED:
      return <CheckCircleIcon className="h-10 w-10" />;
    default:
      return <></>;
  }
};

export const MAX_TOKEN_SUPPLY = 100000;

/**
 * get latest sale and listing price from Orders
 */
export const getPricesFromOrders = ({ orders, listings }) => {
  if (emptyArray(orders) && emptyArray(listings))
    return { price: '', lastSale: '' };

  const saleOrder = orders?.find(
    (order) => order.status === ORDER_STATUSES.FULFILLED
  );

  let listingOrder;
  listingOrder = orders?.find((order) =>
    [ORDER_STATUSES.CREATED, ORDER_STATUSES.PARTIALLY_FULFILLED].includes(
      order.status
    )
  );

  const price = findLowerNumber({
    num1: listings?.[0]?.price,
    num2: listingOrder?.priceUnit
  });

  return {
    curatorFeePercentage: fromBasisPointToPercentage(
      listingOrder?.curatorFeePercentage
    ),
    price: price ? toShort18(price) : '',
    lastSale: saleOrder ? toShort18(saleOrder.priceUnit) : ''
  };
};

export const assetOwnerMapper = ({ owner, ownerAddress, quantity, User }) => ({
  quantity: +quantity,
  ...(User || {}),
  avatar: getAvatar(User),
  WalletAddresses: [
    {
      address: ownerAddress || owner,
      isMain: true
    }
  ]
});

/**
 * Get asset detail page link from gallery or assetId
 */
export const getAssetDetailPageLink = ({ assetId, customUrl }) => {
  if (customUrl) {
    return ROUTES.asset.view(customUrl);
  }
  if (assetId) {
    return ROUTES.asset.view(assetId);
  }
  return '';
};
