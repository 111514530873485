import { useMemo, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { usePaginatedQuery } from './usePaginatedQuery';

import { PAGE_LOAD_MODES, PAGE_SIZE } from '/utils/constants';

export const getSubmissionsFromPaginatedData = (data, formatter) => ({
  data:
    data?.pages?.map((submission) => formatter(submission).data)?.flat() ?? [],
  count: formatter(data?.pages?.[0])?.count ?? 0
});

export const useSubmissions = (
  {
    promptId,
    onlyHidden = false,
    showHidden = false,
    onlyShortlisted = false,
    sortBy,
    onlyListed = false,
    showRequirementStepCompleted = false,
    excludeWinningSubmissions = false,
    page,
    search,
    pageLoadMode
  },
  queryKeys,
  fetcher,
  formatter,
  parser,
  enabled = true
) => {
  const queryClient = useQueryClient();

  const {
    isLoading: isLoadingSubmissions,
    isFetchingNextPage: isFetchingNextSubmissions,
    hasNextPage: hasMoreSubmissions,
    fetchNextPage: fetchMoreSubmissions,
    refetch: onReloadSubmissions,
    isFetched: isLoadedSubmissions
  } = usePaginatedQuery(
    queryKeys,
    fetcher,
    formatter,
    { enabled: !!promptId && enabled },
    {
      promptId,
      onlyHidden,
      showHidden,
      onlyShortlisted,
      sortBy,
      onlyListed,
      showRequirementStepCompleted,
      excludeWinningSubmissions,
      search,
      ...(pageLoadMode === PAGE_LOAD_MODES.PAGINATION ? { page } : {})
    }
  );

  const data = queryClient?.getQueryData(queryKeys);

  const { data: submissions, count: totalSubmissions } = useMemo(
    () => getSubmissionsFromPaginatedData(data, formatter),
    [data, formatter]
  );

  const handleSetSubmissions = useCallback(
    (newSubmissions) => {
      const chunks = {
        pageParams: [],
        pages: []
      };
      for (let i = 0; i < newSubmissions.length; i += PAGE_SIZE) {
        const chunk = newSubmissions.slice(i, i + PAGE_SIZE);
        chunks.pages.push(parser(chunk, totalSubmissions));
        chunks.pageParams.push(chunks.pageParams.length + 1);
      }
      queryClient?.setQueriesData(queryKeys, chunks);
    },
    [queryKeys, queryClient, totalSubmissions, parser]
  );

  const removeSubmissions = useCallback(() => {
    queryClient.removeQueries(queryKeys);
  }, [queryKeys, queryClient]);

  return useMemo(
    () => ({
      isLoadingSubmissions,
      isFetchingNextSubmissions,
      isLoadedSubmissions,
      submissions,
      totalSubmissions,
      hasMoreSubmissions,
      fetchMoreSubmissions,
      onSetSubmissions: handleSetSubmissions,
      onReloadSubmissions,
      removeSubmissions
    }),
    [
      isLoadingSubmissions,
      isFetchingNextSubmissions,
      isLoadedSubmissions,
      submissions,
      totalSubmissions,
      hasMoreSubmissions,
      fetchMoreSubmissions,
      handleSetSubmissions,
      onReloadSubmissions,
      removeSubmissions
    ]
  );
};
