import React from 'react';
import { Button } from '/design-systems/Atoms/Button';
import { Typography } from '/design-systems/Atoms/Typography';
import { PlusIcon, MinusIcon } from '@heroicons/react/outline';

export const QuantitySelector = ({
  quantity,
  onQuantityChange,
  minusButtonDisabled,
  plusButtonDisabled
}) => {
  return (
    <div className="flex items-center gap-2 rounded-md border border-neutral-300 bg-white p-2 dark:border-neutral-700 dark:bg-black">
      <Button
        id="quantitySelectorMoins"
        color="white"
        disabled={minusButtonDisabled}
        onClick={() => onQuantityChange(quantity - 1)}
        className="!p-0"
      >
        <MinusIcon
          className={`h-6 w-6 ${
            minusButtonDisabled && '!text-neutral-300 dark:!text-neutral-700'
          }`}
        />
      </Button>
      <Typography
        id="token-quantity"
        variant="medium"
        className="min-w-[32px] text-center"
      >
        {quantity}
      </Typography>
      <Button
        id="quantitySelectorPlus"
        color="white"
        disabled={plusButtonDisabled}
        onClick={() => onQuantityChange(quantity + 1)}
        className="!p-0"
      >
        <PlusIcon
          className={`h-6 w-6 ${
            plusButtonDisabled && '!text-neutral-300 dark:!text-neutral-700'
          }`}
        />
      </Button>
    </div>
  );
};
