import axios from '/services/axios';

const shortlistSubmission = ({ promptId, submissionIds }) => {
  return axios.put(`/prompt-submissions/${promptId}/shortlist`, {
    submissionIds,
  });
};

const hideSubmission = ({ promptId, submissionIds }) => {
  return axios.put(`/prompt-submissions/${promptId}/hide`, { submissionIds });
};

const unshortlistSubmission = ({ promptId, submissionIds }) => {
  return axios.put(`/prompt-submissions/${promptId}/unshortlist`, {
    submissionIds,
  });
};

const unhideSubmission = ({ promptId, submissionIds }) => {
  return axios.put(`/prompt-submissions/${promptId}/unhide`, { submissionIds });
};

export { shortlistSubmission, hideSubmission, unshortlistSubmission, unhideSubmission };
