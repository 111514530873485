import axios from '/services/axios';
import { generateQueryParams } from '/utils/url';

const getV2ActiveListings = ({ tokenId, galleryId }) => {
  return axios.get(`/v2/listing/${tokenId}/active${galleryId ? `?galleryId=${galleryId}` : ''}`);
};

const getV2ListingOpenCuratorFeeConsiderations = ({ orderId, galleryId }) => {
  return axios.get(`/v2/listing/${orderId}/open-curator-fee-considerations?${generateQueryParams({ galleryId })}`);
};

const getV2CheapestActiveListing = ({ tokenId, galleryId }) => {
  return axios.get(`/v2/listing/${tokenId}/cheapest-active${galleryId ? `?galleryId=${galleryId}` : ''}`);
};

const getV2ListingsByUser = ({ userId, page, size }) => {
  return axios.get(
    `/v2/listings/${userId}?${generateQueryParams({ page, size })}`
  );
};

export {
  getV2ActiveListings,
  getV2CheapestActiveListing,
  getV2ListingsByUser,
  getV2ListingOpenCuratorFeeConsiderations
};
