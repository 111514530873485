import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProductOwner } from '../../Product/ProductOwner';
import { QuantitySelector } from '../../QuantitySelector';
import { useErc20Contract } from '/hooks/on-chain/useErc20Contract';
import PlaceholderSVG from '/images/placeholder.svg';
import {
  OFFER_DURATIONS,
  DURATIONS,
  bidIncrementPercentage
} from '/utils/constants';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';
import { objectBlank } from '/utils/object';
import { parseToWei } from '/utils/web3';
import {
  getWrappedNativeTokenContractAddress,
  getCurrencies
} from '/utils/contracts';
import { ChainButton } from '../../Buttons/ChainButton';
import { XIcon } from '@heroicons/react/outline';
import { Divider } from '/design-systems/Atoms/Divider';
import { DropDownInput } from '/design-systems/Atoms/DropDownInput';
import { FormInput } from '/design-systems/Atoms/FormInput';
import { Image } from '/design-systems/Atoms/Image';
import { Typography } from '/design-systems/Atoms/Typography';
import dayjs from 'dayjs';
import { ethers } from 'ethers';
import { toShort18 } from '/utils/string';
import { FormTextArea } from '/design-systems/Atoms/FormTextArea';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNetwork } from 'wagmi';
import TimedDurationDropdown from '/design-systems/Atoms/TimedDurationDropdown';

export const MakeOfferForm = ({
  onSubmit,
  onClose,
  assetChainId,
  assetCreator,
  assetCreatorAddress,
  title,
  assetImage,
  isOneOfMany,
  totalSupply,
  tokenId,
  isSubmitting,
  isAvailableForPlaceBid,
  highestOffer,
  auctionEndTime,
  reservePrice
}) => {
  const currentUser = useSelector((state) => state.user.current);
  const currentWalletAddress = retrieveMainWalletAddressFromWalletAddresses(
    currentUser?.WalletAddresses ?? []
  )?.address;

  const modalTitle = useMemo(
    () => (isAvailableForPlaceBid ? 'Place a bid' : 'Make an offer'),
    [isAvailableForPlaceBid]
  );

  const submitLabel = useMemo(() => {
    if (isAvailableForPlaceBid) {
      return isSubmitting ? 'Placing bid' : 'Place bid';
    }

    return isSubmitting ? 'Making offer' : 'Make offer';
  }, [isAvailableForPlaceBid, isSubmitting]);

  const defaultOfferDuration = OFFER_DURATIONS.find(
    (duration) => duration.title === DURATIONS.SEVEN_DAYS
  )?.value;

  const { timedOfferBidIncrement } = useFlags();

  const { chain } = useNetwork();
  const WETH_CONTRACT_ADDRESS = getWrappedNativeTokenContractAddress({
    chainId: chain?.id
  });
  const CURRENCIES = useMemo(() => getCurrencies(chain?.id), [chain]);

  const { balance } = useErc20Contract({
    tokenAddress: WETH_CONTRACT_ADDRESS,
    walletAddress: currentWalletAddress
  });

  const getCurrency = useCallback(
    (selectedCurrency) => {
      return CURRENCIES.find(({ value }) => selectedCurrency === value)?.title;
    },
    [CURRENCIES]
  );

  const getTotalAmount = useCallback(({ offerPrice, amount }) => {
    if (amount > 0 && offerPrice > 0) {
      return amount * offerPrice;
    }

    return 0;
  }, []);

  const hasOffer = useMemo(() => !!highestOffer, [highestOffer]);

  const isValidBid = useCallback(
    ({ offerPrice }) => {
      const bidValue = ethers.parseEther(offerPrice.toString());
      const highestBid =
        hasOffer && timedOfferBidIncrement
          ? ethers.toBigInt(highestOffer?.priceUnit)
          : ethers.toBigInt(reservePrice);
      const minimumBid =
        hasOffer && timedOfferBidIncrement
          ? highestBid +
            (highestBid * ethers.toBigInt(bidIncrementPercentage)) /
              ethers.toBigInt('10000')
          : highestBid;
      if (bidValue >= minimumBid) {
        return true;
      }

      return false;
    },
    [hasOffer, highestOffer, reservePrice, timedOfferBidIncrement]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        amount: 1,
        currencyContractAddress: WETH_CONTRACT_ADDRESS,
        endTime: isAvailableForPlaceBid
          ? dayjs(auctionEndTime)?.add(48, 'hours').unix()
          : defaultOfferDuration,
        note: ''
      }}
      validate={({ offerPrice, amount }) => {
        const errors = {};
        if (!offerPrice) {
          errors.offerPrice = 'Amount is a required field';
        } else if (getTotalAmount({ offerPrice, amount }) > balance) {
          errors.offerPrice = 'Insufficient Balance';
        } else if (isAvailableForPlaceBid && !isValidBid({ offerPrice })) {
          errors.offerPrice =
            hasOffer && timedOfferBidIncrement
              ? `Bid value must be 10% greater than current offer (${toShort18(
                  highestOffer?.priceUnit
                )} ${getCurrency(WETH_CONTRACT_ADDRESS)}).`
              : `Bid value must be greater than or equals to Reserve Price (${toShort18(
                  reservePrice
                )} ${getCurrency(WETH_CONTRACT_ADDRESS)})`;
        }

        return errors;
      }}
      onSubmit={async (values) => {
        const payload = {
          ...values,
          offerPrice: parseToWei({
            amount: String(values.offerPrice)
          }).toString(),
          walletAddress: currentWalletAddress,
          tokenId
        };
        if (!payload?.note) delete payload.note;

        try {
          onSubmit(payload);
        } catch (error) {
          console.error('Bid error', error);
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit, setValues }) => (
        <form
          className="flex flex-col items-center gap-y-6"
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <div className="relative w-full text-center">
            <button
              className="absolute bottom-0 left-0 top-0 cursor-pointer"
              onClick={onClose}
            >
              <XIcon className="h-6 w-6 stroke-[#111827]" />
            </button>
            <Typography heading variant="h6" weight="semibold">
              {modalTitle}
            </Typography>
          </div>

          <Divider size="smallest" />

          <div className="flex w-full items-center gap-4">
            <Image
              src={assetImage?.fileUrl}
              fileType={assetImage?.fileType}
              placeholder={PlaceholderSVG.src}
              alt={assetImage?.fileName}
              className="h-[100px] w-[100px] rounded-lg"
              style={{ objectFit: 'cover' }}
              staticFile={assetImage?.staticFile}
            />
            <div className="flex flex-col gap-2">
              {title && (
                <Typography weight="semibold" variant="medium" className="">
                  {title}
                </Typography>
              )}
              {(assetCreator || assetCreatorAddress) && (
                <ProductOwner
                  owner={assetCreator}
                  address={assetCreatorAddress}
                  tweet={''}
                  withRedirectProfile={!objectBlank(assetCreator)}
                  fontSize="text-sm"
                  avatarSize={24}
                />
              )}
            </div>
          </div>

          {isOneOfMany && (
            <div className="flex w-full justify-between">
              <div className="flex flex-col gap-2">
                <Typography variant="medium" weight="medium">
                  Quantity
                </Typography>
                <Typography
                  variant="small"
                  className="self-start"
                  colorVariant="secondary"
                >
                  Supply: {totalSupply}
                </Typography>
              </div>

              <QuantitySelector
                quantity={values.amount}
                onQuantityChange={(value) =>
                  handleChange({ target: { name: 'amount', value } })
                }
                minusButtonDisabled={values.amount === 1}
                plusButtonDisabled={values.amount === totalSupply}
              />
            </div>
          )}

          <div className="flex w-full flex-col gap-2">
            <Typography variant="medium" weight="medium">
              Amount
            </Typography>
            <div className="align-center flex">
              <FormInput
                id="offer-amount-input"
                type="number"
                name="offerPrice"
                touched
                value={values.offerPrice}
                error={errors.offerPrice}
                placeholder={`0.10 ${getCurrency(
                  values.currencyContractAddress
                )}`}
                onChange={handleChange}
              />
              <DropDownInput
                id="currency-dropdown"
                placeholder="Currency"
                value={values.currencyContractAddress}
                options={CURRENCIES}
                onChange={(value) =>
                  setValues({ ...values, currencyContractAddress: value })
                }
                wrapperClassName="ml-3 w-[120px]"
              />
            </div>
            {balance && (
              <Typography variant="small" className="text-[#111827]">
                Balance: {balance} {getCurrency(values.currencyContractAddress)}
              </Typography>
            )}
          </div>
          {!isAvailableForPlaceBid && (
            <TimedDurationDropdown
              fieldName="endTime"
              defaultDuration={defaultOfferDuration}
              durationOptions={OFFER_DURATIONS}
              values={values}
              setValues={setValues}
              onChange={(value) => setValues({ ...values, endTime: value })}
            />
          )}

          <div className="flex w-full flex-col gap-y-2">
            <div className="flex items-center gap-x-1">
              <Typography variant="medium" weight="medium">
                Add a note
              </Typography>
              <Typography colorVariant="secondary">(optional)</Typography>
            </div>
            <FormTextArea
              id="offer-note"
              name="note"
              value={values.note}
              className="!mb-0 min-h-[120px] w-full"
              wrapperClassName="!mb-0"
              rows={3}
              placeholder="Tell the owner why you love this piece"
              onChange={handleChange}
            />
          </div>

          <Divider size="none" />

          <div className="flex w-full justify-between">
            <Typography
              variant="medium"
              className="self-start"
              colorVariant="secondary"
            >
              Total bid amount
            </Typography>
            <Typography
              id="total-offer-amount"
              variant="medium"
              weight="medium"
              className="self-start text-[#111827]"
            >
              {getTotalAmount(values)}{' '}
              {getCurrency(values.currencyContractAddress)}
            </Typography>
          </div>

          <ChainButton
            submit
            id="make-offer-btn"
            chainId={assetChainId}
            title={submitLabel}
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={handleSubmit}
          />
        </form>
      )}
    </Formik>
  );
};
