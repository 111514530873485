import React from 'react';
import { SpinnerFullWidth } from '/design-systems/Atoms/SpinnerFullWidth';
import { Typography } from '/design-systems/Atoms/Typography';

export const Loader = ({ children, color = '#16A34A' }) => {
  return (
    <div className='flex items-center gap-2'>
      <div className='h-[20px] w-[20px]'>
        <SpinnerFullWidth color={color} />
      </div>
      <Typography color={color} variant='xsmall' align='center'>
        {children}
      </Typography>
    </div>
  );
};
