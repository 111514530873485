import React from 'react';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useAccount, useNetwork } from 'wagmi';

import { useModalManager } from '/context/ModalManager';
import { useAuthRouterHelpers } from '/hooks/router';
import { emptyArray } from '/utils/object';
import { ROUTES } from '/config/routes';
import { ChainButton } from '/design-systems/Molecules/Buttons/ChainButton';
import { ConnectWallet } from '/design-systems/Molecules/Buttons/ConnectWallet';
import { Button } from '/design-systems/Atoms/Button';
import { Typography } from '/design-systems/Atoms/Typography';
import { LinkToExistingNFT } from '/design-systems/Molecules/Buttons/LinkToExistingNFTButton';
import { mergeClassNames } from '/utils/string';
import { isSupportedChain } from '/utils/web3';
import { useAnalytics } from '/hooks/useAnalytics';

const CALL_TO_ACTIONS = {
  MINT: 'MINT',
  LIST: 'LIST',
  MANAGE_AUCTION: 'MANAGE_AUCTION',
  BUY: 'BUY'
};

export const MarketplaceButton = ({
  assetId,
  assetChainId,
  galleryId,
  withFullWidth = false,
  disabled = false,
  hasEnoughBalance = false,
  onBuy,
  isOneOfMany,
  isFreeListing,
  unitsToFill,
  amountAvailable,
  isCurrentUserLoggedIn,
  isAssetAvailableForBuyNow,
  isAvailableForListing,
  isAvailableForManageAuction,
  isAvailableForMint,
  isAssetAvailableToAcceptOffer,
  isCardVariant,
  showRelistWithFee
}) => {
  const className = withFullWidth ? 'w-full' : 'w-fit';
  const router = useRouter();
  const { isConnected } = useAccount();
  const { chain } = useNetwork();
  const { navigateToLogin } = useAuthRouterHelpers();
  const { closeModal, showConnectWalletModal, closeConnectWalletModal } =
    useModalManager();
  const currentUser = useSelector((state) => state.user.current);
  const hasConnectedWallet = useMemo(
    () => !emptyArray(currentUser?.WalletAddresses),
    [currentUser?.WalletAddresses]
  );

  const isCorrectChainConnected = useMemo(
    () => isSupportedChain({ chainId: chain?.id }),
    [chain]
  );
  const handleRedirectToAssetInteraction = useCallback(
    (route) => {
      router.push(route);
      closeModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeModal]
  );

  const handleCheckWalletConnection = useCallback(
    (callback) => {
      if (!isCurrentUserLoggedIn) {
        navigateToLogin({ isForce: true });
        closeConnectWalletModal();
        return;
      }
      if (!isConnected) {
        showConnectWalletModal({
          onClose: () => {
            closeConnectWalletModal();
            callback();
          },
          withUnlockWallet: true
        });
        return;
      }
      callback();
    },
    [
      isCurrentUserLoggedIn,
      isConnected,
      navigateToLogin,
      showConnectWalletModal,
      closeConnectWalletModal
    ]
  );

  const { trackMarketplaceButtonClickEvent } = useAnalytics();

  const handleAssetAction = useCallback(
    (route) => () => {
      trackMarketplaceButtonClickEvent({
        assetId,
        galleryId,
        callToAction
      });

      handleCheckWalletConnection(() =>
        handleRedirectToAssetInteraction(route)
      );
    },
    [
      trackMarketplaceButtonClickEvent,
      assetId,
      galleryId,
      callToAction,
      handleCheckWalletConnection,
      handleRedirectToAssetInteraction
    ]
  );

  const handleRedirectToLinkNft = useCallback(() => {
    router.push(ROUTES.asset.link(assetId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  const callToAction = useMemo(() => {
    if (isAssetAvailableForBuyNow) {
      return CALL_TO_ACTIONS.BUY;
    }

    if (isAvailableForListing) {
      return CALL_TO_ACTIONS.LIST;
    }

    if (isAvailableForManageAuction) {
      return CALL_TO_ACTIONS.MANAGE_AUCTION;
    }

    if (isAvailableForMint) {
      return CALL_TO_ACTIONS.MINT;
    }

    return null;
  }, [
    isAssetAvailableForBuyNow,
    isAvailableForListing,
    isAvailableForManageAuction,
    isAvailableForMint
  ]);

  const getCallToActionLabel = useCallback(
    (action) => {
      switch (action) {
        case CALL_TO_ACTIONS.MINT:
          return 'Mint NFT';
        case CALL_TO_ACTIONS.LIST:
          if (showRelistWithFee) return 'Re-list with curator fee';
          return 'List for sale';
        case CALL_TO_ACTIONS.MANAGE_AUCTION:
          return 'Manage auction';
        case CALL_TO_ACTIONS.BUY:
          return isFreeListing
            ? 'Claim now'
            : isOneOfMany
            ? `Buy ${unitsToFill} now`
            : 'Buy now';
        default:
          return '';
      }
    },
    [isOneOfMany, isFreeListing, unitsToFill, showRelistWithFee]
  );

  const AssetMintButton = () => (
    <Button
      id="asset-mint-button"
      variant="primary"
      color="black"
      className={mergeClassNames(className, '!whitespace-nowrap')}
      onClick={handleAssetAction(ROUTES.asset.mint(assetId))}
    >
      {getCallToActionLabel(CALL_TO_ACTIONS.MINT)}
    </Button>
  );

  const listButtonColor = useMemo(() => {
    return isAssetAvailableToAcceptOffer && isAvailableForListing
      ? 'primary'
      : 'black';
  }, [isAssetAvailableToAcceptOffer, isAvailableForListing]);

  const listButtonVariant = useMemo(() => {
    return isAssetAvailableToAcceptOffer && isAvailableForListing
      ? 'secondary'
      : 'primary';
  }, [isAssetAvailableToAcceptOffer, isAvailableForListing]);

  const AssetListButton = () => (
    <Button
      id="listForSaleButton"
      color={listButtonColor}
      variant={listButtonVariant}
      className={mergeClassNames('min-w-fit', className)}
      onClick={handleAssetAction(ROUTES.asset.list(assetId))}
    >
      {getCallToActionLabel(CALL_TO_ACTIONS.LIST)}
    </Button>
  );

  const ManageOfferWindowButton = () => (
    <Button
      id="manage-offer-window-button"
      variant="primary"
      color="black"
      className={className}
      onClick={handleAssetAction(
        ROUTES.asset.updateListing(assetId, galleryId)
      )}
    >
      {getCallToActionLabel(CALL_TO_ACTIONS.MANAGE_AUCTION)}
    </Button>
  );

  const BuyButton = () => (
    <div className="flex w-full flex-col gap-2">
      {isOneOfMany && isCardVariant && (
        <Typography id="amountAvailable" colorVariant="secondary">
          {amountAvailable} available
        </Typography>
      )}
      <ChainButton
        id="buyNowButton"
        chainId={assetChainId}
        title={
          hasEnoughBalance || !isCorrectChainConnected
            ? getCallToActionLabel(CALL_TO_ACTIONS.BUY)
            : 'Insufficient Balance'
        }
        disabled={isCorrectChainConnected ? disabled : false}
        handleCallbackAfterSwitchNetwork={false}
        onClick={onBuy}
      />
    </div>
  );

  const isLoggedInWithoutWalletConnection = useMemo(
    () =>
      isCurrentUserLoggedIn &&
      (!hasConnectedWallet || !isConnected) && [
        hasConnectedWallet,
        isConnected,
        isCurrentUserLoggedIn
      ],
    [hasConnectedWallet, isConnected, isCurrentUserLoggedIn]
  );

  {
    switch (true) {
      case isLoggedInWithoutWalletConnection:
        return (
          <>
            <ConnectWallet
              id="connectWalletButton"
              isConnectWallet={false}
              withReturn={false}
              isUnlockWallet={hasConnectedWallet && !isConnected}
              noIcon
              label={getCallToActionLabel(callToAction)}
              btnVariant={
                callToAction == CALL_TO_ACTIONS.LIST
                  ? listButtonVariant
                  : 'primary'
              }
              btnColor={
                callToAction == CALL_TO_ACTIONS.LIST ? listButtonColor : 'black'
              }
            />
            {callToAction === CALL_TO_ACTIONS.MINT && (
              <LinkToExistingNFT onClick={handleRedirectToLinkNft} />
            )}
            {callToAction === CALL_TO_ACTIONS.BUY && isOneOfMany && (
              <Typography id="amountAvailable" colorVariant="secondary">
                {amountAvailable} available
              </Typography>
            )}
          </>
        );
      case callToAction === CALL_TO_ACTIONS.MINT:
        return (
          <>
            <AssetMintButton />
            <LinkToExistingNFT
              onClick={handleRedirectToLinkNft}
              variant={isCardVariant || 'button'}
            />
          </>
        );
      case callToAction === CALL_TO_ACTIONS.LIST:
        return <AssetListButton />;
      case callToAction === CALL_TO_ACTIONS.MANAGE_AUCTION:
        return <ManageOfferWindowButton />;
      case callToAction === CALL_TO_ACTIONS.BUY:
        return <BuyButton />;
      default:
        return <></>;
    }
  }
};
