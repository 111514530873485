/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from 'react';

export const TRACKED_EVENTS = {
  /**
   * View events
   *
   * This means when user lands on the page, this event would be tracked.
   *
   * */
  HOME_VIEW: 'home-view',
  SPACE_VIEW: 'space-view',
  EXPLORE_VIEW: 'explore-view',
  ASSET_VIEW: 'asset-view',
  GALLERY_VIEW: 'gallery-view',
  PROFILE_VIEW: 'profile-view',
  SUBMISSION_VIEW: 'submission-view',
  OPEN_CALL_VIEW: 'open-call-view',
  ART_PLUS_COMPOSE_VIEW: 'art-plus-compose',

  /**
   * Click events
   *
   * Unlike the "View events", this event will be tracked when something (button, link or etc) is clicked.
   */

  /** Submission */
  MARKETPLACE_BTN_CLICK: 'marketplace-btn-click',

  /** Asset */
  ASSET_LINK_CLICK: 'asset-link-click',

  /** Campaign */
  CAMPAIGN_SUBMIT_BTN_CLICK: 'open-call-submit-btn-click',
  CAMPAIGN_INTEREST_BTN_CLICK: 'open-call-interested-btn-click',
  SUBMISSION_SUBMIT_BTN_CLICK: 'submission-form-submit-btn-click',

  /** Composer */
  COMPOSE_BTN_CLICK: 'compose-btn-click',
  COMPOSER_OPTION_CLICK: 'composer-option-click',
  COMPOSER_PUBLISH_CLICK: 'composer-publish-click',
  COMPOSER_STATUS_CHANGE: 'composer-status-change'
};

/**
 * Custom React hook for tracking analytics events.
 *
 * @param {Object} options - Options for configuring the analytics event.
 * @param {string} options.event - The type of analytics event to track.
 * @param {Object} options.values - Additional values specific to the event being tracked.
 * @param {boolean} options.enabled - Flag to enable or disable analytics tracking. Default is `false`.
 */
export const useAnalytics = ({ event, values, enabled = false } = {}) => {
  const ref = useRef({});
  ref.current.event = event;
  ref.current.values = values;

  const trackEvent = useCallback((event, params) => {
    window?.analytics?.track(event, params);

    /** For debugging */
    // console.info('Tracking Events: ', { event, params });
  }, []);

  /** View events */
  const trackHomeViewEvent = useCallback(() => {
    trackEvent(TRACKED_EVENTS.HOME_VIEW, {});
  }, []);

  const trackSpaceViewEvent = useCallback(({ spaceId }) => {
    trackEvent(TRACKED_EVENTS.SPACE_VIEW, { spaceId });
  }, []);

  const trackExploreViewEvent = useCallback(({ entityType }) => {
    trackEvent(TRACKED_EVENTS.EXPLORE_VIEW, { entityType });
  }, []);

  const trackAssetViewEvent = useCallback(
    ({ assetId, galleryId, pageType }) => {
      trackEvent(TRACKED_EVENTS.ASSET_VIEW, {
        assetId,
        galleryId,
        pageType
      });
    },
    []
  );

  const trackGalleryViewEvent = useCallback(({ galleryId, galleryType }) => {
    trackEvent(TRACKED_EVENTS.GALLERY_VIEW, {
      galleryId,
      galleryType
    });
  }, []);

  const trackProfileViewEvent = useCallback(({ userId }) => {
    trackEvent(TRACKED_EVENTS.PROFILE_VIEW, {
      userId
    });
  }, []);

  const trackSubmissionViewEvent = useCallback(({ submissionId }) => {
    trackEvent(TRACKED_EVENTS.SUBMISSION_VIEW, {
      submissionId
    });
  }, []);

  const trackOpencallViewEvent = useCallback(({ campaignId }) => {
    trackEvent(TRACKED_EVENTS.OPEN_CALL_VIEW, { campaignId });
  }, []);

  const trackArtPlusComposeViewEvent = useCallback(({ assetId, galleryId }) => {
    trackEvent(TRACKED_EVENTS.ART_PLUS_COMPOSE_VIEW, { assetId, galleryId });
  }, []);

  /** Campaign */
  const trackCampaignSubmitButtonClickEvent = useCallback(({ campaignId }) => {
    trackEvent(TRACKED_EVENTS.CAMPAIGN_SUBMIT_BTN_CLICK, {
      campaignId
    });
  }, []);

  const trackCampaignInterestButtonClickEvent = useCallback(
    ({ campaignId }) => {
      trackEvent(TRACKED_EVENTS.CAMPAIGN_INTEREST_BTN_CLICK, {
        campaignId
      });
    },
    []
  );

  const trackSubmissionSubmitButtonClickEvent = useCallback(
    ({ campaignId }) => {
      trackEvent(TRACKED_EVENTS.SUBMISSION_SUBMIT_BTN_CLICK, {
        campaignId
      });
    },
    []
  );

  /** Submission */
  const trackMarketplaceButtonClickEvent = useCallback(
    ({ assetId, galleryId, callToAction }) => {
      trackEvent(TRACKED_EVENTS.MARKETPLACE_BTN_CLICK, {
        assetId,
        galleryId,
        callToAction
      });
    },
    []
  );

  /** Asset */
  const trackAssetLinkClickEvent = useCallback(
    ({ assetId, galleryId, url }) => {
      trackEvent(TRACKED_EVENTS.ASSET_LINK_CLICK, {
        assetId,
        galleryId,
        url
      });
    },
    []
  );

  /** Composer */
  const trackComposeButtonClickEvent = useCallback(() => {
    trackEvent(TRACKED_EVENTS.COMPOSE_BTN_CLICK, {});
  }, []);

  const trackComposerOptionClickEvent = useCallback(({ option }) => {
    trackEvent(TRACKED_EVENTS.COMPOSER_OPTION_CLICK, { option });
  }, []);

  const trackComposerPublishClickEvent = useCallback(({ galleryId }) => {
    trackEvent(TRACKED_EVENTS.COMPOSER_PUBLISH_CLICK, {
      galleryId
    });
  }, []);

  const trackComposerStatusChangeEvent = useCallback(
    ({ galleryId, status }) => {
      trackEvent(TRACKED_EVENTS.COMPOSER_PUBLISH_CLICK, {
        galleryId,
        status
      });
    },
    []
  );

  useEffect(
    () => {
      if (typeof window === 'undefined') return;
      if (typeof window.analytics === 'undefined') return;
      if (!ref.current.event) return;
      if (!enabled) return;

      switch (ref.current.event) {
        case TRACKED_EVENTS.HOME_VIEW:
          // trackHomeViewEvent();
          break;

        case TRACKED_EVENTS.SPACE_VIEW:
          // trackSpaceViewEvent(ref.current.values);
          break;

        case TRACKED_EVENTS.EXPLORE_VIEW:
          // trackExploreViewEvent(ref.current.values);
          break;

        case TRACKED_EVENTS.ASSET_VIEW:
          // trackAssetViewEvent(ref.current.values);
          break;

        case TRACKED_EVENTS.GALLERY_VIEW:
          // trackGalleryViewEvent(ref.current.values);
          break;

        case TRACKED_EVENTS.PROFILE_VIEW:
          // trackProfileViewEvent(ref.current.values);
          break;

        case TRACKED_EVENTS.SUBMISSION_VIEW:
          // trackSubmissionViewEvent(ref.current.values);
          break;

        case TRACKED_EVENTS.OPEN_CALL_VIEW:
          // trackOpencallViewEvent(ref.current.values);
          break;

        case TRACKED_EVENTS.ART_PLUS_COMPOSE_VIEW:
          // trackArtPlusComposeViewEvent(ref.current.values);
          break;

        default:
          break;
      }
    },
    [enabled] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    /** View events */
    trackAssetViewEvent,
    trackGalleryViewEvent,
    trackProfileViewEvent,

    /** Submission */
    trackMarketplaceButtonClickEvent,

    /** Asset */
    trackAssetLinkClickEvent,

    /** Campaign */
    trackCampaignSubmitButtonClickEvent,
    trackCampaignInterestButtonClickEvent,
    trackSubmissionSubmitButtonClickEvent,

    /** Composer */
    trackComposeButtonClickEvent,
    trackComposerOptionClickEvent,
    trackComposerPublishClickEvent,
    trackComposerStatusChangeEvent
  };
};
