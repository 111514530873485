import { createSlice } from '@reduxjs/toolkit';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';
import { objectBlank } from '/utils/object';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    current: {},
    isProducer: false,
    selectedWalletAddressId: '',
    editUser: {}
  },
  reducers: {
    setCurrent: (state, action) => {
      state.current = action.payload;

      if (!objectBlank(action.payload)) {
        const mainWallet = retrieveMainWalletAddressFromWalletAddresses(
          action.payload?.WalletAddresses ?? []
        );
        state.current.primaryWalletAddress = mainWallet?.address;
        state.selectedWalletAddressId = mainWallet?.id;
      }
    },
    edit: (state, action) => {
      state.current = action.payload;
    },
    setProducerView: (state, action) => {
      state.isProducer = action.payload;
    },
    setSelectedWalletAddressId: (state, action) => {
      state.selectedWalletAddressId = action.payload;
    },
    setEditUser: (state, action) => {
      state.editUser = action.payload;
    }
  }
});

export const {
  setCurrent,
  edit,
  setProducerView,
  setSelectedWalletAddressId,
  setEditUser
} = userSlice.actions;
export default userSlice.reducer;
