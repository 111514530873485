import { useMutation, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  getHighestOffer,
  getOffers,
  signOffer
} from '/services/joynApi/users/offer';
import { QUERY_KEYS } from '/utils/queries';

export const useOffer = ({ tokenId = '', auctionId = null, enabled }) => {
  const { mutateAsync: signOfferMutation } = useMutation(
    async ({ offerId, order }) => {
      const res = await signOffer({ offerId, order });
      return res.data.data;
    }
  );

  const {
    isLoading: isLoadingOffers,
    data: offers,
    refetch: refetchOffers
  } = useQuery(
    [QUERY_KEYS.OFFER.GET_OFFERS, tokenId],
    () => getOffers({ tokenId, auctionId }),
    {
      enabled: !!tokenId && !!enabled,
      select: (res) => res.data.data
    }
  );

  const {
    isLoading: isLoadingHighestOffer,
    data: highestOffer,
    refetch: refetchHighestOffer
  } = useQuery(
    [QUERY_KEYS.OFFER.GET_HIGHEST_OFFER, tokenId],
    () => getHighestOffer({ tokenId, auctionId }),
    {
      enabled: !!tokenId && !!enabled,
      select: (res) => res.data.data
    }
  );

  return useMemo(
    () => ({
      signOfferMutation,
      isLoadingOffers,
      offers,
      refetchOffers,
      isLoadingHighestOffer,
      highestOffer,
      refetchHighestOffer
    }),
    [
      signOfferMutation,
      isLoadingOffers,
      offers,
      refetchOffers,
      isLoadingHighestOffer,
      highestOffer,
      refetchHighestOffer
    ]
  );
};
