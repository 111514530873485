import { Contract } from './contractService';

class ERC721Service {
  contract;

  constructor(address, abi) {
    this.contract = new Contract(address, abi);
  }

  approve({ operator, tokenId }) {
    return this.contract.write('approve', [operator, tokenId]);
  }

  getApproved({ tokenId }) {
    return this.contract.read('getApproved', [tokenId]);
  }

  isApprovedForAll({ owner, operator }) {
    return this.contract.read('isApprovedForAll', [owner, operator]);
  }

  setApprovalForAll({ operator, approved }) {
    return this.contract.write('setApprovalForAll', [operator, approved]);
  }

  ownerOf({ tokenId }) {
    return this.contract.read('ownerOf', [tokenId]);
  }

  safeTransferFrom({ from, to, tokenId }) {
    return this.contract.write('safeTransferFrom', [from, to, tokenId]);
  }
}

export default ERC721Service;
