import React from 'react';
import { Typography } from '/design-systems/Atoms/Typography';
import { FloatingTooltip } from '/design-systems/Atoms/FloatingTooltip';
import { mergeClassNames } from '/utils/string';

export const TemplateCard = ({
  id = '',
  icon,
  title,
  description,
  disabled,
  disabledMessage,
  selectedTemplate,
  onClick
}) => {
  const handleClick = () => !disabled && onClick?.();

  return (
    <FloatingTooltip
      label={disabledMessage}
      placement="top"
      hide={!disabled || !disabledMessage}
    >
      <div
        id={id}
        className={mergeClassNames(
          'template-card dark:border-neutral-600 dark:bg-neutral-900',
          'hover:bg-neutral-100 dark:hover:bg-neutral-800',
          'transition duration-200',
          disabled && 'template-card__disabled',
          selectedTemplate === id && 'template-card__active'
        )}
        onClick={handleClick}
      >
        <div className="flex gap-x-4">
          {icon && <div className="template-card-icon">{icon}</div>}
          <div className="template-card-content flex flex-col gap-2">
            <Typography weight="medium" variant="large">
              {title}
            </Typography>
            <Typography colorVariant="secondary">{description}</Typography>
          </div>
        </div>
      </div>
    </FloatingTooltip>
  );
};
