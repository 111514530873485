import React, { useMemo } from 'react';
import { Typography } from '/design-systems/Atoms/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mergeClassNames } from '/utils/string';
import { DotBadge } from '/design-systems/Atoms/Badge/DotBadge';

export const SidebarTab = ({
  isActive,
  icon,
  activeIcon,
  title,
  onClick,
  showBadge = false,
  variant = 'thin'
}) => {
  const isThin = useMemo(
    () => variant === SIDEBAR_ITEM_VARIANTS.THIN,
    [variant]
  );

  const tabContainerClassName = useMemo(
    () => (isThin ? 'h-[72px] w-[72px]' : ''),
    [variant]
  );

  const tabContentClassName = useMemo(() => {
    const bgColorClassName = isActive ? 'bg-neutral-100' : 'bg-white';

    return mergeClassNames(
      bgColorClassName,
      isThin ? 'flex-col justify-center px-0 py-4 gap-1' : 'p-3 gap-3'
    );
  }, [variant, isActive]);

  const currentIcon = useMemo(
    () => (isActive ? activeIcon : icon),
    [isActive, activeIcon, icon]
  );

  return (
    <div
      id={`sidebar-tab-${title.toLowerCase()}`}
      className={mergeClassNames(
        `group flex items-center justify-center gap-2 bg-white p-1`,
        tabContainerClassName
      )}
    >
      <div
        className={mergeClassNames(
          `relative flex h-full w-full cursor-pointer items-center rounded-lg  transition duration-300 hover:bg-neutral-100`,
          tabContentClassName
        )}
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={currentIcon}
          className={`min-h-6 min-w-6 h-6 w-6 ${
            isActive ? 'text-black' : 'text-neutral-600'
          } group-hover:text-black`}
        />

        {showBadge && (
          <span class="absolute right-3 top-1 flex">
            <DotBadge />
          </span>
        )}

        <Typography
          weight={isThin || isActive ? 'medium' : 'normal'}
          colorVariant={isActive ? 'primary' : 'secondary'}
          className={mergeClassNames(
            'group-hover:text-black',
            isThin ? '!text-[10px]' : '!text-base'
          )}
        >
          {title}
        </Typography>
      </div>
    </div>
  );
};

export default SidebarTab;

const SIDEBAR_ITEM_VARIANTS = {
  THIN: 'thin',
  FULL: 'full'
};
