import axios from '/services/axios';
import { generateQueryParams } from '/utils/url';

const createGallery = (payload) => {
  return axios.post(`/gallery`, payload);
};

const updateGallery = ({ id, gallery, tabId }) => {
  return axios.put(`/gallery/${id}`, { ...gallery, tabId });
};

const deleteGallery = (galleryId) => {
  return axios.delete(`/gallery/${galleryId}`);
};

const getGalleries = ({ projectId, userId, page = 1, size = 12, filters }) => {
  const query = generateQueryParams({
    page,
    size,
    projectId,
    userId,
    ...filters
  });
  return axios.get(`/galleries?${query}`);
};

const getGalleriesForAsset = ({ assetId }) => {
  return axios.get(
    `/galleries/${assetId}`
  );
};

const getGallery = ({ id }) => {
  return axios.get(`/gallery/${id}`);
};

const getGalleryActivities = ({ id }) => {
  return axios.get(`/gallery/${id}/activities`);
};

const publishGallery = ({ id, status }) =>
  axios.put(`/gallery/${id}/publish`, { status });

export {
  createGallery,
  updateGallery,
  deleteGallery,
  getGalleries,
  getGallery,
  publishGallery,
  getGalleriesForAsset,
  getGalleryActivities
};
