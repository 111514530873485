import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isTest } from '/utils/environment';

export const useFeatureFlags = () => {
  const {
    galleryVirtualization,
    featuredShowcases,
    assetGallery,
    listingPromo,
    marketplaceFrontend,
    cardImgBg,
    composerOpenAccess,
    featuredHomeAsset,
    showcaseSalesActivities,
    artPlusFlow,
    artPlusPromoModal,
    showcaseOpenAccess,
    discoverAssets,
    lazyMinting,
    paidOpenCall
    // TODO move existing feature flags here
  } = useFlags();

  /**
   * @note Follow the naming convention.
   *
   * {`is${string}Enabled`}
   *
   * @example isVirtualizationEnabled
   * @example isMarketplaceV2Enabled
   */
  const values = {
    /** Showcase */
    isVirtualizationEnabled: galleryVirtualization,

    /** Composer */
    isComposerAccessEnabled: composerOpenAccess || isTest(),
    isArtPlusFlowEnabled: artPlusFlow || isTest(),
    isArtPlusPromoModalEnabled: artPlusPromoModal || isTest(),
    isShowcaseOpenAccessEnabled: showcaseOpenAccess || isTest(),

    /** Gallery */
    isAssetGalleryEnabled: assetGallery || isTest(),
    isDiscoverAssetsEnabled: discoverAssets || isTest(),

    /** Marketplace */
    isLazyMintingEnabled: lazyMinting || isTest(),
    isMarketplaceFrontendEnabled: marketplaceFrontend || isTest(),

    /** Submission */
    isPromptSubmissionCardImageBgEnabled: cardImgBg,

    /** Featured */
    isFeaturedShowcaseEnabled: featuredShowcases || isTest(),
    isFeaturedHomeAssetEnabled: featuredHomeAsset || isTest(),

    isShowcaseSalesActivitiesEnabled: showcaseSalesActivities || isTest(),

    /** Listing */
    isListingPromoEnabled: listingPromo || isTest(),

    /** Open call */
    isPaidOpenCallEnabled: paidOpenCall || isTest()
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => values, [Object.values(values)]);
};
