import { getSpaces } from '/services/joynApi/users/space';
import { useMemo } from 'react';
import { usePaginatedQuery } from '/hooks/usePaginatedQuery';
import { QUERY_KEYS } from '/utils/queries';

const allSpacesResponseFormatter = (res) => ({
  data: res.data.data.rows,
  count: res.data.data.count,
});

export const useSpaces = ({ size = 12, enabled = true, search, producerId }) => {
  const {
    data: spacesData,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = usePaginatedQuery(
    [QUERY_KEYS.SPACES.ALL, search],
    getSpaces,
    allSpacesResponseFormatter,
    {
      enabled,
    },
    { search, size, producerId }
  );

  const { spaces, totalSpaces } = useMemo(
    () => ({
      spaces: spacesData?.map((item) => item.data).flat() ?? [],
      totalSpaces: spacesData?.[0]?.count ?? 0,
    }),
    [spacesData]
  );

  return useMemo(
    () => ({
      isLoading,
      isFetchingNextPage,
      spaces,
      totalSpaces,
      hasNextPage,
      fetchNextPage,
      refetch,
    }),
    [isLoading, isFetchingNextPage, spaces, totalSpaces, hasNextPage, fetchNextPage, refetch]
  );
};
