import React from 'react';
import { Team } from '/design-systems/Molecules/Team';
import { getImageProps } from '/utils/prompt-submission';
import { Image } from '/design-systems/Atoms/Image';
import { Typography } from '/design-systems/Atoms/Typography';
import { mergeClassNames } from '/utils/string';
import { HyperlinkWrapper } from '/design-systems/Organisms/HyperlinkWrapper';
import { ROUTES } from '/config/routes';
import Router from 'next/router';

export const MiniAssetCard = ({
  title,
  id,
  heading = true,
  titleSize,
  files,
  username,
  twitter,
  avatar,
  hideAvatar = false,
  walletAddresses,
  className,
  cardTitle,
  imageSize,
  titleClassName,
  openLinkInNewTab = false,
  url = '#',
  detailsClassName
}) => {
  const creator = {
    username,
    twitter,
    avatar,
    WalletAddresses: walletAddresses
  };

  return (
    <div className={mergeClassNames(className, 'flex flex-col gap-2')}>
      {cardTitle && (
        <Typography
          className={mergeClassNames(
            'capitalize',
            titleClassName,
            'cursor-pointer'
          )}
          colorVariant="secondary"
          weight="medium"
        >
          {cardTitle}
        </Typography>
      )}
      <div className="flex w-full min-w-fit items-center gap-4">
        <div
          className={mergeClassNames(
            'overflow-hidden rounded-xl',
            `w-[${imageSize}] min-w-[${imageSize}] h-[${imageSize}]`
          )}
        >
          <HyperlinkWrapper url={url} onClick={false} newTab={openLinkInNewTab}>
            <Image
              id={`${id}-image`}
              alt="asset-image"
              className={mergeClassNames(
                `h-full w-full object-cover`,
                'cursor-pointer'
              )}
              {...getImageProps(files)}
            />
          </HyperlinkWrapper>
        </div>
        <div
          className={mergeClassNames(
            'flex w-full flex-col gap-2 overflow-hidden',
            detailsClassName
          )}
        >
          <HyperlinkWrapper url={url} onClick={false} newTab={openLinkInNewTab}>
            <Typography
              heading={heading}
              variant={titleSize}
              weight="semibold"
              className="!line-clamp-1"
            >
              {title || 'Untitled'}
            </Typography>
          </HyperlinkWrapper>
          {!hideAvatar && (
            <Team
              size={32}
              users={[creator]}
              rounded
              className="w-[160px] overflow-hidden"
              handleProfileClick={({ profileId }) =>
                Router.push(ROUTES.profile.view({ profileId }))
              }
              hideAvatar={hideAvatar}
            />
          )}
        </div>
      </div>
    </div>
  );
};
