const CLOUD_URL = process.env.CLOUDFRONT_URL;

export const getFileURL = ({
  src,
  resolution,
  placeholder,
  isStaticFile = false,
  isVideoFile = false
}) => {
  if (!src) return placeholder;
  if (isStaticFile) return src;
  if (!resolution || resolution === 'default') return `${CLOUD_URL}/${src}`;

  let res = resolution;
  // video doesnt support xl size
  if (resolution === 'xl' && isVideoFile) {
    res = 'l';
  }
  return `${CLOUD_URL}/${src}?d=${res}`;
};

export const getAutomaticallyCreatedVideoThumbnailKey = (videoS3Key) => {
  if (!videoS3Key) return '';

  const splittedKey = videoS3Key.split('/');
  const toUnshift = splittedKey.shift();
  splittedKey.unshift('Thumbnails');
  splittedKey.unshift(toUnshift);

  return `${splittedKey.join('/')}.jpg`;
};
