import React, { useState, useMemo, useEffect } from 'react';
import { FormInput } from '/design-systems/Atoms/FormInput';
import { FileUploader } from '/design-systems/Organisms/FileUploader';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Button } from '/design-systems/Atoms/Button';
import { urlValidation } from '/utils/regex';

export const FileUploadModal = ({
  id = 'image-upload-modal',
  type = 'image',
  open,
  onClose,
  onAddFile
}) => {
  const [fileURL, setFileURL] = useState('');
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');

  const labels = useMemo(() => {
    if (type === 'video') {
      return {
        url: 'Video URL',
        button: 'Add video'
      };
    }

    return {
      url: 'Image URL',
      button: 'Add image'
    };
  }, [type]);

  const acceptableExtensions = useMemo(() => {
    if (type === 'video') {
      return ['video/*'];
    }

    return ['image/*'];
  }, [type]);

  const canAddFile = useMemo(() => {
    return (
      ((!!fileURL?.length && urlValidation(fileURL)) || !!files?.length) &&
      !uploading
    );
  }, [fileURL, files, uploading]);

  const onFilesChange = (imageList) => {
    setFiles(imageList);
  };

  const handleAddFile = () => {
    if (fileURL) {
      onAddFile(fileURL);
    } else if (files?.length) {
      const { url } = files[0];
      onAddFile(`${process.env.CLOUDFRONT_URL}/${url}?d=l`);
    }
  };

  const handleClose = () => {
    if (uploading) return;

    onClose();
  };

  const handleBlur = (e) => {
    let msg = '';
    if (e.target.value && !urlValidation(e.target.value)) {
      msg = 'Invalid URL';
    }

    setError(msg);
  };

  useEffect(() => {
    setFileURL('');
    setFiles([]);
  }, [open]);

  return (
    <Modal
      id={id}
      open={open}
      onClose={handleClose}
      autoHeight
      showCloseIconOnTop
    >
      <div className="mb-6 w-full">
        <Typography weight="bold" className="mb-2">
          {labels.url}
        </Typography>
        <FormInput
          type="text"
          value={fileURL}
          error={error}
          disabled={!!files?.length}
          touched
          placeholder="e.g. https://figmage.com/images/Y2MfIfQrQtwC8ovC6wolK.png"
          onChange={(e) => setFileURL(e.target.value)}
          onBlur={handleBlur}
        />
      </div>

      <Typography align="center" className="mb-6">
        or
      </Typography>

      <div className="mb-6 w-full">
        <FileUploader
          disabled={!!fileURL?.length}
          accepts={acceptableExtensions}
          entity="editor"
          keyName="default"
          existingFiles={files}
          onChange={onFilesChange}
          setUploading={setUploading}
        />
      </div>

      <Button
        variant="primary"
        color="black"
        disabled={!canAddFile}
        blocked
        onClick={handleAddFile}
      >
        {labels.button}
      </Button>
    </Modal>
  );
};
