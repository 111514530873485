import axios from '/services/axios';
import { generateQueryParams, isNumber } from '/utils/url';

const getContest = ({ idOrSlug }) => {
  let id = idOrSlug;
  if (!isNumber(idOrSlug)) {
    id = idOrSlug.split('-').at(-1);
  }

  return axios.get(`/contest/${id}`);
};

const getContests = ({ projectId, page = 1, size = 12 }) => {
  const query = generateQueryParams({ page, size });
  return axios.get(`/contests/${projectId}?${query}`);
};

export { getContest, getContests };
