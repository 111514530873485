import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CheckCircleIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import { useAccount, useNetwork } from 'wagmi';
import { useRouter } from 'next/router';
import { useAuthRouterHelpers } from '/hooks/router';

import { mergeClassNames, toShort18 } from '/utils/string';
import { objectBlank } from '/utils/object';
import { openLink } from '/utils/url';
import { getTransactionLink } from '/utils/web3';
import { ROUTES } from '/config/routes';
import { useModalManager } from '/context/ModalManager';
import { useWallet } from '/hooks/useWallet';
import { useTokenBalances } from '/hooks/useTokenBalances';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';
import { ACTIONS, STEPS } from '/utils/marketplace';
import { getUserAvatarDetails } from '/utils/user';
import { MARKETPLACE_FREE_LISTING_FEE } from '/utils/constants';
import { HyperlinkWrapper } from '/design-systems/Organisms/HyperlinkWrapper';
import { Alert } from '/design-systems/Atoms/Alert';
import { AssetPrice } from '/design-systems/Molecules/Assets/AssetPrice';
import { Typography } from '/design-systems/Atoms/Typography';
import { Button } from '/design-systems/Atoms/Button';
import { FloatingTooltip } from '/design-systems/Atoms/FloatingTooltip';
import { AcceptOffer } from '/design-systems/Molecules/Buttons/AcceptOffer';
import { Banner } from '/design-systems/Molecules/Banner';
import { MakeOfferButton } from '/design-systems/Molecules/Buttons/MakeOfferButton';
import { MarketplaceButton } from '/design-systems/Molecules/Buttons/MarketplaceButton';
import { QuantitySelector } from 'design-systems/Molecules/QuantitySelector';
import { TabContainer } from '/design-systems/Molecules/TabContainer';
import { WalletAddressDropDown } from '/design-systems/Molecules/WalletAddressDropDown';
import { OfferWindowDetails } from '/design-systems/Molecules/OfferWindow';
import { MiniAssetCard } from '/design-systems/Molecules/Cards/MiniAssetCard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleExclamation,
  faTag,
  faLightbulb,
  faCircleInfo
} from '@fortawesome/pro-regular-svg-icons';
import { joynSupportedProtocols } from '/utils/constants';
import { ProtocolLogo } from '/design-systems/Atoms/ProtocolLogo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FreeClaimDetails from './FreeClaimDetails';
import { Divider } from '/design-systems/Atoms/Divider';

export const MarketplaceCard = ({
  isAssetOwner,
  assetId,
  assetChainId,
  asset,
  files,
  galleryId,
  listingPrice,
  isAssetAvailableForBuyNow,
  handleBuyAsset,
  isOneOfMany,
  amountAvailable,
  step,
  txHash,
  unitsToFill,
  showSellTab,
  onUnitsToFillChange,
  onShowSellTabChange,
  purchasePrice,
  onMakeOffer,
  isAssetAvailableForOffer,
  onAcceptOffer,
  isAssetAvailableToAcceptOffer,
  highestOffer,
  isAvailableForListing,
  isAvailableForMint,
  userHasListings,
  userHasOffers,
  showRelistWithFee,
  isAvailableForPlaceBid,
  reservePrice,
  auctionEndTime,
  auctionStartTime,
  hasAuction,
  isAuctionEnded,
  isAuctionStarted,
  auctionId,
  variant = 'card',
  blockchain,
  cheapestActiveListingProtocol
}) => {
  const router = useRouter();
  const { navigateToLogin } = useAuthRouterHelpers();
  const { freeSeaportListing } = useFlags();
  const currentUser = useSelector((state) => state.user.current);
  const isCurrentUserLoggedIn = useMemo(
    () => !objectBlank(currentUser),
    [currentUser]
  );
  const hasMultipleWallets = currentUser?.WalletAddresses?.length > 1;
  const { isConnected } = useAccount();
  const { chain } = useNetwork();

  const { closeModal } = useModalManager();
  const { isLoadingBalance, nativeCurrencyBalance } = useTokenBalances();

  const { isConnectedCorrectWallet, onChangeWallet } = useWallet({
    walletAddresses: currentUser?.WalletAddresses ?? [],
    withSync: true
  });

  const isFreeListing = useMemo(() => {
    return freeSeaportListing
      ? !!listingPrice && Number(listingPrice) === 0
      : false;
  }, [listingPrice, freeSeaportListing]);

  const isFreeClaimDisabled = useMemo(() => {
    return isFreeListing && isAssetOwner;
  }, [isFreeListing, isAssetOwner]);

  const redirectToLoginPage = useCallback(() => {
    navigateToLogin({ isForce: true });
    closeModal();
  }, [navigateToLogin, closeModal]);

  const handleChangeTab = async (index) => {
    if (index === 0) {
      onShowSellTabChange(true);
    } else {
      onShowSellTabChange(false);
    }
  };

  const hasEnoughBalance = useMemo(
    () =>
      listingPrice &&
      Number(nativeCurrencyBalance?.formatted ?? 0) >=
        Number(toShort18(listingPrice) * unitsToFill),
    [nativeCurrencyBalance, listingPrice, unitsToFill]
  );

  const handleRedirectToProfile = () => {
    closeModal();
    router.push(
      ROUTES.profile.view({
        profileId:
          currentUser?.username ||
          retrieveMainWalletAddressFromWalletAddresses(
            currentUser?.WalletAddresses
          )?.address,
        tab: 'owned'
      })
    );
  };

  const showSellBuyTabs = useMemo(
    () =>
      isOneOfMany &&
      (isAssetAvailableForBuyNow || isAssetAvailableForOffer) &&
      (isAvailableForListing || isAssetAvailableToAcceptOffer) &&
      !purchasePrice &&
      !isAvailableForMint,
    [
      isOneOfMany,
      isAssetAvailableForBuyNow,
      isAssetAvailableForOffer,
      isAvailableForListing,
      isAssetAvailableToAcceptOffer,
      purchasePrice,
      isAvailableForMint
    ]
  );

  const showAssetListingPrice = useMemo(() => {
    return (
      listingPrice &&
      !purchasePrice &&
      (!isAssetAvailableToAcceptOffer || (showSellBuyTabs && !showSellTab))
    );
  }, [
    listingPrice,
    purchasePrice,
    isAssetAvailableToAcceptOffer,
    showSellBuyTabs,
    showSellTab
  ]);

  const showQuantitySelector = useMemo(() => {
    return (
      isAssetAvailableForBuyNow && !showSellTab && isOneOfMany && !purchasePrice
    );
  }, [isAssetAvailableForBuyNow, showSellTab, isOneOfMany, purchasePrice]);

  const showPriceSection = useMemo(() => {
    return (
      (showAssetListingPrice || purchasePrice || showQuantitySelector) &&
      (!hasAuction || (hasAuction && !userHasOffers))
    );
  }, [
    showAssetListingPrice,
    purchasePrice,
    showQuantitySelector,
    hasAuction,
    userHasOffers
  ]);

  const handleMakeOffer = useCallback(
    () => (isCurrentUserLoggedIn ? onMakeOffer?.() : redirectToLoginPage()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCurrentUserLoggedIn, onMakeOffer]
  );

  const handleAcceptOffer = useCallback(
    () =>
      isCurrentUserLoggedIn
        ? onAcceptOffer?.(highestOffer)
        : redirectToLoginPage(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCurrentUserLoggedIn, onAcceptOffer, highestOffer]
  );

  const isSellOrNoTab = useMemo(
    () => !showSellBuyTabs || showSellTab,
    [showSellBuyTabs, showSellTab]
  );

  const isBuyOrNoTab = useMemo(
    () => !showSellBuyTabs || !showSellTab,
    [showSellBuyTabs, showSellTab]
  );

  const isAvailableForManageAuction = useMemo(
    () => !isAvailableForPlaceBid && hasAuction && !highestOffer,
    [isAvailableForPlaceBid, hasAuction, highestOffer]
  );

  const isCardVariant = useMemo(() => variant === 'card', [variant]);
  const isBarVariant = useMemo(() => variant === 'bottom-bar', [variant]);

  const showHighestOffer = useMemo(
    () =>
      isSellOrNoTab &&
      !isAssetAvailableForBuyNow &&
      (isAssetAvailableToAcceptOffer || highestOffer) &&
      (!hasAuction || (hasAuction && isAuctionEnded)),
    [
      isSellOrNoTab,
      isAssetAvailableToAcceptOffer,
      highestOffer,
      hasAuction,
      isAuctionEnded,
      isAssetAvailableForBuyNow
    ]
  );

  const showOfferReminder = useMemo(
    () => isBuyOrNoTab && userHasOffers,
    [isBuyOrNoTab, userHasOffers]
  );

  const showListingReminder = useMemo(
    () => isSellOrNoTab && userHasListings && !hasAuction,
    [isSellOrNoTab, userHasListings]
  );

  const showBalance = useMemo(
    () =>
      nativeCurrencyBalance?.symbol &&
      isAssetAvailableForBuyNow &&
      !showSellTab &&
      !hasAuction &&
      !isBarVariant,
    [
      nativeCurrencyBalance,
      isAssetAvailableForBuyNow,
      showSellTab,
      hasAuction,
      isBarVariant
    ]
  );

  const containerClassName = useMemo(() => {
    switch (variant) {
      case 'bottom-bar':
        return 'fixed justify-center inset-x-0 bottom-0 z-10 w-full border-t border-neutral-200 px-6 py-4';
      default:
        return 'border border-neutral-200 rounded-xl p-6 ';
    }
  }, [variant]);

  const marketplaceDetailsClassName = useMemo(() => {
    switch (variant) {
      case 'bottom-bar':
        return 'flex items-center gap-6 flex-wrap md:flex-nowrap md:w-auto w-full h-full';
      default:
        return 'flex flex-col gap-y-4 w-full';
    }
  }, [variant]);

  const buttonGroupClassName = useMemo(() => {
    switch (variant) {
      case 'bottom-bar':
        return 'flex items-center md:flex-row flex-col gap-x-3 gap-y-2 md:w-auto w-full';
      default:
        return 'flex flex-col gap-y-4 w-full';
    }
  }, [variant]);

  return (
    <div
      className={mergeClassNames(
        'flex bg-white drop-shadow-xl',
        isCardVariant && 'dark:border-none dark:bg-neutral-800',
        isBarVariant && 'dark:border-neutral-800 dark:bg-neutral-900',
        isCardVariant && showSellBuyTabs && '!pt-3',
        containerClassName
      )}
    >
      {/* inner container with max-w */}
      <div
        className={mergeClassNames(
          'flex w-full max-w-[1280px] items-center justify-between'
        )}
      >
        {/* asset details */}
        {isBarVariant && (
          <div className="hidden md:block">
            <MiniAssetCard
              id="marketplace-cta-asset-card"
              title={asset?.title}
              files={files}
              titleSize="h6"
              avatar={getUserAvatarDetails(asset?.User)?.avatar}
              username={getUserAvatarDetails(asset?.User)?.username}
              walletAddresses={
                getUserAvatarDetails(asset?.User)?.WalletAddresses
              }
              imageSize="80px"
            />
          </div>
        )}

        {/* marketplace details */}
        <div className={marketplaceDetailsClassName}>
          {showSellBuyTabs && (
            <TabContainer
              tabs={[
                {
                  key: 'sell',
                  title: 'Sell'
                },
                {
                  key: 'buy',
                  title: 'Buy'
                }
              ]}
              tabIndex={Number(!showSellTab)}
              onChange={handleChangeTab}
            />
          )}
          {/* price */}
          {showPriceSection && (
            <>
              <div
                className={mergeClassNames(
                  'flex min-w-fit items-center',
                  isBarVariant
                    ? 'w-full justify-between md:w-fit md:gap-4'
                    : 'justify-between'
                )}
              >
                {showAssetListingPrice && (
                  <div className="flex">
                    <div className="flex flex-col gap-2">
                      {isFreeListing ? (
                        !showSellTab &&
                        isAssetAvailableForBuyNow && (
                          <FloatingTooltip
                            label={`with a ${MARKETPLACE_FREE_LISTING_FEE} ETH fee, which helps
                            Joyn cover operating costs`}
                            placement="top"
                            hide={!isBarVariant}
                          >
                            <div>
                              <FreeClaimDetails
                                variant="large"
                                showAstriesk={true}
                              />
                            </div>
                          </FloatingTooltip>
                        )
                      ) : (
                        <AssetPrice
                          price={toShort18(listingPrice)}
                          size="large"
                          blockchain={blockchain}
                        />
                      )}
                      {isBarVariant && amountAvailable && (
                        <Typography
                          id="amountAvailable"
                          colorVariant="secondary"
                        >
                          {amountAvailable} available
                        </Typography>
                      )}
                    </div>
                    {isBarVariant &&
                      cheapestActiveListingProtocol ===
                        joynSupportedProtocols.FOUNDATION && (
                        <div className="ml-2">
                          <ProtocolLogo
                            protocol={cheapestActiveListingProtocol}
                            width={'32px'}
                            height={'32px'}
                          />
                        </div>
                      )}
                  </div>
                )}
                {purchasePrice && (
                  <AssetPrice
                    price={toShort18(purchasePrice)}
                    size="large"
                    blockchain={blockchain}
                  />
                )}
                {showQuantitySelector && (
                  <QuantitySelector
                    quantity={unitsToFill}
                    onQuantityChange={onUnitsToFillChange}
                    minusButtonDisabled={unitsToFill === 1}
                    plusButtonDisabled={
                      unitsToFill === Number(amountAvailable) || isFreeListing
                    }
                  />
                )}
              </div>
              {/* vertical divider */}
              {isBarVariant && (
                <div className="hidden h-full w-[1px] bg-neutral-200 dark:bg-neutral-800 md:block"></div>
              )}
            </>
          )}
          {!step &&
            !showSellTab &&
            isAssetAvailableForBuyNow &&
            !isAssetOwner &&
            isCurrentUserLoggedIn &&
            hasMultipleWallets && (
              <div className="flex flex-col gap-y-1">
                <Typography size="small" weight="medium">
                  Wallet
                </Typography>
                <WalletAddressDropDown
                  walletAddresses={currentUser?.WalletAddresses}
                  onChange={onChangeWallet}
                  showFullWalletAddress
                  showFullWidth
                  withSync
                />
              </div>
            )}
          {step &&
          ![
            STEPS.start(ACTIONS.UPDATE_LISTING),
            STEPS.start(ACTIONS.CREATE_LISTING)
          ].includes(step) ? (
            <>
              {!isBarVariant && (
                <Typography variant="medium" className="">
                  {step}
                </Typography>
              )}
              {!isBarVariant &&
                step === STEPS.txConfirmed(ACTIONS.BUY_LISTING) && (
                  <Typography
                    variant="medium"
                    weight="medium"
                    link
                    onClick={handleRedirectToProfile}
                  >
                    View in profile
                  </Typography>
                )}
              {txHash && (
                <div
                  className="flex cursor-pointer items-center gap-x-1 text-neutral-500 underline"
                  onClick={() =>
                    openLink(
                      getTransactionLink({
                        chainId: chain.id,
                        txHash
                      })
                    )
                  }
                >
                  View transaction
                  <ExternalLinkIcon className="h-[16px] w-[16px]" />
                </div>
              )}

              <Button
                variant="primary"
                color="black"
                disabled={true}
                blocked={isCardVariant}
              >
                {step === STEPS.WAIT_FOR_CONFIRM ? (
                  'Confirm transaction'
                ) : step === STEPS.txConfirming(ACTIONS.BUY_LISTING) ||
                  step === STEPS.txConfirming(ACTIONS.CANCEL_LISTING) ? (
                  'Awaiting transaction'
                ) : step === STEPS.txConfirmed(ACTIONS.BUY_LISTING) ? (
                  <span className="flex items-center justify-center gap-2">
                    <CheckCircleIcon className="h-5 w-5" />
                    {isFreeListing ? 'Claimed' : 'Purchased'}
                  </span>
                ) : step === STEPS.txConfirmed(ACTIONS.CANCEL_LISTING) ? (
                  <span className="flex items-center justify-center gap-2">
                    <CheckCircleIcon className="h-5 w-5" />
                    Cancelled
                  </span>
                ) : null}
              </Button>
            </>
          ) : (
            <>
              {showPriceSection &&
                hasAuction &&
                !userHasOffers &&
                (isAssetAvailableForBuyNow || isAvailableForListing) && (
                  <>
                    <MarketplaceButton
                      assetId={assetId}
                      assetChainId={assetChainId}
                      galleryId={galleryId}
                      withFullWidth
                      disabled={!isConnectedCorrectWallet || !hasEnoughBalance}
                      hasEnoughBalance={hasEnoughBalance}
                      onBuy={handleBuyAsset}
                      unitsToFill={unitsToFill}
                      amountAvailable={amountAvailable}
                      isFreeListing={isFreeListing}
                      redirectToLoginPage={redirectToLoginPage}
                      isCurrentUserLoggedIn={isCurrentUserLoggedIn}
                      isAssetAvailableForBuyNow={
                        isAssetAvailableForBuyNow &&
                        isBuyOrNoTab &&
                        !isFreeClaimDisabled
                      }
                      isAvailableForListing={
                        isAvailableForListing && isSellOrNoTab
                      }
                      isCardVariant={isCardVariant}
                      showRelistWithFee={showRelistWithFee}
                    />

                    <Divider
                      size="smaller"
                      variant={isBarVariant ? 'vertical' : 'horizontal'}
                    />
                  </>
                )}
              {showBalance && (
                <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                    <Typography>Balance</Typography>
                    {isLoadingBalance ? (
                      <div className="animate-pulse">
                        <div className="h-[20px] w-[50px] rounded-sm bg-[#e5e5e5]"></div>
                      </div>
                    ) : (
                      <Typography>
                        {nativeCurrencyBalance.formatted}{' '}
                        {nativeCurrencyBalance.symbol}
                      </Typography>
                    )}
                  </div>
                  {cheapestActiveListingProtocol ===
                    joynSupportedProtocols.FOUNDATION && (
                    <div>
                      <ProtocolLogo
                        protocol={cheapestActiveListingProtocol}
                        width={'32px'}
                        height={'32px'}
                      />
                    </div>
                  )}
                </div>
              )}

              <div className={buttonGroupClassName}>
                {showHighestOffer && (
                  <>
                    <div className="flex min-w-fit flex-col gap-2">
                      <Typography variant="small" weight="medium">
                        Highest bid
                      </Typography>
                      <AssetPrice
                        price={toShort18(highestOffer?.priceUnit || '')}
                        size="large"
                        blockchain={blockchain}
                      />
                    </div>
                    {isAssetAvailableToAcceptOffer && (
                      <AcceptOffer
                        label={hasAuction ? 'Accept Bid' : 'Accept Offer'}
                        color={
                          isAssetAvailableToAcceptOffer && isAvailableForListing
                            ? 'black'
                            : 'primary'
                        }
                        variant={
                          isAssetAvailableToAcceptOffer && isAvailableForListing
                            ? 'primary'
                            : 'secondary'
                        }
                        className="w-full"
                        onAcceptOffer={handleAcceptOffer}
                      />
                    )}
                  </>
                )}

                {/* auction details placeholder */}
                {hasAuction === true && isAuctionEnded === undefined && (
                  <div className="flex flex-col gap-2">
                    <div className="h-2 w-[80px] animate-pulse rounded-full bg-neutral-200 transition duration-200"></div>
                    <div className="h-6 w-[160px] animate-pulse rounded-full bg-neutral-200 transition duration-200"></div>
                  </div>
                )}

                {/* auction details */}
                {hasAuction === true && isAuctionEnded === false && (
                  <OfferWindowDetails
                    auctionId={auctionId}
                    offerLabel={
                      highestOffer?.priceUnit ? 'Highest bid' : 'Reserve'
                    }
                    offerPrice={
                      highestOffer?.priceUnit
                        ? toShort18(highestOffer?.priceUnit)
                        : toShort18(reservePrice)
                    }
                    offerPriceTextSize="large"
                    countdownLabel={
                      isAuctionStarted ? 'Auction ends in' : 'Auction starts in'
                    }
                    countdownStyle="gap-1"
                    countdownTypographyVariant="h5"
                    countdownTime={
                      isAuctionStarted
                        ? new Date(auctionEndTime)
                        : new Date(auctionStartTime)
                    }
                    withCountDown={!!auctionEndTime}
                    className="gap-x-4 gap-y-2"
                    isDividerRequired={true}
                    isForPreviewModal={true}
                    blockchain={blockchain}
                  />
                )}

                {/* banner to show the auction ended */}
                {hasAuction && isAuctionEnded && !highestOffer && (
                  <Banner
                    variant="black"
                    title="Auction ended"
                    description="Extend or cancel the auction."
                  />
                )}

                {/* reminder about existing offer(s) */}
                {showOfferReminder && (
                  <div>
                    <Alert
                      variant="primary"
                      color="neutral"
                      icon={
                        <FontAwesomeIcon icon={faTag} className="h-4 w-4" />
                      }
                      iconOnly={isBarVariant}
                      className="text-neutral-500"
                    >
                      You have an active bid. See "Bids".
                    </Alert>
                  </div>
                )}

                {/* marketplace button container */}
                {(!hasAuction || isAvailableForManageAuction) && (
                  <div
                    className={mergeClassNames(
                      'flex w-full min-w-fit gap-2',
                      isCardVariant ? 'flex-col' : 'flex-row'
                    )}
                  >
                    {/* onboard existing listing to curator fee */}
                    {showRelistWithFee && isAssetOwner && (
                      <Alert
                        variant="primary"
                        color="neutral"
                        icon={
                          <FontAwesomeIcon
                            icon={faLightbulb}
                            className="h-4 w-4"
                          />
                        }
                        iconOnly={isBarVariant}
                        className="text-sm text-neutral-500"
                      >
                        Add a curator fee for curators who help sell your work.
                      </Alert>
                    )}

                    {/* reminder about existing listing(s) */}
                    {showListingReminder && !showRelistWithFee && (
                      <Alert
                        variant="primary"
                        color="neutral"
                        icon={
                          <FontAwesomeIcon icon={faTag} className="h-4 w-4" />
                        }
                        iconOnly={isBarVariant}
                        className="text-neutral-500"
                      >
                        You have an active listing. See "Listings".
                      </Alert>
                    )}

                    {hasAuction &&
                      !userHasOffers &&
                      isAvailableForListing &&
                      isAssetOwner &&
                      !showAssetListingPrice && (
                        <MarketplaceButton
                          assetId={assetId}
                          assetChainId={assetChainId}
                          galleryId={galleryId}
                          withFullWidth
                          disabled={
                            !isConnectedCorrectWallet || !hasEnoughBalance
                          }
                          hasEnoughBalance={hasEnoughBalance}
                          unitsToFill={unitsToFill}
                          isOneOfMany={isOneOfMany}
                          isFreeListing={isFreeListing}
                          redirectToLoginPage={redirectToLoginPage}
                          isCurrentUserLoggedIn={isCurrentUserLoggedIn}
                          isAvailableForListing={
                            isAvailableForListing && isSellOrNoTab
                          }
                          isAvailableForManageAuction={
                            isAvailableForManageAuction
                          }
                          isCardVariant={isCardVariant}
                        />
                      )}

                    <MarketplaceButton
                      assetId={assetId}
                      assetChainId={assetChainId}
                      galleryId={galleryId}
                      withFullWidth
                      disabled={!isConnectedCorrectWallet || !hasEnoughBalance}
                      hasEnoughBalance={hasEnoughBalance}
                      onBuy={handleBuyAsset}
                      unitsToFill={unitsToFill}
                      amountAvailable={amountAvailable}
                      isOneOfMany={isOneOfMany}
                      isFreeListing={isFreeListing}
                      isAssetAvailableToAcceptOffer={
                        isAssetAvailableToAcceptOffer
                      }
                      redirectToLoginPage={redirectToLoginPage}
                      isCurrentUserLoggedIn={isCurrentUserLoggedIn}
                      isAssetAvailableForBuyNow={
                        isAssetAvailableForBuyNow &&
                        isBuyOrNoTab &&
                        !hasAuction &&
                        !isFreeClaimDisabled
                      }
                      isAvailableForListing={
                        isAvailableForListing && isSellOrNoTab && !hasAuction
                      }
                      isAvailableForManageAuction={isAvailableForManageAuction}
                      isAvailableForMint={isAvailableForMint}
                      isCardVariant={isCardVariant}
                      showRelistWithFee={showRelistWithFee}
                    />
                  </div>
                )}

                {((isBuyOrNoTab && isAssetAvailableForOffer && !hasAuction) ||
                  (isAvailableForPlaceBid &&
                    !isAvailableForMint &&
                    isAuctionStarted)) && (
                  <MakeOfferButton
                    className="w-full"
                    onMakeOffer={handleMakeOffer}
                    variant={isAvailableForPlaceBid ? 'primary' : 'secondary'}
                    color={isAvailableForPlaceBid ? 'black' : 'primary'}
                    label={hasAuction ? 'Place bid' : 'Make offer'}
                    disabled={
                      isAuctionEnded ||
                      (isConnected && !isConnectedCorrectWallet)
                    }
                  />
                )}
              </div>
            </>
          )}
          {isConnected && !isConnectedCorrectWallet && !hasMultipleWallets && (
            <FloatingTooltip
              label="Your current wallet is different from the main wallet of this
            account."
              hide={!isBarVariant}
              placement="left"
            >
              <div>
                <Alert
                  className={isCardVariant ? 'h-fit w-full p-2' : 'w-fit'}
                  icon={
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="text-top h-3 w-3 self-start pl-2 text-neutral-500"
                    />
                  }
                >
                  {isCardVariant && (
                    <>
                      Your current wallet is different from the main wallet of
                      this account.
                    </>
                  )}
                </Alert>
              </div>
            </FloatingTooltip>
          )}
          {isFreeListing &&
            !showSellTab &&
            isAssetAvailableForBuyNow &&
            isCardVariant && (
              <Typography variant="xsmall" colorVariant="secondary">
                * with a {MARKETPLACE_FREE_LISTING_FEE} ETH fee, which helps
                Joyn cover operating costs
              </Typography>
            )}
        </div>
      </div>
    </div>
  );
};
