import React from 'react';
import { Image } from '/design-systems/Atoms/Image';
import exampleArtwork from '/images/exampleArtwork.png';
import exampleShowcase from '/images/exampleShowcase.png';
import exampleStory from '/images/exampleStory.png';
import exampleOpenCall from '/images/exampleOpenCall.png';

export const Illustration = ({ variant }) => {
  switch (variant) {
    case 'artwork':
      return (
        <div className="absolute inset-x-0 bottom-0 mx-auto w-[200px] overflow-hidden rounded-t-lg  drop-shadow-xl">
          <Image src={exampleArtwork.src} staticFile />
        </div>
      );
    case 'showcase':
      return (
        <div className="absolute inset-x-0 bottom-0 mx-auto w-[200px] overflow-hidden rounded-t-lg  drop-shadow-xl">
          <Image src={exampleShowcase.src} staticFile />
        </div>
      );
    case 'story':
      return (
        <div className="absolute inset-x-0 bottom-0 mx-auto w-[200px] overflow-hidden rounded-t-lg  drop-shadow-xl">
          <Image src={exampleStory.src} staticFile />
        </div>
      );

    case 'open-call':
      return (
        <div className="absolute inset-x-0 bottom-0 mx-auto w-[200px] overflow-hidden rounded-t-lg px-2 pt-2 drop-shadow-xl">
          <Image src={exampleOpenCall.src} staticFile />
        </div>
      );

    default:
      return <></>;
  }
};
