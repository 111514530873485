import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Banner } from '../../Banner';
import { AttentionIcon } from '/design-systems/Atoms/Icon/attention';
import { useUser } from '/hooks/useUser';
import { useSelector } from 'react-redux';
import { Avatar } from '/design-systems/Atoms/Avatar';
import { getAvatar } from '/utils/image';
import { TwitterIcon } from '/design-systems/Atoms/Icon/twitter';
import { Button } from '/design-systems/Atoms/Button';
import useToggle from '/hooks/useToggle';
import { mergeUsers } from '/services/joynApi/users/user';

const mergeTypeMessages = {
  twitter: 'Twitter account',
  wallet: 'Wallet address'
};

export const MergeUsersModal = ({
  open,
  onClose,
  type,
  duplicateUserId,
  onConfirm
}) => {
  const currentUser = useSelector((state) => state.user.current);
  const { user: duplicateUser } = useUser({ id: duplicateUserId });
  const [primary, setPrimary] = useState(currentUser.id);
  const [isMerging, toggleIsMerging] = useToggle(false);

  const handleConfirm = useCallback(async () => {
    toggleIsMerging();
    await mergeUsers({
      primaryUserId: primary,
      mergingUserId: duplicateUser.id
    });
    if (onConfirm) {
      await onConfirm();
    }
    toggleIsMerging();
  }, [onConfirm, duplicateUser, primary]);

  return (
    <Modal open={open} autoHeight showCloseIconOnTop onClose={onClose}>
      <div className="flex flex-col gap-6">
        <Typography weight="semibold" variant="h4" heading align="center">
          Merge accounts
        </Typography>
        <Typography variant="small" align="center">
          There's an existing account with this {mergeTypeMessages[type]}. Would
          you like to merge?
        </Typography>
      </div>
      <div className="my-6 border-t border-gray-300"></div>
      <Banner
        variant="warning"
        icon={<AttentionIcon />}
        title="This action cannot be undone"
        description="This will permanently merge these accounts into one."
      />
      <div className="mt-6 flex flex-col">
        <Typography variant="small" align="left" color="#6b7280">
          The following accounts will be merged
        </Typography>
        <div className="flex flex-col">
          {[currentUser, duplicateUser].map((user, index) => {
            const isPrimary = primary === user?.id;
            return (
              <div
                key={index}
                className={classNames(
                  'flex',
                  'flex-row',
                  'p-2',
                  'gap-4',
                  'rounded-lg',
                  {
                    'bg-gray-100': isPrimary
                  }
                )}
              >
                <div>
                  <Avatar src={getAvatar(user)} size={64} rounded />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row items-center gap-2">
                    <Typography variant="medium" align="left">
                      {user?.name}
                    </Typography>
                    <Typography variant="small" align="left" color="#6b7280">
                      @{user?.username}
                    </Typography>
                  </div>
                  {user?.isTwitterConnected ? (
                    <div className="flex flex-row items-center gap-1">
                      <TwitterIcon width="20" /> {user?.twitter}
                    </div>
                  ) : (
                    <div>
                      <Typography variant="small" align="left" color="#6b7280">
                        {user?.WalletAddresses[0]?.address}
                      </Typography>
                    </div>
                  )}
                </div>
                <div className="ml-auto flex items-center pr-2">
                  <Typography variant="small" align="left" color="#6b7280">
                    {isPrimary && 'Primary'}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-6 flex flex-row justify-between">
          <Button
            id="cancel-user-merge-button"
            type="button"
            variant="secondary"
            color="primary"
            disabled={isMerging}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            id="confirm-user-merge-button"
            type="button"
            color="black"
            variant="primary"
            loading={isMerging}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};
