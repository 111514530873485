import { createUrlBySlug } from '../utils/url';
import { PAGES_ID } from './pages';
import { getWrappedNativeTokenContractAddress } from '/utils/contracts';

export const ROUTES = {
  home: () => '/',
  profile: {
    view: ({ profileId, tab, assetId }) =>
      `/profile/${profileId}?tab=${tab ?? 'created'}${
        assetId ? `&assetId=${assetId}` : ''
      }`,
    edit: () => '/profile/edit',
    editOther: ({ userId }) => `/profile/edit/${userId}`
  },
  dashboard: (tabId) => `/dashboard${tabId ? `?tabId=${tabId}` : ''}`,
  auth: {
    login: () => `/auth/login`,
    signup: () => `/auth/signup`,
    forgotPassword: () => `/auth/forgot-password`,
    resetPassword: () => `/auth/reset-password`,
    verifyEmail: () => `/auth/verify-email`,
    completeProfile: () => `/auth/complete-profile`
  },
  create: {
    home: () => `/create`
  },
  compose: {
    home: () => `/compose`,
    landing: () => `/composer`,
    prelaunch: () => `/prelaunch/composer`
  },
  project: {
    view: (projectId) => `/project/${projectId}`,
    viewBySlug: (projectTitle, projectSlug) =>
      `/project/${createUrlBySlug(projectTitle, projectSlug)}`,
    edit: (projectId) => `/project/${projectId}/edit`,
    editBySlug: (projectTitle, projectSlug) =>
      `/project/${createUrlBySlug(projectTitle, projectSlug)}/edit`,
    about: (projectId) => `/project/${projectId}/about`,
    aboutBySlug: (projectTitle, projectSlug) =>
      `/project/${createUrlBySlug(projectTitle, projectSlug)}/about`
  },
  contest: {
    view: (contestId) => `/contest/${contestId}`,
    viewBySlug: (contestTitle, contestSlug) =>
      `/contest/${createUrlBySlug(contestTitle, contestSlug)}`,
    new: () => `/contest/new`,
    edit: (contestId) => `/contest/${contestId}/edit`
  },
  gallery: {
    view: (galleryId, assetId) =>
      `/gallery/${galleryId}${assetId ? `?assetId=${assetId}` : ''}`,
    new: (projectId) =>
      projectId ? `/gallery/new?projectId=${projectId}` : '/gallery/new',
    edit: (galleryId) => `/gallery/${galleryId}/edit`,
    preview: (galleryId) => `/gallery/${galleryId}/preview`
  },
  story: {
    view: (galleryId, assetId) =>
      `/story/${galleryId}${assetId ? `?assetId=${assetId}` : ''}`,
    new: (projectId) =>
      projectId ? `/story/new?projectId=${projectId}` : '/story/new',
    edit: (galleryId) => `/story/${galleryId}/edit`,
    preview: (galleryId) => `/story/${galleryId}/preview`
  },
  showcase: {
    view: (galleryId, assetId) =>
      `/showcase/${galleryId}${assetId ? `?assetId=${assetId}` : ''}`,
    new: (projectId) =>
      projectId ? `/showcase/new?projectId=${projectId}` : '/showcase/new',
    edit: (galleryId) => `/showcase/${galleryId}/edit`,
    preview: (galleryId) => `/showcase/${galleryId}/preview`
  },
  giveaway: {
    view: (giveawayId) => `/giveaway/${giveawayId}`,
    viewBySlug: (giveawayTitle, giveawaySlug) =>
      `/giveaway/${createUrlBySlug(giveawayTitle, giveawaySlug)}`,
    new: () => `/giveaway/new`,
    edit: (giveawayId) => `/giveaway/${giveawayId}/edit`
  },
  members: {
    index: (projectId) => `/project/${projectId}/members`
  },
  poll: {
    index: (projectId) => `/project/${projectId}/polls`
  },
  milestone: {
    index: (projectId) => `/project/${projectId}/milestones`,
    new: (projectId) => `/project/${projectId}/milestone/new`,
    edit: (projectId, milestoneId) =>
      `/project/${projectId}/milestone/${milestoneId}/edit`
  },
  gallerySubmission: {
    new: (promptId) => `/contest/${promptId}/gallery-submission/new`
  },
  promptSubmission: {
    new: (promptId) => `/contest/${promptId}/prompt-submission/new`,
    view: ({ promptTitle, promptSlug, submissionId }) =>
      `/contest/${createUrlBySlug(
        promptTitle,
        promptSlug
      )}?submissionId=${submissionId}`,
    edit: (promptId, submissionId) =>
      `/contest/${promptId}/prompt-submission/${submissionId}/edit`
  },
  reviewSubmission: {
    index: (projectId, promptId) =>
      `/project/${projectId}/contest/${promptId}/review-submission`
  },
  asset: {
    view: (assetId) => `/asset/${assetId}`,
    edit: (assetId) => `/asset/${assetId}/edit`,
    compose: (assetId) => `/asset/${assetId}/compose`,
    mint: (assetId, collabRequestId) =>
      `/asset/${assetId}/mint${
        collabRequestId ? `?collabRequestId=${collabRequestId}` : ''
      }`,
    list: (assetId, collabRequestId) =>
      `/asset/${assetId}/list${
        collabRequestId ? `?collabRequestId=${collabRequestId}` : ''
      }`,
    new: () => `/asset/new`,
    updateListing: (assetId, galleryId) =>
      `/asset/${assetId}/list/update${
        galleryId ? `?galleryId=${galleryId}` : ''
      }`,
    link: (assetId, galleryId) =>
      `/asset/${assetId}/link/nft/${
        galleryId ? `?galleryId=${galleryId}` : ''
      }`,
    tokenSettings: (assetId) => `/asset/${assetId}/token-settings`,
    myAssets: () => '/my-assets',
    newListing: () => '/listing/new'
  },
  space: {
    view: (spaceId) => `/space/${spaceId}`,
    viewBySlug: (spaceTitle, spaceSlug) =>
      `/space/${createUrlBySlug(spaceTitle, spaceSlug)}`,
    newAsset: (spaceId) => `/space/${spaceId}/asset/new`,
    mySpaces: () => `/my-spaces`
  },
  explore: {
    galleries: '/explore/galleries',
    openCalls: '/explore/open-calls',
    pieces: '/explore/pieces',
    spaces: '/explore/spaces',
    artists: '/explore/artists',
    showcases: '/explore/showcases'
  },
  settings: {
    index: () => `/settings`
  },
  site: {
    about: 'https://info.joyn.xyz/about-us',
    partners: 'https://info.joyn.xyz/for-producers',
    blog: 'https://info.joyn.xyz/blog',
    terms: '/tos'
  },
  socials: {
    twitter: 'https://twitter.com/joynxyz',
    discord: 'https://discord.com/invite/fku6Zyjd4t',
    instagram: 'https://www.instagram.com/joynxyz',
    youtube: 'https://www.youtube.com/channel/UCdrMaqIGwxpwBBO-DVqAxFQ'
  },
  external: {
    uniswap_weth: ({ chain, chainId }) =>
      `https://app.uniswap.org/swap?chain=${chain}&outputCurrency=${getWrappedNativeTokenContractAddress(
        {
          chainId
        }
      )}`
  }
};

export const BANNER_ROUTES = [
  {
    id: 'overview',
    pathname: '/project/[id]',
    tab: PAGES_ID.overview
  },
  {
    id: 'prompts',
    pathname: '/project/[id]/co-create',
    tab: PAGES_ID.prompts
  },
  {
    id: 'members',
    pathname: '/project/[id]/members',
    tab: PAGES_ID.members
  },
  {
    id: 'prompt',
    pathname: '/project/[id]/prompt/[promptId]',
    tab: PAGES_ID.prompts
  },
  {
    id: 'prompt-review-submission',
    pathname: '/project/[id]/prompt/[promptId]/review-submission',
    tab: PAGES_ID.prompts
  },
  {
    id: 'prompt-submission',
    pathname:
      '/project/[id]/prompt/[promptId]/prompt-submission/[promptSubmissionId]',
    tab: PAGES_ID.prompts
  },
  {
    id: 'polls',
    pathname: '/project/[id]/polls',
    tab: PAGES_ID.polls
  }
];

export const LOCAL_SOCIAL_AUTH_REDIRECT_URL_KEY = 'SOCIAL_AUTH_REDIRECT_URL';
export const LOCAL_SOCIAL_AUTH_TYPE_KEY = 'SOCIAL_AUTH_TYPE';

export const REDIRECT_URL = process.env.NEXT_PUBLIC_REDIRECT_URL;
