import React, { useCallback, useState, useEffect } from 'react';

import { SubmissionListItem } from './SubmissionList';
import { Loader } from './Loader';
import { useShallowState } from '/hooks/shallowState';
import { promptRewardTitle } from '/utils/prompt-reward';
import { getDraw } from '/services/joynApi/producers/draw';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Button } from '/design-systems/Atoms/Button';

export const ReviewPromptWinnersModal = ({ open, drawId, title, description, onClose, onRedraw, onConfirmDraw }) => {
  const [draw, setDraw] = useState({});
  const [state, setState] = useShallowState({
    loading: false,
    confirming: false,
  });

  const handleConfirmDraw = async () => {
    setState({ confirming: true });
    await onConfirmDraw();
    setState({ confirming: false });
  };

  const fetchDraw = useCallback(async (id) => {
    setState({ loading: true });

    const {
      data: { data },
    } = await getDraw({ id });
    setDraw(data);

    setState({ loading: false });
  }, []);

  useEffect(() => {
    drawId && fetchDraw(drawId);
  }, [drawId]);

  return (
    <Modal
      autoHeight
      showCloseIconOnTop
      maxWidth={600}
      open={open}
      onClose={onClose}
      title={title}
      description={description}
    >
      <Typography weight='medium' variant='medium' className='mb-1'>
        Reward tier
      </Typography>
      <Typography className='mb-4' variant='medium'>
        {promptRewardTitle(draw?.PromptReward || {})}
      </Typography>

      <div className='mb-4 max-h-[300px] overflow-y-auto overflow-x-hidden'>
        {draw?.DrawWinners?.map(({ PromptSubmission: submission }) => (
          <SubmissionListItem key={submission.id} submission={submission} />
        ))}
      </div>

      {state.loading && <Loader>Loading draw data...</Loader>}

      <div className='flex items-center gap-2'>
        {onConfirmDraw && (
          <Button
            blocked
            variant='primary'
            color='black'
            loading={state.confirming}
            disabled={state.confirming}
            onClick={handleConfirmDraw}
          >
            Confirm
          </Button>
        )}
        {onRedraw && (
          <Button blocked variant='primary' color='black' disabled={state.confirming} onClick={onRedraw}>
            Redraw
          </Button>
        )}
      </div>
    </Modal>
  );
};
