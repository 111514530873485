import React, { useEffect, useMemo, useRef } from 'react';
import { mergeClassNames } from '/utils/string';
import { getHeaderProfileName, getProfileRoute } from '/utils/user';

import { useSelector } from 'react-redux';

import { Avatar } from '/design-systems/Atoms/Avatar';
import { Typography } from '/design-systems/Atoms/Typography';

import { getAvatar } from '/utils/image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

export const UserRow = ({
  user,
  address,
  tokenOwner,
  withHandle = true,
  onView,
  // form props
  selectionEnabled,
  canSelectSelf = false,
  isSelectedByDefault = false,
  checkBoxName,
  onChange,
  className
}) => {
  const currentUser = useSelector((state) => state.user.current);
  const avatar = useMemo(() => user?.avatar || getAvatar(user), [user]);
  const handle = useMemo(() => user?.username || user?.text, [user]);
  const displayName = useMemo(
    () =>
      getHeaderProfileName(user) ||
      address ||
      user?.address ||
      user?.twitter ||
      '',
    [user]
  );

  const checkboxRef = useRef(null);

  const isSelf = useMemo(
    () => currentUser?.id === user?.id,
    [currentUser, user]
  );

  const canSelect = useMemo(() => {
    if (!user) return false;

    if (isSelf) return canSelectSelf;

    return true;
  }, [user, currentUser, canSelectSelf]);

  const cursorClassName = useMemo(() => {
    if (!user?.id) return 'cursor-default';

    if (selectionEnabled && !canSelect) return 'cursor-not-allowed';

    return 'cursor-pointer';
  }, [selectionEnabled, canSelect]);

  useEffect(() => {
    if (!checkboxRef.current || !isSelectedByDefault || !canSelect) return;
    checkboxRef.current.click();
  }, [isSelectedByDefault, checkboxRef, canSelect]);

  return (
    <div
      id="link-to-profile-page-button"
      className={mergeClassNames(
        'flex items-center gap-2 rounded-lg px-2 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-800',
        cursorClassName,
        className
      )}
      onClick={() => {
        if (selectionEnabled) {
          if (!canSelect) return;

          checkboxRef.current.click();
          return;
        }

        onView(getProfileRoute(user));
      }}
    >
      <Avatar id={user?.id} resolution="s" src={avatar} size={32} rounded />

      <div className="justify-gap-2 flex w-full flex-col">
        <Typography variant="small" weight="medium" className="text-[#111827]">
          {displayName}
        </Typography>
        {withHandle && handle && (
          <Typography variant="small" weight="normal" colorVariant="secondary">
            @{handle}
          </Typography>
        )}
        {tokenOwner?.quantity && (
          <Typography variant="small" colorVariant="secondary">
            Quantity: {tokenOwner.quantity}
          </Typography>
        )}
      </div>

      {selectionEnabled && isSelf && (
        <Typography variant="xsmall" colorVariant="secondary">
          (You)
        </Typography>
      )}

      {selectionEnabled && (
        <div className="relative flex">
          <input
            id="token-owner-checkbox"
            type="checkbox"
            ref={checkboxRef}
            name={checkBoxName}
            value={tokenOwner?.id}
            onChange={onChange}
            className="peer h-5 w-5 appearance-none rounded-full accent-neutral-700 checked:bg-neutral-700"
          />

          <FontAwesomeIcon
            icon={faCheck}
            className="absolute left-1/2 top-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 text-white"
          />
        </div>
      )}
    </div>
  );
};
