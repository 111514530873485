import { useQuery } from '@tanstack/react-query';
import { getTokenOwners } from '/services/joynApi/users/token';
import { QUERY_KEYS } from '/utils/queries';
import { useMemo } from 'react';

export const useTokenOwner = ({ tokenId }) => {
  const {
    data: tokenOwner,
    isLoading: isLoadingTokenOwner,
    refetch: refetchTokenOwner,
  } = useQuery([QUERY_KEYS.TOKEN_OWNER.GET, tokenId], () => getTokenOwners({ tokenId }), {
    enabled: !!tokenId,
    select: (res) => res.data.data,
  });

  return useMemo(
    () => ({
      tokenOwner,
      isLoadingTokenOwner,
      refetchTokenOwner,
    }),
    [tokenOwner, isLoadingTokenOwner, refetchTokenOwner]
  );
};
