import React, { useCallback } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImageSlash } from '@fortawesome/pro-regular-svg-icons';

import { isImageVideo, showImageOrVideoAsFirst } from '/utils/file';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import OtherBackSVG from '/images/other-back.svg';
import { Image } from '/design-systems/Atoms/Image';
import { Typography } from '/design-systems/Atoms/Typography';
import { FullScreenButton } from './FullScreenButton';
import { mergeClassNames } from '/utils/string';
import { emptyArray } from '/utils/object';

export const ImageCarousel = ({
  files = [],
  onChange,
  onShowFullScreen,
  className,
  resolution = 'xl',
  withFullScreen = true,
  withEmptyState = false
}) => {
  const slideFiles = showImageOrVideoAsFirst(files)?.filter(Boolean);

  const hasFiles = !emptyArray(slideFiles);

  const handleChangeIndex = useCallback(
    (index) => {
      onChange?.(index);
    },
    [onChange]
  );

  return (
    <div className={mergeClassNames('flex flex-row flex-wrap', className)}>
      <div
        className={mergeClassNames(
          'flex w-full flex-col items-center justify-center',
          hasFiles
            ? slideFiles?.length === 1
              ? 'h-full'
              : 'h-[calc(100%-70px)]'
            : '',
          'relative'
        )}
      >
        <Carousel
          className={`carousel-wrapper`}
          showArrows={false}
          onSwipeMove={true}
          useKeyboardArrows={true}
          showStatus={false}
          showThumbs={true}
          showIndicators={false}
          thumbWidth={46}
          renderThumbs={(childImages) => {
            if (emptyArray(childImages) || childImages?.length <= 1)
              return null;

            return childImages.map((thumb, index) => {
              return (
                <div
                  key={index}
                  className="h-fit w-fit cursor-pointer rounded-md"
                >
                  <Image
                    fileType={thumb.props.fileType}
                    src={thumb.props.src}
                    staticFile={false}
                    className="h-9 h-full w-9 w-full rounded-md object-cover"
                    resolution="s"
                  />
                </div>
              );
            });
          }}
          onChange={handleChangeIndex}
        >
          {hasFiles ? (
            slideFiles?.map((file, index) => {
              if (!isImageVideo(file?.fileType)) {
                return (
                  <Image
                    key={index}
                    src={OtherBackSVG.src}
                    fileType={file?.fileType}
                    className="flex h-full"
                    style={{ objectFit: 'contain' }}
                    resolution={resolution}
                    staticFile
                  />
                );
              }

              return (
                <Image
                  key={index}
                  src={file.fileUrl}
                  fileType={file.fileType}
                  className="flex h-full w-full"
                  style={{ objectFit: 'contain' }}
                  resolution={resolution}
                  videoControls={true}
                  staticFile={file.staticFile}
                />
              );
            })
          ) : (
            <EmptyState withEmptyState={withEmptyState} />
          )}
        </Carousel>

        {withFullScreen && (
          <FullScreenButton onShowFullScreen={onShowFullScreen} />
        )}
      </div>
    </div>
  );
};

const EmptyState = ({ withEmptyState = false }) => {
  if (withEmptyState) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-4">
        <FontAwesomeIcon
          icon={faImageSlash}
          className="h-6 w-6 text-neutral-900 dark:text-neutral-300"
        />
        <Typography size="large" variant="paragraph" colorVariant="secondary">
          No asset file selected
        </Typography>
      </div>
    );
  }

  return <></>;
};
