import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { createAsset } from '/services/joynApi/users/assets';
import { ROUTES } from '/config/routes';

import useToggle from './useToggle';

export const useImportToken = ({ tokenId, tokenAddress, chain }) => {
  const router = useRouter();

  const [isLoadingToken, , setIsLoadingToken] = useToggle(false);

  const onImportToken = useCallback(
    (callback) => {
      setIsLoadingToken(true);
      createAsset({
        tokenId,
        contractAddress: tokenAddress,
        chain
      })
        .then(({ data }) => {
          router.push(ROUTES.asset.list(data.data.id));
        })
        .catch((error) => console.error('Asset creation error: ', error))
        .finally(() => {
          setIsLoadingToken(false);
          callback?.();
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokenId, tokenAddress, chain]
  );

  return { isLoadingToken, onImportToken };
};
