import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDiscord,
  faInstagram,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

import { ROUTES } from '/config/routes';
import { Typography } from '/design-systems/Atoms/Typography';
import { Logo } from '/design-systems/Atoms/Logo';
import { PageContainer } from '/design-systems/Molecules/PageContainer';
import { NavMenu } from '/design-systems/Organisms/Header/NavMenu';
import { HyperlinkWrapper } from '/design-systems/Organisms/HyperlinkWrapper';
import { MailchimpForm } from './MailchimpForm';
import { usePage } from '/hooks/usePage';
import { mergeClassNames } from '/utils/string';
import { emptyArray } from '/utils/object';

export const Footer = () => {
  const {
    isDashboardPage,
    isDemoPage,
    isProfilePage,
    isNewPage,
    isEditPage,
    is1120,
    isPreviewPage,
    isOraclePage,
    isManageAssetPage,
    isAssetDetailsPage,
    isAssetComposePage,
    isUserOnboardingPage
  } = usePage();

  const hide = !emptyArray(
    [
      isDashboardPage,
      isDemoPage,
      isProfilePage,
      isNewPage,
      isEditPage,
      isPreviewPage,
      isOraclePage,
      isManageAssetPage,
      isAssetDetailsPage,
      isAssetComposePage,
      isUserOnboardingPage
    ].filter(Boolean)
  );
  if (hide) {
    return <></>;
  }

  return (
    <div id="footer" className="w-full bg-neutral-100 py-8 dark:bg-neutral-800">
      <PageContainer
        width={mergeClassNames(
          'w-full',
          is1120 ? 'max-w-[1120px]' : 'max-w-[1280px]'
        )}
        py="0"
      >
        <div className="flex w-full flex-wrap gap-x-8 gap-y-4 md:flex-nowrap">
          <div className="flex w-full flex-col gap-6">
            <Logo />
            <div className="flex flex-wrap gap-x-8 gap-y-2">
              <NavMenu
                className="!px-0 text-neutral-600 dark:text-neutral-500"
                id="footer-about"
                newTab
                title="About"
                url={ROUTES.site.about}
              />
              <NavMenu
                className="!px-0 text-neutral-600 dark:text-neutral-500"
                id="footer-partner"
                newTab
                title="Partner with us"
                url={ROUTES.site.partners}
              />
              <NavMenu
                className="!px-0 text-neutral-600 dark:text-neutral-500"
                id="footer-blog"
                newTab
                title="Blog"
                url={ROUTES.site.blog}
              />
              <NavMenu
                className="!px-0 text-neutral-600 dark:text-neutral-500"
                id="footer-help"
                newTab
                title="Help"
                url={ROUTES.socials.discord}
              />
              <NavMenu
                className="!px-0 text-neutral-600 dark:text-neutral-500"
                id="footer-terms"
                newTab
                title="Terms"
                url={ROUTES.site.terms}
              />
            </div>
            {/* social links */}
            <div className="flex flex-wrap items-center gap-x-6 gap-y-2">
              <HyperlinkWrapper newTab={true} url={ROUTES.socials.twitter}>
                <FontAwesomeIcon
                  className="h-6 w-6 text-neutral-500 group-hover:text-neutral-800"
                  icon={faTwitter}
                />
              </HyperlinkWrapper>
              <HyperlinkWrapper newTab={true} url={ROUTES.socials.instagram}>
                <FontAwesomeIcon
                  className="h-6 w-6 text-neutral-500 group-hover:text-neutral-800"
                  icon={faInstagram}
                />
              </HyperlinkWrapper>
              <HyperlinkWrapper newTab={true} url={ROUTES.socials.discord}>
                <FontAwesomeIcon
                  className="h-6 w-6 text-neutral-500 group-hover:text-neutral-800"
                  icon={faDiscord}
                />
              </HyperlinkWrapper>
              <HyperlinkWrapper newTab={true} url={ROUTES.socials.youtube}>
                <FontAwesomeIcon
                  className="h-6 w-6 text-neutral-500 group-hover:text-neutral-800"
                  icon={faYoutube}
                />
              </HyperlinkWrapper>
            </div>
          </div>
          <div className="flex w-full flex-col gap-4">
            <Typography
              colorVariant="secondary"
              variant="medium"
              weight="medium"
            >
              Join our newsletter
            </Typography>

            {/* Mailchimp form */}
            <MailchimpForm />
          </div>
        </div>
      </PageContainer>
    </div>
  );
};
