import React, { useMemo } from 'react';

import { Typography } from '/design-systems/Atoms/Typography';

export const ComposerOptionCard = ({
  id,
  title,
  description,
  illustration,
  onClick,
  variant = 'default'
}) => {
  const isComingSoon = useMemo(() => variant === 'coming-soon', [variant]);
  return (
    <div
      id={id}
      className={`flex flex-col gap-0 overflow-hidden rounded-xl border border-neutral-200 ${
        isComingSoon
          ? 'opacity-50'
          : 'cursor-pointer transition duration-300 hover:-translate-y-1'
      }`}
      onClick={onClick}
    >
      <div className="relative flex h-[200px] w-full items-center justify-center gap-3 bg-neutral-100">
        {illustration}
      </div>
      <div className="flex w-full flex-col gap-2 rounded-none bg-white p-4">
        <div className="flex items-center gap-2">
          <Typography weight="semibold" variant="medium">
            {title}
          </Typography>
          {isComingSoon && (
            <Typography colorVariant="secondary" variant="xsmall">
              Coming soon
            </Typography>
          )}
        </div>
        <Typography colorVariant="secondary">{description}</Typography>
      </div>
    </div>
  );
};
