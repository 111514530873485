import { Contract } from './contractService';

class ERC20Service {
  contract;

  constructor(address, abi) {
    this.contract = new Contract(address, abi);
  }

  balanceOf({ address }) {
    return this.contract.read('balanceOf', [address]);
  }

  allowance({ owner, spender }) {
    return this.contract.read('allowance', [owner, spender]);
  }

  approve({ spender, amount }) {
    return this.contract.write('approve', [spender, amount]);
  }
}

export default ERC20Service;
