import classNamesHelper from 'classnames';

import { getCoverFile } from '/utils/image';
import { createUrlBySlug } from '/utils/url';

import { META_ENTITIES } from './constants';
import { emptyArray } from './object';

export const getGalleryLink = (gallery) =>
  gallery?.customUrl || createUrlBySlug(gallery?.title, gallery?.slug);

export const GALLERY_VISIBILITY_EXPLANATION = {
  unpublished: 'Only you can view.',
  published: "Anyone can view. And it's discoverable.",
  unlisted: 'Anyone with the link can view. Collab requests will be sent.'
};

export const CUSTOM_GALLERY_SUMMARY = {
  cellscapes: {
    pieces: {
      text: '16'
    },
    price: {
      text: '0.67 ETH'
    }
  }
};

export const getGalleryCoverImage = (gallery) => {
  const coverFile = getCoverFile(gallery);

  if (coverFile) {
    return coverFile;
  }

  const firstGallerySectionWithAssets = gallery?.GallerySections?.find(
    (s) => s.Assets.length
  );
  return firstGallerySectionWithAssets?.Assets?.[0].Files[0];
};

export const getGalleryBgClassName = (gallery) => {
  const galleryBackgroundFile =
    gallery?.Files?.filter(({ type }) => type === 'background') || [];

  let bgUrlKey;
  if (galleryBackgroundFile.length)
    bgUrlKey = `bg-[url('${process.env.CLOUDFRONT_URL}/${galleryBackgroundFile[0].fileUrl}')]`;
  const className = classNamesHelper(
    gallery?.customData?.pageContainer?.backgroundClassName,
    'bg-center bg-cover bg-no-repeat bg-fixed',
    {
      [bgUrlKey]: galleryBackgroundFile.length
    }
  );

  return className;
};

export const buildGalleryPayload = ({
  galleryId,
  payload,
  defaultTitle,
  requestType = 'update',
  galleryType = META_ENTITIES.STORY,
  tabId
}) => {
  const data = {
    title: payload.title || defaultTitle,
    description: payload.description,
    sections: payload.sections,
    curatorSplits: payload.curatorSplits?.map(({ address, percentage }) => ({
      address,
      percentage: percentage * 100
    })),
    cover: payload.cover,
    theme: payload.theme,
    tabId
  };

  if (requestType === 'create') {
    return {
      ...data,
      projectId: payload.projectId,
      galleryType,
      customUrl: payload.customUrl
    };
  } else if (requestType === 'update') {
    return {
      id: galleryId,
      gallery: {
        ...data,
        customUrl: payload.customUrl
      }
    };
  }
};

export const getFirstAssetFiles = (gallery) => {
  const firstAssets = [];

  if (!gallery?.GallerySections) {
    return firstAssets;
  }

  gallery.GallerySections.map((section) => {
    section.Assets?.map((Asset) => {
      firstAssets.push(Asset);
    });
  });

  return firstAssets;
};

export const isGallerySectionEmpty = (section) => {
  return emptyArray(section?.Assets) && !section?.description;
};
