import React, { useMemo } from 'react';

import { OverlayCircles } from '../OverlayCircles';

import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';
import { getHeaderProfileName } from '/utils/user';
import { getAvatar } from '/utils/image';

export const Team = ({
  staticFile,
  users,
  size = 48,
  rounded,
  required = 3,
  handleProfileClick,
  hideAvatar = false,
  noHover = false,
  noWrap = false,
  noLabel = false,
  noMore = false,
  AdditionalChild,
  className,
  labelClassName,
  handleShowMore
}) => {
  const elements = useMemo(
    () =>
      users?.map((user) => ({
        ...user,
        displayName: getHeaderProfileName(user)
      })),
    [users]
  );

  if (!elements || !elements.length) return <></>;

  const handleElementClick = (user) => {
    handleProfileClick?.({
      profileId: user.username || getMainWalletAddress(user)
    });
  };

  return (
    <OverlayCircles
      AdditionalChild={AdditionalChild}
      className={className}
      elements={users}
      elementsResolution={'xs'}
      getElementAvatar={getAvatar}
      getFloatingTipLabel={getHeaderProfileName}
      handleElementClick={handleElementClick}
      handleShowMore={handleShowMore}
      hideAvatar={hideAvatar}
      labelClassName={labelClassName}
      noHover={noHover}
      noLabel={noLabel}
      noMore={noMore}
      noWrap={noWrap}
      required={required}
      rounded={rounded}
      size={size}
      staticFile={staticFile}
    />
  );
};

const getMainWalletAddress = (user) =>
  retrieveMainWalletAddressFromWalletAddresses(user.WalletAddresses)?.address;
