import mesh1 from '/images/mesh-1.png';
import mesh2 from '/images/mesh-2.png';
import mesh3 from '/images/mesh-3.png';
import mesh4 from '/images/mesh-4.png';
import mesh5 from '/images/mesh-5.png';
import mesh6 from '/images/mesh-6.png';

import meshPfp1 from '/images/mesh-pfp-1.png';
import meshPfp2 from '/images/mesh-pfp-2.png';
import meshPfp3 from '/images/mesh-pfp-3.png';
import meshPfp4 from '/images/mesh-pfp-4.png';
import meshPfp5 from '/images/mesh-pfp-5.png';
import meshPfp6 from '/images/mesh-pfp-6.png';
import meshPfp7 from '/images/mesh-pfp-7.png';
import meshPfp8 from '/images/mesh-pfp-8.png';

import PlaceholderSVG from '/images/placeholder.svg';

const meshImages = [mesh1, mesh2, mesh3, mesh4, mesh5, mesh6];
const meshPfpImages = [
  meshPfp1,
  meshPfp2,
  meshPfp3,
  meshPfp4,
  meshPfp5,
  meshPfp6,
  meshPfp7,
  meshPfp8
];

export const promptPlaceholderImg = (prompt) => {
  const index = prompt.id % meshImages.length;
  return meshImages[index].src;
};

export const profilePlaceholderImg = (id) => {
  let index;
  if (!id) {
    // index = 0; //Math.floor(Math.random() * meshPfpImages.length);
    return PlaceholderSVG;
  } else if (typeof id === 'string') {
    index = id.charCodeAt(id.length - 1) % meshPfpImages.length;
  } else {
    index = id % meshPfpImages.length;
  }

  return meshPfpImages[index];
};

export const staticImgSrc = (src, staticFile) => {
  return staticFile ? src : `${process.env.CLOUDFRONT_URL}/${src}`;
};

export const getCoverFile = (obj) =>
  obj?.Files?.find(({ type }) => type == 'cover');

export const getAvatarFile = (obj) => {
  return obj?.Files?.find(({ type }) => type == 'avatar');
};

export const getCover = (obj) => {
  return getCoverFile(obj)?.fileUrl;
};

export const getSignature = (obj) =>
  obj?.Files?.find(({ type }) => type == 'signature');

export const getAvatar = (obj) => {
  return getAvatarFile(obj)?.fileUrl;
};

export const getLogo = (obj) => {
  return obj?.Files?.find(({ type }) => type == 'logo')?.fileUrl;
};
