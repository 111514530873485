import React from 'react';
import { BLOCKCHAINS } from '/utils/constants';
import { Alert } from '/design-systems/Atoms/Alert';
import { Button } from '/design-systems/Atoms/Button';
import { useImportToken } from '/hooks/useImportToken';

export const ImportNftCard = ({ label = '', tokenId, tokenAddress }) => {
  const { isLoadingToken: isLoading, onImportToken } = useImportToken({
    tokenAddress,
    tokenId,
    chain: BLOCKCHAINS.ETHEREUM
  });

  return (
    <div className="flex flex-col gap-y-4 rounded-[12px] border border-[#D1D5DB] bg-white p-6 drop-shadow-xl">
      <Button
        disabled={isLoading}
        loading={isLoading}
        variant="primary"
        color="black"
        onClick={onImportToken}
      >
        {label}
      </Button>

      {isLoading && <Alert color="warning">Importing NFT metadata...</Alert>}
    </div>
  );
};
