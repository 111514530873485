export const sortByAsc = ({ array, attribute, date = false }) => {
  return date
    ? array?.sort((a, b) =>
        new Date(a[attribute]) > new Date()
          ? new Date(a[attribute]) - new Date(b[attribute])
          : new Date(b[attribute]) - new Date(a[attribute])
      )
    : array?.sort((a, b) => a[attribute] - b[attribute]);
};

export const sortByDesc = ({ array, attribute, date = false }) => {
  return date
    ? array.sort((a, b) => new Date(b[attribute]) - new Date(a[attribute]))
    : array.sort((a, b) => b[attribute] - a[attribute]);
};

/**
 * Reorders an array by moving an element from one position to another.
 * @param {Array} list The original array to be reordered
 * @param {Number} startIndex The index of the element to move
 * @param {Number} endIndex The new index of the element after it has been moved
 * @return {Array} A new array that is a modified version of the original array with the element moved
 */
export const reorder = (list, startIndex, endIndex) => {
  // Make a copy of the original array to avoid modifying it
  const copiedList = Array.from(list);

  // Move the element from the startIndex to the endIndex
  const movedList = moveElement(copiedList, startIndex, endIndex);

  // Return the modified array
  return movedList.map((item, index) => {
    return { ...item, order: index };
  });
};

/**
 * Moves an element from one position to another in an array.
 * @param {Array} array The array to be modified
 * @param {Number} startIndex The index of the element to move
 * @param {Number} endIndex The new index of the element after it has been moved
 * @return {Array} A new array that is a modified version of the original array with the element moved
 */
const moveElement = (array, startIndex, endIndex) => {
  const removedElement = array[startIndex];
  const remainingElements = array.filter((_, index) => index !== startIndex);

  const movedElements = [
    ...remainingElements.slice(0, endIndex),
    removedElement,
    ...remainingElements.slice(endIndex)
  ];

  return movedElements;
};

export const makeArrayWithUniqueElements = ({ array, attribute }) => [
  ...new Map(array.map((e) => [e[attribute], e])).values()
];

export const getTabIndexByKey = (tabItems, key) => {
  if (!tabItems || !key) return -1;
  return tabItems.findIndex((tab) => tab.key === key);
};

export const insertToArray = ({ array, item, where }) => {
  return where === undefined
    ? [...array, item]
    : [...array.slice(0, where), item, ...array.slice(where)];
};

export const removeDuplications = ({ array }) => {
  return [...new Set(array)];
};

export const generateArray = (length, mapper = () => {}) => {
  return Array.from(new Array()).map(mapper);
};
