import { useMemo } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  getGallery,
  getGalleryActivities as fetchGallaryActivities
} from '/services/joynApi/producers/gallery';
import { QUERY_KEYS } from '/utils/queries';
import { COLLABORATABLE_TYPES } from '/utils/constants';
import { getCollaboratorInvitations } from '/services/joynApi/producers/collaboration-invitation';
import { getCollaborators } from '/services/joynApi/producers/collaborator';

export const useGallery = ({ id, excludeExpiredInvites = false }) => {
  const {
    isLoading: isFetchingGallery,
    data: gallery,
    refetch
  } = useQuery([QUERY_KEYS.GALLERY.GET], () => getGallery({ id }), {
    enabled: !!id,
    select: (res) => res.data.data
  });

  const { isLoading: isFetchingActivities, mutateAsync: getGalleryActivities } =
    useMutation(async (galleryId) => {
      const galleryActivities = await fetchGallaryActivities({ id: galleryId });
      return galleryActivities?.data?.data;
    });

  const {
    isLoading: isFetchingGalleryCollaborators,
    data: galleryCollaborators,
    refetch: refetchGalleryCollaborators
  } = useQuery(
    [QUERY_KEYS.GALLERY.Get_Gallery_COLLABORATORS, gallery],
    () =>
      getCollaborators({
        id: gallery?.id,
        collaboratableType: COLLABORATABLE_TYPES.GALLERY
      }),
    {
      enabled: !!gallery?.id,
      select: (res) => res.data.data
    }
  );

  const {
    isLoading: isFetchingGalleryCollaboratorInvitations,
    data: galleryCollaboratorInvitations,
    refetch: refetchGalleryCollaboratorInvitations
  } = useQuery(
    [QUERY_KEYS.GALLERY.GET_GALLERY_COLLABORATOR_INVITATIONS, gallery],
    () =>
      getCollaboratorInvitations({
        id: gallery?.id,
        collaboratableType: COLLABORATABLE_TYPES.GALLERY,
        excludeExpiredInvites
      }),
    {
      enabled: !!gallery?.id,
      select: (res) => res.data.data
    }
  );

  return useMemo(
    () => ({
      isLoadingGallery: isFetchingGallery,
      /**
       * @deprecated use isLoadingGallery instead
       */
      isFetchingGallery,
      gallery,
      getGalleryActivities,
      isFetchingActivities,
      refetch,
      galleryCollaborators,
      isFetchingGalleryCollaborators,
      refetchGalleryCollaborators,
      galleryCollaboratorInvitations,
      isFetchingGalleryCollaboratorInvitations,
      refetchGalleryCollaboratorInvitations
    }),
    [
      isFetchingGallery,
      gallery,
      refetch,
      getGalleryActivities,
      isFetchingActivities,
      galleryCollaborators,
      isFetchingGalleryCollaborators,
      refetchGalleryCollaborators,
      galleryCollaboratorInvitations,
      isFetchingGalleryCollaboratorInvitations,
      refetchGalleryCollaboratorInvitations
    ]
  );
};
