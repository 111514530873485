import { useMutation, useQuery } from '@tanstack/react-query';
import { getUser, updateUserProfile } from '/services/joynApi/users/user';
import { useMemo } from 'react';
import { retrieveMainWalletAddressFromWalletAddresses } from '../utils';

export const useUser = ({ id }) => {
  const {
    isLoading: isLoadingUser,
    data: user,
    refetch: handleRefetchUser
  } = useQuery(['get-single-user', id], () => getUser({ id }), {
    enabled: !!id,
    select: (res) => res.data.data
  });

  const { isLoading: isUpdatingProfile, mutateAsync: handleUpdateUserProfile } =
    useMutation(async (payload) => {
      await updateUserProfile(payload);
      await handleRefetchUser();
    });

  const userProfileHeader = useMemo(
    () =>
      (user?.username && `@${user?.username}`) ||
      user?.name ||
      retrieveMainWalletAddressFromWalletAddresses(user?.WalletAddresses)
        .address,
    [user]
  );

  return useMemo(
    () => ({
      isLoadingUser,
      user,
      userProfileHeader,
      onRefetchUser: handleRefetchUser,
      onUpdateUserProfile: handleUpdateUserProfile
    }),
    [
      isLoadingUser,
      user,
      userProfileHeader,
      handleRefetchUser,
      handleUpdateUserProfile
    ]
  );
};
