import React, { useCallback } from 'react';
import { useConnect } from 'wagmi';

import { Button } from '/design-systems/Atoms/Button';
import { Typography } from '/design-systems/Atoms/Typography';
import { Image } from '/design-systems/Atoms/Image';
import { Modal } from '/design-systems/Atoms/Modal';
import { useAuth } from '/hooks/useAuth';
import { getWalletIcon } from '/utils/auth';

import { isMobile } from 'react-device-detect';
import { mergeClassNames } from '/utils/string';
import { useGlobal } from '/context/GlobalManager';

export const ConnectWalletModal = ({
  open,
  withReturn,
  withUnlockWallet,
  onClose
}) => {
  const { pendingConnector, connectors } = useConnect();
  const { isConnectingWallet, onConnectWallet } = useAuth();
  const { connectorInProcess, setConnectorInProcess } = useGlobal();

  const handleConnect = (connector) => () => {
    if (connector?.name !== 'MetaMask') {
      handleClose();
    }

    setConnectorInProcess(connector);

    onConnectWallet({ connector, withReturn, withUnlockWallet })
      .then(() => {
        if (connector?.name !== 'WalletConnect') onClose();
      })
      .catch((error) => {
        onClose(error);
      })
      .finally(() => {
        setConnectorInProcess(undefined);
      });
  };

  const handleClose = useCallback(() => {
    if (pendingConnector || isConnectingWallet) return;
    onClose();
  }, [pendingConnector, isConnectingWallet, onClose]);

  return (
    <Modal
      isTop={true}
      open={open}
      onClose={handleClose}
      autoHeight
      maxWidth={600}
      className="flex flex-col gap-6"
    >
      <div className="flex items-center justify-center gap-6">
        <Typography weight="semibold" align="center" variant="h3" heading>
          Connect wallet
        </Typography>
      </div>
      <div className="flex flex-col gap-4">
        {connectors?.map((connector) => (
          <Button
            key={connector.id}
            loading={
              connectorInProcess && connectorInProcess?.id === connector.id
            }
            disabled={!connector.ready || connectorInProcess}
            variant="outlined"
            color="neutral"
            className={mergeClassNames(
              (!connector.ready || connectorInProcess) && '!bg-neutral-100'
            )}
            onClick={handleConnect(connector)}
          >
            <div className="flex w-full items-center gap-x-4">
              <Image
                src={getWalletIcon(connector.name)?.src}
                staticFile={true}
                className="max-h-[32px] max-w-[32px]"
              />
              <div className="flex-1 text-left">{connector.name}</div>
            </div>
          </Button>
        ))}
      </div>
      {isMobile && (
        <Typography colorVariant="secondary">
          Need more wallet options? Try using Joyn on desktop.
        </Typography>
      )}
    </Modal>
  );
};
