import React, { useCallback, useState } from 'react';
import { useRouter } from 'next/router';

import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Button } from '/design-systems/Atoms/Button';
import { Divider } from '/design-systems/Atoms/Divider';
import { ModalTitleRow } from '/design-systems/Molecules/Modals/ModalTitleRow';
import { TemplateCard } from '/design-systems/Molecules/Cards/TemplateCard';

import { ROUTES } from '/config/routes';

export const AcceptRequestUnmintedModal = ({
  open,
  onClose,
  assetId,
  collabRequestId,
  galleryId
}) => {
  const router = useRouter();
  const [selectedTemplate, setSelectedTemplate] = useState(
    ACCEPT_ACTIONS[0].id
  );

  const findAction = (id) => ACCEPT_ACTIONS.find((action) => action.id === id);

  const getDescription = (id) => findAction(id)?.description;

  const handleNext = useCallback(() => {
    let path;
    switch (selectedTemplate) {
      case ACCEPT_ACTION_IDS.MINT:
        path = ROUTES.asset.mint(assetId, collabRequestId);
        break;
      case ACCEPT_ACTION_IDS.LINK:
        path = ROUTES.asset.link(assetId, galleryId);
        break;
      default:
        return;
    }

    router.push(path);
    onClose();
  }, [router, selectedTemplate, onClose, assetId, collabRequestId, galleryId]);

  return (
    <Modal
      id="users-modal"
      className="relative flex flex-col gap-4"
      wrapperClassName="pt-10"
      autoHeight={true}
      open={open}
      onClose={onClose}
    >
      <ModalTitleRow title="Accept request" />

      <Divider size="smallest" />

      <Typography variant="medium" colorVariant="secondary">
        In order to list, you first need to take one of the following actions.
      </Typography>

      <div className="flex flex-col gap-4">
        {ACCEPT_ACTIONS.map(({ id, title }) => (
          <TemplateCard
            key={id}
            id={id}
            title={title}
            description={getDescription(id)}
            selectedTemplate={selectedTemplate}
            onClick={() => setSelectedTemplate(id)}
          />
        ))}

        <Button
          id="art-plus-next-button"
          color="black"
          variant="primary"
          disabled={!selectedTemplate}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </Modal>
  );
};

const ACCEPT_ACTION_IDS = {
  MINT: 'accept-request-mint',
  LINK: 'accept-request-link'
};

const ACCEPT_ACTIONS = [
  {
    id: ACCEPT_ACTION_IDS.LINK,
    title: 'Link to NFT',
    description: 'Already minted? Link to an existing NFT.'
  },
  {
    id: ACCEPT_ACTION_IDS.MINT,
    title: 'Mint new',
    description: 'Make the piece available for sale. '
  }
];
