import React, { useCallback, useMemo, useState } from 'react';
import { OfferModal } from '/design-systems/Molecules/Modals/OfferModal';

import { ethers } from 'ethers';
import { useTokenRoyaltyRecipients } from '/hooks/useTokenRoyaltyRecipients';
import BigNumber from 'bignumber.js';
import { abbreviateNumber } from '/utils/number';
import { useSelector } from 'react-redux';
import { retrieveMainWalletAddressFromWalletAddresses } from '/utils/index';
import { useAssetContract } from '/hooks/on-chain/useAssetContract';
import { acceptOffer } from '/utils/marketplace';
import { useSigner } from '/utils/ethers';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { getTransactionLink } from '/utils/web3';
import { openLink } from '/utils/url';
import Countdown from 'react-countdown';
import { Divider } from '/design-systems/Atoms/Divider';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { useNetwork } from 'wagmi';
import { useWallet } from '/hooks/useWallet';

const ACCEPT_OFFER_STEPS = {
  ACCEPT_OFFER_DETAILS: '1',
  APPROVE_COLLECTION: '2',
  ACCEPT_OFFER: '3'
};

export const AcceptOfferModal = ({
  open,
  onClose,
  assetChainId,
  assetCreatorAddress,
  assetCreator,
  title,
  assetImage,
  offer,
  tokenOwnedByCurrentUser,
  assetTokenId,
  assetTokenAddress,
  tokenStandard,
  refetchOfferData
}) => {
  const [step, setStep] = useState(ACCEPT_OFFER_STEPS.ACCEPT_OFFER_DETAILS);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [txHash, setTxHash] = useState('');
  const currentUser = useSelector((state) => state.user.current);
  const { walletAddress } = useWallet({
    walletAddresses: currentUser?.WalletAddresses ?? []
  });
  const signer = useSigner();
  const { chain } = useNetwork();

  const { tokenRoyaltyRecipients } = useTokenRoyaltyRecipients({
    tokenId: offer?.tokenId
  });

  const {
    isApprovedForAll: isSeaportApproved,
    setApprovalForAll,
    refetchIsApprovedForAll
  } = useAssetContract({
    assetChainId: chain?.id,
    tokenId: assetTokenId,
    tokenAddress: assetTokenAddress,
    owner: retrieveMainWalletAddressFromWalletAddresses(
      currentUser?.WalletAddresses ?? []
    )?.address,
    tokenStandard
  });

  const originalOfferUnits = useMemo(
    () =>
      offer?.Considerations?.find(({ itemType }) =>
        ['ERC721', 'ERC1155'].includes(itemType)
      )?.startAmount,
    [offer]
  );

  const fulfillableItems = useMemo(
    () =>
      +offer?.fulfillmentLeft <= tokenOwnedByCurrentUser ||
      !tokenOwnedByCurrentUser
        ? offer?.fulfillmentLeft
        : tokenOwnedByCurrentUser,
    [offer?.fulfillmentLeft, tokenOwnedByCurrentUser]
  );

  const { tokenId, tokenAddress } = useMemo(() => {
    const tokenConsideration = offer?.orderData?.parameters?.consideration.find(
      (considerationData) => considerationData?.itemType === 3
    );

    return {
      tokenId: tokenConsideration?.identifierOrCriteria,
      tokenAddress: tokenConsideration?.token
    };
  }, [offer?.orderData]);

  const onAcceptOfferDetails = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await acceptOffer({
        isSeaportApproved,
        refetchIsApprovedForAll,
        setApprovalForAll,
        order: offer?.orderData,
        refetchOffer: refetchOfferData,
        signer,
        tokenId: tokenId || assetTokenId,
        tokenAddress: tokenAddress || assetTokenAddress,
        fulfillerAddress: walletAddress,
        galleryId: offer?.galleryId,
        unitsToFill: fulfillableItems,
        assetTitle: title,
        chainId: assetChainId,
        setTxHash: (hash) => setTxHash(hash),
        onApproveCollection: () =>
          setStep(ACCEPT_OFFER_STEPS.APPROVE_COLLECTION),
        onAcceptOffer: () => setStep(ACCEPT_OFFER_STEPS.ACCEPT_OFFER),
        onError: () => setStep(ACCEPT_OFFER_STEPS.ACCEPT_OFFER_DETAILS),
        onSuccess: () => {
          setStep(ACCEPT_OFFER_STEPS.ACCEPT_OFFER_DETAILS);
          onClose?.();
        },
        seaportVersion: offer?.seaportVersion
      });
    } catch (error) {
      console.error('Accepting offer error: ', error);
    } finally {
      setIsSubmitting(false);
    }
  }, [
    isSeaportApproved,
    refetchIsApprovedForAll,
    setApprovalForAll,
    offer,
    assetChainId,
    refetchOfferData,
    signer,
    fulfillableItems,
    title,
    setTxHash,
    tokenId,
    tokenAddress
  ]);

  const offerAmount = useMemo(
    () => new BigNumber(offer?.priceUnit * fulfillableItems),
    [offer?.priceUnit, fulfillableItems]
  );

  const serviceFeePerUnit = useMemo(() => {
    const serviceFees = new BigNumber(
      offer?.Considerations?.find(({ label }) => label === 'marketplaceFee')
        ?.startAmount || 0
    );
    return serviceFees.dividedBy(+originalOfferUnits);
  }, [offer, originalOfferUnits]);

  const serviceFee = useMemo(
    () => serviceFeePerUnit.multipliedBy(fulfillableItems),
    [serviceFeePerUnit, fulfillableItems]
  );

  const serviceFeePercentage = useMemo(() => {
    return (100 * serviceFeePerUnit) / offerAmount;
  }, [serviceFeePerUnit, offerAmount]);

  const totalRoyalties = useMemo(() => {
    return offer?.Considerations?.filter(
      ({ label }) => label === 'royaltyFee'
    )?.reduce((total, { startAmount }) => total + startAmount, 0);
  }, []);

  const { isRoyaltyRecipient, royaltyAmount } = useMemo(() => {
    const royaltyRecipient = offer?.Considerations.find(
      (consideration) =>
        consideration.label === 'royaltyFee' &&
        consideration.recipient.toLowerCase() === walletAddress.toLowerCase()
    );

    return {
      isRoyaltyRecipient: !!royaltyRecipient,
      royaltyAmount: royaltyRecipient?.startAmount
    };
  }, [walletAddress, offer]);

  const royaltyPercentPerUnit = useMemo(() => {
    let royaltyFee = new BigNumber(totalRoyalties);
    if (isRoyaltyRecipient) {
      royaltyFee = royaltyFee.minus(new BigNumber(royaltyAmount));
    }
    return royaltyFee.dividedBy(originalOfferUnits);
  }, [totalRoyalties, originalOfferUnits, isRoyaltyRecipient, royaltyAmount]);

  const offerRoyaltyAmount = useMemo(
    () => royaltyPercentPerUnit.multipliedBy(fulfillableItems),
    [royaltyPercentPerUnit, fulfillableItems]
  );

  const curatorFee = useMemo(() => {
    let curatorFees = new BigNumber(0);
    offer?.Considerations?.map((consideration) => {
      if (consideration.label === 'curatorSplit')
        curatorFees = curatorFees.plus(consideration.startAmount);
    });
    return curatorFees;
  }, [offer]);

  const finalPayableAmount = useMemo(() => {
    return offerAmount
      .minus(offerRoyaltyAmount)
      .minus(serviceFee)
      .minus(curatorFee);
  }, [offerRoyaltyAmount, serviceFee, offerAmount, curatorFee]);

  const expiredDay = useMemo(() => {
    return offer?.endTime;
  }, [offer]);

  const DateFormat = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="flex flex-row gap-x-3">
        {!!days && (
          <div className="flex items-center">
            <Typography weight="semibold" variant="medium" className="mr-1">
              {days}
            </Typography>
            <Typography variant="medium" colorVariant="secondary">
              days
            </Typography>
          </div>
        )}
        <div className="flex items-center">
          <Typography weight="semibold" variant="medium" className="mr-1">
            {hours}
          </Typography>
          <Typography variant="medium" colorVariant="secondary">
            hours
          </Typography>
        </div>
        <div className="flex items-center">
          <Typography weight="semibold" variant="medium" className="mr-1">
            {minutes}
          </Typography>
          <Typography variant="medium" colorVariant="secondary">
            mins
          </Typography>
        </div>
        <div className="flex items-center">
          <Typography weight="semibold" variant="medium" className="mr-1">
            {seconds}
          </Typography>
          <Typography variant="medium" colorVariant="secondary">
            secs
          </Typography>
        </div>
      </div>
    );
  };

  const acceptOfferDetailsContent = useMemo(
    () => (
      <div className="flex w-full flex-col gap-y-6">
        <div className="flex w-full items-center gap-y-3">
          <div className="flex w-full flex-col justify-between gap-2">
            <Typography variant="medium" weight="medium">
              Quantity
            </Typography>
            {fulfillableItems && (
              <Typography variant="small" colorVariant="secondary">
                {abbreviateNumber(fulfillableItems)} available
              </Typography>
            )}
          </div>
          <Typography
            id="offer-quantity"
            variant="medium"
            weight="medium"
            className="text-[#111827]"
          >
            {fulfillableItems}
          </Typography>
        </div>
        <Divider size="none" />
        <div className="flex w-full flex-col gap-y-3">
          <Typography variant="medium" weight="medium">
            Offer details
          </Typography>
          <div className="flex w-full justify-between">
            <Typography
              variant="medium"
              className="self-start"
              colorVariant="secondary"
              weight="medium"
            >
              Unit price
            </Typography>
            <Typography
              id="offer-unit-price"
              variant="medium"
              weight="medium"
              className="self-start text-[#111827]"
            >
              {ethers.formatEther(offer?.priceUnit)} WETH
            </Typography>
          </div>
          <div className="w-full items-center justify-between sm:flex">
            <Typography
              variant="medium"
              className="self-start"
              colorVariant="secondary"
              weight="medium"
            >
              Expiration
            </Typography>
            <Typography
              variant="medium"
              weight="medium"
              className="mt-1 self-start text-[#111827] sm:mt-0"
            >
              <Countdown
                date={new Date(expiredDay)}
                intervalDelay={0}
                precision={3}
                renderer={DateFormat}
              />
            </Typography>
          </div>
        </div>

        <Divider size="none" />

        <div className="flex w-full flex-col gap-y-3">
          <Typography variant="medium" weight="medium">
            Fees
          </Typography>
          <div className="flex w-full justify-between">
            <Typography
              variant="medium"
              className="self-start"
              colorVariant="secondary"
              weight="medium"
            >
              Service fee
            </Typography>
            <Typography
              variant="medium"
              weight="medium"
              className="self-start text-[#111827]"
            >
              {serviceFeePercentage}%
            </Typography>
          </div>
          <div className="flex w-full justify-between">
            <Typography
              variant="medium"
              className="self-start"
              colorVariant="secondary"
              weight="medium"
            >
              Creator earnings
            </Typography>
            <Typography
              variant="medium"
              weight="medium"
              className="self-start text-[#111827]"
            >
              {tokenRoyaltyRecipients?.sumRoyaltyPercentage}%
            </Typography>
          </div>
        </div>

        <Divider size="none" />

        <div className="flex w-full justify-between">
          <Typography
            variant="medium"
            className="self-start"
            colorVariant="secondary"
            weight="medium"
          >
            You will receive
          </Typography>
          <Typography
            variant="medium"
            weight="medium"
            className="potential-earning self-start text-[#111827]"
          >
            {ethers.formatEther(finalPayableAmount.toString())} WETH
          </Typography>
        </div>
      </div>
    ),
    [
      offerAmount,
      finalPayableAmount,
      serviceFeePercentage,
      fulfillableItems,
      tokenRoyaltyRecipients?.sumRoyaltyPercentage,
      expiredDay
    ]
  );

  const approveCollectionContent = useMemo(
    () => (
      <div className="flex w-full flex-col gap-y-4">
        <Typography
          variant="medium"
          className="align-center flex self-start text-[#111827]"
        >
          Approve the transfer of the token above to the buyer.
        </Typography>
        <Typography
          variant="medium"
          className="align-center flex self-start text-[#111827]"
        >
          You only need to do this once.
        </Typography>
      </div>
    ),
    []
  );

  const acceptOfferContent = useMemo(
    () => (
      <>
        <Typography
          variant="medium"
          className="align-center flex self-start text-[#111827]"
        >
          Please review and confirm this bid from your wallet.
        </Typography>
        {txHash && (
          <div
            className="flex cursor-pointer items-center gap-x-1 self-start text-neutral-500 underline"
            onClick={() =>
              openLink(
                getTransactionLink({
                  chainId: assetChainId,
                  txHash
                })
              )
            }
          >
            View transaction
            <ExternalLinkIcon className="h-[16px] w-[16px]" />
          </div>
        )}
      </>
    ),
    [txHash, assetChainId]
  );

  const modalData = useMemo(() => {
    switch (step) {
      case ACCEPT_OFFER_STEPS.ACCEPT_OFFER_DETAILS:
        return {
          onSubmit: onAcceptOfferDetails,
          modalTitle: 'Accept Offer',
          modalActionButtonText: 'Accept Offer',
          modalActionButtonLoadingText: 'Accepting Offer',
          modalContent: acceptOfferDetailsContent
        };
      case ACCEPT_OFFER_STEPS.APPROVE_COLLECTION:
        return {
          modalTitle: 'Approve collection',
          modalActionButtonText: 'Confirm transaction',
          modalActionButtonLoadingText: 'Confirming transaction',
          modalContent: approveCollectionContent
        };
      case ACCEPT_OFFER_STEPS.ACCEPT_OFFER:
        return {
          modalTitle: 'Accept bid',
          modalActionButtonText: txHash
            ? 'Awaiting transaction'
            : 'Confirm transaction',
          modalActionButtonLoadingText: 'Confirming transaction',
          modalContent: acceptOfferContent
        };

      default:
        return {};
    }
  }, [step, onAcceptOfferDetails, acceptOfferDetailsContent, txHash]);

  return (
    <Modal
      id="make-offer-modal"
      open={open}
      padding="p-6"
      onClose={onClose}
      innerContainerClassName="h-auto"
      isTop={true}
    >
      <OfferModal
        isSubmitting={isSubmitting && !txHash}
        isDisabled={txHash}
        title={title}
        assetChainId={assetChainId}
        assetImage={assetImage}
        assetCreator={assetCreator}
        assetCreatorAddress={assetCreatorAddress}
        onSubmit={modalData.onSubmit}
        modalTitle={modalData.modalTitle}
        modalContent={modalData.modalContent}
        modalActionButtonText={modalData.modalActionButtonText}
        modalActionButtonLoadingText={modalData.modalActionButtonLoadingText}
        onClose={onClose}
      />
    </Modal>
  );
};
