import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo, useCallback } from 'react';
import { getPromptSubmission } from '/services/joynApi/users/prompt-submission';
import {
  hideSubmission,
  unhideSubmission
} from '/services/joynApi/producers/prompt-submission';
import { QUERY_KEYS } from '/utils/queries';

export const usePromptSubmission = (
  promptSubmissionId,
  { withAssetGallery = false } = {}
) => {
  const queryClient = useQueryClient();
  const queryKeys = [QUERY_KEYS.PROMPT_SUBMISSION.GET, promptSubmissionId];

  /** Get Prompt Submission data from react-query cache */
  const submission = queryClient?.getQueryData(queryKeys);

  const submissionFiles = useMemo(
    () => submission?.Files ?? [],
    [submission?.Files]
  );
  const submissionCreator = useMemo(() => submission?.User, [submission?.User]);

  /**
   * Fetch Prompt Submission Data
   * The response data will be stored in react-query cache
   */
  const { isLoading: isLoadingSubmission } = useQuery(
    queryKeys,
    async () => {
      const res = await getPromptSubmission({
        submissionId: promptSubmissionId,
        withAssetGallery,
      });
      return res.data.data;
    },
    {
      enabled: !!promptSubmissionId
    }
  );

  /**
   * Update current Prompt Submission Data
   */
  const onSetSubmission = useCallback(
    (newState) => {
      queryClient.setQueriesData(queryKeys, newState);
    },
    [queryKeys, queryClient]
  );

  return useMemo(
    () => ({
      isLoadingSubmission,
      submission,
      onSetSubmission,
      submissionFiles,
      submissionCreator
    }),
    [
      isLoadingSubmission,
      submission,
      onSetSubmission,
      submissionFiles,
      submissionCreator
    ]
  );
};

export const usePromptSubmissionMutations = () => {
  const {
    isLoading: isHidingPromptSubmission,
    mutateAsync: onHidePromptSubmission
  } = useMutation(({ promptId, submissionIds, hide }) => {
    const action = hide ? hideSubmission : unhideSubmission;
    return action({
      promptId,
      submissionIds
    });
  });

  return useMemo(
    () => ({
      isHidingPromptSubmission,
      onHidePromptSubmission
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isHidingPromptSubmission]
  );
};
