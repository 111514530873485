import React, { useState } from 'react';
import { DownloadIcon } from '@heroicons/react/outline';
import { downloadBlobFile } from '/utils/file';
import { Spinner } from '/design-systems/Atoms/Spinner';

export const BlobFileDownload = ({ fileUrl, fileName, fileExtension, DownloadComponent, loaderClassName }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await downloadBlobFile({
      urls: `${process.env.CLOUDFRONT_URL}/${fileUrl}`,
      doc: fileName,
      fileExtension: fileExtension,
    });
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <div className={`flex h-10 w-10 items-center justify-center ${loaderClassName}`}>
          <Spinner />
        </div>
      ) : (
        <div onClick={handleClick} className='w-full'>
          {DownloadComponent ? (
            <DownloadComponent />
          ) : (
            <div className='cursor-pointer rounded-md border-2 p-2'>
              <DownloadIcon className='h-5 w-5' />
            </div>
          )}
        </div>
      )}
    </>
  );
};
