import axios from '/services/axios';
import { generateQueryParams } from '/utils/url';

export const sendInvitation = (payload) => {
  return axios.post(`/collaboration-invitation`, payload);
};

export const acceptInvitation = (verificationCode) => {
  return axios.put(`/collaboration-invitation/${verificationCode}/accept`);
};

export const getCollaboratorInvitations = ({
  id,
  collaboratableType,
  excludeExpiredInvites = false
}) => {
  console.log({ id, collaboratableType });
  const query = generateQueryParams({
    collaboratableType,
    excludeExpiredInvites
  });

  return axios.get(`/collaboration-invitations/${id}?${query}`);
};

export const removeCollaboratorInvitation = ({
  id,
  collaboratableType,
  collaboratableId
}) => {
  const query = generateQueryParams({
    collaboratableType,
    collaboratableId
  });

  return axios.delete(`/collaborator-invitation/remove/${id}?${query}`);
};
