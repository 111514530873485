import React, { useEffect, useState } from 'react';
import { isEmptyArray } from 'formik';

import { useShallowState } from '/hooks/shallowState';
import { useContests } from '/hooks/useContests';

import { PAGE_SIZE } from '/utils/constants';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Divider } from '/design-systems/Atoms/Divider';
import { Button } from '/design-systems/Atoms/Button';
import { ToggleButton } from '/design-systems/Atoms/ToggleButton';
import { FormCheckbox } from '/design-systems/Atoms/FormCheckbox';
import { FormInput } from '/design-systems/Atoms/FormInput';
import { ModalTitleRow } from '../ModalTitleRow';

export const AssetFilterModal = ({
  projectId,
  filters,
  onClose,
  onFilter,
  showOpenCallsSection = true,
  showWinnersSection = true,
  showShortlistedSection = false,
  showOnlyListedSection = false,
  showOnlySpaceAssets = false
}) => {
  const [assetFilters, setAssetFilters] = useShallowState(filters);
  const {
    isLoadingContests,
    contests,
    hasMoreContests,
    isLoadingNextContests,
    fetchMoreContests,
    refetchContests
  } = useContests(projectId);

  const [filterCampaigns, setFilterCampaigns] = useState(
    new Set(assetFilters.campaigns ? assetFilters.campaigns : [])
  );

  useEffect(() => {
    setAssetFilters({ campaigns: [...filterCampaigns] });
  }, [filterCampaigns]);

  const handleClearFilters = () => {
    setAssetFilters({});
    onFilter({});
    onClose();
  };

  const handleFilterAssets = () => {
    onFilter(assetFilters);
    onClose();
  };

  const handleSelectCampaign =
    ({ promptId }) =>
    () => {
      if (filterCampaigns.has(promptId)) {
        setFilterCampaigns((currentFilterCampaigns) => {
          currentFilterCampaigns.delete(promptId);
          return new Set([...currentFilterCampaigns]);
        });
      } else {
        setFilterCampaigns(
          (currentFilterCampaigns) =>
            new Set([...currentFilterCampaigns, promptId])
        );
      }
    };

  const renderOpenCallsSection = () => {
    return (
      <>
        <Divider size="small" />
        <div className="open-calls flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <Typography variant="medium" weight="medium">
              Open calls
            </Typography>
            <Typography colorVariant="secondary">
              Filter assets by open calls
            </Typography>
          </div>

          <div className="flex flex-col">
            <div className="grid grid-cols-2 gap-y-3 md:grid-cols-3">
              {contests?.map((campaign) => (
                <div
                  key={campaign.promptId}
                  className="max-w-full overflow-hidden"
                >
                  <FormCheckbox
                    value={filterCampaigns.has(campaign.promptId)}
                    label={campaign.title}
                    onChange={handleSelectCampaign(campaign)}
                  />
                </div>
              ))}
            </div>
            {contests?.length >= PAGE_SIZE && hasMoreContests && (
              <Button
                color="black"
                variant="underline"
                onClick={() =>
                  !isLoadingContests &&
                  !isLoadingNextContests &&
                  fetchMoreContests()
                }
                className="w-fit"
              >
                Load more
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderToggleSection = ({
    title,
    subTitle,
    buttonId,
    isChecked,
    onToggleChange
  }) => {
    return (
      <div className="flex w-full justify-between">
        <div className="flex flex-col gap-y-2">
          <Typography variant="medium" weight="medium">
            {title}
          </Typography>
          <Typography colorVariant="secondary">{subTitle}</Typography>
        </div>

        <ToggleButton
          id={buttonId}
          label=""
          checked={isChecked}
          onChange={onToggleChange}
        />
      </div>
    );
  };

  const renderOnlySpaceAssetsSection = () => {
    return (
      <>
        <Divider size="small" />
        {renderToggleSection({
          title: 'From this Space',
          subTitle: 'Show only artworks that have been added to this space',
          buttonId: 'space-artworks-toggle',
          isChecked: assetFilters.onlySpace,
          onToggleChange: () =>
            setAssetFilters({
              onlySpace: !!!assetFilters.onlySpace
            })
        })}
      </>
    );
  };

  const renderWinnersSection = () => {
    return (
      <>
        <Divider size="small" />
        {renderToggleSection({
          title: 'Open call winners',
          subTitle: 'Show only winning assets',
          buttonId: 'campaign-winner-toggle',
          isChecked: assetFilters.winners,
          onToggleChange: () =>
            setAssetFilters({
              winners: !!!assetFilters.winners
            })
        })}
      </>
    );
  };

  const renderShortlistedSection = () => {
    return (
      <>
        <Divider size="small" />
        {renderToggleSection({
          title: 'Shortlisted',
          subTitle: 'Show only shortlisted assets',
          isChecked: assetFilters.shortlisted,
          onToggleChange: () =>
            setAssetFilters({
              shortlisted: !!!assetFilters.shortlisted
            })
        })}
      </>
    );
  };

  const renderOnlyListedSection = () => {
    return (
      <>
        <Divider size="small" />
        {renderToggleSection({
          title: 'Listed for sale',
          subTitle: 'Show only listed artworks',
          buttonId: 'toggle-only-listed-assets',
          isChecked: assetFilters.onlyListed,
          onToggleChange: () => {
            const pricingFilters = {};
            if (assetFilters.onlyListed) {
              pricingFilters.minPrice = undefined;
              pricingFilters.maxPrice = undefined;
              pricingFilters.minCuratorFee = undefined;
            }
            setAssetFilters({
              onlyListed: !!!assetFilters.onlyListed,
              ...pricingFilters
            });
          }
        })}
        {assetFilters.onlyListed && (
          <div className="listing-sub-filters flex flex-col gap-4 pt-4">
            <div className="flex flex-row gap-4">
              <div className="flex flex-grow flex-col">
                <Typography variant="medium" weight="medium">
                  Minimum price
                </Typography>
                <FormInput
                  value={assetFilters.minPrice}
                  type={'number'}
                  onChange={({ target: { value: minPrice } }) =>
                    setAssetFilters({
                      ...assetFilters,
                      minPrice
                    })
                  }
                  placeholder="0 ETH"
                />
              </div>
              <div className="flex flex-grow flex-col">
                <Typography variant="medium" weight="medium">
                  Maximum price
                </Typography>
                <FormInput
                  value={assetFilters.maxPrice}
                  type={'number'}
                  onChange={({ target: { value: maxPrice } }) =>
                    setAssetFilters({
                      ...assetFilters,
                      maxPrice
                    })
                  }
                  placeholder="100 ETH"
                />
              </div>
            </div>
            <div>
              <Typography variant="medium" weight="medium">
                Minimum curator fee
              </Typography>
              <FormInput
                value={assetFilters.minCuratorFee}
                type={'number'}
                onChange={({ target: { value: minCuratorFee } }) =>
                  setAssetFilters({
                    ...assetFilters,
                    minCuratorFee
                  })
                }
                placeholder={'6.9'}
                postfixContent="%"
              />
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <Modal
      open
      onClose={onClose}
      maxWidth={720}
      innerContainerClassName="h-fit"
    >
      <ModalTitleRow onClose={onClose} title="Filters" />
      {showOpenCallsSection &&
        !isEmptyArray(contests) &&
        renderOpenCallsSection()}
      {showWinnersSection && renderWinnersSection()}
      {showShortlistedSection && renderShortlistedSection()}
      {showOnlyListedSection && renderOnlyListedSection()}
      {showOnlySpaceAssets && renderOnlySpaceAssetsSection()}

      <Divider size="small" />

      <div className="flex w-full items-center justify-between">
        <Button
          color="black"
          variant="underline"
          onClick={handleClearFilters}
          id="clear-all-filter-assets"
        >
          Clear all
        </Button>
        <Button color="black" onClick={handleFilterAssets} id="filter-assets">
          Filter assets
        </Button>
      </div>
    </Modal>
  );
};
