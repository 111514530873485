import React from 'react';
import foundationLogo from '/images/foundation-logo.svg';
import { Image } from '/design-systems/Atoms/Image';
import { joynSupportedProtocols } from '/utils/constants';

const logos = {
	[joynSupportedProtocols.FOUNDATION]: foundationLogo,
	// add other protocols here
}

const logoTitles = {
	[joynSupportedProtocols.FOUNDATION]: 'This transaction is facilitated by Foundation protocol.',
	// add other protocols here
}

export const ProtocolLogo = ({ protocol, width, height }) => (
	<Image
		src={logos[protocol].src}
		style={{ width, height, objectFit: 'contain' }}
		staticFile={true}
		title={logoTitles[protocol]}
	/>
);

