import React from 'react';
import { BLOCKCHAINS, CHAIN_ICONS } from '/utils/constants';

import { Image } from '../Image';

export const NetworkIcon = ({
  chain = BLOCKCHAINS.ETHEREUM,
  className = ''
}) => (
  <Image src={CHAIN_ICONS[chain]} staticFile={true} className={className} />
);
