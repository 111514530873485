import { getTitleAndSlugFromUrl, isNumber } from './url';
import { parseLastNumInStr } from './regex';
import { BLOCKCHAINS } from './constants';
import { objktURLValidation } from './regex';
import { emptyArray } from './object';

export const updateOrAddToArray = (values, payload) => {
  const index = values.findIndex((value) => value.id === payload.id);
  if (index === -1) {
    return [{ ...payload }, ...values];
  }

  return values.map((value, idx) =>
    idx === index ? { ...payload } : { ...value }
  );
};

export const getImageURLFromS3 = (files) =>
  !files || !files.length ? '' : files[0].fileUrl;

export const replaceBetween = (str, fromIndex, toIndex, newStr) => {
  return `${str.substring(0, fromIndex)}${newStr}${str.substring(toIndex)}`;
};

export const removeLineBreakFromCode = (value) => {
  const fromIndex = value.indexOf('```');
  if (fromIndex === -1) return value;
  const toIndex = value.indexOf('```', fromIndex + 3);
  const newStr = value
    .substring(fromIndex + 4, toIndex - 1)
    .replaceAll('\n &nbsp;', '\n');

  return `${value.substring(0, fromIndex + 3)}${newStr}${value.substring(
    toIndex
  )}`;
};

export const getDetailByIdOrSlug = ({ id, getDetailById, getDetailBySlug }) => {
  if (isNumber(id)) {
    return getDetailById({ id });
  }
  const { title, slug } = getTitleAndSlugFromUrl(id);
  return getDetailBySlug({ title, slug });
};

export const submissionVisibilityOptions = {
  ANYONE: 'Anyone',
  ONLY_THE_PRODUCERS: 'Only the producers'
};

export const retrieveMainWalletAddressFromWalletAddresses = (
  walletAddresses = []
) => {
  return walletAddresses?.find((walletAddress) => walletAddress.isMain) || {};
};

export const parseChainFromLink = ({ link }) => {
  if (objktURLValidation(link)) {
    return BLOCKCHAINS.TEZOS;
  }

  if (link.includes('optimism')) {
    return BLOCKCHAINS.OPTIMISM;
  }

  return BLOCKCHAINS.ETHEREUM;
};

export const parseNftTokenFromLink = ({ link = '' }) => {
  if (link.includes('rarible')) {
    return parseRaribleTokenFromLink({ link });
  }

  const splittedLink = link.split('/');
  const contractAddress = splittedLink.at(-2);

  return {
    chain: parseChainFromLink({ link }),
    tokenId: parseLastNumInStr(splittedLink.at(-1)),
    contractAddress
  };
};

export const parseRaribleTokenFromLink = ({ link = '' }) => {
  const contractAddress = link.split('/').at(-1).split(':').at(-2);
  const tokenId = link.split(':').at(-1);

  return {
    chain: parseChainFromLink({ link }),
    tokenId,
    contractAddress
  };
};

export const sleep = (seconds) =>
  new Promise((resolve) => setTimeout(resolve, 1000 * seconds));

export const falseIfEmpty = (arr) => {
  if (arr) {
    return emptyArray(arr) ? false : arr;
  }
  return false;
};
