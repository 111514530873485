import React from 'react';
import { Button } from '/design-systems/Atoms/Button';

export const AcceptOffer = ({
  label = 'Accept Offer',
  className,
  onAcceptOffer,
  color = 'primary',
  variant = 'secondary',
  disabled = false
}) => (
  <Button
    id="accept-offer-button"
    variant={variant}
    color={color}
    blocked
    className={className}
    onClick={onAcceptOffer}
    disabled={disabled}
  >
    {label}
  </Button>
);
