import React from 'react';
import {
  Node,
  mergeAttributes,
  ReactNodeViewRenderer,
  NodeViewWrapper
} from '@tiptap/react';

import { Image } from '/design-systems/Atoms/Image';

const VIDEO_NODE_DOM = 'editor-video';

export const VideoNode = Node.create({
  name: 'editorVideo',
  group: 'block',
  selectable: true,
  draggable: true,
  atom: true,
  addAttributes() {
    return {
      src: {
        default: null
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: VIDEO_NODE_DOM
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [VIDEO_NODE_DOM, mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(({ node }) => (
      <NodeViewWrapper className={VIDEO_NODE_DOM}>
        <Image
          alt="editor-video"
          src={node.attrs.src}
          fileType="video/mp4"
          staticFile={true}
          className="h-auto w-full object-contain"
          resolution="l"
          isVideo={true}
          videoControls={true}
        />
      </NodeViewWrapper>
    ));
  }
});

export const getEditorVideoElement = ({ src }) => {
  return `<${VIDEO_NODE_DOM} src="${src}"></${VIDEO_NODE_DOM}>`;
};
