import React, { useCallback, useEffect, useMemo } from 'react';
import pluralize from 'pluralize';

import { Divider } from '/design-systems/Atoms/Divider';
import { DropDownInput } from '/design-systems/Atoms/DropDownInput';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Button } from '/design-systems/Atoms/Button';

import { SubmissionList, SubmissionListItem } from './SubmissionList';
import { Loader } from './Loader';

import { useShallowState } from '/hooks/shallowState';

import {
  DRAW_STATUSES,
  PROMPT_REWARD_TYPES,
  WINNER_SELECTION_TYPES
} from '/utils/constants';
import { emptyArray } from '/utils/object';
import { getDraw } from '/services/joynApi/producers/draw';
import { promptRewardTitle } from '/utils/prompt-reward';
import { XIcon } from '@heroicons/react/outline';
import { useSubmissions } from '/hooks/useSubmissions';
import { QUERY_KEYS } from '/utils/queries';
import { getPromptSubmissions } from '/services/joynApi/users/prompt-submission';
import {
  formatPromptSubmissionsData,
  parsePromptSubmissionsData
} from '/utils/formatter';

export const SelectPromptWinnersModal = ({
  open,
  onClose,
  promptId,
  promptRewards,
  onConfirmWinners,
  rewardId
}) => {
  const [state, setState] = useShallowState({
    reward: rewardId || '',
    unit: '',
    error: false,
    confirming: false
  });
  const [query, setQuery] = useShallowState({
    page: 1,
    promptId,
    onlyShortlisted: false
  });
  const [
    selectedSubmissions,
    setSelectedSubmissions,
    { clearState: clearSelectSubmissions }
  ] = useShallowState({});

  const submissionsQueryKeys = [
    'winner-submissions',
    promptId,
    query.onlyShortlisted
  ];
  const {
    submissions,
    hasMoreSubmissions,
    totalSubmissions: submissionsCount,
    isLoadingSubmissions,
    isFetchingNextSubmissions,
    fetchMoreSubmissions,
    onSetSubmissions: setSubmissions
  } = useSubmissions(
    query,
    submissionsQueryKeys,
    getPromptSubmissions,
    formatPromptSubmissionsData,
    parsePromptSubmissionsData,
    Boolean(promptId)
  );

  const [draw, setDraw] = useShallowState({
    loadingDraw: false,
    submissions: []
  });

  const rewards = useMemo(
    () =>
      (
        promptRewards?.filter(
          (reward) => reward.rewardTypeId !== PROMPT_REWARD_TYPES.pop
        ) || []
      ).map((reward) => ({
        title: promptRewardTitle({ ...reward, withETHTitle: true }),
        value: reward.id
      })),
    [promptRewards]
  );

  const selectedSubmissionsCount = useMemo(
    () =>
      Object.keys(selectedSubmissions).filter((id) => !!selectedSubmissions[id])
        .length,
    [selectedSubmissions]
  );

  const winnerSubmissions = useMemo(() => {
    return (
      submissions?.filter(
        (submission) =>
          !emptyArray(submission?.PromptSubmissionApprovals) &&
          !!submission.PromptSubmissionApprovals.filter(
            ({ promptRewardId }) => promptRewardId === state.reward
          )?.length
      ) || []
    );
  }, [submissions, state.reward]);

  const {
    winnerSelectionTypeId,
    unit,
    Draws: rewardDraws
  } = useMemo(
    () => promptRewards?.find((r) => r.id === state.reward) || {},
    [state.reward, promptRewards]
  );

  const hasConfirmedDraw = useMemo(
    () =>
      !emptyArray(
        rewardDraws?.filter((draw) => draw.status === DRAW_STATUSES.confirmed)
      ),
    [rewardDraws]
  );

  const handleSelectSubmission = (submissionId) => {
    setSelectedSubmissions((s) => ({ [submissionId]: !s?.[submissionId] }));
  };

  const handleConfirmWinners = async () => {
    const error = validate();
    setState({ error });
    if (error) return;

    setState({ confirming: true });
    try {
      if (winnerSelectionTypeId === WINNER_SELECTION_TYPES.byTeam) {
        // Get selected submission Ids
        const submissionIds = Object.keys(selectedSubmissions)
          .filter((id) => !!selectedSubmissions[id])
          .map((id) => +id);

        // Get ids of deselected submissions that were selected as winners before
        const deletedSubmissionApprovalIds = winnerSubmissions
          .filter((submission) => !submissionIds.includes(submission.id))
          .map(
            (submission) =>
              submission.PromptSubmissionApprovals.find(
                (approval) => approval.promptRewardId === state.reward
              ).id
          );

        const preSelectedSubmissionIds = winnerSubmissions.map(({ id }) => id);

        await onConfirmWinners({
          submissionIds: submissionIds.filter(
            (submissionId) => !preSelectedSubmissionIds.includes(submissionId)
          ),
          deletedSubmissionApprovalIds,
          reward: state.reward,
          isConfirmed: true
        });
      } else {
        await onConfirmWinners({
          unit: submissionsCount > unit ? unit : submissionsCount,
          reward: state.reward,
          isConfirmed: false
        });
      }
    } catch (error) {
      console.error('Confirming winners error: ', error);
    }
    setState({ confirming: false });
  };

  const handleNextPage = () => {
    if (!hasMoreSubmissions) return;
    setQuery((p) => ({ page: p.page + 1 }));
  };

  const handleChangeFilters = (values) => {
    setSubmissions([]);
    setQuery({ ...values, page: 1 });
  };

  const handleChangeReward = (reward) => {
    clearSelectSubmissions();
    setState({ reward, error: '' });
  };

  const validate = () => {
    if (winnerSelectionTypeId === WINNER_SELECTION_TYPES.byTeam) {
      if (!selectedSubmissionsCount && emptyArray(winnerSubmissions)) {
        return 'Select submissions to select as winners';
      }

      if (selectedSubmissionsCount > unit) {
        return `Number of selected submissions can't be greater than reward amount - (${unit})`;
      }
    }

    return false;
  };

  const getDrawData = useCallback(
    async (id) => {
      setDraw({ loadingDraw: true });

      let drawSubmissions = [];
      try {
        const {
          data: { data }
        } = await getDraw({ id });
        drawSubmissions = data.DrawWinners.map(
          ({ PromptSubmission }) => PromptSubmission
        );
      } catch (error) {
        console.error('Get draw info error: ', error);
      }

      setDraw({ loadingDraw: false, submissions: drawSubmissions });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      /* Keep selected submissions state after pagination */
      setSelectedSubmissions((values) =>
        winnerSubmissions
          .filter((submission) => values[submission.id] === undefined)
          .reduce(
            (selected, submission) => ({
              ...selected,
              [submission.id]: true
            }),
            values
          )
      );
    },
    [winnerSubmissions] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      if (!hasConfirmedDraw) return;
      const drawId = rewardDraws.find(
        (draw) => draw.status === DRAW_STATUSES.confirmed
      ).id;
      getDrawData(drawId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasConfirmedDraw, rewardDraws]
  );

  return (
    <Modal
      autoHeight
      maxWidth={state.reward ? 1120 : 720}
      open={open}
      onClose={onClose}
    >
      <div className="item-center relative flex w-full justify-center">
        <div
          className="absolute left-0 flex h-full cursor-pointer items-center bg-white/60"
          onClick={onClose}
        >
          <XIcon className="h-5 w-5" />
        </div>
        <Typography variant="large" weight="semibold" align="center">
          Select winners
        </Typography>
      </div>
      <Divider size="small" />
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col gap-y-2">
          <Typography weight="medium" variant="medium" className="">
            Reward tier
          </Typography>

          <Typography className="" colorVariant="secondary">
            Which reward do you want to select winners for?
          </Typography>

          <DropDownInput
            id="select-winner-by-team"
            placeholder="Select reward"
            options={rewards}
            value={state.reward}
            onChange={handleChangeReward}
          />
        </div>

        <Divider size="smallest" />

        {winnerSelectionTypeId === WINNER_SELECTION_TYPES.byTeam && (
          <>
            <SubmissionList
              submissions={submissions}
              selectedSubmissions={selectedSubmissions}
              selectedSubmissionsCount={selectedSubmissionsCount}
              unit={unit}
              onlyShortlisted={query.onlyShortlisted}
              onSelectSubmission={handleSelectSubmission}
              onNextPage={() =>
                !isLoadingSubmissions &&
                !isFetchingNextSubmissions &&
                hasMoreSubmissions &&
                fetchMoreSubmissions()
              }
              onChangeFilters={handleChangeFilters}
            />
            {(isLoadingSubmissions || isFetchingNextSubmissions) && (
              <Loader>Loading more submissions...</Loader>
            )}
          </>
        )}

        {winnerSelectionTypeId === WINNER_SELECTION_TYPES.randomly &&
          (hasConfirmedDraw ? (
            <div className="flex items-center gap-2">
              <Typography className="mb-4">
                This reward was already confirmed.
              </Typography>
            </div>
          ) : (
            <Typography className="mb-4">
              {unit} {pluralize('winner', parseInt(unit))} will be selected
              randomly.
            </Typography>
          ))}

        {hasConfirmedDraw && (
          <div className="mb-4 max-h-[300px] overflow-y-auto overflow-x-hidden">
            {draw.loadingDraw ? (
              <Loader>Loading draw data...</Loader>
            ) : (
              draw.submissions.map((submission) => (
                <SubmissionListItem
                  key={submission.id}
                  submission={submission}
                />
              ))
            )}
          </div>
        )}

        {state.error && (
          <Typography color="#ef4444" variant="xsmall" className="mb-4">
            {state.error}
          </Typography>
        )}

        {state.reward && (
          <Typography variant="medium" colorVariant="secondary">
            Winners will be displayed publicly once the open call ends.
          </Typography>
        )}

        {!hasConfirmedDraw && (
          <div className="flex w-full items-center justify-between">
            <Button color="black" variant="underline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              id="select-winner-confirm-button"
              color="black"
              variant="primary"
              disabled={!state.reward || state.confirming}
              loading={state.confirming}
              onClick={handleConfirmWinners}
            >
              {winnerSelectionTypeId === WINNER_SELECTION_TYPES.randomly
                ? 'Draw'
                : 'Save winners'}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
