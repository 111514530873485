import { useMemo } from 'react';
import { useAccount, useBalance, useNetwork } from 'wagmi';

import { ZeroAddress } from 'ethers';
import { adjustDecimals } from '/utils/string';

export const useTokenBalances = () => {
  const { address } = useAccount();
  const { chain } = useNetwork();

  const { id: chainId } = chain ?? {};

  const {
    isLoading: isLoadingBalance,
    data: nativeCurrencyBalanceData,
    refetch: refetchNativeCurrencyBalance
  } = useBalance({
    address,
    chainId,
    enabled: address && chainId,
    watch: [address, chainId]
  });

  const nativeCurrencyBalance = useMemo(
    () => ({
      ...nativeCurrencyBalanceData,
      formatted: adjustDecimals(nativeCurrencyBalanceData?.formatted, 6),
      tokenAddress: ZeroAddress
    }),
    [nativeCurrencyBalanceData]
  );

  return {
    // ETH Balance data
    isLoadingBalance,
    nativeCurrencyBalance,
    refetchNativeCurrencyBalance
  };
};
