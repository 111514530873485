import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHourglass,
  faHandshake,
  faXmark
} from '@fortawesome/pro-regular-svg-icons';
import { Button } from '/design-systems/Atoms/Button';
import { toast } from 'react-toastify';
import { Modal } from '/design-systems/Atoms/Modal';
import { FieldLabel } from '/design-systems/Atoms/FieldLabel';
import { Typography } from '/design-systems/Atoms/Typography';
import { FormInput } from '/design-systems/Atoms/FormInput';
import { ModalTitleRow } from '/design-systems/Molecules/Modals/ModalTitleRow';
import { MiniAssetCard } from '/design-systems/Molecules/Cards/MiniAssetCard';
import {
  createGalleryCollabRequest,
  deleteGalleryCollabRequest
} from '/services/joynApi/users/gallery-collab-request';
import { UserInfo } from '/design-systems/Molecules/Cards/PromptSubmissionCard/UserInfo';
import { Divider } from '/design-systems/Atoms/Divider';
import { ToggleButton } from '/design-systems/Atoms/ToggleButton';
import pluralize from 'pluralize';
import {
  getContributorImage,
  getContributorName
} from '/utils/prompt-submission';
import { getGalleryLink } from '/utils/gallery';
import { PillBadge } from '/design-systems/Atoms/PillBadge';
import { capitalizeFirstLetter } from '/utils/string';
import { DropDown } from '/design-systems/Atoms/DropDown';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { SearchBar } from '/design-systems/Atoms/SearchBar';
import { TokenOwners } from '/design-systems/Molecules/TokenOwners';
import { SETTINGS_TAB_IDS } from '/design-systems/Organisms/GalleryFormPage/GallerySettingsTab';
import { getAssetOwners } from '/services/joynApi/users/assets';
import { fromBasisPointToPercentage } from '/utils/number';
import { emptyArray } from '/utils/object';

import { useRouter } from 'next/router';
import { useGallery } from '/hooks/useGallery';
import { useGalleryDetails } from '/hooks/gallery/useGalleryDetails';
import { ROUTES } from '/config/routes';

const STEPS = {
  VIEW_REQUESTS: 1,
  NEW_REQUEST: 2,
  SELECT_RECIPIENTS: 3
};

const MODAL_TITLES = {
  [STEPS.VIEW_REQUESTS]: 'Curator fee',
  [STEPS.NEW_REQUEST]: 'New listing request',
  [STEPS.SELECT_RECIPIENTS]: 'Select recipients'
};

const REQUEST_STATUSES = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected'
};

const REQUEST_STATUSES_ICONS = {
  [REQUEST_STATUSES.PENDING]: faHourglass,
  [REQUEST_STATUSES.ACCEPTED]: faHandshake,
  [REQUEST_STATUSES.REJECTED]: faXmark
};

const SUBMIT_BUTTON_IDS = {
  [STEPS.NEW_REQUEST]: 'create-collab-request-btn',
  [STEPS.SELECT_RECIPIENTS]: 'send-collab-request-btn'
};

export const GalleryCollabRequestModal = ({
  open,
  onClose,
  tokenId,
  galleryId,
  asset,
  feePercentage
}) => {
  const router = useRouter();
  const { creator } = asset;
  const [step, setStep] = useState(STEPS.VIEW_REQUESTS);
  const [galleryCollabRequests, setGalleryCollabRequests] = useState(
    asset.galleryCollabRequests
  );
  const [tokenOwners, setTokenOwners] = useState(asset.tokenOwners || []);

  const { gallery } = useGallery({ id: galleryId });
  const { canSendRequests } = useGalleryDetails({ gallery });

  const handleSubmit = useCallback(
    async ({ walletAddress, fee, open, tokenOwnerIds }, { setSubmitting }) => {
      if (step === STEPS.NEW_REQUEST) {
        setStep(STEPS.SELECT_RECIPIENTS);
      } else if (step === STEPS.SELECT_RECIPIENTS) {
        setSubmitting(true);
        const feeInBasisPoint = Number(fee) * 100;

        try {
          const res = await createGalleryCollabRequest({
            galleryId,
            fee: feeInBasisPoint,
            assetId: asset.id,
            walletAddress,
            open,
            tokenOwnerIds
          });
          setGalleryCollabRequests(
            galleryCollabRequests.push(...res.data.data)
          );

          toast.success('Your request has been sent successfully.');
          onClose();
        } catch (error) {
          toast(error?.reason || error?.message, { type: 'error' });
        } finally {
          setSubmitting(false);
        }
      }
    },
    [tokenId, step, asset]
  );

  const onBackArrowClick = useCallback(() => {
    if (step === STEPS.SELECT_RECIPIENTS) {
      setStep(STEPS.NEW_REQUEST);
    } else if (step === STEPS.NEW_REQUEST) {
      setStep(STEPS.VIEW_REQUESTS);
    }
  });

  const handleMenuItemClick = async ({ action, galleryCollabRequestId }) => {
    switch (action) {
      case 'Delete':
        await deleteGalleryCollabRequest({ galleryCollabRequestId, galleryId });

        let index = galleryCollabRequests.findIndex(
          (element) => Number(element.id) === Number(galleryCollabRequestId)
        );
        if (index > -1) {
          // Splice before filtering is needed.
          // Splice() mutate the array coming from the parent component.
          galleryCollabRequests.splice(index, 1);
          // filter() filters out the deleted element and allows to update state
          setGalleryCollabRequests(
            galleryCollabRequests.filter(
              (galleryCollabRequest) =>
                Number(galleryCollabRequest.id) !==
                Number(galleryCollabRequestId)
            )
          );
        }
        toast.success('Request deleted successfully.');
        return;
      default:
        return;
    }
  };

  const handleTokenOwnerSubmit = async (queryString) => {
    await fetchTokenOwners({ assetId: asset.id, search: queryString });
  };

  const fetchTokenOwners = async ({ assetId, search }) => {
    const res = await getAssetOwners({ assetId, search });
    setTokenOwners(res.data.data);
  };

  const primaryCtaLabel = useCallback(
    ({ isSubmitting, values }) => {
      if (step === STEPS.NEW_REQUEST) {
        return 'Next';
      }

      return isSubmitting
        ? 'Sending...'
        : `Send ${
            values.tokenOwnerIds.length > 1 ? values.tokenOwnerIds.length : ''
          } ${pluralize('request', values.tokenOwnerIds.length)}`;
    },
    [step]
  );

  useEffect(async () => {
    if (step === STEPS.SELECT_RECIPIENTS) {
      await fetchTokenOwners({ assetId: asset.id });
    }
  }, [step]);

  return (
    <Modal
      id="gallery-collab-request-modal"
      open={open}
      showCloseIconOnTop={false}
      padding="p-6"
      onClose={onClose}
      innerContainerClassName="h-fit"
      isTop={true}
    >
      <div className="flex flex-col items-center gap-y-6">
        <ModalTitleRow
          title={MODAL_TITLES[step]}
          onClose={onClose}
          showBack={[STEPS.NEW_REQUEST, STEPS.SELECT_RECIPIENTS].includes(step)}
          onBackClick={onBackArrowClick}
        />
        <Divider size="smallest" />
        {[STEPS.VIEW_REQUESTS, STEPS.NEW_REQUEST].includes(step) && (
          <>
            <div className="flex w-full items-center">
              <MiniAssetCard
                title={asset?.title}
                files={asset?.files}
                titleSize="h7"
                avatar={creator.image.src}
                username={creator?.name}
                imageSize="100px"
              />
            </div>
            <>
              {(!!feePercentage || feePercentage === 0) && (
                <>
                  <div className="flex w-full items-center justify-between">
                    <div className="flex flex-col gap-2">
                      <FieldLabel
                        label="Curator fee"
                        tooltipLabel="If the sale takes place in your Showcase, this is the fee you will receive."
                      />
                      <Typography variant="small" colorVariant="secondary">
                        The current fee that has been approved by the owner.
                      </Typography>
                    </div>

                    <div>
                      <Typography weight="semibold" variant="medium">
                        {feePercentage}%
                      </Typography>
                    </div>
                  </div>

                  <Divider size="smallest" />
                </>
              )}
              {step === STEPS.VIEW_REQUESTS && (
                <>
                  {!emptyArray(galleryCollabRequests) && (
                    <div className="w-full">
                      <Typography variant="large" weight="semibold">
                        Your listing requests
                      </Typography>
                      {galleryCollabRequests?.map((galleryCollabRequest) => {
                        return (
                          <div
                            className="gallery-collab-request my-4 flex w-full items-center justify-between gap-2"
                            data-collab-request-id={galleryCollabRequest?.id}
                          >
                            <UserInfo
                              avatarSize={32}
                              img={getContributorImage(galleryCollabRequest)}
                              name={getContributorName(galleryCollabRequest)}
                              description={`${fromBasisPointToPercentage(
                                galleryCollabRequest?.fee
                              )}% · ${
                                galleryCollabRequest?.open ? 'Open' : 'Private'
                              }`}
                            />
                            <div className="align-items flex">
                              <div className="collab-request-status mr-4 w-24">
                                <PillBadge
                                  variant="outlined"
                                  className
                                  text={capitalizeFirstLetter(
                                    galleryCollabRequest
                                      ?.GalleryCollabRequestStatuses?.[0]
                                      ?.status || REQUEST_STATUSES.PENDING
                                  )}
                                  icon={
                                    <FontAwesomeIcon
                                      icon={
                                        REQUEST_STATUSES_ICONS[
                                          galleryCollabRequest
                                            ?.GalleryCollabRequestStatuses?.[0]
                                            ?.status || REQUEST_STATUSES.PENDING
                                        ]
                                      }
                                      className="h-3 w-3"
                                    />
                                  }
                                />
                              </div>
                              <DropDown
                                items={['Delete']}
                                label={
                                  <Button
                                    variant="icon-only"
                                    color="neutral"
                                    iconOnly
                                    rounded
                                    className="edit-collab-request-icon relative"
                                  >
                                    <DotsVerticalIcon className="h-[20px] min-h-[20px] w-[20px] min-w-[20px]" />
                                  </Button>
                                }
                                onDropDownClick={(action) =>
                                  handleMenuItemClick({
                                    action,
                                    galleryCollabRequestId:
                                      galleryCollabRequest.id,
                                    galleryId
                                  })
                                }
                                hideChevron={true}
                                px="0"
                                py="0"
                                top="9"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {canSendRequests ? (
                    <div className="flex w-full flex-col gap-4">
                      {galleryCollabRequests?.length === 0 && (
                        <Typography variant="medium">
                          Want to propose a curator fee? Send a listing request
                          to the owner.
                        </Typography>
                      )}

                      <Button
                        variant="secondary"
                        id="new-listing-request-btn"
                        onClick={() => setStep(STEPS.NEW_REQUEST)}
                        className="w-fit"
                      >
                        New listing request
                      </Button>
                    </div>
                  ) : (
                    <div className="flex w-full flex-col gap-4">
                      <Typography variant="medium">
                        Before you can send a listing request, you need to first
                        set the curator fee recipients.
                      </Typography>

                      <Button
                        variant="secondary"
                        id="set-recipients-btn"
                        onClick={() => {
                          router.push({
                            pathname: ROUTES[gallery.galleryType].edit(
                              getGalleryLink(gallery)
                            ),
                            query: {
                              ...router.query,
                              tab: SETTINGS_TAB_IDS.EARNINGS
                            }
                          });

                          onClose();
                        }}
                        className="w-fit"
                      >
                        Set recipients
                      </Button>
                    </div>
                  )}
                </>
              )}
            </>
          </>
        )}
        {[STEPS.NEW_REQUEST, STEPS.SELECT_RECIPIENTS].includes(step) && (
          <Formik
            enableReinitialize
            initialValues={{ open: true, fee: null, tokenOwnerIds: [] }}
            validate={({ fee, tokenOwnerIds }) => {
              const errors = {};
              const regex = /^\d+(\.\d{1,2})?$/;

              if (step === STEPS.NEW_REQUEST) {
                if (!fee && fee !== 0) {
                  errors.fee = 'Curator fee is a required field';
                } else if (fee < 0 || fee > 100) {
                  errors.fee =
                    'Please specify a curator fee within the range of 0% to 100%';
                } else if (!String(fee).match(regex)) {
                  errors.fee = 'Curator fee must be up to two decimal';
                }
              }

              if (step === STEPS.SELECT_RECIPIENTS) {
                if (tokenOwnerIds.length === 0) {
                  errors.tokenOwnerIds = 'At least one recipient is required';
                }
              }

              return errors;
            }}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              setValues,
              isSubmitting
            }) => (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
                className="flex w-full flex-col gap-4"
              >
                {step === STEPS.NEW_REQUEST && (
                  <>
                    <div className="flex w-full flex-col gap-2">
                      <FieldLabel
                        label="Proposed curator fee"
                        tooltipLabel="Propose a new curator fee % for this piece."
                      />
                      <div className="align-center flex">
                        <FormInput
                          name="fee"
                          type={'number'}
                          value={values.fee}
                          error={errors.fee}
                          touched
                          placeholder="6.9"
                          onChange={handleChange}
                          postfixContent="%"
                        />
                      </div>
                      <Typography variant="small" colorVariant="secondary">
                        Until the request is accepted, the existing listing will
                        be displayed in the Showcase.
                      </Typography>
                    </div>
                    <div>
                      <div className="flex w-full justify-between gap-2">
                        <Typography variant="medium" weight="medium">
                          Private listing
                        </Typography>
                        <ToggleButton
                          id="private-collab-request-toggle-btn"
                          label=""
                          checked={!values.open}
                          onChange={() => {
                            handleChange({
                              target: {
                                name: 'open',
                                value: !values.open
                              }
                            });
                          }}
                        />
                      </div>
                      <Typography variant="small" colorVariant="secondary">
                        Restrict the proposed fee only to this Showcase.
                      </Typography>
                    </div>
                  </>
                )}

                {step === STEPS.SELECT_RECIPIENTS && (
                  <div className="flex w-full flex-col gap-2">
                    <div className="align-center flex flex-col gap-6">
                      <SearchBar
                        placeholder="Ethereum address, username, E.g. 0x..."
                        id="collab-request-recipients-search-bar"
                        showQuery={true}
                        onSubmit={handleTokenOwnerSubmit}
                        variant="nav"
                      />
                      <TokenOwners
                        tokenOwners={tokenOwners}
                        selectionEnabled
                        checkBoxName="tokenOwnerIds"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}

                <div className="align-items mt-4 flex justify-between">
                  <Button
                    id="cancel-collab-request-btn"
                    type="button"
                    variant="underline"
                    color="black"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    submit
                    id={SUBMIT_BUTTON_IDS[step]}
                    color="black"
                    variant="primary"
                    disabled={
                      isSubmitting ||
                      (step === STEPS.SELECT_RECIPIENTS &&
                        values.tokenOwnerIds.length === 0)
                    }
                    onClick={handleSubmit}
                  >
                    {primaryCtaLabel({ isSubmitting, values })}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};
