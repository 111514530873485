import '../styles/globals.css';
import React, { useEffect } from 'react';

import { Provider, useDispatch } from 'react-redux';
import * as FullStory from '@fullstory/browser';
import { ToastContainer } from 'react-toastify';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import store from '/redux/store';
import { setCurrent } from '/redux/slices/userSlice';
import { Layout } from '/design-systems/Organisms/Layout';
import { MetaTags } from '/design-systems/Organisms/MetaTags';
import { Providers } from '/context/Providers';
import { getUserProfile } from '/services/joynApi/users/user';
import { isUserLoggedIn } from '/utils/auth';
import { isProduction } from '/utils/environment';

import '/assets/scss/styles.scss';
import '/assets/scss/tooltip.css';
import 'react-virtualized/styles.css';
import 'swiper/css';

/**
 * @reference https://github.com/vercel/next.js/issues/8592
 */
function AppWrapper({ Component, pageProps, err }) {
  useEffect(() => {
    if (!isProduction()) {
      return;
    }
    FullStory.init({ orgId: process.env.FULLSTORY_ORG_ID });
  }, []);

  return (
    <Provider store={store}>
      <Providers>
        {pageProps?.metadata && <MetaTags metadata={pageProps.metadata} />}
        <Layout>
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <App Component={Component} pageProps={pageProps} err={err} />
        </Layout>
      </Providers>
    </Provider>
  );
}

function App({ Component, pageProps, err }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isUserLoggedIn()) {
      getUserProfile().then((res) => {
        const user = res.data.data;
        dispatch(setCurrent(user));
      });
    }
  }, []);

  return <Component {...pageProps} err={err} />;
}

const AppPage = withLDProvider({
  clientSideID: process.env.LAUNCHDARKLY_CLIENT_ID
})(AppWrapper);

export default AppPage;
