import { configureStore } from '@reduxjs/toolkit';

import userReducer from './slices/userSlice';
import projectReducer from './slices/projectSlice';
import promptReducer from './slices/promptSlice';
import milestoneSlice from './slices/milestoneSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    project: projectReducer,
    prompt: promptReducer,
    milestone: milestoneSlice,
  },
});
