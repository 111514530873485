import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { formatDateToISOString } from '/utils/date';
import { DropDownInput } from '../DropDownInput';
import { Typography } from '../Typography';
import { FormInput } from '../FormInput';
import { mergeClassNames } from '/utils/string';

/**
 * Dropdown component for selecting timed durations with optional time input.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} [props.className=''] - Additional class names
 * @param {Array} props.durationOptions - Duration options
 * @param {string} [props.defaultDuration] - Default duration value
 * @param {string} props.fieldName - Name for state management
 * @param {string} [props.placeholder='Duration'] - Placeholder for dropdown
 * @param {Object} props.values - Form values object
 * @param {function} props.setValues - Function to update form values
 * @param {function} [props.onBlur] - Event handler for blur
 * @param {function} [props.onChange] - Event handler for change
 * @param {Object} props.touched - Object tracking touched state
 * @param {Object} props.errors - Object holding validation errors
 * @param {boolean} [props.withTimeInputField=false] - Enable time input
 * @returns {JSX.Element} TimedDurationDropdown component
 */

const TimedDurationDropdown = ({
  className = '',
  durationOptions,
  defaultDuration,
  fieldName,
  placeholder = 'Duration',
  values,
  setValues,
  onBlur,
  onChange,
  touched,
  errors,
  withTimeInputField
}) => {
  const formattedDefaultDuration = useMemo(() => {
    if (defaultDuration) {
      return withTimeInputField
        ? formatDateToISOString(defaultDuration, 2)
        : defaultDuration;
    } else return values?.[fieldName];
  }, [defaultDuration, withTimeInputField, fieldName, values]);

  // Set default duration value and clean up on unmount
  useEffect(() => {
    if (defaultDuration) {
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: formattedDefaultDuration
      }));
    }

    return () => {
      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: ''
      }));
    };
  }, [formattedDefaultDuration, fieldName, setValues, defaultDuration]);

  const expirationDate = useMemo(
    () =>
      withTimeInputField
        ? moment(values[fieldName])
        : moment.unix(values[fieldName]),
    [fieldName, values, withTimeInputField]
  );

  const handleDropdownChange = (value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [fieldName]: withTimeInputField ? formatDateToISOString(value, 2) : value
    }));
  };

  return (
    <div
      className={mergeClassNames(
        'flex w-full flex-col justify-start gap-2',
        className
      )}
    >
      <Typography variant="medium" weight="medium">
        Duration
      </Typography>
      <div className="flex items-center justify-between gap-2">
        <div className="flex max-h-[350px] w-full flex-col gap-2">
          <DropDownInput
            id="duration-dropdown"
            placeholder={placeholder}
            options={durationOptions}
            value={
              withTimeInputField ? expirationDate.unix() : values[fieldName]
            }
            onSelect={(options, value) =>
              options.find(
                (option) =>
                  formatDateToISOString(option.value, 2) ===
                  formatDateToISOString(value, 2)
              )
            }
            onChange={handleDropdownChange}
          />
        </div>
        {withTimeInputField && (
          <div className="w-full">
            <FormInput
              name={fieldName}
              type="datetime-local"
              touched={touched[fieldName]}
              value={values[fieldName]}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>
        )}
      </div>
      {errors?.[fieldName] ? (
        <Typography
          id="listing-end-date-error"
          color="#ef4444"
          variant="xsmall"
          gutterBottom
        >
          {errors[fieldName]}
        </Typography>
      ) : (
        <Typography variant="xsmall" colorVariant="secondary">
          Expiration at {expirationDate.format('MM/DD/YYYY, hh:mm A')}
        </Typography>
      )}
    </div>
  );
};

export default TimedDurationDropdown;
