import React from 'react';
import { Typography } from '/design-systems/Atoms/Typography';
import { File } from '/design-systems/Atoms/File';

export const FileList = ({
  images = [],
  uploadingImages,
  uploadingProgresses,
  onRemove,
  errors
}) => {
  return (
    <div className="flex w-full flex-col gap-y-2">
      {!!images?.length && (
        <>
          <Typography
            weight="semi-bold"
            variant="small"
            colorVariant="secondary"
          >
            Uploaded
          </Typography>
          <div className="flex w-full items-start gap-x-4 overflow-x-scroll">
            {images.map((image, index) => (
              <File
                error={errors?.[index]}
                key={index}
                title={image?.fileName}
                uploading={uploadingImages[index]}
                progress={uploadingProgresses[index]}
                onRemove={() => onRemove(index)}
                image={image}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
