import React from 'react';
import { mergeClassNames } from '/utils/string';

export const Card = ({
  id,
  children,
  clickable,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  style = {},
  dropShadow,
  marginBottom,
  rounded,
  noBorder = false
}) => {
  const classNameDefault = mergeClassNames(
    'w-full',
    dropShadow && `drop-shadow-${dropShadow}`,
    rounded ? `rounded-${rounded}` : 'rounded-xl',
    'p-4',
    marginBottom ? `mb-${marginBottom}` : 'mb-4',
    'bg-white dark:bg-neutral-900',
    !noBorder && 'border border-neutral-300 dark:border-neutral-700',
    clickable ? 'cursor-pointer' : '',
    clickable ? 'hover:bg-neutral-50 dark:hover:bg-neutral-800' : ''
  );

  return (
    <div
      id={id}
      className={`${classNameDefault} ${className}`}
      style={style}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
};
