import React from 'react';

import { RewardForm } from '/design-systems/Molecules/Forms/RewardForm';
import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';

export const RewardModal = ({ open, initialFormValues, initialFormFiles, onClose, onSubmit }) => {
  return (
    <Modal title='Reward' maxWidth={582} open={open} autoHeight showCloseIconOnTop onClose={onClose}>
      <Typography weight='semibold' variant='h4' heading align='center'>
        Reward
      </Typography>
      <RewardForm initialFormValues={initialFormValues} initialFormFiles={initialFormFiles} onSubmit={onSubmit} />
    </Modal>
  );
};
