import React from 'react';
import { Button } from '../Button';
import { Typography } from '../Typography';
import { UPLOADING_MESSAGE } from '/utils/constants';

export const FileDrop = ({
  disabled = false,
  onUpload,
  uploadingDone,
  multiple = false,
  ...props
}) => {
  const className = [
    'flex flex-col gap-y-4 items-center justify-center p-[16px] mb-[16px] border-2 rounded-md border-dashed border-neutral-300',
    disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:border-neutral-500'
  ].join(' ');

  const handleUpload = (event) => {
    event.preventDefault();
    onUpload && onUpload(event);
  };

  return (
    <div className={className} {...props}>
      <Typography align="center" variant="small" colorVariant="secondary">
        Drag and drop file{multiple ? 's' : ''}
      </Typography>
      <Typography align="center" variant="small" colorVariant="secondary">
        or
      </Typography>
      <Button
        id="upload-files-button"
        color="primary"
        variant="secondary"
        onClick={handleUpload}
        disabled={!uploadingDone || disabled}
      >
        {uploadingDone
          ? `Upload file${multiple ? 's' : ''}`
          : UPLOADING_MESSAGE}
      </Button>
    </div>
  );
};
