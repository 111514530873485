import React from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import { FullScreenManager } from './FullScreenManager';
import { ModalManager } from './ModalManager';
import { SocketManager } from './SocketManager';
import { ChainManager } from './ChainManager';
import { GlobalManager } from './GlobalManager';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 0
    }
  }
});

export const Providers = ({ children }) => {
  return (
    <ChainManager>
      <QueryClientProvider client={queryClient}>
        <GlobalManager>
          <FullScreenManager>
            <SocketManager>
              <ModalManager>{children}</ModalManager>
            </SocketManager>
          </FullScreenManager>
        </GlobalManager>
      </QueryClientProvider>
    </ChainManager>
  );
};
