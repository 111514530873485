import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { Header } from '/design-systems/Organisms/Header';
import { Footer } from '/design-systems/Organisms/Footer';
import { usePage } from '/hooks/usePage';
import { mergeClassNames } from '/utils/string';

export const Layout = ({ children }) => {
  const currentUser = useSelector((state) => state.user.current);
  const router = useRouter();
  const layoutRef = useRef();
  const [isDarkTheme, setIsDarkTheme] = useState(undefined);

  const {
    isGalleryFormPage,
    isAssetComposePage,
    isDashboardPage,
    showTransparentNav,
    showTransparentNavLight,
    isPreviewPage,
    isManagePromptPage,
    isManageAssetPage,
    isDemoPage,
    isNetworkLandingPage,
    shouldBypassLayout
  } = usePage();

  const navHeightOffsetClassName = useMemo(
    () =>
      !(
        showTransparentNav ||
        showTransparentNavLight ||
        isPreviewPage ||
        isDemoPage ||
        isDashboardPage
      ) && 'mt-[68px]',
    [
      showTransparentNav,
      showTransparentNavLight,
      isPreviewPage,
      isDemoPage,
      isDashboardPage
    ]
  );

  const showCustomHeader = useMemo(
    () =>
      isGalleryFormPage || isAssetComposePage || isDemoPage || isDashboardPage,
    [isGalleryFormPage, isAssetComposePage, isDemoPage, isDashboardPage]
  );

  useEffect(() => {
    layoutRef?.current?.scrollTo(0, 0);
  }, [router]);

  const getUserSetPreference = () => {
    // to re-enable dark mode, uncomment the line below instead
    return 'light';
    // return localStorage.getItem('theme');
  };

  const storeUserSetPreference = (pref) => {
    localStorage.setItem('theme', pref);
  };

  // dark theme on load
  useEffect(() => {
    const userSetPreference = getUserSetPreference();

    if (userSetPreference !== null) {
      setIsDarkTheme(userSetPreference === 'dark');
    }
  }, []);

  useEffect(() => {
    if (isDarkTheme !== undefined) {
      if (isDarkTheme) {
        // root.setAttribute("data-theme", "dark");
        document.documentElement.classList.add('dark');
        storeUserSetPreference('dark');
      } else {
        // root.removeAttribute("data-theme");
        storeUserSetPreference('light');
        document.documentElement.classList.remove('dark');
      }
    }
    // hard code Tyler Hobbs
    else if (window.location.pathname.includes('qql')) {
      storeUserSetPreference('dark');
      setIsDarkTheme('dark');
    }
  }, [isDarkTheme]);

  // identify user for analytics (Segment)
  useEffect(() => {
    if (currentUser) {
      window.analytics &&
        window.analytics.identify(currentUser.id, {
          email: currentUser.email,
          walletAddress: currentUser?.walletAddresses?.[0]?.address,
          twitter: currentUser?.twitter
        });
    }
  }, [currentUser]);

  if (shouldBypassLayout) {
    return <div>{children}</div>;
  }

  return (
    <div
      className={mergeClassNames(
        'relative h-screen w-screen overflow-x-hidden dark:bg-neutral-900',
        (isManageAssetPage || isManagePromptPage) && 'bg-neutral-100'
      )}
    >
      {showCustomHeader || (
        <Header isDarkTheme={isDarkTheme} setIsDarkTheme={setIsDarkTheme} />
      )}

      <div ref={layoutRef} className="flex">
        <div
          className={mergeClassNames(
            'layout-container-body flex h-full flex-col dark:bg-neutral-900 dark:text-neutral-100',
            navHeightOffsetClassName
          )}
        >
          {children}
        </div>
      </div>

      <Footer />
    </div>
  );
};
