import React, { useMemo } from 'react';

import { useRouter } from 'next/router';
import Link from 'next/link';

import { Modal } from '/design-systems/Atoms/Modal';
import { Typography } from '/design-systems/Atoms/Typography';
import { Divider } from '/design-systems/Atoms/Divider';
import { FAQSection } from '/design-systems/Molecules/FAQSection';
import { ModalTitleRow } from '/design-systems/Molecules/Modals/ModalTitleRow';
import { HyperlinkWrapper } from '/design-systems/Organisms/HyperlinkWrapper';

export const ShowcaseIntroModal = ({ open, onClose }) => {
  const faqSections = useMemo(() => {
    return [
      {
        title: 'How do I add my pieces to my Showcase?',
        content: (
          <>
            <Typography>
              Add pieces directly from the ‘Your collection’ tab in the left
              panel. Your collection will show pieces you’ve collected or pieces
              you’ve minted to your wallet.
            </Typography>
            <Typography>
              If a newly minted piece isn’t showing in your collection, use the
              All NFT tab to search using a marketplace link or with the token
              details.
            </Typography>
          </>
        )
      },
      {
        title: 'How do I add artworks from other artists?',
        content: (
          <>
            <Typography>
              Curators can utilize the{' '}
              <HyperlinkWrapper
                url="https://www.joyn.xyz/explore/pieces"
                className="link-underlined"
                newTab
              >
                explore page
              </HyperlinkWrapper>{' '}
              to discover and bookmark pieces on Joyn. They can then easily find
              these bookmarked pieces in the left side panel and drag them into
              the Showcase page.
            </Typography>
            <Typography>
              Curators also have the option to search for a piece on Joyn using
              keywords or by applying filters directly in the left panel.
            </Typography>
            <Typography>
              If a piece is not available on Joyn, curators can use the All NFT
              tab to search for it using a marketplace link or token details.
            </Typography>
          </>
        )
      },
      {
        title: 'How do I configure curator fees?',
        content: (
          <>
            <Typography>
              To begin, navigate to the Earnings tab on the left side navigation
              and add a curator fee recipient. You can add your own wallet or
              multiple wallets if you are curating with others. Specify the
              percentage of curator earnings to be paid to each wallet. If you
              are a solo curator, set the percentage to 100%.
            </Typography>
            <Typography>
              When you add pieces that have an open curator fee, you won't need
              to coordinate with the artists. The curator fees for these pieces
              are already set by the artists, indicating their agreement to pay
              that percentage if you successfully sell the piece. If you wish to
              request a private curator fee, click on the curator fee to edit it
              and send a request.
            </Typography>
            <Typography>
              If a piece does not have a curator fee listed, hover over the
              piece in your showcase and click the "unlisted" button. Artists
              will receive a listing request on their dashboard, where they can
              choose to opt-in to your curator fee and list the piece.
            </Typography>
          </>
        )
      },
      {
        title: 'How do I add text, images or video to my Showcase?',
        content: (
          <Typography>
            To add text to the Showcase page, click the + button. Select the
            text option. Inside the text area, you can write and format your
            content. You also have the option to add images and videos to the
            text area.
          </Typography>
        )
      },

      {
        title: 'How do I schedule my Showcase',
        content: (
          <Typography>
            To schedule your show, click on the "Settings" tab in the left
            navigation. Then, select a start date in the future. This will allow
            collectors to save the date to their calendar. Please note that the
            Showcase content will not be visible to anyone until the start date.
          </Typography>
        )
      },

      {
        title: 'How do I configure the URL for my Showcase?',
        content: (
          <Typography>
            Click the settings tab on the left nav, then add a URL extension.
          </Typography>
        )
      },

      {
        title: 'Who can see my Showcase page?',
        content: (
          <Typography>
            To configure the visibility of your Showcase, click on the settings
            tab in the left navigation. By default, your showcase will be in
            draft mode, meaning only you can view it. If you want to make the
            page visible to anyone with the link, change the visibility to
            unlisted. Unlisted Showcases will not be discoverable on Joyn. When
            you are ready to share your Showcase with collectors, change the
            visibility to published.
          </Typography>
        )
      },

      {
        title: 'How do I turn on dark mode?',
        content: (
          <Typography>
            Click on the "Settings" tab in the left navigation menu, then switch
            the theme from light to dark.
          </Typography>
        )
      },

      {
        title: 'How do I add a banner to my Showcase?',
        content: (
          <Typography>
            When previewing your Showcase, you will see a button labeled 'Edit
            cover image' in the top right section of the page. Banners work best
            at 2000x800px.
          </Typography>
        )
      }
    ];
  }, []);

  return (
    <Modal
      id="art-plus-intro-modal"
      className="relative"
      wrapperClassName="pt-10"
      autoHeight={true}
      open={open}
      onClose={onClose}
      innerContainerClassName="!p-0"
    >
      {/* title row */}
      <div className="px-6 py-4">
        <ModalTitleRow onClose={onClose} title="Introducing Showcase" />
      </div>

      <Divider size="smallest" />

      {/* body */}
      <div className="flex flex-col gap-6 p-6 ">
        <div className="leading-7">
          Showcase is a template for curators and artists to present and sell
          multiple artworks on one page.
        </div>

        <div className="leading-7">
          Easily drag and drop pieces from the left panel to rows on the
          Showcase page. Click the + button on the showcase page to add
          additional rows or text areas.
        </div>

        <div className="flex w-full justify-center">
          <iframe
            src="https://www.youtube.com/embed/QdKhkNRyPws?si=betLMeMpsw3IU36k"
            width="600"
            height="400"
            frameborder="0"
            allowfullscreen=""
            title="My iframe"
          ></iframe>
        </div>
      </div>

      <Divider size="smallest" />

      {/* FAQ */}
      <div className="flex flex-col gap-6 p-6 ">
        <FAQSection sections={faqSections} />
      </div>
    </Modal>
  );
};
