import React from 'react';
import { ROUTES } from '/config/routes';
import { toShortAddress } from '/utils/string';
import { Avatar } from '/design-systems/Atoms/Avatar';
import { HyperlinkWrapper } from '/design-systems/Organisms/HyperlinkWrapper';

export const UsernameAvatarBadge = ({
  avatarUrl,
  username,
  userWalletAddress,
  showWalletAddress = false,
  avatarSize = 50,
  staticImage = false,
  withRedirectProfile = true,
  fontSize
}) => {
  const handleProfileClick = ({ e, profileId }) => {
    e.preventDefault();
    window.open(ROUTES.profile.view({ profileId }), '_blank');
  };

  return (
    <HyperlinkWrapper
      url={ROUTES.profile.view({
        profileId: username || userWalletAddress
      })}
      disabled={!withRedirectProfile}
    >
      <div
        className="flex cursor-pointer flex-row items-center"
        onClick={(e) =>
          withRedirectProfile &&
          handleProfileClick({
            e,
            profileId: username || userWalletAddress
          })
        }
      >
        <Avatar
          resolution="xl"
          rounded
          bordered
          size={avatarSize}
          src={avatarUrl}
          staticFile={staticImage}
        />
        <div className="flex flex-col">
          <div
            className={`mx-2 font-medium ${fontSize} text-neutral-600 dark:text-neutral-400`}
          >
            {username || toShortAddress(userWalletAddress).toLowerCase()}
          </div>
          {showWalletAddress && (
            <div className="mx-2 text-[#374151]">
              <span>
                {userWalletAddress &&
                  `${toShortAddress(userWalletAddress, 5).toLowerCase()}`}
              </span>
            </div>
          )}
        </div>
      </div>
    </HyperlinkWrapper>
  );
};
