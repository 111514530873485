import { useMemo } from 'react';
import dayjs from 'dayjs';

import { useV2Listing } from '/hooks/useV2Listing';
import { useFeatureFlags } from '/hooks/useFeatureFlags';

export const useAssetListings = ({
  galleryId,
  tokenIdForListing,
  tokenOwner,
  currentUser
}) => {
  const { isListingPromoEnabled } = useFeatureFlags();

  const { activeListings, cheapestActiveListing, refetchActiveListings } =
    useV2Listing({
      tokenId: tokenIdForListing,
      galleryId
    });

  const isListed = useMemo(() => !!activeListings?.length, [activeListings]);

  const isAssetAvailableForBuyNow = useMemo(
    () =>
      !!cheapestActiveListing ||
      (isListed &&
        Number(activeListings?.[0]?.userId) !== Number(currentUser?.id)),
    [cheapestActiveListing, isListed, activeListings, currentUser]
  );

  const listingPrice = useMemo(() => {
    return cheapestActiveListing?.priceUnit ?? activeListings?.[0]?.priceUnit;
  }, [activeListings, cheapestActiveListing?.priceUnit]);

  const amountAvailable = useMemo(() => {
    return cheapestActiveListing?.fulfillmentLeft;
  }, [cheapestActiveListing]);

  const isAvailableForListing = useMemo(
    () => tokenOwner?.isOwner,
    [tokenOwner?.isOwner]
  );

  const userHasListings = useMemo(() => {
    return activeListings?.some(
      (listing) => Number(listing.userId) === Number(currentUser.id)
    );
  }, [activeListings, currentUser]);

  // whether we should prompt the user to relist with a fee
  const showRelistWithFee = useMemo(() => {
    const promotedListing = activeListings?.[0];

    if (!promotedListing || !isListingPromoEnabled) {
      return false;
    }

    const releaseDate = '2024-01-15';
    const isBeforeOpenAccess = dayjs(promotedListing?.createdAt).isBefore(
      dayjs(releaseDate)
    );

    const hasNoCuratorFee = promotedListing.curatorFeePercentage == 0;

    return isBeforeOpenAccess && hasNoCuratorFee;
  }, [activeListings, isListingPromoEnabled]);

  return {
    activeListings,
    cheapestActiveListing,
    refetchActiveListings,
    isListed,
    isAssetAvailableForBuyNow,
    isAvailableForListing,
    listingPrice,
    amountAvailable,
    userHasListings,
    showRelistWithFee
  };
};
